import React, { useEffect, useState } from 'react'
import DraggableSection from '../DraggableSection';
import { DndProvider } from 'react-dnd';
import { motion } from "framer-motion";
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ComponentVariantsLists } from '../../../../Utils/template-variants';
import { useDispatch, useSelector } from 'react-redux';
import { actionsCreator } from '../../../../Redux/actions/actionsCreator';

const SectionOverview = ({ handleComponentOptionSelect, setSelectedSectionIdx, selectedSectionIdx }) => {
    const sectionsTree = useSelector(state => state.sectionsList.list);
    const templateConfig = useSelector(state => state.sectionsList.config);
    const currConfig = useSelector(state => state.sectionsList.currConfig)
    const [selectedComponentType, setSelectedComponentType] = useState(null);
    const allSections = templateConfig?.[currConfig]?.sections;
    const dispatch = useDispatch();
    const [sections, setSections] = useState(allSections);

    const getSectionTreeForOption = (idx) => {
        const compType = allSections?.[idx]?.type;
        const layout = allSections?.[idx]?.layout;
        return sectionsTree?.[compType]?.[layout];
    }
    const moveSection = (fromIndex, toIndex) => {
        const updatedSections = [...sections];
        const [movedItem] = updatedSections.splice(fromIndex, 1);
        updatedSections.splice(toIndex, 0, movedItem);
        setSections(updatedSections);
    };
    const handleChangeVariant = (newVariant) => {
        const updatedConfig = { ...templateConfig };
        updatedConfig[currConfig].sections[selectedSectionIdx].variant = newVariant;
        dispatch(actionsCreator.SET_SECTIONS_LIST({
            config: updatedConfig,
        }))
        dispatch(actionsCreator.SET_UPDATE_SECTIONS_LIST({
            config: updatedConfig,
        }))
    }
    //to handle drag and drop changes
    useEffect(() => {
        const updatedConfig = { ...templateConfig };
        updatedConfig[currConfig].sections = [...sections];
        dispatch(actionsCreator.SET_SECTIONS_LIST({
            config: updatedConfig,
        }))
        dispatch(actionsCreator.SET_UPDATE_SECTIONS_LIST({
            config: updatedConfig,
        }))
    }, [sections]);
    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <div className={'optionBody'} style={{ borderRight: selectedSectionIdx != null ? '2px solid gainsboro' : 'none' }}>
                    {allSections?.map((section, idx) => (
                        <DraggableSection
                            key={idx}
                            idx={idx}
                            section={section}
                            moveSection={moveSection}
                            selectedSectionIdx={selectedSectionIdx}
                            handleComponentOptionSelect={handleComponentOptionSelect}
                            setSelectedComponentType={setSelectedComponentType}
                            childSections={getSectionTreeForOption(idx)}
                            setSelectedSectionIdx={setSelectedSectionIdx}
                        />
                    ))}
                </div>
            </DndProvider>
            {selectedComponentType &&
                <motion.div
                    className={'variantsContainer'}
                    initial={{ x: '-20px' }}
                    animate={{ x: 0 }}
                    transition={{ type: 'spring', stiffness: 100, damping: 20 }}
                >
                    <div className={'variantsHeader'}>
                        <span>{`Available Variants`}</span>
                        <button onClick={() => setSelectedComponentType(null)} className='close-variant'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" height={23}>
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                            </svg>

                        </button>
                    </div>
                    <div className={'variantsBody'}>
                        {ComponentVariantsLists?.[selectedComponentType]?.variants?.map((vari, idx) => (
                            <div
                                className={'variant'}
                                key={idx}
                                onClick={() => handleChangeVariant(vari.type)}
                                style={{
                                    border: vari.type == allSections?.[selectedSectionIdx]?.layout && '2px solid blue'
                                }}
                            >
                                <span>{vari.type}</span>
                                <img src={vari.previewImg} alt='preview' className={'previewImg'} />
                            </div>
                        ))}
                    </div>
                </motion.div>
            }
        </>
    )
}

export default SectionOverview