import React, { useEffect, useState } from 'react'
import AnimationType from './AnimationType'
import EventType from './EventType'
import Wrapper from '../Wrapper'
import { useCustomizer } from '../../../../../../Provider/customizerProvider'
export default function Type() {
    const { onChange } = useCustomizer()
    const [event, setEvent] = useState('none')
    const [type, setType] = useState('none')


    useEffect(() => {
        
    }, [event, type])

    return (
        <Wrapper title="Type">
            <EventType event={event} onChange={setEvent}></EventType>
            <AnimationType type={type} onChange={setType}></AnimationType>
        </Wrapper>

    )
}
