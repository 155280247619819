import React, { useEffect, useState } from 'react';
import styles from "../../Design.module.scss";
import { FaLink } from "react-icons/fa6";
import { useCustomizer } from '../../../../../../Provider/customizerProvider';

import { useUtils } from '../../../../../../Hooks/useUtils';
export default function Margin() {
    const { onChange, currentStyles } = useCustomizer();
    const { parseNumber } = useUtils()
    const [sync, toggleSync] = useState(false);
    const [top, setTop] = useState(parseNumber(currentStyles['marginTop']));
    const [right, setRight] = useState(parseNumber(currentStyles['marginRight']));
    const [left, setLeft] = useState(parseNumber(currentStyles['marginLeft']));
    const [bottom, setBottom] = useState(parseNumber(currentStyles['marginBottom']));


    useEffect(() => {
        onChange({ 'margin-top': `${top}px` });
    }, [top, onChange]);

    useEffect(() => {
        onChange({ 'margin-right': `${right}px` });
    }, [right, onChange]);

    useEffect(() => {
        onChange({ 'margin-bottom': `${bottom}px` });
    }, [bottom, onChange]);

    useEffect(() => {
        onChange({ 'margin-left': `${left}px` });
    }, [left, onChange]);

    useEffect(() => {
        setTop(parseNumber(currentStyles['marginTop']))
        setLeft(parseNumber(currentStyles['marginLeft']))
        setRight(parseNumber(currentStyles['marginRight']))
        setBottom(parseNumber(currentStyles['marginBottom']))
    }, [currentStyles])



    const handleMarginChange = (setter, value) => {
        setter(value);
        if (sync) {
            setTop(value);
            setRight(value);
            setLeft(value);
            setBottom(value);
        }
    };

    return (
        <div className={styles.itemWrapper}>
            <div className="flex justify-between items-center align-center relative">
                <h4 className={styles.textLabel}>Margin</h4>
                <FaLink
                    onClick={() => toggleSync(prev => !prev)}
                    className={`${styles.syncButton} ${sync ? styles.activeSync : ""}`}
                />
            </div>
            <div className={`${styles.dummyWrapper} flex items-center flex-col`}>
                <div className="flex items-center gap-1rem">
                    <input
                        type="number"
                        className={`${styles.textSmallInput} flex items-center m-1`}
                        max={999}
                        value={top}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d+$/.test(value)) {
                                handleMarginChange(setTop, Number(value));
                            }
                        }}
                    />
                </div>
                <div className="flex items-center">
                    <input
                        type="number"
                        className={`${styles.textSmallInput} flex items-center m-1  text-align-right`}
                        max={999}
                        value={left}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d+$/.test(value)) {
                                handleMarginChange(setLeft, Number(value));
                            }
                        }}
                    />
                    <div className={styles.dummyBox}></div>
                    <input
                        type="number"
                        className={`${styles.textSmallInput} flex items-center m-1 p-0`}
                        max={999}
                        value={right}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d+$/.test(value)) {
                                handleMarginChange(setRight, Number(value));
                            }
                        }}
                    />
                </div>
                <div className="flex items-center gap-1rem">
                    <input
                        type="number"
                        className={`${styles.textSmallInput} flex items-center m-1`}
                        max={999}
                        value={bottom}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d+$/.test(value)) {
                                handleMarginChange(setBottom, Number(value));
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
