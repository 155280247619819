import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Table } from "../../Components";
import { sellableInventoryTableColumns } from "../../Utils/table-utils";
import "./SellableInventory.scss";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreator } from "../../Redux/actions/actionsCreator";

const mapStateToProps = ({ sellableInventories = {} }) => ({
  sellableInventories,
});
export default function SellableInventory() {
  const {
    sellableInventories: { list: sellableInventoryList = [] },
  } = useSelector(mapStateToProps);
  const history = useHistory();
  const columns = sellableInventoryTableColumns;
  const dispatch = useDispatch();
  useEffect(() => {
    fetchInventories();
  }, []);

  const fetchInventories = () => {
    dispatch(actionsCreator.FETCH_SELLABLE_INVENTORIES());
  };

  const addSellableInventory = () => {
    history.push(`/dashboard/sellable_inventories/add`);
  };

  return (
    <div className="Inventories">
      <div className="Inventories__Actions">
        <Button
          text={"Add Sellable Inventory"}
          className={"Black__Button"}
          onClick={addSellableInventory}
        />
      </div>
      <Table
        columns={columns}
        data={sellableInventoryList || []}
        height="100%"
      />
    </div>
  );
}
