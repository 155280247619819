import React from "react";
import Profile from "../Profile";
import "./Sidebar.scss";
import Items from "../Items";
import Button from "../../Button";
import { useDispatch } from "react-redux";
import { actionsCreator } from "../../../Redux/actions/actionsCreator";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export default function Sidebar() {
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    dispatch(actionsCreator.LOGOUT_USER());
    toast.error("User is logged out successfully");
    history.push("/login");
  };

  return (
    <div className="Sidebar">
      <div className="Sidebar__Content">
        <Profile />
        <Items />
      </div>
      {/* <div className="Sidebar__Contents">
        <div className="Sidebar__Contents__Heading">
          <div className="Sidebar__Contents__Favorites">Favorites</div>
          <div className="Sidebar__Contents__Recently">Recently</div>
        </div>
        <li>Overview</li>
        <li>Projects</li>
      </div> */}
      {/* <div className="Sidebar__Items">
        <div className="Sidebar__Items__Heading">Dashboard</div>
        <div className="Sidebar__Items__Item">
          <Dropdown
            icon={def}
            title="Default"
            items={["Item 1", "Item 2", "Item 3"]}
          />
        </div>
      </div> */}
      <div className="Sidebar__Logout">
        <Button text={"Logout"} onClick={logout} />
      </div>
    </div>
  );
}
