import React from "react";
import "./Footer.scss";

const Footer = () => {
  const current_year = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="footer-wrapper">
        <div className="footer-content-wrapper">
          <div className="footer-content footer-aboutus">
            <p className="footer-head">About Us</p>
            <p>
            At Appsketch, we specialize in building next-generation applications tailored to meet the demands of modern businesses. Our solutions are designed to streamline operations, enhance user experiences, and scale with your business growth.
            Whether you need advanced mobile applications, seamless integrations, or robust backend systems, Appsketch has you covered. Our mission is to empower you with powerful, flexible tech solutions, allowing you to focus on what truly matters—delivering value to your customers and driving your business forward.
            {" "}
            </p>
          </div>
          <div className="footer-content footer-contact footer-links">
            <div>
              <ul>
                <li>
                  <p className="footer-head">Useful Link</p>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="/privacy-policy#Aboutus"
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="/privacy-policy#PrivacyPolicy"
                  >
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a
                    rel="noreferrer"
                    target="_blank"
                    href="/privacy-policy#ContactUs"
                  >
                    Contact Information
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="footer-content footer-contact">
            <div>
              <ul>
                <li>
                  <p className="footer-head">Contact</p>
                </li>
                <li>
                  <a href="mailto:care@aiktech.in">
                    E-mail us at <br /> care@appsketch.ai
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <p>© Appsketch India Private Limited {current_year}</p>
    </div>
  );
};

export default Footer;
