import React, { useRef } from 'react'
import styles from "./Content.module.scss"
import { MdDelete } from "react-icons/md";
import { GrRevert } from "react-icons/gr";
import { MdOutlineCreate } from "react-icons/md";
import { VscOpenPreview } from "react-icons/vsc";
import { useCustomizer } from '../../../../Provider/customizerProvider';
export default function Content() {


    const { handleRemoveElement, handleRevertContent, handleImageUpload, uploadingImage, selectedElementType, selectedImage, editText, setEditText, handleCheckContent, } = useCustomizer();


    const fileInputRef = useRef(null);

    const handleChangeImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    return (

        <div className={styles.editorBoxContainer}>
            {(selectedElementType === "text" || selectedElementType === "button") ?
                <>
                    <p className={styles.editorContainerHeader}>Edit Text</p>
                    <textarea
                        className={styles.textEditorBox}
                        value={editText?.text}
                        onChange={e => setEditText(e.target.value)}
                        placeholder='Edit Text'
                        rows={10}
                    />
                    <div className={styles.editorButtonGrid}>
                        <button className={`${styles.revertButton} ${styles.editorButton}`} onClick={() => handleRevertContent(selectedElementType)}>
                            <GrRevert />
                            Revert </button>
                        <button className={`${styles.loaderButton} ${styles.editorButton}`} onClick={() => handleCheckContent(selectedElementType)}>
                            <MdOutlineCreate />
                            Apply </button>
                    </div>
                </>

                :
                <>
                    <p className={styles.editorContainerHeader}>Edit Image</p>

                    <img className={styles.imagePreview} src={selectedImage?.src} alt='Preview'
                        width={240}
                    />


                    <input
                        ref={fileInputRef}
                        hidden
                        type="file"
                        accept="image/*"
                        onChange={handleImageUpload}
                    />

                    <button className={`${styles.editorButton} ${styles.imageButton}`} onClick={handleChangeImageClick}>Change Image</button>

                    <div className={styles.editorButtonGrid}>
                        <button onClick={() => handleCheckContent('image')} className={`${styles.loaderButton} ${styles.editorButton} ${uploadingImage ? "activeUploading" : ""}`}>
                            {
                                uploadingImage ?
                                    <>
                                        Uploading...
                                    </>
                                    :
                                    <>
                                        <VscOpenPreview />
                                        Preview
                                    </>
                            }
                        </button>
                        <button onClick={() => handleRevertContent("image")} className={`${styles.revertButton} ${styles.editorButton}`}>
                            <GrRevert />
                            Revert
                        </button>
                    </div></>
            }

            <button onClick={handleRemoveElement} className={`${styles.deleteButton} ${styles.editorButton}`}>
                <MdDelete />
                Remove Element
            </button>

        </div>
        // </div>

    )
}
