import React, { useEffect, useState } from 'react';
import styles from "../../Design.module.scss";
import { FaLink } from "react-icons/fa6";
import { useCustomizer } from '../../../../../../Provider/customizerProvider';

import { useUtils } from '../../../../../../Hooks/useUtils';
export default function Margin() {
    const { parseNumber } = useUtils()
    const { onChange, currentStyles } = useCustomizer();
    const [sync, toggleSync] = useState(false);
    const [top, setTop] = useState(parseNumber(currentStyles['paddingTop']));
    const [right, setRight] = useState(parseNumber(currentStyles['paddingRight']));
    const [left, setLeft] = useState(parseNumber(currentStyles['paddingLeft']));
    const [bottom, setBottom] = useState(parseNumber(currentStyles['paddingBottom']));

    const handleChange = (setter, value) => {
        setter(value);
        if (sync) {
            setTop(value);
            setRight(value);
            setLeft(value);
            setBottom(value);
        }
    };

    useEffect(() => {
        onChange({ 'padding-top': `${top}px` });
    }, [top, onChange]);

    useEffect(() => {
        onChange({ 'padding-right': `${right}px` });
    }, [right, onChange]);

    useEffect(() => {
        onChange({ 'padding-bottom': `${bottom}px` });
    }, [bottom, onChange]);

    useEffect(() => {
        onChange({ 'padding-left': `${left}px` });
    }, [left, onChange]);

    useEffect(() => {
        setTop(parseNumber(currentStyles['paddingTop']));
        setRight(parseNumber(currentStyles['paddingRight']));
        setLeft(parseNumber(currentStyles['paddingLeft']));
        setBottom(parseNumber(currentStyles['paddingBottom']));
    }, [currentStyles]);

    return (
        <div className={styles.itemWrapper}>
            <div className="flex justify-between items-center align-center relative">
                <h4 className={styles.textLabel}>Padding</h4>
                <FaLink
                    onClick={() => toggleSync(prev => !prev)}
                    className={`${styles.syncButton} ${sync ? styles.activeSync : ""}`}
                />
            </div>
            <div className={`${styles.dummyWrapper} flex items-center flex-col`}>
                <div className="flex items-center gap-1rem">
                    <input
                        type="number"
                        className={`${styles.textSmallInput} flex items-center m-1`}
                        max={999}
                        value={top}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d+$/.test(value)) {
                                handleChange(setTop, Number(value));
                            }
                        }}
                    />
                </div>
                <div className="flex items-center">
                    <input
                        type="number"
                        className={`${styles.textSmallInput} flex items-center m-1 text-align-right`}
                        max={999}
                        value={left}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d+$/.test(value)) {
                                handleChange(setLeft, Number(value));
                            }
                        }}
                    />
                    <div className={styles.dummyBox}></div>
                    <input
                        type="number"
                        className={`${styles.textSmallInput} flex items-center m-1`}
                        max={999}
                        value={right}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d+$/.test(value)) {
                                handleChange(setRight, Number(value));
                            }
                        }}
                    />
                </div>
                <div className="flex items-center gap-1rem">
                    <input
                        type="number"
                        className={`${styles.textSmallInput} flex items-center m-1`}
                        max={999}
                        value={bottom}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d+$/.test(value)) {
                                handleChange(setBottom, Number(value));
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
