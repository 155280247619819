import axios from "axios";
import requestMakerWithCurrentUrl from "./Template/NetworkHandler";

export const fetchCategories = async () => {
    // const url = `/shop/category/`;
    const url = `https://realvaluemart.in/api/shop/category/`;
    const res = await axios.get(url);
    return res;
    // return requestMakerWithCurrentUrl(url,"get",{});
};
// export const fetchOrderDetails = (data)=>{
//   const url = `/shop/order/details/`
//   const params={ ...data }
//   const payload={}
//   return requestMaker(url,"get",{params,payload})
// }
export const fetchOrderDetails = async (data)=>{
  const url = `https://realvaluemart.in/api/shop/order/details/`
  const res = await axios.get(url);
  return res;
  // return requestMaker(url,"get",{params,payload})
}	
export const fetchInvoice = async (data) => {
  const { invoiceId = "" } = data;
  const url = `https://realvaluemart.in/api/shop/invoice/722/`;
  const res = await axios.get(url);
  return res;
};

export const fetchCategoriesGlobal = async (data) => {
    const url = `https://realvaluemart.in/api/shop/fetchcategories/`;
    const params = { ...data };
    const payload = {};
    const res = await axios.get(url, { params, payload });
    return res;
};
export const fetchPagedProducts = async (data) => {
    const { page, subCategoryName, subCategoryId } = data;
    if (!page) page = 1
    const url = `https://realvaluemart.in/api/shop/products/Pagination/`;
    const params = { data };
    const payload = {};
    const res = await axios.get(url, { params, payload });
    return res;
}
export const fetchProducts = async (data) => {
  const { name, id } = data;


  const url = `https://realvaluemart.in/api/shop/products/${name}/${id}/`;
  const res = await axios.get(url);
  return res;
};
export const fetchFavouriteProducts = (data) => {
  const url = `https://realvaluemart.in/shop/favourite_products/`;
  const params = {};
  const payload = {};
  return requestMakerWithCurrentUrl(url, "get", { params, payload });
};
export const sellableProductSearch = (data) => {
  const { search, page } = data;
  const url = `https://realvaluemart.in/api/shop/sellableproductsearch/${search}/`;
  const params = page ? { page: page } : {};
  const payload = {};
  return requestMakerWithCurrentUrl(url, "get", { params, payload });
};
export const addFavouriteProduct = (data) => {
  const url = `https://realvaluemart.in/api/shop/addfavourite_products/`;
  const params = {};
  const payload = { ...data };
  return requestMakerWithCurrentUrl(url, "post", { params, payload });
};

export const deleteFavouriteProduct = (data) => {
  const url = `https://realvaluemart.in/api/shop/favourite_products/`;
  const params = {};
  const payload = { ...data };
  return requestMakerWithCurrentUrl(url, "delete", { params, payload });
};
export const fetchUserDetails = (data) => {
    const url = `/account/userprofile/`;
    const params = {};
    const payload = {};
    return (url, 'get', { params, payload });
};
export const updateUserInventory = (data) => {
    const url = `/account/user/updateInventory/`;
    const params = {...data};
    const payload = {};
    return requestMakerWithCurrentUrl(url, 'get', { params, payload });
};


export const addAddresses = (data) => {
    const url = `/account/add_alternative/`;
    const params = {};
    const payload = { ...data };
    return requestMakerWithCurrentUrl(url, 'post', { params, payload });
};

export const editAddresses = (data) => {
    const url = `/account/add_alternative/`;
    const params = {};
    const payload = { ...data };
    return requestMakerWithCurrentUrl(url, 'patch', { params, payload });
};
export const fetchCartDetails = (data) => {
  const url = `/shop/get_cart/`;
  const params = {};
  const payload = {};
  return requestMakerWithCurrentUrl(url, "get", { params, payload });
};

// export const fetchCartDetails = async (data) => {
//     const url = `https://realvaluemart.in/api/shop/get_cart/`;
//     const res = await axios.post(url);
//     return res;
//   };
  
  export const addCartItems =  (payload) => {
    console.log(payload)

    const url = `/shop/add_to_cart/`;
    // const res = await axios.post(url);
    // return res;
    const params = {};
    return requestMakerWithCurrentUrl (url, "post", { params, payload });
  };
  export const fetchAddresses = async (data) => {
    const url = `https://realvaluemart.in/api/account/add_alternative/`;
    const params = { ...data };
    const res = await axios.get(url);
    return res;
};
  
  export const placeOrder = (payload, cartId) => {
    const url = `/payments/checkout/${cartId}/EXPRESS/`;
    const params = {};
    return requestMakerWithCurrentUrl(url, "post", { params, payload });
  };
  
  export const placeEverythingDelivery = (payload) => {
    const url = `/shop/place_order/-1/EVERYTHING/`;
    const params = {};
    return requestMakerWithCurrentUrl(url, "post", { params, payload });
  };
  
  export const activeOrders = (pageno, payload) => {
    const url = `/shop/recent_order/?page=${pageno}`;
    const params = {};
    return requestMakerWithCurrentUrl(url, "get", { params, payload });
  };
  
  
  export const placeOrderByAgent = (data) => {
    const url = `/shop/place_order_everything/`;
    const params = {};
    const payload = { ...data };
    return requestMakerWithCurrentUrl(url, "post", { params, payload });
  };
  
  export const fetchUnits = (data) => {
    const url = `/shop/stock/units/`;
    const params = {};
    const payload = {};
    return requestMakerWithCurrentUrl(url, "get", { params, payload });
  };
  
  export const fetchDiscounts = (data) => {
    const url = `/shop/discount/`;
    const params = {};
    const payload = {};
    return requestMakerWithCurrentUrl(url, "get", { params, payload });
  };
  
  export const applyDiscount = (data) => {
    const url = `/shop/cart/discount/`;
    const params = {};
    const payload = { ...data };
    return requestMakerWithCurrentUrl(url, "post", { params, payload });
  };
  
  export const removeDiscount = (data) => {
    const url = `/shop/cart/discount/`;
    const params = {};
    const payload = { ...data };
    return requestMakerWithCurrentUrl(url, "delete", { params, payload });
  };
  
  export const  getWalletData = async (data) => {
    const url = `https://realvaluemart.in/account/wallet/`;
    const res = await axios.get(url);
    return res;
    // return requestMakerWithCurrentUrl(url, "get", { params, payload });
  };
  
  export const debitWallet = (payload) => {
    const url = `/account/wallet/`;
    const params = {};
    return requestMakerWithCurrentUrl(url, "post", { params, payload });
  };


//   export const fetchDiscounts = (data) => {
//     const url = `/shop/discount/`;
//     const params = {};
//     const payload = {};
//     return requestMakerWithCurrentUrl(url, "get", { params, payload });
//   };
  
//   export const applyDiscount = (data) => {
//     const url = `/shop/cart/discount/`;
//     const params = {};
//     const payload = { ...data };
//     return requestMaker(url, "post", { params, payload });
//   };
  
//   export const removeDiscount = (data) => {
//     const url = `/shop/cart/discount/`;
//     const params = {};
//     const payload = { ...data };
//     return requestMaker(url, "delete", { params, payload });
//   };
//   export const fetchUserDetails = (data) => {
//     const url = `/account/userprofile/`;
//     const params = {};
//     const payload = {};
//     return requestMaker(url, 'get', { params, payload });
// };
  

