import React from "react";
import PropTypes from "prop-types";
import "./SideModal.scss";
import Button from "../Button";
import RoundButton from "../RoundButton";
import close from "../../Assets/Icons/close.svg";

import { useHistory } from "react-router-dom";

export default function SideModal({
  className,
  children,
  onClose,
  onSave,
  title,
  saveButtonText,
}) {
  const history = useHistory();
  const closeHandler = () => {
    if (onClose) {
      onClose();
      return;
    }
  };
  return (
    <div className={`Modal ${className}`} onClick={closeHandler}>
      <section
        className={"Modal__Section"}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="Modal__Section__Head">
          <div className="Modal__Section__Head__Title">{title}</div>
          <RoundButton onclick={closeHandler} imageURL={close} />
        </div>
        <div className="Modal__Section__Body">{children}</div>
      </section>
    </div>
  );
}

SideModal.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
  children: PropTypes.node,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  title: PropTypes.string,
  saveButtonText: PropTypes.string
};

SideModal.defaultProps = {
  className: "",
  children: null,
  onClose: null,
  onSave: () => {},
  show: false,
  saveButtonText: "Save",
};
