import React, { useEffect, useState } from 'react';
import styles from "../../Design.module.scss"
import { useCustomizer } from '../../../../../../Provider/customizerProvider';
import { useUtils } from '../../../../../../Hooks/useUtils';
export default function Opacity() {
    const { onChange, currentStyles } = useCustomizer()
    const { parseNumber } = useUtils()
    const [opacity, setOpacity] = useState(parseNumber(currentStyles['borderOpacity']) ?? 100);


    useEffect(() => {
        onChange({ 'border-opacity': opacity })
    }, [opacity])

    useEffect(() => { setOpacity(parseNumber(currentStyles['borderOpacity']) ?? 100) }, [currentStyles])


    return (
        <div className={styles.itemWrapper} >
            <div className="flex justify-between items-center relative">
                <h4 className={styles.textLabel}>Opacity</h4>
                <div className={`flex items-center gap-05rem ${styles.inputContainer}`}>
                    <input
                        type="number"
                        className={`${styles.textInput} flex items-center`}
                        max={100}
                        value={opacity}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                                setOpacity(value);
                            }
                        }}
                    />
                    <span className={styles.textLabel}>%</span>

                </div>
            </div>
        </div>
    );
}
