import React, { useEffect, useState, useRef } from 'react';
import './Builder.scss';
import WebFont from 'webfontloader';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loader from "../../Assets/Images/Template/builderloader.gif";
import { useLocation } from "react-router-dom";
import LeftSidebar from '../../Components/BuilderSidebars/LeftSidebar/LeftSidebar';
// import RightSidebar from '../../Components/BuilderSidebars/RightSidebar/RightSidebar';
import RightSidebar from "../../Components/BuilderSidebars/RightSidebarV2/index"
import { actionsCreator } from '../../Redux/actions/actionsCreator';
import { fetchConfigByTenant, publishTemplate } from '../../Api/tenantAPI';
// import { fetchConfigByTenant } from '../../Api/tenantAPI';
import { getLoadingText } from './utils';
import DynamicComponentLoader from './DynamicComponentLoader';
import dotIcon from "../../Assets/Icons/builder/dot.svg";
import Tooltip from '../../Components/TooltipV2';
import mobileIcon from "../../Assets/Icons/builder/mobile.svg";
import tabletIcon from "../../Assets/Icons/builder/tablet.svg";
import desktopIcon from "../../Assets/Icons/builder/desktop.svg";
import { getCurrConfigByPage } from './utils';
import Logger from './UI/Logger';
import SaveBtn from './UI/SaveBtn';
import CustomizerProvider from '../../Provider/customizerProvider';


const viewModeToTextMap = new Map([
    ["mobile", "Mobile (600px and below)"],
    ["tablet", "Tablet (1000px and below)"],
    ["desktop", "Desktop (Primary)"]
]);
const Builder = () => {
    const [template, setTemplate] = useState(null);
    const location = useLocation();
    const dispatch = useDispatch();
    const [renderedComponents, setRenderedComponents] = useState([]);
    const componentRefs = useRef([]);
    const rightSidebarStatus = useSelector(state => state.sectionsList.showRightSidebar);
    const selectedElementId = useSelector(state => state.sectionsList.selectedElementId);
    const currMode = useSelector(state => state.sectionsList.currMode);
    const currPage = useSelector(state => state.sectionsList.currPage);
    const currConfig = useSelector(state => state.sectionsList.currConfig);
    const config = useSelector(state => state.sectionsList.config);
    const viewMode = useSelector(state => state.sectionsList.viewMode) || "desktop";
    const updatedConfig = useSelector(state => state.sectionsList.config)
    const [allPages, setAllPages] = useState(null);
    const queryParams = new URLSearchParams(location.search);
    const tenant = queryParams.get('tenant');
    const tenantTemplateId = useSelector(state => state.sectionsList.tenantTemplateId);

    const fetchConfig = async () => {
        try {
            const { data } = await fetchConfigByTenant(tenant);
            // console.log(data);
            const updatedConfig = data?.template_configs?.[0]?.config;
            const tenantTemplateId = data?.template_configs?.[0]?.id;
            dispatch(actionsCreator.SET_SECTIONS_LIST({
                config: updatedConfig,
                tenantTemplateId: tenantTemplateId,
                tenantId: data?.id,
            }))
            dispatch(actionsCreator.SET_UPDATE_SECTIONS_LIST({ config: updatedConfig }))
        } catch (err) {
            console.error(err);
            toast.error("Error while creating template");
        }
    };

    const renderComponents = () => {
        if (!config || !config?.[currConfig]) return;
        const sections = config?.[currConfig]?.sections;
        setRenderedComponents(sections);
    };

    const handleToggleEditMode = () => {
        if (currMode === "view") {
            dispatch(
                actionsCreator.SET_SECTIONS_LIST({
                    currMode: "edit",
                })
            )
        } else if (currMode === "edit") {
            dispatch(
                actionsCreator.SET_SECTIONS_LIST({
                    selectedElementId: null,
                    hoveredElementId: null,
                    currMode: "view",
                })
            )
        }
    }

    const handleChangeViewMode = (newMode) => {
        dispatch(actionsCreator.SET_SECTIONS_LIST({
            viewMode: newMode,
        }))
        // setViewMode(newMode);
    }
    function getTemplateCSSVariable(element, variableName) {
        return element?.style?.getPropertyValue(variableName);
    }


    const handlePublishTemplate = async () => {
        try {
            await publishTemplate({
                tenantId: tenantTemplateId,
                config: updatedConfig,
            });
            toast.success("Template Updated");
        } catch (err) {
            console.error(err);
            toast.error("Error Saving Template");
        }
    }
    const handleUrlChange = (newUrl) => {
        dispatch(actionsCreator.SET_SECTIONS_LIST({
            currUrl: newUrl
        }))
    }
    function getCurrConfigByPage(page) {
        let counter = 0;
        for (const key in config) {
            if (counter === 0) {
                counter++;
                continue;
            }
            if (config?.[key]?.page == page) {
                return key;
            }
            counter++;
        }
    }
    const handlePageChange = (newPage) => {
        // console.log(newPage, getCurrConfigByPage(newPage))
        dispatch(actionsCreator.SET_SECTIONS_LIST({
            currPage: newPage,
            currConfig: getCurrConfigByPage(config, newPage),
        }))

    }
    useEffect(() => {
        renderComponents();
    }, [template, currConfig, config]);

    useEffect(() => {
        const pages = [];
        let counter = 0;
        for (const key in config) {
            if (counter === 0) {
                counter++;
                continue;
            }
            if (key !== "cms_config" && key !== "siteConfig") {
                pages.push(config[key]?.page);
            }
            counter++;
        }
        setAllPages(pages);

        function setTemplateCSSVariable(element, variableName, value) {
            element?.style?.setProperty(variableName, value);
        }
        WebFont.load({
            google: {
                families: [config.siteConfig?.font||""],
            },
        });
        const rootContainer = document.getElementById('root-container');
        setTemplateCSSVariable(rootContainer, '--font', config.siteConfig?.font);
        setTemplateCSSVariable(rootContainer, '--primary-color', config.siteConfig?.primaryColor);
        setTemplateCSSVariable(rootContainer, '--secondary-color', config.siteConfig?.secondaryColor);
    }, [config]);

    useEffect(() => {
        fetchConfig();
    }, [])
    return (
        <CustomizerProvider>
            <div className='builder-container'
                style={{
                    height: "100vh",
                    overflow: 'hidden',
                    backgroundColor: "#F0F1F5",
                    position: 'relative'
                }
                }
            >
                <div className='customize-header' style={{ zIndex: "100" }}>
                    <div className='header-left-part'>
                        <span>Minimal</span>
                        <button>
                            <img src={dotIcon} height={21} />
                            <span>Live</span>
                        </button>
                    </div>
                    <div>
                        <div className="page-selector">
                            <select
                                value={currPage}
                                onChange={e => handlePageChange(e.target.value)}
                            >
                                {allPages?.map((opt, idx) => (
                                    <option key={idx} value={opt}>{`${opt} Page`}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='header-right-part'>
                        <div className={`view-button-container`}>
                            <Tooltip content="Desktop View" position="left">
                                <button onClick={() => handleChangeViewMode('desktop')} className={`${viewMode === "desktop" && "active-view-mode"}`}>
                                    <img src={desktopIcon} height={25} />
                                </button>
                            </Tooltip>
                            <Tooltip content="Tablet View" position="right">
                                <button onClick={() => handleChangeViewMode('tablet')} className={`${viewMode === "tablet" && "active-view-mode"}`}>
                                    <img src={tabletIcon} height={25} />
                                </button>
                            </Tooltip>
                            <Tooltip content="Mobile View" position="right">
                                <button onClick={() => handleChangeViewMode('mobile')} className={`${viewMode === "mobile" && "active-view-mode"}`}>
                                    <img src={mobileIcon} height={25} />
                                </button>
                            </Tooltip>
                        </div>
                        <Logger></Logger>
                        {/* <div className='undo-redo-btn-container'>
                            <Tooltip content="Undo" position="left">
                                <button>
                                    <img src={undoIcon} height={22} />
                                </button>
                            </Tooltip>
                            <Tooltip content="Redo" position="right">
                                <button>
                                    <img src={redoIcon} height={22} />
                                </button>
                            </Tooltip>

                        </div> */}
                        <div style={{ display: "flex", gap: "15px", }}>
                            <button className='edit-template-btn'
                                onClick={handleToggleEditMode}>
                                {currMode === "view" ? "Edit Template" : "Preview"}
                            </button>
                            {currMode === "edit" &&
                                <>
                                    <SaveBtn></SaveBtn>
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', height: '91%', position: 'relative', width: '100%' }}>
                    {currMode === "edit" &&
                        <LeftSidebar />
                    }
                    <div style={{ display: 'flex' }} id="root-container">
                        <span className='current-view'>{viewModeToTextMap.get(viewMode)}</span>
                        <div className={`wrapper-${viewMode} flex-col`}>
                            <div id="domain-header" className={`domain-header-${viewMode}`}></div>
                            <div className='resizer-left' />
                            <div className={`container-${viewMode}`}>
                                {renderedComponents?.map((section, index) => (
                                    <DynamicComponentLoader
                                        key={index}
                                        type={section.type}
                                        delay={index * 0.3}
                                        ref={(el) => componentRefs.current[index] = el}
                                        {...section}
                                    />
                                ))}
                            </div>
                            <div className='resizer-right' />
                        </div>
                    </div>
                    {
                        (rightSidebarStatus && selectedElementId) &&
                        <RightSidebar />
                    }
                </div>
            </div >
        </CustomizerProvider>
    );
};

export default Builder;
