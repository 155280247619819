import React from "react";
import Item from "../Item";
import { NAV_ITEMS } from "../../../Assets/Constant";
import "./Items.scss";

export default function Items() {
  const items = NAV_ITEMS;
  return (
    <div className="Items">
      {items.map((item) => {
        return <Item item={item} />;
      })}
    </div>
  );
}
