import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Table } from "../../Components";
import { inventoryTableColumns } from "../../Utils/table-utils";
import "./Inventory.scss";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreator } from "../../Redux/actions/actionsCreator";

const mapStateToProps = ({ inventories = {} }) => ({
  inventories,
});
export default function Inventory() {
  const {
    inventories: { list: inventoryList = [] },
  } = useSelector(mapStateToProps);
  const history = useHistory();
  const columns = inventoryTableColumns;
  const dispatch = useDispatch();
  useEffect(() => {
    fetchInventories();
  }, []);

  const fetchInventories = () => {
    dispatch(actionsCreator.FETCH_INVENTORIES());
  };

  const addInventory = () => {
    history.push(`/dashboard/inventories/add`);
  };

  return (
    <div className="Inventories">
      <div className="Inventories__Actions">
        <Button
          text={"Add Inventory"}
          className={"Black__Button"}
          onClick={addInventory}
        />
      </div>
      <Table columns={columns} data={inventoryList || []} height="100%" />
    </div>
  );
}
