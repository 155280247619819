import React from "react";
import "./loader.scss";
import { useSelector } from "react-redux";

import { FiLoader } from "react-icons/fi";

const mapStateToProps = ({ message }) => ({
  message,
});

function Loader(props) {
  return (
    <div className="modal-loader">
      {/* <div class="lds-ring"> */}
        <FiLoader size={'2.5rem'} className="lds-ring"/>
      {/* </div> */}
      <div className="modal-message">{props.message}</div>
    </div>
  );
}

export default Loader;
