import React from 'react'
import "./Home.scss";
import Hero from './Hero';
import SetupSteps from './SetupSteps';
import Offerings from './Offerings';
import Testimonials from './Testimonials';
import ContactUs from './ContactUs';
import Benefits from './Benefits';
import Seller from './Seller';
import Features from './Features';

const Home = () => {
    return (
        <>
            <div className='home-v3-color-bg'></div>
            <div className='home-v3'>
                <Hero />
                <SetupSteps />
                <Features />
                <Benefits />
                <Seller />
                {/* <Offerings /> */}
                {/* <Testimonials /> */}
                {/* <ContactUs /> */}
            </div>
        </>
    )
}

export default Home