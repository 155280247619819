import "./Studio.scss";
import React, { useEffect, useState } from 'react'
import studioLogo from "../../Assets/Images/studio/image.png";
import { errorMsg } from '../../Utils';
import toast from 'react-hot-toast';
import defaultLogo from "../../Assets/Images/studio/default-logo.png";
import { fetchAllTenantsByUser, fetchCompanyDetails, updateTenant } from '../../Api/tenantAPI';
import { motion } from "framer-motion";
import linkIcon from "../../Assets/Images/studio/link.svg";
import { getToken } from "../../Utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Menu, Modal } from "../../Components";
import { useDispatch } from "react-redux";
import { actionsCreator } from "../../Redux/actions/actionsCreator";
import profileIcon from "../../Assets/Icons/profile.svg";
import { formatName } from "../../Utils";
import { useSelector } from "react-redux";
import appSketchLogo from "../../Assets/Images/studio/app-sketch-logo.png";
import loaderLogo from "../../Assets/Images/studio/app-sketch-loader-logo.png";

const variants = {
    hidden: { x: '-100%' },
    visible: { x: 0 },
    exit: { x: '-100%' }
};

const mapStateToProps = ({ auth = {} }) => ({
    auth,
});

const Studio = () => {
    const [allTenants, setAllTenants] = useState(null);
    const [leftSideBar, toggleLeftSidebar] = useState(false);
    const [selectedTenantCard, setSelectedTenantCard] = useState(null);
    const isLoggedIn = getToken();
    const history = useHistory();
    const dispatch = useDispatch();
    const { auth = {} } = useSelector(mapStateToProps);
    const { user = {} } = auth;
    const [isLoading, setIsLoading] = useState(true);
    const [selectedSidebarOption, setSelectedSidebarOption] = useState("app");

    const { name = "" } = user || {};
    const formattedName = formatName(name);
    const menuText = `Hey ${formattedName}!`;
    const logOut = () => {
        dispatch(actionsCreator.LOGOUT_USER());
        toast.error("User is logged out successfully");

    };

    const navigate = (path) => {
        history.push(path);
    };

    const logoutOption = [
        {
            name: "logout",
            action: logOut
        },

    ];
    const fetchAllTenants = async () => {
        try {
            const { data } = await fetchAllTenantsByUser();
            setAllTenants(data);
        } catch (err) {
            const errMsg = errorMsg(err);
            toast.error(errMsg);
            console.error(err);
        }
    }
    const handleCreateApp = () => {
        history.push("/register",
            {
                from: window.location.pathname,
            }
        )
    }
    const handleSelecteTenant = (tenant) => {
        setSelectedTenantCard(tenant);
        toggleLeftSidebar(true);
    }
    const handleUpdateTenant = async (tenantId) => {
        try {
            const res = await updateTenant(tenantId);
            return res;
        } catch (err) {
            const errMsg = errorMsg(err);
            toast.error(errMsg);
            return err;
        }
    }
    const handleRedirectBuilder = async (tenantId,tenantTemplateId) => {
        const res = await handleUpdateTenant(tenantId);
        if (res?.status == 200) {
            history.push(`/builder?tenant=${tenantId}` , {
                tenantTemplateId,
            })
            dispatch(actionsCreator.SET_SECTIONS_LIST({
                tenantTemplateId: tenantTemplateId
            }))
        } else {
            toast.error("Unable to update Tenant");
        }
    }
    const handleRedirectCMS = async (tenantId,tenantTemplateId) => {
        const res = await handleUpdateTenant(tenantId);
        if (res?.status == 200) {
            history.push(`/cms?tenant=${tenantId}`, {
                tenantTemplateId
            })
        } else {
            toast.error("Unable to update Tenant");
        }
    }
    const handleRedirectCRM = async (tenantId,tenantTemplateId) => {
        const res = await handleUpdateTenant(tenantId);
        if (res?.status == 200) {
            history.push(`/crm?tenant=${tenantId}`, {
                tenantTemplateId
            })
        } else {
            toast.error("Unable to update Tenant");
        }
    }
    useEffect(() => {
        fetchAllTenants();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setIsLoading(false);
        }, 3000)
    }, [])
    return (
        <>
            <Modal
                show={isLoading}
                showCloseBtn={false}
                className="loading-modal"
                style={{ height: "320px", width: "400px", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <div className="logo-container">
                    <img src={loaderLogo} alt="loader" height={110} width={300}
                        style={{ objectFit: "contain" }}
                    />
                </div>
                <div className="progress mt-0">
                    <div class="progress-value" />
                </div>
            </Modal>
            <div className={`studio-wrapper ${isLoading ? 'blur-background' : ''}`}>
                <header>
                    <div>
                        <img src={appSketchLogo} height={50} width={170} className="header-logo" />
                    </div>
                    <div className={`Header_Auth`}>
                        {
                            !isLoggedIn
                                ?
                                (
                                    <button
                                        className="btn-inactive btn-sm login-btn"
                                        onClick={() => {
                                            navigate('/login')
                                        }}
                                    >
                                        Log in
                                    </button>
                                )
                                :
                                (
                                    <div className="Navbar__Container__DashboardActions">
                                        <div className="Navbar__Container__DashboardActions__Profile">
                                            <Menu
                                                items={logoutOption}
                                                menuIcon={profileIcon}
                                                menuText={menuText}
                                            />
                                        </div>
                                    </div>
                                )
                        }
                    </div>
                </header>
                <div className='studio-container w-full'>
                    <motion.div
                        className="left-sidebar"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={variants}
                        transition={{ duration: 0.5 }}
                    >
                        <div className="sidebar-header">
                            <div className="w-full">
                                <p className="w-full bold-xl">{user?.workspace?.name}</p>
                                <p className="text-sm">{user?.workspace?.email}</p>
                            </div>
                        </div>
                        <div className="py-3 sidebar-body">
                            <div className={`px-3 ${selectedSidebarOption === "discover" && "active-opt"}`} onClick={() => setSelectedSidebarOption("discover")}>
                                <span>Discover</span>
                            </div>
                            <div className={`px-3 ${selectedSidebarOption === "app" && "active-opt"}`} onClick={() => setSelectedSidebarOption("app")}>
                                <span>Apps</span>
                            </div>
                            <div className={`px-3 ${selectedSidebarOption === "settings" && "active-opt"}`} onClick={() => setSelectedSidebarOption("settings")}>
                                <span>Settings</span>
                            </div>
                            {/* <div className="left-sidebar-option" >
                                <span>Website Builder</span>
                                <img src={linkIcon} />
                            </div>
                            <div className="left-sidebar-option">
                                <span>Website CMS</span>
                                <img src={linkIcon} />
                            </div>
                            <div className="left-sidebar-option">
                                <span>Website CRM</span>
                                <img src={linkIcon} />
                            </div> */}
                        </div>
                    </motion.div>
                    <main className="p-6 w-full">
                        {selectedSidebarOption === "app" &&
                            <>
                                <div className='studio-logo-container flex p-1'>
                                    <div>
                                        <h3 className="bold-2xl text-xl" style={{ color: "#131720" }}>Apps</h3>
                                        <span className="text-md">View and manage all your apps in one place.</span>
                                    </div>
                                    <button className="py-2 px-4 h-10" onClick={handleCreateApp}>
                                        Create App</button>
                                </div>
                                <div className='tenant-container mt-4'>
                                    {
                                        allTenants && allTenants?.map((tenant, idx) => (
                                            <div
                                                className='tenant-card'
                                                key={idx}
                                                onClick={() => handleSelecteTenant(tenant)}
                                            >
                                                <div className="image-wrapper">
                                                    <img src={tenant?.logo || studioLogo} alt='logo' />
                                                </div>
                                                <div style={{ backgroundColor: "#131720", color: "#FFFF", textAlign: "right", padding: "0.5px 0px" }} className="text-sm w-full bold-lg px-2">
                                                    <span>Built on App Sketch Studio</span>
                                                </div>
                                                <span className='tenant-title mt-3'>{tenant?.title}</span>
                                                <a
                                                    href={tenant?.website_url}
                                                    className="bold-md"
                                                    style={{ fontSize: "12px" }}
                                                >
                                                    {tenant?.website_url}
                                                </a>
                                                <div className="button-wrapper">
                                                    <button onClick={() => handleRedirectBuilder(tenant?.id,tenant?.template_configs?.[0]?.id, tenant?.template_configs?.[0]?.config)}>Customize</button>
                                                    <button onClick={() => handleRedirectCMS(tenant?.id,tenant?.template_configs?.[0]?.id, tenant?.template_configs?.[0]?.config)}>CMS</button>
                                                    <button onClick={() => handleRedirectCRM(tenant?.id,tenant?.template_configs?.[0]?.id, tenant?.template_configs?.[0]?.config)}>CRM</button>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </>
                        }
                    </main>
                </div >
            </div >
        </>
    )
}

export default Studio