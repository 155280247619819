import React, { useEffect, useState } from 'react';
import styles from "../../Design.module.scss";
import { FaLink } from "react-icons/fa6";
import { useCustomizer } from '../../../../../../Provider/customizerProvider';
import { useUtils } from '../../../../../../Hooks/useUtils';
import { RxCornerTopLeft, RxCornerBottomRight, RxCornerBottomLeft, RxCornerTopRight } from "react-icons/rx";
export default function Radius() {
    const { onChange, currentStyles } = useCustomizer();
    const [sync, toggleSync] = useState(false);
    const { parseNumber } = useUtils()

    const [topLeft, setTopLeft] = useState(parseNumber(currentStyles['borderTopLeftRadius']));
    const [topRight, setTopRight] = useState(parseNumber(currentStyles['borderTopRightRadius']));
    const [bottomLeft, setBottomLeft] = useState(parseNumber(currentStyles['borderBottomLeftRadius']));
    const [bottomRight, setBottomRight] = useState(parseNumber(currentStyles['borderBottomRightRadius']));


    useEffect(() => {
        onChange({
            'border-top-left-radius': `${topLeft}px`,
        });
    }, [topLeft]);

    useEffect(() => {
        onChange({
            'border-top-right-radius': `${topRight}px`,
        });
    }, [topRight]);

    useEffect(() => {
        onChange({
            'border-bottom-right-radius': `${bottomRight}px`,
        });
    }, [bottomRight]);

    useEffect(() => {
        onChange({
            'border-bottom-left-radius': `${bottomLeft}px`,
        });
    }, [bottomLeft]);


    const handleRadiusChange = (setter, value) => {
        const parsedValue = value
        setter(parsedValue);

        if (sync) {
            setTopRight(parsedValue);
            setBottomLeft(parsedValue);
            setTopLeft(parsedValue);
            setBottomRight(parsedValue);
        }
    };


    useEffect(() => {
        setTopLeft(parseNumber(currentStyles['borderTopLeftRadius']));
        setTopRight(parseNumber(currentStyles['borderTopRightRadius']));
        setBottomRight(parseNumber(currentStyles['borderBottomRightRadius']));
        setBottomLeft(parseNumber(currentStyles['borderBottomLeftRadius']));
    }, [currentStyles]);


    return (
        <div className={styles.itemWrapper}>
            <div className="flex justify-between items-center align-center relative">
                <h4 className={styles.textLabel}>Radius</h4>
                <FaLink onClick={() => toggleSync(prev => !prev)} className={`${styles.syncButton} ${sync ? styles.activeSync : ""}`}></FaLink>

            </div>

            <div className='m-3'></div>
            {/* Radius controls */}
            <div className={`${styles.grid_2} gap-175rem `}>

                <div className={`flex items-center gap-05rem`}>
                    <input
                        type="number"
                        className={`${styles.textInput} flex items-center m-1 text-align-right`}
                        maxLength={3}
                        value={topLeft}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                                handleRadiusChange(setTopLeft, Number(e.target.value))
                            }
                        }}
                    />
                    <RxCornerTopLeft />

                </div>
                <div className={`flex items-center gap-05rem`}>
                    <RxCornerTopRight />
                    <input
                        type="number"
                        className={`${styles.textInput} flex items-center m-1`}
                        maxLength={3}
                        value={topRight}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                                handleRadiusChange(setTopRight, Number(e.target.value))
                            }
                        }}
                    />

                </div>
                <div className={`flex items-center gap-05rem`}>
                    <input
                        type="number"
                        className={`${styles.textInput} flex items-center m-1 text-align-right`}
                        maxLength={3}
                        value={bottomLeft}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                                handleRadiusChange(setBottomLeft, Number(e.target.value))
                            }
                        }}
                    />
                    <RxCornerBottomLeft />

                </div>
                <div className={`flex items-center gap-05rem`}>
                    <RxCornerBottomRight />
                    <input
                        type="number"
                        className={`${styles.textInput} flex items-center m-1`}

                        value={bottomRight}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                                handleRadiusChange(setBottomRight, Number(e.target.value))
                            }
                        }}
                    />

                </div>

            </div>
        </div>
    );
}
