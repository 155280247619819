import React, { useEffect, useState, useRef } from 'react';
import styles from "../../Design.module.scss"
import { useCustomizer } from '../../../../../../Provider/customizerProvider';

export default function Width() {
    const { onChange } = useCustomizer();
    const [width, setWidth] = useState(0)

    useEffect(() => { onChange({ "border-width": width }) }, [width])

    return (

        <div className={styles.itemWrapper} >
            <div className="flex justify-between items-center relative">
                <h4 className={styles.textLabel}>Width</h4>
                <div className={`flex items-center gap-05rem ${styles.inputContainer}`}>
                    <input
                         type="number"
                        className={`${styles.textInput} flex items-center`}
                        maxLength={3}
                        value={width}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                                setWidth(value);
                            }
                        }}
                    />
                    <span className={styles.textLabel}>px</span>

                </div>
            </div>
        </div>

    );
}
