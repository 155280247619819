import React, { useEffect, useState } from 'react';
import styles from "../../Design.module.scss";
import { useCustomizer } from '../../../../../../Provider/customizerProvider';
import { useUtils } from '../../../../../../Hooks/useUtils';

export default function FontSize() {
    const { parseNumber } = useUtils();
    const { onChange, currentStyles } = useCustomizer();
    const [fontSize, setFontSize] = useState(parseNumber(currentStyles['fontSize']));
    useEffect(() => {
        if (fontSize) {
            onChange({ 'font-size': `${fontSize}px` });
        }
    }, [fontSize]);

    useEffect(() => { setFontSize(parseNumber(currentStyles['fontSize'])) }, [currentStyles])
    return (
        <div className={styles.itemWrapper}>
            <div className="flex justify-between items-center relative">
                <h4 className={styles.textLabel}>Size</h4>
                <div className={`flex items-center gap-05rem ${styles.inputContainer}`}>
                    <input
                        type="number"
                        className={`${styles.textInput} flex items-center`}
                        max={100}
                        min={0}
                        value={fontSize}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                                setFontSize(value);
                            }
                        }}
                    />
                    <span className={styles.textLabel}>px</span>
                </div>
            </div>
        </div>
    );
}
