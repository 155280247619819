import React from 'react'
import "./Features.scss"
import FeatureImage from "../../../Assets/Images/home/home-v3-features-image.png"

const FeaturesCard = ({ data }) => {
    return <>
        <div className='flex items-start flex-1 gap-05rem flex-col' >
            <h1 className='home-v3-benefits-card-h1 font-bold'>{data?.title}</h1>
            <p className='home-v3-benefits-card-p'> {data?.desc}</p>
        </div>
    </>
}



export default function Features() {
    const features = [
        {
            title: "Unlimited Products",
            desc: "With Appsketch, there’s no restriction on the number of products you can add to your store. This means you’re free to offer an extensive range, catering to diverse customer needs and seasonal trends without limitations. Stock up and satisfy the demand for variety that today’s shoppers expect."
        },
        {
            title: "Integrated POS Access",
            desc: "Appsketch provides seamless integration between your online and in-store sales using a Point of Sale (POS) system. Effortlessly synchronize sales data across all platforms, ensuring a consistent shopping experience for customers—whether they purchase online or in person."
        },
        {
            title: "Enhanced Security",
            desc: "Security is a top priority at Appsketch. Our platform includes advanced fraud analysis and secure domain features, safeguarding every customer’s data. We’re also Level 1 PCI DSS compliant, providing the highest standard of security for financial transactions to ensure trust and reliability."
        }
    ]
    
    return (
        <div className='home-v3-features  flex items-center gap-175rem flex-col'>
            <h1 className='home-v3-features-h1 font-bold' style={{ marginRight: 'auto' }}>Your store, your way.</h1>
            <img src={FeatureImage} alt="" className='home-v3-features-image' />
            <div className='home-v3-features-grid'>
                {
                    features.map((ele, index) => <FeaturesCard data={ele} key={`benefit-card-${index}`} />)
                }
            </div>
        </div>
    )
}
