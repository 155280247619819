import React, { useEffect, useState } from "react";

export default function Paytm({ paymentData, handleSuccess, handleFailure }) {
  useEffect(() => {
    initiatePaytmGateway(paymentData);
  }, []);

  const initiatePaytmGateway = (paymentData) => {
    var config = {
      root: "",
      style: {
        bodyBackgroundColor: "#fafafb",
        bodyColor: "",
        themeBackgroundColor: "#3399cc",
        themeColor: "#ffffff",
        headerBackgroundColor: "#3399cc",
        headerColor: "#ffffff",
        errorColor: "",
        successColor: "",
        card: {
          padding: "",
          backgroundColor: "",
        },
      },
      data: {
        orderId: paymentData.order_id,
        token: paymentData.token,
        tokenType: "TXN_TOKEN",
        amount: paymentData.amount /* update amount */,
      },
      payMode: {
        labels: {},
        filter: {
          exclude: [],
        },
        order: ["UPI"],
      },
      website: paymentData.website,
      flow: "DEFAULT",
      merchant: {
        mid: paymentData.mid,
        redirect: false,
      },
      handler: {
        transactionStatus: function transactionStatus(paymentStatus) {
          // 	TXN_SUCCESS, TXN_FAILURE and PENDING
          const payload = {
            paymentStatus,
            order_id: paymentData.order_id,
            mode_of_payment: paymentData.mode_of_payment,
          };
          if (paymentStatus.STATUS === "TXN_SUCCESS") {
            // Success API
            handleSuccess(payload);
          } else if (paymentStatus.STATUS === "TXN_FAILURE") {
            // Failure API
            handleFailure(payload);
          }
          document.querySelector("#app-close-btn").click();
        },
        notifyMerchant: function notifyMerchant(eventName, data) {},
      },
    };
    console.log(window.Paytm, "---");
    if (window.Paytm && window.Paytm.CheckoutJS) {
      window.Paytm.CheckoutJS.init(config)
        .then(function onSuccess() {
          window.Paytm.CheckoutJS.invoke();
        })
        .catch(function onError(error) {
          console.log("Error => ", error);
        });
    }
  };
  return <div className="Paytm"></div>;
}
