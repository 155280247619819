import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import "./CheckBox.scss";

export default function CheckBox({ label, value, onChange }) {
  const changeHandler = (e) => {
    const value = get(e, "target.checked", false);
    onChange(value);
  };

  return (
    <div className="CheckBox">
      <div className="CheckBox__Title">
        <label className="CheckBox__Title__Label">{label}</label>
        <input
          type="checkbox"
          className="CheckBox__Title__Input"
          checked={value}
          onChange={changeHandler}
        />
      </div>
    </div>
  );
}

CheckBox.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

CheckBox.defaultProps = {
  label: "",
  value: false,
  onChange: () => {},
};
