import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import "./LeftSidebar.scss";
import { useDispatch, useSelector } from 'react-redux';
import { actionsCreator } from '../../../Redux/actions/actionsCreator';
import SectionOverview from './SectionOverview/SectionOverview';
import AddSection from './AddSection/AddSection';
import ViewPages from './ViewPages/ViewPages';
import { Toaster } from 'react-hot-toast';
import LayoutIcon from "../../../Assets/Icons/Layout.svg";
import PlusIcon from "../../../Assets/Icons/plus.svg";
import PageIcon from "../../../Assets/Icons/page.svg"
import closeIcon from "../../../Assets/Icons/close.svg";
import Theme from './Theme';
import { VscColorMode } from "react-icons/vsc";
import Tooltip from '../../TooltipV2';


const componentMap = {
    viewSections: SectionOverview,
    addSection: AddSection,
    viewPages: ViewPages,
    theme: Theme
};
const LeftSidebar = () => {

    const [activeSidebar, setActiveSidebar] = useState("");
    const [selectedSectionIdx, setSelectedSectionIdx] = useState(null);
    const leftSidebarStatus = useSelector(state => state.sectionsList.showLeftSidebar);
    const rightSidebarStatus = useSelector(state => state.sectionsList.showRightSidebar);
    const templateConfig = useSelector(state => state.sectionsList.config)
    const dispatch = useDispatch();
    const currPage = useSelector(state => state.sectionsList.currPage);


    const optionToHeadingMap = new Map([
        ["addSection", "Add New Section"],
        ["viewSections", `${currPage} Page`],
        ["theme", `Theme`],
        ["viewPages", "All Pages"],
    ]);
    const handleComponentOptionSelect = (idx) => {
        setSelectedSectionIdx(idx);
    };


    useEffect(() => {
        if (typeof activeSidebar === 'string' && activeSidebar !== "") {

            dispatch(
                actionsCreator.SET_SECTIONS_LIST({
                    showRightSidebar: false,
                    showLeftSidebar: true,
                })
            );
        }
        else {
            dispatch(
                actionsCreator.SET_SECTIONS_LIST({
                    showLeftSidebar: false,
                })
            );
        }
    }, [activeSidebar])

    const ActiveComponent = componentMap[activeSidebar];
    return (
        <>
            <motion.div
                className={'sidebarContainer'}
                initial={{ x: '-100%' }}
                animate={{ x: 0 }}
                transition={{ type: 'spring', stiffness: 100, damping: 20 }}
            >
                <button
                    onClick={() => {
                        // setOptionSidebar(true);
                        setActiveSidebar("addSection");
                    }}
                    id='add'
                    className={activeSidebar === "addSection" && 'activeOption'}
                >
                    <Tooltip content="Add Section" position="right">
                        <img src={PlusIcon} alt='icon' height={25} />
                    </Tooltip>
                </button>

                <button
                    onClick={() => {
                        // setOptionSidebar(true);
                        setActiveSidebar("viewSections");
                    }}
                    id='layout'
                    className={activeSidebar === "viewSections" && 'activeOption'}
                >
                    <Tooltip content="View Layout" position="right">
                        <img src={LayoutIcon} alt='icon' height={20} />
                    </Tooltip>
                </button>
                <button
                    onClick={() => {
                        // setOptionSidebar(true);
                        setActiveSidebar("theme");
                    }}
                    id='theme'
                    className={activeSidebar === "theme" && 'activeOption'}
                >
                    <VscColorMode className="v2-theme-icon" />
                </button>
                {/* <button
                    onClick={() => {
                        setOptionSidebar(true);
                        setActiveSidebar("viewPages");
                    }}
                    id="pages"
                    className={activeSidebar === "viewPages" && 'activeOption'}
                >
                    <img src={PageIcon} alt='icon' height={25} />
                </button> */}
            </motion.div >
            {leftSidebarStatus &&
                <motion.div
                    className={'optionContainer'}
                    initial={{ x: '-30px' }}
                    animate={{ x: 0 }}
                    transition={{ type: 'spring', stiffness: 100, damping: 20 }}
                >
                    <div className={'optionHeader'}>
                        <h6 style={{ wordSpacing: "1px" }}>{optionToHeadingMap.get(activeSidebar)}</h6>
                        <button onClick={() => setActiveSidebar("")}>
                            <img src={closeIcon} height={20} />
                        </button>
                    </div>
                    <div style={{ display: 'flex', height: 'calc(100vh - 120px)' }}>
                        {ActiveComponent && (
                            <ActiveComponent
                                handleComponentOptionSelect={handleComponentOptionSelect}
                                setActiveSidebar={setActiveSidebar}
                                setSelectedSectionIdx={setSelectedSectionIdx}
                                selectedSectionIdx={selectedSectionIdx}
                            />
                        )}
                    </div>
                </motion.div>
            }

            <Toaster
                position="top-left"
                reverseOrder={false}
            />
        </>
    );
}

export default LeftSidebar;