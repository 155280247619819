import React, { useEffect, useState, useRef } from 'react';
import styles from "../../Animation.module.scss"
import { useCustomizer } from '../../../../../../Provider/customizerProvider';

export default function Delay() {
    const { onChange } = useCustomizer();
    const [delay, setDelay] = useState(0)

    useEffect(() => { onChange({ "animation-delay": delay + "s" }) }, [delay])

    return (

        <div className={styles.itemWrapper} >
            <div className="flex justify-between items-center relative">
                <h4 className={styles.textLabel}>Delay</h4>
                <div className={`flex items-center gap-05rem ${styles.inputContainer}`}>
                    <input
                        type="decimal"
                        className={`${styles.textInput} flex items-center`}
                        maxLength={10}
                        step="0.01"
                        value={delay}
                        onChange={e => {
                            setDelay(e.target.value);

                        }}
                    />
                    <span className={styles.textLabel}>s</span>

                </div>
            </div>
        </div>

    );
}
