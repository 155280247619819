import { useLogger } from "../../../Hooks/useLogger";
import React from 'react'
import undoIcon from "../../../Assets/Icons/builder/undo.svg";
import redoIcon from "../../../Assets/Icons/builder/redo.svg";
import Tooltip from '../../../Components/TooltipV2';
import "../Builder.scss"
import { useCustomizer } from "../../../Provider/customizerProvider";
export default function Logger() {
    const { setCurrentStyles } = useCustomizer()
    const { redo, undo } = useLogger({ setCurrentStyles })

    return (
        <div className='undo-redo-btn-container'>
            <Tooltip content="Undo" position="left">
                <button onClick={() => undo()}>
                    <img src={undoIcon} height={22} />
                </button>
            </Tooltip>
            <Tooltip content="Redo" position="right">
                <button onClick={() => redo()}>
                    <img src={redoIcon} height={22} />
                </button>
            </Tooltip>

        </div>
    )
}
