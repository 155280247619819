import { call, put, takeLatest, select } from "redux-saga/effects";
import { catalogAPI } from "../../Api";
import { actionsCreator } from "../actions/actionsCreator";
import {
  ADD_PRODUCT,
  FETCH_PRODUCTS,
  UPDATE_PRODUCT,
  //category
  ADD_CATEGORY,
  FETCH_CATEGORIES,
  UPDATE_CATEGORY,
  //Inventory
  UPDATE_INVENTORY,
  FETCH_INVENTORIES,
  ADD_INVENTORY,
  //Stock
  ADD_STOCK,
  UPDATE_STOCK,
  FETCH_STOCKS,
  //sellable inventory
  ADD_SELLABLE_INVENTORY,
  UPDATE_SELLABLE_INVENTORY,
  FETCH_SELLABLE_INVENTORIES,
  FETCH_UNITS,
  ADD_ORDER,
  UPDATE_ORDER,
  FETCH_ORDERS,
} from "../actions/actionTypes";
import get from "lodash/get";
import { toast } from "react-toastify";
import { errorMsg } from "../../Utils";

export const getTenantId = (state) =>
  get(state, "tenantData.selectedTenant.id", "");

function* addProduct(action) {
  try {
    const payload = action.payload;
    payload.append("tenant", yield select(getTenantId));
    yield call(catalogAPI.addProduct, payload);
    toast.success("Product is added successfully");
    yield put(actionsCreator.FETCH_PRODUCTS());
  } catch (error) {
    const msg = errorMsg(error);
    toast.error(msg);
  }
}

function* updateProduct(action) {
  try {
    const id = get(action, "payload.productId", "");
    const payload = get(action, "payload.formData", {});
    payload.append("tenant", yield select(getTenantId));
    yield call(catalogAPI.updateProduct, payload, id);
    toast.success("Product is updated successfully");
    yield put(actionsCreator.FETCH_PRODUCTS());
  } catch (error) {
    const msg = errorMsg(error);
    toast.error(msg);
  }
}

function* fetchProducts(action) {
  try {
    const payload = action.payload ? action.payload : {};
    payload["tenant"] = yield select(getTenantId);
    const response = yield call(catalogAPI.fetchProducts, payload);
    const data = get(response, "data.data", []);
    yield put(actionsCreator.SET_PRODUCTS({ list: data }));
  } catch (error) {
    yield put(actionsCreator.SET_PRODUCTS({ list: [] }));
  }
}

//category saga

function* addCategory(action) {
  try {
    const payload = action.payload;
    payload.append("tenant", yield select(getTenantId));
    yield call(catalogAPI.addCategory, payload);
    toast.success("Product is added successfully");
    yield put(actionsCreator.FETCH_CATEGORIES());
  } catch (error) {
    const msg = errorMsg(error);
    toast.error(msg);
  }
}

function* updateCategory(action) {
  try {
    const id = get(action, "payload.categoryId", "");
    const payload = get(action, "payload.formData", {});
    payload.append("tenant", yield select(getTenantId));
    yield call(catalogAPI.updateCategory, payload, id);
    toast.success("Product is updated successfully");
    yield put(actionsCreator.FETCH_CATEGORIES());
  } catch (error) {
    const msg = errorMsg(error);
    toast.error(msg);
  }
}

function* fetchCatogries(action) {
  try {
    const payload = action.payload ? action.payload : {};
    payload["tenant"] = yield select(getTenantId);
    const response = yield call(catalogAPI.fetchCatogries, payload);
    const data = get(response, "data.data", []);
    yield put(actionsCreator.SET_CATEGORIES({ list: data }));
  } catch (error) {
    yield put(actionsCreator.SET_CATEGORIES({ list: [] }));
  }
}

//invetory saga

function* addInventory(action) {
  try {
    const payload = action.payload;
    payload["tenant"] = yield select(getTenantId);
    yield call(catalogAPI.addInventory, payload);
    toast.success("Product is added successfully");
    yield put(actionsCreator.FETCH_INVENTORIES());
  } catch (error) {
    const msg = errorMsg(error);
    toast.error(msg);
  }
}

function* updateInventory(action) {
  try {
    const payload = action.payload;
    payload["tenant"] = yield select(getTenantId);
    yield call(catalogAPI.updateInventory, payload);
    toast.success("Product is updated successfully");
    yield put(actionsCreator.FETCH_INVENTORIES());
  } catch (error) {
    const msg = errorMsg(error);
    toast.error(msg);
  }
}

function* fetchInventories(action) {
  try {
    const payload = action.payload ? action.payload : {};
    payload["tenant"] = yield select(getTenantId);
    const response = yield call(catalogAPI.fetchInventories, payload);
    const data = get(response, "data.results", []);
    yield put(actionsCreator.SET_INVENTORIES({ list: data }));
  } catch (error) {
    yield put(actionsCreator.SET_INVENTORIES({ list: [] }));
  }
}

//Stock saga
function* addStock(action) {
  try {
    const payload = action.payload;
    payload["tenant"] = yield select(getTenantId);
    yield call(catalogAPI.addStock, payload);
    toast.success("Product is added successfully");
    yield put(actionsCreator.FETCH_STOCKS());
  } catch (error) {
    const msg = errorMsg(error);
    toast.error(msg);
  }
}

function* updateStock(action) {
  try {
    const payload = action.payload;
    payload["tenant"] = yield select(getTenantId);
    yield call(catalogAPI.updateStock, payload);
    toast.success("Product is updated successfully");
    yield put(actionsCreator.FETCH_STOCKS());
  } catch (error) {
    const msg = errorMsg(error);
    toast.error(msg);
  }
}

function* fetchStocks(action) {
  try {
    const payload = action.payload ? action.payload : {};
    payload["tenant"] = yield select(getTenantId);
    const response = yield call(catalogAPI.fetchStocks, payload);
    const data = get(response, "data.results", []);
    yield put(actionsCreator.SET_STOCKS({ list: data }));
  } catch (error) {
    yield put(actionsCreator.SET_STOCKS({ list: [] }));
  }
}

// Sellable invetory saga
function* addSellableInventory(action) {
  try {
    const payload = action.payload;
    payload["tenant"] = yield select(getTenantId);
    yield call(catalogAPI.addSellableInventory, payload);
    toast.success("Product is added successfully");
    yield put(actionsCreator.FETCH_SELLABLE_INVENTORIES());
  } catch (error) {
    const msg = errorMsg(error);
    toast.error(msg);
  }
}

function* updateSellableInventory(action) {
  try {
    const payload = action.payload;
    payload["tenant"] = yield select(getTenantId);
    yield call(catalogAPI.updateSellableInventory, payload);
    toast.success("Product is updated successfully");
    yield put(actionsCreator.FETCH_SELLABLE_INVENTORIES());
  } catch (error) {
    const msg = errorMsg(error);
    toast.error(msg);
  }
}

function* fetchSellableInventories(action) {
  try {
    const payload = action.payload ? action.payload : {};
    payload["tenant"] = yield select(getTenantId);
    const response = yield call(catalogAPI.fetchSellableInventories, payload);
    const data = get(response, "data.data", []);
    yield put(actionsCreator.SET_SELLABLE_INVENTORIES({ list: data }));
  } catch (error) {
    yield put(actionsCreator.SET_SELLABLE_INVENTORIES({ list: [] }));
  }
}

function* fetchUnits(action) {
  try {
    const payload = action.payload ? action.payload : {};
    payload["tenant"] = yield select(getTenantId);
    const response = yield call(catalogAPI.fetchUnits, payload);
    const data = get(response, "data.data", []);
    yield put(actionsCreator.SET_UNITS({ list: data }));
  } catch (error) {
    yield put(actionsCreator.SET_UNITS({ list: [] }));
  }
}

//Order saga

function* updateOrder(action) {
  try {
    const payload = action.payload;
    payload["tenant"] = yield select(getTenantId);
    yield call(catalogAPI.updateOrder, payload);
    toast.success("Product is updated successfully");
    yield put(actionsCreator.FETCH_ORDERS());
  } catch (error) {
    const msg = errorMsg(error);
    toast.error(msg);
  }
}

function* fetchOrders(action) {
  const data = [
    { order_id: "23", status: "Delivered", customer: "Anurag" },
    { order_id: "15", status: "Shipped", customer: "Aashish" },
  ];
  try {
    const payload = action.payload ? action.payload : {};
    payload["tenant"] = yield select(getTenantId);
    const response = yield call(catalogAPI.fetchOrders, payload);
    // const data = get(response, "data.results", []);
    yield put(actionsCreator.SET_ORDERS({ list: data }));
  } catch (error) {
    yield put(actionsCreator.SET_ORDERS({ list: [] }));
  }
}

export default function* itemSaga() {
  yield takeLatest(ADD_PRODUCT, addProduct);
  yield takeLatest(UPDATE_PRODUCT, updateProduct);
  yield takeLatest(FETCH_PRODUCTS, fetchProducts);
  //category
  yield takeLatest(ADD_CATEGORY, addCategory);
  yield takeLatest(UPDATE_CATEGORY, updateCategory);
  yield takeLatest(FETCH_CATEGORIES, fetchCatogries);
  //Inventory
  yield takeLatest(ADD_INVENTORY, addInventory);
  yield takeLatest(UPDATE_INVENTORY, updateInventory);
  yield takeLatest(FETCH_INVENTORIES, fetchInventories);
  //Stock
  yield takeLatest(ADD_STOCK, addStock);
  yield takeLatest(UPDATE_STOCK, updateStock);
  yield takeLatest(FETCH_STOCKS, fetchStocks);
  //Sellable Inventory
  yield takeLatest(ADD_SELLABLE_INVENTORY, addSellableInventory);
  yield takeLatest(UPDATE_SELLABLE_INVENTORY, updateSellableInventory);
  yield takeLatest(FETCH_SELLABLE_INVENTORIES, fetchSellableInventories);
  yield takeLatest(FETCH_UNITS, fetchUnits);
  //Order
  yield takeLatest(UPDATE_ORDER, updateOrder);
  yield takeLatest(FETCH_ORDERS, fetchOrders);
}
