import React from 'react'
import "./Hero.scss";
import { useHistory } from "react-router-dom";
import HeroImg from "../../../Assets/Images/home/hero-v3-image.png"
import Button from '../../../Components/Button/Button';
const Hero = () => {
    const history = useHistory();

    const navigate = (path) => {
        history.push(path);
    };
    return (
        <div className='hero-v3 flex items-center justify-between'>

            <div className='hero-v3-heading-wrapper' >
                <h1 className='hero-v3-h1 flex justify-start flex-col'>
                    <span className='font-bold'>Build Stunning Websites,& Apps
                    </span>
                    <span className='font-bold'>Effortlessly in Seconds!</span>
                </h1>
                <h4 className='hero-v3-h4'>Create professional websites and mobile apps with ease—no coding required, customizable designs, and instant deployment!</h4>

                <div className='flex gap-1rem' style={{ margin: "25px 0px 0px 10px" }}>
                    <Button className="hero-v3-auth-btn" text="Create Now " onClick={() => navigate("/studio")}></Button>
                    {/* <Button className="hero-v3-auth-btn" style={{ backgroundColor: "transparent", color: "black" }} text="Book a Demo "></Button> */}
                </div>
            </div>


            <img src={HeroImg} alt="" loading='lazy' className='hero-v3-image' />

        </div >
    )
}

export default Hero











// <div className='hero-v3-container'>
// <h1>
//     <span style={{ fontSize: '1.3em' }}></span>
//     <span>Build Stunning Websites,& Apps</span>
//     <p style={{ marginTop: "5px" }}>Effortlessly in Seconds!
//         {/* <div className='curved-line'></div> */}
//         <div>
//             <svg width="250" height="15" style={{ translate: "0px -20px" }}
//                 viewBox="0 0 431 19" fill="none" xmlns="http://www.w3.org/2000/svg">
//                 <path d="M2.50002 16.5001L107 8.50006L215.5 2.50002L322 2.50002L428.5 8.50006" stroke="url(#paint0_linear_306_193)" stroke-opacity="0.75" stroke-width="5" stroke-linecap="round" />
//                 <defs>
//                     <linearGradient id="paint0_linear_306_193" x1="8.98514" y1="14.0148" x2="416.985" y2="11" gradientUnits="userSpaceOnUse">
//                         <stop stop-color="#E862FE" />
//                         <stop offset="1" stop-color="#FFC46C" />
//                     </linearGradient>
//                 </defs>
//             </svg>
//         </div>

//     </p>
// </h1>
// <p style={{ marginTop: "-15px" }}>Create professional websites and mobile apps with ease—no coding required, customizable designs, and instant deployment!</p>
// <div className='button-container'>
//     <button onClick={() => navigate("/studio")}>
//         <span>Create Now</span>
//         <svg width="22" height="22" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path fill-rule="evenodd" clip-rule="evenodd" d="M28.3229 0.676997L27.4239 2.57485L16.1738 26.3252L15.1663 28.4519L14.7574 26.1344L12.9736 16.0263L2.86544 14.2425L0.547938 13.8335L2.67471 12.8261L26.425 1.57598L28.3229 0.676997ZM5.44362 13.1743L13.7513 14.6404L14.2683 14.7316L14.3595 15.2486L15.8256 23.5563L25.1693 3.83057L5.44362 13.1743Z" fill="white" />
//         </svg>

//     </button>
//     <button onClick={() => navigate("")}>
//         <span>Book a Demo</span>
//         <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6" height={21}>
//             <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />
//         </svg>

//     </button>
// </div>
// </div>