import React from "react";
//style
import "./StepCard.scss";

export default function StepCard({ step, index }) {
  const { title = "", description = "", className = "" } = step || {};
  return (
    <div className={`StepCard col-md-4 col-sm-4 col-12 ${className}`}>
      <div className="StepCard__Content">
        <div className="StepCard__Content__Head">
          <div className="StepCard__Content__Head__Line"></div>
          <div className="StepCard__Content__Head__Number">{index + 1}</div>
        </div>
        <div className="StepCard__Content__Heading">{title}</div>
        <div className="StepCard__Content__Description">{description}</div>
      </div>
    </div>
  );
}
