import React, { useEffect, useState, useRef, useMemo } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { actionsCreator } from '../../Redux/actions/actionsCreator';
import { fetchConfigByTenant } from '../../Api/tenantAPI';
import useWindowDimensions from '../../Hooks/useWindowDimensions';
import DynamicComponentLoader from '../TemplateBuilderV3/DynamicComponentLoader';

const AuthWrapper = ({ children, requiresAuth, id }) => {
    const auth = JSON.parse(localStorage.getItem('auth')) || {};
    const isLoggedIn = Boolean(auth?.[id]);
    return requiresAuth && !isLoggedIn ? <Redirect to={`/app/${id}`} /> : <>{children}</>;
};

const PageRenderer = ({ sections, pageConfig, requiresAuth, config, id }) => {
    useEffect(() => {
        const [rootContainer] = document.getElementsByClassName('root-container');
        if (rootContainer && pageConfig) {
            rootContainer.style.setProperty('--font', pageConfig?.theme?.fontFamily || config.siteConfig.font);
            rootContainer.style.setProperty('--primary-color', pageConfig?.theme?.primaryColor || config.siteConfig.primaryColor);
            rootContainer.style.setProperty('--secondary-color', pageConfig?.theme?.secondaryColor || config.siteConfig.secondaryColor);
        }
    }, [pageConfig, config]);

    return (
        <AuthWrapper requiresAuth={requiresAuth} id={id}>
            {sections?.map((section, idx) => (
                <DynamicComponentLoader key={idx} type={section.type} {...section} />
            ))}
        </AuthWrapper>
    );
};

const App = () => {
    const [config, setConfig] = useState(null);
    const { id } = useParams();
    const dispatch = useDispatch();
    // const { width } = useWindowDimensions();
    const currentViewMode = useRef("desktop");

    // const determineViewMode = useMemo(() => {
    //     if (width <= 430) return "mobile";
    //     if (width <= 800) return "tablet";
    //     return "desktop";
    // }, [width]);

    // useEffect(() => {
    //     if(determineViewMode === 430 || determineViewMode === 800){
    //         if (determineViewMode !== currentViewMode.current) {
    //             currentViewMode.current = determineViewMode;
    //             dispatch(actionsCreator.SET_SECTIONS_LIST({
    //                 viewMode: determineViewMode,
    //             }));
    //         }
    //     }
    // }, []);
    // console.log(determineViewMode)

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const { data } = await fetchConfigByTenant(id);
                setConfig(data?.template_configs?.[0]?.config);
                dispatch(actionsCreator.SET_SECTIONS_LIST({
                    tenantId: id,
                }));
            } catch (err) {
                console.error(err);
            }
        };
        fetchConfig();
    }, [id]);

    const generateRoutes = useMemo(() => {
        if (!config) return null;
        const baseUrl = `/app/${id}`;
        return Object.keys(config)
            .filter(page => !["siteConfig", "cms_config"].includes(page))
            .map((page, index) => (
                <Route
                    key={index}
                    path={`${baseUrl}${config[page].path}`}
                    exact
                    render={() => (
                        <PageRenderer
                            sections={config[page].sections}
                            pageConfig={config[page]}
                            requiresAuth={config[page].authRequired}
                            config={config}
                            id={id}
                        />
                    )}
                />
            ));
    }, [config, id]);

    return (
        <Router>
            <div className="root-container" style={{ display: 'flex' }}>
                <div className="container-desktop">
                    <Switch>
                        {generateRoutes}
                        <Route
                            path="/app/:id/*"
                            exact
                            render={() => (
                                <PageRenderer
                                    sections={config?.homepageConfig?.sections}
                                    pageConfig={config?.homepageConfig}
                                    requiresAuth={false}
                                    config={config}
                                    id={id}
                                />
                            )}
                        />
                    </Switch>
                </div>
            </div>
        </Router>
    );
};

export default App;
