import { method } from "lodash";
import requestMaker from "../Lib";

export const fetchAllOrders=(data)=>{
    const url = "/shop/tenant/orders/"
    const params={}
    const payload={}
    return requestMaker(url,"get",{params,payload})
}

export const fetchTenantID=(data)=>{
    const url='/account/tenants/fetch_tenant_id/'
    const params={}
    const payload={}
    return requestMaker(url,"get",{params,payload})
}

export const fetchInventorys=(data)=>{
    const url='/shop/inventories/'
    const params={}
    const payload={}
    return requestMaker(url,"get",{params,payload})
}


export const fetchOrderDetails = (data)=>{ 
    const url = `/shop/order/details/`
    const params={ ...data }
    const payload={}
    return requestMaker(url,"get",{params,payload})
}

export const fetchTenantProducts = (result)=>{
  const url = `/shop/products/all/`
  const params =  {}
  const payload = {}
  return requestMaker(url, "get", {params,payload})

}

export const fetchTenantCategories = (result)=>{

    const url = `/shop/tenant/categories/all/`
    const params = {}
    const payload = {}
    return requestMaker(url, "get", {params,payload})

}

//  deleteProduct and delete Categories, subCategories
export const deleteProduct = (data)=>{
    const url = `/shop/tenant/products/`
    const params = {  }
    const payload = { ...data }
    return requestMaker(url, "delete", {params, payload})
}

export const deleteCategory = (data)=>{
    const url = `/shop/tenant/categories/`
    const params = {  }
    const payload = { ...data }
    return requestMaker(url, "delete", {params, payload})
}

export const deleteProductFromCategory = (data)=>{
    const url = `/shop/category/product/`
    const params = {  }
    const payload = { ...data }
    return requestMaker(url, "delete", {params, payload})
}

export const deleteSubCategoryFromCategory = (data)=>{
    const url = `/shop/category/subcategory/`
    const params = {  }
    const payload = { ...data }
    return requestMaker(url, "delete", {params, payload})
}

export const addCategory = (data)=>{
    const getFormData = data => Object.keys(data).reduce((formData, key) => {
        formData.append(key, data[key]);
        return formData;
    }, new FormData());
    const url = `/shop/post/add_category/`
    const params = {  }
    const payload = getFormData(data);
    return requestMaker(url, "post", {params,payload},{
        'Content-Type': 'multipart/form-data'
    })
}

export const editCategory = (data)=>{
    const getFormData = data => Object.keys(data).reduce((formData, key) => {
        formData.append(key, data[key]);
        return formData;
    }, new FormData());
    const url = `/shop/tenant/categories/`
    const params = {  }
    const payload = getFormData(data)
    return requestMaker(url, "patch", {params,payload})
}
export const addProductToCategory = (data)=>{
    const getFormData = data => Object.keys(data).reduce((formData, key) => {
        formData.append(key, data[key]);
        return formData;
    }, new FormData());
    const url = `/shop/category/product/`
    const params = {  }
    const payload = getFormData(data)
    return requestMaker(url, "patch", {params,payload})
}
export const linkProductToCategory = (data)=>{
    const url = `/shop/category/product/`
    const params = {  }
    const payload = { ...data }
    return requestMaker(url, "put", {params,payload})
}

export const fetchAllTenantProducts = (data)=>{
    const url = `/shop/tenant/products/all/`
    const params = { ...data }
    const payload = {}
    return requestMaker(url, "get", {params,payload})
}

export const addSubCategory = (data) => {
    const getFormData = data => Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
  }, new FormData());
    const url = `/shop/category/subcategory/`;
    const params = {};
    const payload = getFormData(data);
    return requestMaker(url, "put", { params, payload },  {
      'Content-Type': 'multipart/form-data',
    });
  };

  export const editProduct = (data) => {
    const getFormData = data => Object.keys(data).reduce((formData, key) => {
      formData.append(key, data[key]);
      return formData;
  }, new FormData());
  
    const url = `/shop/tenant/products/`;
    const params = {};
    const payload = getFormData(data);
    return requestMaker(url, "patch", { params, payload }, {
      'Content-Type': 'multipart/form-data',
    });
  };

  export const fetchleafcategory = (data) => {
    const { leaf } = data;
    const url = `/shop/categories/leaf/`;
    const params = {};
    const payload = {};
    return requestMaker(url, "get", { params, payload });
  };


  export const fetchTenantUsers = (data)=>{
    const url = `/account/tenant/users`;
    const params = {...data}
    const payload = {};
    return requestMaker(url, "get", {params,payload})
}

export const editTenantUsers = (data)=>{
    const url = `/account/tenant/users`;
    const params = {}
    const payload = {...data};
    return requestMaker(url, "patch", {params,payload})
}

export const deleteTenantUsers = (data)=>{
    const url = `/account/tenant/users`;
    const params = {}
    const payload = {...data};
    return requestMaker(url, "delete", {params,payload})
}
export const addTenantUser = (data)=>{
    const url = `/account/tenant/users`;
    const params = {}
    const payload = {...data};
    return requestMaker(url, "post", {params,payload})
}

export const addStock = (data) => {
    const url = `/shop/post/stock/`;
    const params = {};
    const payload = { ...data };
    return requestMaker(url, "post", { params, payload });
};

export const addInventory = (data) => {
    const url = `/shop/post/inventory/`;
    const params = {};
    const payload = { ...data };
    return requestMaker(url, "post", { params, payload });
  };

  export const addUnit = (data) => {
    const url = `/shop/post/stockunit/`;
    const params = {};
    const payload = { ...data };
    return requestMaker(url, "post", { params, payload });
  };

  export const addProduct = (data) => {
    const url = `/shop/post/add_product/`;
    const params = {};
    const payload = { ...data };
    return requestMaker(url, "post", { params, payload },  {
      'Content-Type': 'multipart/form-data'
    });
  };

  export const searchProduct = (data) => {
    const { inv } = data;
    const url = `/shop/productsearch/?inv=${inv}`;
    const params = {};
    const payload = {};
    return requestMaker(url, "get", { params, payload });
  };

  export const stockDropdowndetail = (data) => {
    const url = `/shop/get/stock/dropdowndetail/`;
    const params = { ...data };
    const payload = {};
    return requestMaker(url, "get", { params, payload });
  };

  export const fetchTenantSellableInventories = (data)=>{
    const url = `/shop/tenant/sellableInventories`;
    const params = {...data.data};
    const payload = {...data.payload};
    return requestMaker(url, "get", { params, payload });
 }
 export const addTenantSellableInventory = (data)=>{
   const url = `/shop/tenant/sellableInventories`;
   const params = {};
   const payload = { ... data };
   return requestMaker(url, "post", { params, payload });
}
export const editTenantSellableInventory = (data)=>{
   const url = `/shop/tenant/sellableInventories`;
   const params = {};
   const payload = { ... data };
   return requestMaker(url, "patch", { params, payload });
}
export const deleteTenantSellableInventory = (data)=>{
   const url = `/shop/tenant/sellableInventories`;
   const params = {};
   const payload = { ... data };
   return requestMaker(url, "delete", { params, payload });
}

export const fetchCompanyUsers = (data)=>{
  const url = `/account/tenant/tenant_users/`;
  const params = {...data};
  const payload = {}
  return requestMaker(url, "get", { params, payload });
}

export const addCompanyUsers = (data)=>{
  const url = `/account/tenant/tenant_users/`;
  const params = {};
  const payload = {...data}
  return requestMaker(url, "post", { params, payload });
}
export const editCompanyUsers = (data)=>{
  const url = `/account/tenant/tenant_users/`;
  const params = {};
  const payload = {...data}
  return requestMaker(url, "patch", { params, payload });
}
export const deleteCompanyUsers = (data)=>{
  const url = `/account/tenant/tenant_users/`;
  const params = {};
  const payload = {...data}
  return requestMaker(url, "delete", { params, payload });
}

export const sellableProductSearch = (data) => {
  const { search, page, inventory_id } = data;
  const url = `/shop/sellableproductsearch/${search}/`;
  const params = page ? { page: page } : {};
  const payload = {inventory_id};
  return requestMaker(url, "get", { params, payload });
};
export const fetch_invoices = (offset = 0, limit = 10) => {
  const url = `/shop/invoices?limit=${limit}&offset=${offset}`;
  const params = {};
  const payload = {};
  return requestMaker(url, "get", { params, payload });
};
export const delete_invoices = (id) => {
  const url = `/shop/invoices/${id}/`;
  const params = {};
  const payload = {};
  return requestMaker(url, "delete", { params, payload });
};
export const patchInvoice = (data,orderid) => {
  const url = `/shop/order/${orderid}/`;
  const params = {};
  const payload = {...data};
  return requestMaker(url, "patch", { params, payload});
}
export const delete_elements_from_invoices = (invoiceid,id) => {
  // const string = invoiceid.toString()
  // delete_inv_item/<int:invoice_id>/<int:item_id>/'
  const url = `/shop/delete_inv_item/${invoiceid}/${id}/`;
  const params = {};
  const payload = {};
  return requestMaker(url, "post", { params, payload });
};

export const createInvoice = (data) => {
  const url = `/shop/invoices/`;
  const params = {};
  const payload = {...data};
  return requestMaker(url, "post", { params, payload});
}
export const updateInvoice = (data,invoiceId) => {
  const url = `/shop/invoices/${invoiceId}/`;
  const params = {};
  const payload = {...data};
  return requestMaker(url, "patch", {params, payload});
}
export const mark_invoice_proforma = (id)=>{
  const url = `/shop/cms/invoices/proforma`
  const params =  {};
  const payload = {"invoice_id": id};
  return requestMaker(url, "patch", { params, payload });
}

export const fetch_stock_history = (offset)=>{
  const url = `/shop/stocks/?limit=10&offset=${offset}`
  const params =  {};
  const payload = {};
  return requestMaker(url, "get", { params, payload });
}

export const fetch_entities = () => {
  const url = `/shop/entities/`
  const params = {};
  const payload = {};
  return requestMaker(url, "get", {params,payload});
}

export const fetch_hsnCodes = () => {
  const url = `/shop/hsncodes/`
  const params = {}
  const payload = {}
  return requestMaker(url,"get", {params,payload})
}
export const add_hsnCode = (data) => {
  const url = `/shop/hsncodes/`
  const params = {}
  const payload = {...data}
  return requestMaker(url,"post",{params,payload})
}

export const add_Entity = (data) => {
  const url = `/shop/entities/`
  const params = {}
  const payload = {...data}
  return requestMaker(url,"post", {params,payload})
}

export const fetch_Purchases = (invoiceId) => {
  const url = `/shop/purchase/${invoiceId}/`;
  const params = {};
  const payload = {};
  return requestMaker(url,"get",{params,payload});
}

export const fetchOrderSalesData = async () => {
  const url = `/dashboard/analytics/get_order_sales_data`;
  return requestMaker(url, "get", {});
};

export const fetchPayment = async (entityId,status) => {
  const url = `/shop/payments/?entity_id=${entityId}&status=${status}`;
  return requestMaker(url, "get", {});
}

export const fetchAllBulkPayments = (offset=0) => {
  const limit = 10;
  const url = `/shop/bulk-payments/?limit=${limit}&offset=${offset}`;
  return requestMaker(url, "get", {});
}

export const searchEntities = (query) => {
  const url = `/shop/entities/?searchKey=${query}`;
  return requestMaker(url, "get", {});
}
export const searchManufacturers = (query) => {
  const url = `/shop/manufacturers/?search=zimmer ${query}`;
  return requestMaker(url, "get", {});
}
export const getManufacturers = () => {
  const url = `/shop/manufacturers/`;
  return requestMaker(url, "get", {});
}

export const searchChallans = (query) => {
  const url = `/shop/challan/searchitem/?searchKey=${query}`;
  return requestMaker(url, "get", {});
}
export const createNote = (data) => {
  const url = `/shop/notes/`;
  const payload = {...data};
  return requestMaker(url, "post", {payload});
}
export const Postmanufacturer = (data) => {
  const url = `/shop/manufacturers/`;
  const payload = {...data};
  return requestMaker(url, "post", {payload});
}

export const fetchAllNotes = () => {
  const url = `/shop/notes/`;
  return requestMaker(url, "get", {});
}

export const searchInvoices = (searchKey) => {
  const url = `/shop/invoices?searchKey=${searchKey}`;
  return requestMaker(url,"get",{});
}

export const fetchChallanTemplates = () => {
  const url = `/shop/template/details/?type=CHALLAN`;
  return requestMaker(url, "get", {});
}

export const updateInvoiceDateAndNo = (invoiceId, data) => {
  const url = `/shop/invoices/${invoiceId}/modify-fields/`;
  const payload = {...data};
  return requestMaker(url, "patch", {payload});
}
export const getTenderSerialNo = (productId) => {
  const url = `/shop/get_tender_sn_from_product/${productId}/`;
  return requestMaker(url, "get", {});
}
export const delChallan = (id)=>{
  const url = `/shop/contracts/${id}/delete/`
  return requestMaker(url, "delete", {});

}
export const updateChallanDetails = (id,payload)=>{
  const url = `/shop/challans/${id}/`
  return requestMaker(url, "patch", {payload});

}
export const convertToInvoice =(id)=>{
  const url = `/shop/challans/${id}/convert_to_invoice/`
  return requestMaker(url, "post", {});
}

export const fetchAllInvoicesAtOnce = () => {
  const url = `/shop/get_invoices/`;
  return requestMaker(url, "get", {});
}

export const fetchAllChallansAtOnce = () => {
  const url = `/shop/get_challans/`;
  return requestMaker(url,"get",{});
}

export const createNewDiscount = (data) => {
  const url = `/shop/discounts/`;
  const payload = {...data};
  return requestMaker(url, "post" , {payload});
}

export const getAllDiscounts = () => {
  const url = `/shop/discounts/`;
  return requestMaker(url, "get", {});
}

export const getAvailableDiscounts = (entityId, inventoryId) => {
  const url = `/shop/discounts/filter_discounts/?inventory=${inventoryId}&entity=${entityId}`;
  return requestMaker(url, "get", {});
}

export const applyDiscount = (data) => {
  const url = `/shop/discounts/apply_discount/`;
  const payload = {...data};
  return requestMaker(url, "post", {payload});
}
export const fetchInvoiceFilter = ()=>{
  const url = `/shop/invoices/filter-options/`;
  return requestMaker(url, "get", {});

}
export const fetchAnalytics=()=>{
  const url = '/dashboard/analytics/get_analytics_graph'
  const params={}
  const payload={}
  return requestMaker(url,"get",{params,payload})
}

export const removeDiscount = (data) => {
  const url = `/shop/discounts/remove_discount/`;
  const payload = {...data};
  return requestMaker(url, "post", {payload});
}