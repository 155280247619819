import React, { useState } from 'react';
import styles from "../../theme.module.scss";
import { IoEyedrop } from "react-icons/io5";
function adjustBrightness(color, amount) {
    let colorValue = color.startsWith('#') ? color.slice(1) : color;
    let num = parseInt(colorValue, 16);

    let r = (num >> 16) + amount;
    let g = ((num >> 8) & 0x00FF) + amount;
    let b = (num & 0x0000FF) + amount;

    r = Math.max(0, Math.min(255, r));
    g = Math.max(0, Math.min(255, g));
    b = Math.max(0, Math.min(255, b));

    return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
}

export default function TextColor() {
    const [color, setColor] = useState('#ffffff');
    const lighter1 = adjustBrightness(color, 180);
    const lighter2 = adjustBrightness(color, 90);
    const darker1 = adjustBrightness(color, -90);
    const darker2 = adjustBrightness(color, -180);

    return (
        <div className={styles.itemWrapper}>
            <div className="flex justify-start relative flex-col">
                <h4 className={styles.textLabel}>Text Color</h4>

                <div className={`${styles.colorWrapper} flex gap-175rem`}>
                    <div
                        className={`${styles.colorPreview} flex items-center justify-center `}

                    >
                        <input
                            type="color"
                            className={styles.colorInput}
                            onChange={e => setColor(e.target.value)}
                            value={color}
                        />
                        < IoEyedrop style={{ 'fill': "#cacaca" }} />
                    </div>

                    <div className={`${styles.colorCardWrapper} flex items-center`}>
                        {/* Display color boxes */}
                        <div className={styles.colorCard} style={{ backgroundColor: lighter1 }}></div>
                        <div className={styles.colorCard} style={{ backgroundColor: lighter2 }}></div>
                        <div className={styles.colorCard} style={{ backgroundColor: color }}></div>
                        <div className={styles.colorCard} style={{ backgroundColor: darker1 }}></div>
                        <div className={styles.colorCard} style={{ backgroundColor: darker2 }}></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
