import React, { useEffect, useRef } from "react";
import "./SetupSteps.scss";
import Step1 from "../../../Assets/Images/V3/setup-steps/step1.png";
import Step2 from "../../../Assets/Images/V3/setup-steps/step2.png";
import Step3 from "../../../Assets/Images/V3/setup-steps/step3.png";

const SetupSteps = () => {
  const steps = [
    {
      title: "Signup",
      description: "Sign Up with your email address Or mobile number.",
      image: Step1,
    },
    {
      title: "Business Details",
      description: "Add your Business details - Name, Logo, Address.",
      image: Step2,
    },
    {
      title: "Plan Selection",
      description:
        "Select your plan. Upload your product inventory in an excel sheet and you are ready to sell through your app and website.",
      image: Step3,
    },
  ];

  const stepRefs = useRef(steps.map(() => React.createRef()));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          }
        });
      },
      { threshold: 0.1 }
    );

    stepRefs.current.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      stepRefs.current.forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);

  return (
    <div className="home-v3-setup-steps flex items-center justify-between">

      <h1 className='home-v3-setup-steps-h1 flex justify-start flex-col font-bold'>
        <span>Turn your idea to</span>
        <span>reality</span>
      </h1>

      <div className="home-v3-setup-steps-wrapper
      flex justify-start items-start flex-col home-v3-setup-step-p">
        <p>
          <span className="font-bold">
            Sign Up
          </span>, Sign Up with your <span className="font-bold">Email </span> or <span className="font-bold">Mobile Number</span></p>
        <p><span className="font-bold">Business Details</span>, Add your Business details <span className="font-bold">Name</span>, <span className="font-bold">Logo</span> and <span className="font-bold"> Address</span></p>
        <p><span className="font-bold">Plan Selection</span> ,Select your plan. Upload your product inventory in an excel sheet and you are ready to sell through your app and website.</p>
      </div>
      {/* <p className="home-v3-setup-steps-heading">
        
      </p>
      <div className="steps-container">
        {steps.map((step, index) => (
          <div
            key={index}
            ref={stepRefs.current[index]}
            className="step"
            style={{ flexDirection: index % 2 !== 0 ? "row-reverse" : "row" }}
          >
            <img src={step.image} alt="" />
            <div className="step-content">
              <span className="step-heading">{`${index + 1}. ${step.title}`}</span>
              <p>{step.description}</p>
            </div>
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default SetupSteps;