import WebFont from 'webfontloader';
import { useEffect, Children, cloneElement } from 'react';
const FontPreviewWrapper = ({ fontFamily = "Roboto", children }) => {
    useEffect(() => {
        WebFont.load({
            google: {
                families: [fontFamily],
            },
        });
    }, [fontFamily]);

    return (
        <>
            {Children.map(children, (child) =>
                cloneElement(child, {
                    style: { ...child.props.style, fontFamily },
                })
            )}
        </>
    );
};

export default FontPreviewWrapper;