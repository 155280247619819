import { TENANT_REGISTRATION_SLIDES } from "../Assets/Constant";
import { isEmpty, get, has } from "lodash";

export const fetchTenantInupts = (slides) => {
  const data = {};
  if (slides && slides.length > 0) {
    slides.forEach((slide) => {
      const inputs = slide.inputs;
      if (inputs) {
        Object.keys(inputs).forEach((key) => {
          data[key] = inputs[key]["defaultValue"];
        });
      }
    });
  }
  return data;
};

export const fetchTenantInitialErrors = () => {
  const slides = TENANT_REGISTRATION_SLIDES;
  let errors = {};
  if (slides && slides.length > 0) {
    slides.forEach((slide) => {
      errors = { ...errors, ...slide.inputs };
    });
  }
  return errors;
};

export const extractTenantRegistrationErrors = (error) => {
  const slides = TENANT_REGISTRATION_SLIDES;
  const allInputs = fetchTenantInupts(slides);
  const errorObj = {};
  const errorFields = get(error, "response.data", {});
  if (!isEmpty(errorFields)) {
    for (let key of Object.keys(errorFields)) {
      if (key && has(allInputs, key)) {
        errorObj[key] = {
          error: true,
          msg: errorFields[key] ? errorFields[key][0] : errorFields[key],
        };
      }
    }
  }
  return errorObj;
};

export const extractuserRegistrationError = (response) => {
  let errorMsg = "";
  const data = get(response, "data.data", {});
  if (data && data.Status === "Error") {
    errorMsg = get(data, "Details", "");
  }
  return errorMsg;
};
