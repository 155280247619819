import React from "react";
import Nav from "./Nav";
import Sidebar from "./Sidebar";
import "./Board.scss";

export default function Board({ children }) {
  return (
    <div className="Board">
      <div className="Board__Nav">
        <Sidebar />
      </div>
      <div className="Board__Content">
        <Nav />
        <div className="Board__Content__Area">{children}</div>
      </div>
    </div>
  );
}
