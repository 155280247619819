import React, { useEffect } from "react";
import { getLoadingText, componentTypeToPathMap } from "./utils";
import { motion } from "framer-motion";
import { Suspense } from "react";

const DynamicComponentLoader = React.forwardRef(({ type, delay, onLoad, onAnimationComplete, ...props }, ref) => {
    console.log(type)
    const loadComponent = componentTypeToPathMap.get(type);

    useEffect(() => {
        if (onLoad && loadComponent) {
            onLoad();
        }
    }, [loadComponent, onLoad]);

    if (!loadComponent) {
        return null;
    }

    const Component = React.lazy(loadComponent);
    const loadingText = getLoadingText(type);

    return (
        <motion.div
            className="dynamic-component-container"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: delay }}
            onAnimationComplete={onAnimationComplete}
            ref={ref}
            tabIndex={-1}
        >
            <Suspense fallback={<div className="loading-text">
                {`Loading ${loadingText}...`}
            </div>}>
                <Component type={type} {...props} />
            </Suspense>
        </motion.div>
    );
});

export default DynamicComponentLoader;
