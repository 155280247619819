import React, { useState, useRef, useEffect } from 'react'
import styles from "../../theme.module.scss"
import { useCustomizer } from '../../../../../../Provider/customizerProvider';
import FontPickerModal from './FontPickerModal';
export default function FontPicker() {
    const { theme } = useCustomizer()
    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);

    const toggleModal = () => {
        setIsOpen(prev => setIsOpen(!prev))
    }

    useEffect(() => {
        if (isOpen) {
            ref.current?.showModal()
        }
        else {
            ref.current?.close()
        }
    }, [isOpen])
    return (
        <>

            <div className={`${styles.designWrapper}`}>
                <div className={`flex justify-between items-center relative  ${styles.dropdownHeader}`}
                    onClick={() => toggleModal()}>
                    <h1 className={`${styles.dropdownLabel} font-semibold`}>Font Style</h1>
                    <span className={styles.textLabel}>
                        {theme.font}
                    </span>
                </div>
            </div>

            {isOpen && <FontPickerModal ref={ref} closeModal={() => toggleModal()} />}

        </>
    )
}
