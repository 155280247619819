import React, { useState, useEffect } from 'react';
import { IoClose } from "react-icons/io5";
import { useCustomizer } from '../../../../../../Provider/customizerProvider';
import { IoSearch } from "react-icons/io5";
import fonts from "./../../../../../../Assets/fonts/static.json";
import styles from "./modal.module.scss";
import FontPreviewWrapper from '../../../../../Font';
const SearchBar = ({ value, onChange }) => {
    return (
        <div className={`${styles.sampleText} flex justify-between items-center`}>
            <input
                type="text"
                defaultValue={value}
                onChange={e => onChange(e.target.value)}
                placeholder="Search fonts..."
                className={styles.fontSearchBarInput}
            />
            <IoSearch className={styles.searchIcon} />
        </div>
    );
};

const SearchItems = ({ font, selectedValue, onClick }) => {
    return (
        <FontPreviewWrapper fontFamily={font}>
            <div
                className={`${styles.fontItem} ${font === selectedValue ? styles.isSelected : null}`}
                onClick={onClick}
            >
                {font}
            </div>
        </FontPreviewWrapper>
    );
};

const SearchWrapper = ({ fonts, selectedValue, setSelectedValue }) => {
    return (
        <div className={`${styles.fontWrapper}`}>
            {fonts.map((ele) => (
                <SearchItems
                    key={ele}
                    font={ele}
                    selectedValue={selectedValue}
                    onClick={() => setSelectedValue(ele)}
                />
            ))}
        </div>
    );
};

const FontPreview = ({ fontFamily }) => {
    return (
        <FontPreviewWrapper fontFamily={fontFamily}>
            <input className={`${styles.sampleText}`} defaultValue={"This is a sample Text"} />
        </FontPreviewWrapper>
    );
};


const FontPickerModal = React.forwardRef(({ closeModal }, ref) => {
    const { theme, onThemeChange } = useCustomizer();
    const [input, setInput] = useState(theme.font || theme.fontFamily);
    const [selectedValue, setSelectedValue] = useState(theme.font || theme.fontFamily);
    const [searchResult, setSearchResult] = useState(fonts);


    const handleSearch = (query) => {
        setInput(query);
        const filteredFonts = fonts.filter((font) => font.toLowerCase().includes(query.toLowerCase()));
        setSearchResult(filteredFonts);
    };
    const handleFontSelect = (font) => {
        setSelectedValue(font);

    };

    useEffect(() => {
        setInput(selectedValue);
    }, [selectedValue]);

    useEffect(() => {
        if (!input || !fonts.includes(input)) {
            setInput(selectedValue);
        }
    }, [input, selectedValue, fonts]);

    return (
        <div className={styles.overlay}>
            <dialog ref={ref} className={`${styles.fontModal} flex flex-col gap-1rem`}>
                <div className='flex items-center justify-between'>
                    <h4 className={styles.fontHeader}>Font Selector</h4>
                    <IoClose onClick={closeModal} className={styles.closeIcon} />
                </div>


                <FontPreview fontFamily={selectedValue} />


                <SearchBar default={input} onChange={handleSearch} />


                <SearchWrapper
                    fonts={searchResult}
                    selectedValue={selectedValue}
                    setSelectedValue={handleFontSelect}
                />
                <button onClick={() => {
                    onThemeChange({ font: selectedValue });
                    closeModal();
                }} className={styles.activeButton}>Apply</button>
            </dialog>
        </div>
    );
});

export default FontPickerModal;
