import React from "react";
import { NavLink } from "react-router-dom";
import "./Item.scss";

export default function Item({ item }) {
  const { title = "", items = [] } = item;
  return (
    <div className="Item">
      {title && <div className="Item__Title">{title}</div>}
      <div className="Item__ChildItems">
        {items.map((childItem) => {
          const {
            text = "",
            icon = "",
            path = "",
            exact = false,
          } = childItem || {};
          return (
            <NavLink exact={exact} to={path} activeClassName="Active">
              <div className="Item__ChildItems__ChildItem">
                <div className="Item__ChildItems__ChildItem__Icon">
                  <img src={icon} />
                </div>
                <div className="Item__ChildItems__ChildItem__Text">{text}</div>
              </div>
            </NavLink>
          );
        })}
      </div>
    </div>
  );
}
