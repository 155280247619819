import React, { useEffect, useState } from "react";
import { useDrop } from "react-dnd";

const DroppableDiv = ({ children, className, style, handleDrop, title, tickets }) => {

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "ticket",
    drop: (result) => handleDrop(result, title),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }), [title]);

  return (
    <div ref={drop} className={className} style={style}>
      {children}
    </div>
  );
};

export default DroppableDiv;
