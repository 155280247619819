import React from "react";
import avatarImg from "../../../Assets/Images/avatar.png";
import "./Profile.scss";

export default function Profile() {
  return (
    <div className="Profile">
      <div className="Profile__Image">
        <img src={avatarImg} alt="" />
      </div>
      <div className="Profile__Name">Bye Wind</div>
    </div>
  );
}
