import React from 'react'
import { motion } from 'framer-motion'
import Font from './Components/Font'
import Color from './Components/Color'
export default function Theme() {
    return (
        <motion.div>
            <Font></Font>
            <Color></Color>
        </motion.div>
    )
}
