import React, { useEffect, useState } from 'react';
import styles from "../../Design.module.scss"
import { useCustomizer } from '../../../../../../Provider/customizerProvider';
import { MdHeight } from "react-icons/md";
import { useUtils } from '../../../../../../Hooks/useUtils';
export default function Height() {

    const { parseNumber } = useUtils();
    const { onChange, currentStyles } = useCustomizer()
    const [height, setHeight] = useState(parseNumber(currentStyles['height']));

    useEffect(() => {
        if (height) {
            onChange({ 'height': height })
        }
    }, [height])
    useEffect(() => { setHeight(parseNumber(currentStyles['height'])) }, [currentStyles])

    return (
        <div className={styles.designWrapper} >
            <div className="flex justify-between items-center relative">
                <h4 className={styles.textLabel}>Height</h4>
                <div className={`flex items-center gap-05rem ${styles.inputContainer}`}>
                    <MdHeight />
                    <input
                        type="number"
                        className={`${styles.textInput} flex items-center`}
                        // max={100}
                        value={height}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                                setHeight(value);
                            }
                        }}
                    />
                    <span className={styles.textLabel}>px</span>

                </div>
            </div>
        </div>
    );
}
