import React from "react";
import './cardItems.scss'
import CardItemsTemplate from "./CardItemsTemplate";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

export default function Carditems({data, onClick}) {
    const history = useHistory()
    const location = useLocation();
    // const data = location.state ;

    // if(Object.is(data,undefined)){
    //     history.push('/cms');
    //     window.location.reload(true)
    // }

    // function clickHandler() {
    //     history.push('/cms');
    // }

    if( data === null || data===undefined ||  data.length <= 0){
        return (
            <p style={{textAlign : 'center'}}>
                No Orders to display
            </p>
        )
    }

    return (
        <div 
            className="table-stock overflowY-scroll" 
            style={{
                flexDirection : 'column',
                gap : '20px',
                padding : '2rem', 
                // minWidth : '40rem', 
                minHeight : '30rem',
                display : 'flex',
                overflow : 'scroll',
            }}
        >
            <table className="overflow-scroll flex-1">
                <thead className="">
                    <th>Order Id</th>
                    <th>Product Id</th>
                    <th>Title</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Final Price</th>
                </thead>

                <tbody>
                    {
                        data.map((record, index) => {
                            return (
                                <tr className="" key={`${record.product_id}`}>
                                    <td>{record.order_id}</td>
                                    <td>{record.product_id}</td>
                                    <td>{record.title}</td>
                                    <td>{record.quantity}</td>
                                    <td>{record.price}</td>
                                    <td>{record.final_price}</td>
                                </tr>
                            );
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

