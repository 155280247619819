import React from "react";
import { Header, FooterV2, Footer } from "../Components";
import HeaderV3 from "../Components/HeaderV3";

export default function PublicRoute({ children }) {
  return (
    <div className="PublicRoute" style={{ height: "100vh" }}>
      <HeaderV3 />
      {children}
      {/* <FooterV2 /> */}
      <Footer />
    </div>
  );
}
