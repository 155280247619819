import React from "react";
import "./BrandCategory.scss";

export default function BrandCategory({ categoryItem, isActive, onClick }) {
  const { name = "", logo_url = "", description = "" } = categoryItem || {};
  return (
    <div
      className={`CategoryCard ${isActive ? "Active" : ""} flex-row gap-10 items-center justify-center`}
      onClick={onClick}
    >
      {
        logo_url &&
        <div className="CategoryCard__Icon">
          <img src={logo_url} />
        </div>
      }
      <div className="flex-column items-center justify-between">
        <div className="CategoryCard__Label">{name}</div>
        <div className="CategoryCard__Desc">{description}</div>
      </div>
    </div>
  );
}
