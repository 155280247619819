import { crmApi } from "../../Api";
import { FETCH_ALL_TICKETS, FETCH_WORKFLOW_CONFIGS } from "../actions/actionTypes";
import { actionsCreator } from "../actions/actionsCreator";
import { call, put, takeLatest } from 'redux-saga/effects';
import get from 'lodash/get';


function* fetchCrmTickets(action) {
    try {
        const { activeComponent } = action
        const response = yield call(crmApi.fetchTickets, {activeComponent: activeComponent});
        const data = get(response, 'data', [])
        yield put(actionsCreator.SET_ALL_TICKETS({ ticketList: data }));
    } catch (error) {

    }
}

function* fetchCrmWorkFlow(action) {
    try {
        const response = yield call(crmApi.fetchWorkflows, {});

        const data = get(response, 'data', [])
        yield put(actionsCreator.SET_WORKFLOW_CONFIGS({ workflow: data }));
    } catch (error) {

    }
}

export default function* crmSagas() {
    yield takeLatest(FETCH_ALL_TICKETS, fetchCrmTickets);
    yield takeLatest(FETCH_WORKFLOW_CONFIGS, fetchCrmWorkFlow);
}