import styles from "../../Design.module.scss"
import React, { useEffect, useState, useRef } from 'react';

import { useCustomizer } from '../../../../../../Provider/customizerProvider';
export default function Color() {
    const { onChange, currentStyles} = useCustomizer()
    const [color, setColor] = useState(currentStyles['color']);
    const targetRef = useRef();


    useEffect(() => {
        if (color) {
            onChange({ 'color': color })
        }
    }, [color])

    useEffect(() => {
        setColor(currentStyles['color'])
    }, [currentStyles])


    return (
        <div className={styles.itemWrapper} >
            <div className="flex justify-between items-center relative">
                <h4 className={styles.textLabel}>Color</h4>
                <div
                    style={{ backgroundColor: color }}
                    className={`${styles.colorPreview}`}
                >
                    <input

                        type="color"
                        className={styles.colorInput}
                        onChange={e => setColor(e.target.value)}
                        value={color}
                    />

                </div>


            </div>
        </div >
    );
}
