import React, { useEffect } from "react";
import { LabelledInput, LabelledSelect, SideModal } from "../../../Components";
import "./ManageStock.scss";
import { useState } from "react";
import { actionsCreator } from "../../../Redux/actions/actionsCreator";
import { validate } from "../../../Utils/validations-utils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty, get } from "lodash";
import { formatOptionsData } from "../../../Utils";
import { UNITS } from "../../../Assets/Constant";

const mapStateToProps = ({
  products = {},
  inventories = {},
  auth = {},
  units = {},
}) => ({
  products,
  inventories,
  auth,
  units,
});

export default function ManageStock() {
  const {
    products: { list: productList = [] },
    inventories: { list: inventoryList = [] },
    units: { list: unitsData = [] },
    auth: { user = {} },
  } = useSelector(mapStateToProps);
  const [data, setData] = useState({
    units: "",
    product: "",
    quantity: "",
    inventory: "",
  });
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const { state: stockData = {} } = location || {};
  const {
    units = "",
    product = "",
    quantity = "",
    inventory = "",
  } = data || {};

  const stockId = stockData ? stockData.id : "";
  const title = stockId ? "Update Stock" : "Add Stock";

  useEffect(() => {
    fetchInventories();
    fetchProducts();
    fetchUnits();
  }, []);

  const fetchInventories = () => {
    dispatch(actionsCreator.FETCH_INVENTORIES());
  };

  const fetchProducts = () => {
    dispatch(actionsCreator.FETCH_PRODUCTS());
  };

  const fetchUnits = () => {
    dispatch(actionsCreator.FETCH_UNITS());
  };

  const addStock = async () => {
    const payload = { ...data };
    payload["user"] = get(user, "id", "") || 1;
    dispatch(actionsCreator.ADD_STOCK(payload));
  };

  const updateStock = async () => {
    const payload = { ...data, id: stockId };
    payload["user"] = get(user, "id", "") || 1;
    dispatch(actionsCreator.UPDATE_STOCK(payload));
  };

  const onChange = (e, key, nonEvent) => {
    const value = nonEvent ? e : get(e, "target.value", "");
    const updatedData = { ...data, [key]: value };
    setData(updatedData);
  };

  const onSave = () => {
    if (stockId) {
      updateStock();
    } else {
      addStock();
    }
    history.goBack();
  };

  const validateInput = (value, rules, key) => {
    const { error = false, msg = "" } = validate(value, rules);
    const errorObj = { ...errors, [key]: { error, msg } };
    setErrors(errorObj);
  };

  useEffect(() => {
    fillForm(stockData);
  }, [stockData]);

  const fillForm = (stockData) => {
    if (!isEmpty(stockData)) {
      const formData = {};
      const idKeys = ["inventory", "product", "units"];
      Object.keys(data).forEach((key) => {
        formData[key] = idKeys.includes(key)
          ? get(stockData, `${key}.id`, "")
          : stockData[key];
      });
      setData(formData);
    }
  };

  const inventories = formatOptionsData(inventoryList, "name", "id");
  const products = formatOptionsData(productList, "product_name", "id");
  const unitList = formatOptionsData(unitsData, "name", "id");
  return (
    <SideModal title={title} onSave={onSave}>
      <div>
        <LabelledSelect
          className={"SmallInput"}
          label={"Inventory"}
          data={inventories}
          onSelect={(e) => {
            onChange(e, "inventory", true);
            validateInput(e, ["isRequired"], "inventory");
          }}
          placeholder={"Select Inventory"}
          values={inventory}
          error={errors["inventory"]}
        />
        <LabelledSelect
          className={"SmallInput"}
          label={"Product"}
          data={products}
          onSelect={(e) => {
            onChange(e, "product", true);
            validateInput(e, ["isRequired"], "product");
          }}
          placeholder={"Select Product"}
          values={product}
          error={errors["product"]}
        />
        <LabelledSelect
          className={"SmallInput"}
          label={"Unit"}
          data={unitList}
          onSelect={(e) => {
            onChange(e, "units", true);
            validateInput(e, ["isRequired"], "units");
          }}
          placeholder={"Select Unit"}
          values={units}
          error={errors["units"]}
        />
        <LabelledInput
          className={"SmallInput"}
          label="Quantity"
          placeholder="Enter Quantity"
          type="number"
          value={quantity}
          onChange={(e) => {
            onChange(e, "quantity");
            validateInput(e.target.value, ["isRequired"], "quantity");
          }}
          error={errors["quantity"]}
        />
      </div>
    </SideModal>
  );
}
