import requestMaker from "../Lib"

export const ticketTransition = (data)=>{
    const { ticket_id, next_state_id } = data
    const url=`/crm/tickets/${ticket_id}/transition/${next_state_id}/`
    const payload = {}
    const params = {}
    return requestMaker(url, "put", {params,payload})
}


export const changeDoctors = (data)=>{
    const url = `/crm/doctors/`
    const payload = {data}
    const params = {}
    return requestMaker(url, "patch", {params, payload})
}

// export const workflowFetch = (data)=>{
//     const {tenant} = data
//     const url=`/crm/workflows/${tenant}/`
//     const payload = {}
//     const params = {}
//     return requestMaker(url, "get", {params,payload})
// }

// export const addProduct = (data)=>{
//     const {} = data
//     const url=`crm/ticket/place_order/`
//     const payload = data
//     const params = {}
//     return requestMaker(url, "post", {params,payload})
// }

export const place_order = (data)=>{
    const url=`/crm/ticket/place_order/`
    const payload = {...data}
    const params = {}
    return requestMaker(url, "post", {params,payload})
}

export const edit_order = (data)=>{
    const url=`/crm/ticket/place_order/`
    const payload = {...data}
    const params = {}
    return requestMaker(url, "patch", {params,payload})
}

export const delete_order = (data)=>{
    const url=`/crm/ticket/place_order/`
    const payload = {...data}
    const params = {}
    return requestMaker(url, "delete", {params,payload})
}
export const patch_priority = (id, data)=>{
    const url = `/crm/tickets/${id}/update/`
    const payload = {...data}
    const params = {}
    return requestMaker(url, "patch", {params, payload});

}
export const createTicket = (data, workflowId)=>{
    const url=`/crm/workflows/${workflowId}/tickets/create/`
    const payload = data
    const params = {}
    return requestMaker(url, "post", {params,payload})
}

export const fetchTenantID=(data)=>{
    const url='/account/tenants/fetch_tenant_id/'
    const params={}
    const payload={}
    return requestMaker(url,"get",{params,payload})
}

export const fetchTickets = (data)=>{
    const url = "/crm/workflows/38/"
    const payload = {}
    const params = {}
    return requestMaker(url,"get", {params, payload})
}
export const fetchWorkflows = (data) =>{
    const {activeComponent} = data
    const url = `/crm/workflows/${activeComponent}/tickets/`
    const payload = {}
    const params = {}
    return requestMaker(url,"get", {params, payload})
}

export const createComment = (data) =>{
    const url = '/crm/ticket/comments/'
    const payload = {...data}
    const params = {}
    return requestMaker(url,"post", {params, payload})
}


export const AddTicketFields = (data) =>{
    const url = '/crm/ticket/attributes'
    const payload = {...data}
    const params = {}
    return requestMaker(url,"post", {params, payload})
}

export const deleteAttachments = (data)=>{
    const url = '/crm/ticket/attachments/'
    const payload = {...data}
    const params = {}
    return requestMaker(url,"delete", {params, payload})

}

export const createState = (data)=>{
    const url = '/crm/workflows/states'
    const payload = {...data}
    const params = {}
    return requestMaker(url,"post", {params, payload})
}

export const updateState = (data)=>{
    const url = '/crm/workflows/states'
    const payload = {...data}
    const params = {}
    return requestMaker(url,"put", {params, payload})
}
export const deleteState = (data)=>{
    const url = '/crm/workflows/states'
    const payload = {...data}
    const params = {}
    return requestMaker(url,"delete", {params, payload})
}

export const createConfig = (data)=>{
    const url = '/crm/workflows/config'
    const payload = {...data}
    const params = {}
    return requestMaker(url,"post", {params, payload})
}

export const createTransitionRules = (data)=>{
    const url = '/crm/states/transition'
    const payload = {...data}
    const params = {}
    return requestMaker(url,"post", {params, payload})
}

export const updateTransitionRules = (data)=>{
    const url = '/crm/states/transition'
    const payload = {...data}
    const params = {}
    return requestMaker(url,"put", {params, payload})
}

export const deleteTransitionRules = (data)=>{
    const url = '/crm/states/transition'
    const payload = {...data}
    const params = {}
    return requestMaker(url,"delete", {params, payload})
}

export const createTicketLayout = (data)=>{
    const url = `/crm/workflows/ticket_layout`
    const payload = {...data}
    const params = {}
    return requestMaker(url,"post", {params, payload})
}

export const deleteTicketField = (data)=>{
    const url = `/crm/workflows/ticket_layout`
    const payload = {...data}
    const params = {}
    return requestMaker(url,"delete", {params, payload})
}

export const fetchCombinationItems = () => {
    const url = `/shop/combinations`
    const payload = {}
    const params = {}
    return requestMaker(url, "get", {params, payload});
}

export const editCombinations = (data) => {
    const url = `/shop/combinations`
    const payload = {...data}
    const params = {}
    return requestMaker(url, "patch", {params, payload});
}

export const addCombinations = (data) => {
    const url = `/shop/combinations`
    const payload = {...data}
    const params = {}
    return requestMaker(url, "post", {params, payload});
}

export const deleteCombinations = (data) => {
    const url = `/shop/combinations`
    const payload = {...data}
    const params = {}
    return requestMaker(url, "delete", {params, payload});
}

export const fetchCombinationItemsProduct = () => {
    const url = `/shop/combination/items`
    const payload = {}
    const params = {}
    return requestMaker(url, "get", {params, payload});
}

export const linkCombinationItems = (data) => {
    const url = `/shop/combination/items`
    const payload = {...data}
    const params = {}
    return requestMaker(url, "post", {params, payload});
}

export const deleteCombinationItems = (data) => {
    const url = `/shop/combination/link/items`
    const payload = {...data}
    const params = {}
    return requestMaker(url, "delete", {params, payload});
}

export const placeOrder = (data) => {
    const url =  `/payments/checkout/176/EXPRESS/`
    const payload = {...data}
    const params = {}
    return requestMaker(url, "post", {params, payload});
}

export const fetch_invoice_status = ()=>{
    const url = "/crm/invoice/status";
    return requestMaker(url, "get", {});

}

export const edit_invoice_status = (data)=>{
    const url = "/crm/invoice/status";
    const payload = {...data}
    const params = {}
    return requestMaker(url, "patch", {params, payload});
    
}

export const archiveTicket = (ticketId,status) => {
    const url = `/crm/tickets/${ticketId}/update/`;
    const payload = { status: status };  // Status to be archived
    return requestMaker(url, 'PATCH', payload);  // Send correct payload
};




  