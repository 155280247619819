import React, { useState } from 'react';
import './comments.scss';
import { createComment } from '../../../../Api/crmApi';
import { toast } from 'react-toastify';
import { LabelledInput } from "../../../../Components";

const Comments = ({ attributes, ticket, comments, onAddComment, setAllTickets, setTickets }) => {
    function getCurrentDateTime() {
        const today = new Date();
        
        // Get date components
        const date = today.getDate();
        const month = today.getMonth() + 1; // Months are zero-indexed, so add 1
        const year = today.getFullYear();
        
        // Get time components
        const hours = today.getHours();
        const minutes = today.getMinutes();
        const seconds = today.getSeconds();
        
        // Format the date and time as 'DD/MM/YYYY HH:MM:SS'
        const formattedDate = `${date}/${month}/${year}`;
        const formattedTime = `${hours}:${minutes}:${seconds}`;
        
        return `${formattedDate} ${formattedTime}`;
    }
    
    // Ex
    

    const [comment, setComment] = useState('');

    const handleSubmitComment = async () => {
        if (comment === "") {
            return toast.error("Comment cannot be empty");
        }
        const date = getCurrentDateTime()

        const newComment = {
            comment: comment,
            ticket_id: ticket.id,
            created_at : date
        };

        setComment('');
        sendCommentToBackend(newComment);
    };

    const sendCommentToBackend = (commentData) => {
        createComment(commentData).then((response) => {
            onAddComment(response.data.ticket);
            setAllTickets((prev) =>
                prev.map((item) =>
                    item.id === ticket.id ? response.data.ticket : item
                )
            );
            setTickets(response.data.ticket);
        }).catch((err) => {
            console.error('Error posting comment:', err);
        });
    };

    const getInitials = (name) => {
        return name ? name[0]: "U"; // default "U" for undefined names
    };

    return (
        <div className='comment-section-wrapper'>
            <h4 className="header">Comments</h4>

            <div className="input-wrapper">
                <input
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="Write a comment..."
                    
                />
                <button onClick={handleSubmitComment} className="post-btn">Post</button>
                
            </div>
            

            <div className='comments'>
                {ticket?.attributes?.comments?.map((comment, index) => (
                    comment?.message.length > 0 &&
                    <div key={index} className="comment-box">
                        <div style={{display:'flex', gap:'30px'}}>
                        <div className="avatar">{getInitials(comment.commented_by)}</div>
                        <span style={{ color: 'gray', fontWeight: 'lighter', fontSize: '10px', marginTop:'10px' }}>{comment?.created_at || 'date not found'}</span>
                        </div>
                        <div className="comment-content">
                            <div className="username">{comment.commented_by}</div>
                            <div className="message">{comment.message}</div>
                        </div>
                    </div>
                )).reverse()}
            </div>
        </div>
    );
};
export default Comments;



// import React, { useEffect, useState } from 'react';
// import { CommentSection } from 'react-comments-section';
// import 'react-comments-section/dist/index.css';
// import './comments.scss';
// import { toast } from 'react-toastify';
// import { createComment } from '../../../../Api/crmApi';

// const Comments = ({ attributes, ticket, comments, onAddComment, setAllTickets, setTickets }) => {
//     const [commentData, setCommentData] = useState([]);

//     useEffect(() => {
//         if (ticket?.attributes?.comments?.length) {
//             const mappedComments = ticket.attributes.comments.map((comment, index) => ({
//                 userId: `user-${index}`,
//                 comId: `comment-${index}`,
//                 fullName: comment.commented_by || 'Anonymous',
//                 userProfile: '', // Optional profile URL
//                 text: comment.message,
//                 avatarUrl: 'https://ui-avatars.com/api/?name=' + (comment.commented_by || 'User'),
//                 replies: [] // Assuming no replies for now
//             }));
//             setCommentData(mappedComments.reverse());
//         }
//     }, [ticket]);

//     const handleSubmitComment = async (commentObj) => {
//         const { text } = commentObj;

//         if (text === "") {
//             return toast.error("Comment cannot be empty");
//         }

//         const newComment = {
//             comment: text,
//             ticket_id: ticket.id
//         };

//         sendCommentToBackend(newComment);
//     };

//     const sendCommentToBackend = (commentData) => {
//         createComment(commentData)
//             .then((response) => {
//                 onAddComment(response.data.ticket);
//                 setAllTickets((prev) =>
//                     prev.map((item) =>
//                         item.id === ticket.id ? response.data.ticket : item
//                     )
//                 );
//                 setTickets(response.data.ticket);
//             })
//             .catch((err) => {
//                 console.error('Error posting comment:', err);
//             });
//     };

//     return (
//         <div className='comments'>
//             <h4 style={{ padding: '0px 10px', fontSize: "20px", fontWeight: '600', marginBottom: '1em' }}>Comments</h4>
//             <CommentSection
//                 currentUser={{
//                     currentUserId: 'current-user-id', // Replace with actual current user ID
//                     currentUserImg: 'https://ui-avatars.com/api/?name=User&background=random', // Current user's avatar
//                     currentUserFullName: 'Current User' // Replace with actual current user name
//                 }}
//                 logIn={{
//                     loginLink: 'http://localhost:3001/',
//                     signupLink: 'http://localhost:3001/'
//                 }}
//                 commentData={commentData}
//                 onSubmitAction={(data) => handleSubmitComment(data)}
//                 currentData={(data) => {
//                     console.log('Updated comments data', data);
//                 }}
//             />
//         </div>
//     );
// };

// export default Comments;
