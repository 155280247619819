import React, { useEffect, useState, useRef } from 'react';
import { AddTicketFields } from '../../../../Api/crmApi';
import './Field.scss';
import { toast } from 'react-toastify';
import { getBaseUrl } from '../../../../Lib/NetworkHandler';
import axios from 'axios';
import { FaTimes } from 'react-icons/fa';
import { LabelledInput, LabelledSelect } from '../../../../Components';
import { formattedInputs } from '../../../Cms/AddStock/AddStock';
import { fetch_entities } from '../../../../Api/cmsAPI';
import { showEntity } from "../Constans";
import Checklist from '../../Components/Attributes/CheckList';

function formatLabelSelectData(list) {
  const formattedList = [];
  list?.map(item => {
    formattedList.push({ 'label': `${item?.name} (${item?.phone_number})`, 'value': `${item?.name} (${item?.phone_number})` });
  })
  console.log(formattedList)
  return formattedList;
}
export const checkActionExists = (array, actionToCheck) => {
  if (Array.isArray(array)) {
    return array.some((item) => item.action === actionToCheck);
  }
  return true
};

const Field = ({ selectedTicket, setTickets, ticketLayout, execution, attributes, ticket_id, ticket }) => {
  const [inputValues, setInputValues] = useState({});
  const [entity, setEntity] = useState("");
  const [entityId, setEntityId] = useState(null);
  const [allEntities, setAllEntities] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [tenantUsers, setTenantUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [usersError, setUsersError] = useState(null);
  const [inputError, setInputError] = useState('');
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [technicianNames, setTechNicianNames] = useState([]);
  const [alldelExecNames, setDelExecNames] = useState([]);
  const [assigneeNames, setAssigNeeNames] = useState([]);
  console.log(selectedTicket)

  const fieldRoleMapping = {
    "technician name": "SURGERY_BOY",
    "delivery executive name": "DP",
    "assignee": "C",
    // "searchtype": "SEARCH_ROLE",
  };
  const roleToListMap = new Map();
  function mapRoleToList() {
    roleToListMap.set('technician name', technicianNames);
    roleToListMap.set('delivery executive name', alldelExecNames);
    roleToListMap.set('assignee', assigneeNames);
  }
  useEffect(() => {
    mapRoleToList();
  }, [technicianNames, alldelExecNames, assigneeNames])

  const dropdownRef = useRef(null);
  const handleInputChange = (e, fieldKey) => {
    const { value } = e.target;
    setInputValues((prevState) => ({
      ...prevState,
      [fieldKey]: value,
    }));

    if (
      ["technician name", "delivery executive name"].includes(
        fieldKey.toLowerCase()
      )
    ) {
      const isValidUser = tenantUsers.some(
        (user) => user.name === value.split(" (")[0] // Compare only the name part
      );
      if (!isValidUser) {
        setInputValues((prevState) => ({
          ...prevState,
          [fieldKey]: "", // Clear the field
        }));
        setInputError(`${fieldKey} must be selected from the dropdown.`);
      } else {
        setInputError(""); // Clear the error if valid
      }
    }
  };

  const fetchAllEntities = async () => {
    try {
      const { data } = await fetch_entities();
      setAllEntities(data);
    } catch (err) {
      console.error(err);
    }
  }

  const fetchTenantUsers = async () => {
    setLoadingUsers(true);
    setUsersError(null);
    try {
      const response = await axios.get('https://aiktech.in/api/account/tenant-users/');
      setTenantUsers(response.data);
    } catch (error) {
      console.error("Error fetching tenant users:", error);
      setUsersError('Failed to fetch tenant users.');
    } finally {
      setLoadingUsers(false);
    }
  };
  useEffect(() => {

  }, [selectedTicket])

  useEffect(() => {
    fetchTenantUsers();
    fetchAllEntities();
    setSearchTerm('');
  }, []);
  const fetchEntityById = async (entityId) => {
    try {
      const baseurl = getBaseUrl();
      const { data } = await axios.get(
        `${baseurl}/api/shop/entities/${entityId}/`
      );
      setSelectedEntity(data);
      setEntity(data.title);
    } catch (err) {
      console.error("Error fetching entity details:", err);
    }
  };


  useEffect(() => {
    if (selectedTicket?.attributes?.ticket_fields) {
      const ticketFields = selectedTicket.attributes.ticket_fields;
      const newInputValues = {};
      ticketLayout?.input_fields?.forEach((field) => {
        newInputValues[field?.key] = ticketFields[field?.key] || "";
      });
      setInputValues(newInputValues);
      if (ticketFields.entity_id) {
        setEntityId(ticketFields.entity_id);
        fetchEntityById(ticketFields.entity_id);
      } else if (ticketFields.entity_title) {
        setEntity(ticketFields.entity_title);
      }
    }
  }, [selectedTicket, ticketLayout]);

  useEffect(() => {
    const [newSelectedEntity] = allEntities?.filter(entity => entity?.id === entityId);
    setSelectedEntity(newSelectedEntity);
  }, [entityId]);

  useEffect(() => {
    fetchTenantUsers();
    setSearchTerm(""); // Clear search input on component mount
  }, []);


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSelectUser = (fieldKey, user) => {
    setInputValues((prevState) => ({
      ...prevState,
      [fieldKey]: `${user.name} (${user.phone_number})`,
    }));
    setActiveDropdown(null);
    setInputError("");
    setSearchTerm(""); // Clear search bar input after selecting a user
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setActiveDropdown(null);
  };
  // const filteredUsers = tenantUsers.filter(
  //   (user) =>

  //     user.role === fieldRoleMapping[activeDropdown?.toLowerCase()] &&
  //     user.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );
  // const filteredUsers = tenantUsers.filter(user =>
  //   user.role === fieldRoleMapping[activeDropdown?.toLowerCase()] &&
  //   user.name.toLowerCase().includes(searchTerm.toLowerCase())
  // );

  useEffect(() => {
    const allTechnicians = tenantUsers?.filter(user => user?.role === 'SURGERY_BOY');
    const allDelExecs = tenantUsers?.filter(user => user?.role === 'DP');
    const allAssignees = tenantUsers?.filter(user => user?.role !== 'C');
    // const OtherNames = tenantUsers?.filter(user=> user?.role!=='C')
    setTechNicianNames(allTechnicians);
    setDelExecNames(allDelExecs);
    setAssigNeeNames(allAssignees);
  }, [tenantUsers])

  const handleSave = async () => {
    if (!selectedEntity?.id) {
      toast.error('Entity ID is required');
      return;
    }

    const inputData = [];
    ticketLayout?.input_fields?.forEach(field => {
      const inputField = {
        key: field.key,
        value: inputValues[field.key] || ''
      };
      inputData.push(inputField);
    });

    inputData.push(
      { key: 'entity_id', value: selectedEntity?.id || '' },
      { key: 'entity_title', value: selectedEntity?.title || '' },
      { key: 'entity_address', value: selectedEntity?.address || '' },
      { key: 'entity_dl_no', value: selectedEntity?.dl_no || '' },
      { key: 'entity_gstin', value: selectedEntity?.gstin || '' },
      { key: 'entity_phone_number', value: selectedEntity?.phone_number || '' }
    );

    const payload = {
      inputs: inputData,
      ticket_id: selectedTicket.id
    };

    try {
      const response = await AddTicketFields(payload);
      toast.success('Saved successfully');
      setEntityId(selectedEntity?.id);
      const ticket = response.data.ticket;
      setTickets((prev) =>
        prev.map((item) => (item.id === ticket.id ? ticket : item))
      );

    } catch (error) {
      toast.error('Failed to save. Please try again.');
      console.error(error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveDropdown(null); // Close the dropdown
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);


  const filteredUsers = tenantUsers.filter((user) => {
    console.log('filtered users')
    const activeField = ticketLayout?.input_fields?.find(
      (field) => field.key.toLowerCase() === activeDropdown?.toLowerCase()
    );

    let roleMatch;
    if (activeDropdown?.toLowerCase() === "assignee") {
      roleMatch = user.role !== "C";
    } else {
      roleMatch = user.role === fieldRoleMapping[activeDropdown?.toLowerCase()];
    }

    return (
      (activeField?.field_type === 'searchtype' || roleMatch) &&
      user.name?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
  })
  const orderMapiing = {
    28: "Tasks",
    1: "Orders",
  };
  function getValueByKey(key) {
    return orderMapiing[key] || "Details";
  }

  
  return (
    <div className="ticket-form space-y-10">
      <div>
        <div className='left-part'>
          <p>{getValueByKey(ticket?.workflow_config)}</p>

          {ticketLayout?.input_fields?.map((field, index) => (
            field.key !== "" && (
              <div key={index} className="assigned_wrapper">
                <label htmlFor={`field-${index}`}>{field.key}:</label>
                {["technician name", "delivery executive name", "assignee"].includes(
                  field.key.toLowerCase()
                ) || (field?.field_type == 'searchtype') ? (
                  <>
                    <LabelledSelect
                      className={"SmallInput"}
                      data={field.key == 'Technician Name' ? formatLabelSelectData(technicianNames) : 'Assignee' ? formatLabelSelectData(assigneeNames) : formatLabelSelectData(alldelExecNames)}
                      values={inputValues?.[field?.key]}
                      multiSelect={false}
                      customHandleChange={option => {
                        if (field?.key == 'Technician Name') {
                          setInputValues(prev => ({
                            ...prev,
                            'Technician Name': option.value
                          }));
                        } 
                        else if(field?.key=='Assignee'){
                          setInputValues(prev => ({
                            ...prev,
                            'Assignee': option.value
                          }));

                        }
                        else {
                          setInputValues(prev => ({
                            ...prev,
                            'Delivery Executive Name': option.value
                          }));
                        }
                      }}
                    />

                  </>
                ) : field?.field_type === "textarea" ? (
                  <LabelledInput
                    textarea={true}
                    id={`field-${index}`}
                    value={inputValues[field.key] || ""}
                    onChange={(e) => handleInputChange(e, field.key)}
                    className={'text-area'}
                  />
                ) :
                  field?.field_type === 'checklist' ? (

                    <Checklist attributes={attributes} ticket_id={ticket_id} ticket={ticket} />
                  ) :
                    (
                      <input
                        id={`field-${index}`}
                        type={
                          field?.field_type === "input/text"
                            ? "text"
                            : field?.field_type === "input/date"
                              ? "date"
                              : field?.field_type === "input/datetime-local"
                                ? "datetime-local"
                                : "text"
                        }
                        value={inputValues[field.key] || ""}
                        onChange={(e) => handleInputChange(e, field.key)}
                        className="mx-2 fields-input"
                      />
                    )}
              </div>
            )
          ))}
        </div>
        {checkActionExists(execution , showEntity) &&  <div className='right-part'>
          <p>Entity Details:</p>
          <div className='assigned_wrapper'>
            <div className="flex entity-input-wrapper" style={{ width: "100%" }}>
              <LabelledSelect
                className="SmallInput"
                label="Entity"
                name="entity"
                data={formattedInputs(allEntities, 'title', 'id')}
                placeholder="Select Entity"
                values={entityId}
                multiSelect={false}
                required={true}
                customHandleChange={(option) => {
                  setEntityId(option.value);
                }}
              />
            </div>

            {selectedEntity &&
              <div className='entity-card'>
                <div>
                  <p><span>Hospital Name:</span> {selectedEntity?.title || ""}</p>
                  <p><span>Hospital Address:</span> {selectedEntity?.address || ""}</p>
                  <p><span>Hospital DL No:</span> {selectedEntity?.dl_no || ""}</p>
                  <p><span>Hospital GSTIN:</span> {selectedEntity?.gstin || ""}</p>
                  <p><span>Hospital Phone No:</span> {selectedEntity?.phone_number || ""}</p>
                </div>

              </div>
            }
          </div>
        </div>}
      </div>
      {inputError && <p className="error">{inputError}</p>}
      <button className='saveButton' onClick={handleSave}>Save</button>
    </div>
  );
};


export default Field;
