import React, { useEffect, useState } from "react";
import EditButton from "../../EditButton/EditButton";
import { useHistory } from "react-router-dom";
import get from "lodash/get";

export default function EditProduct(props) {
  const history = useHistory();
  const onClick = () => {
    const product = get(props, "row.original", "");
    const { id } = product || {};
    history.push(`/dashboard/products/${id}/update`, product);
  };
  return <EditButton text={"Edit"} className={"Edit"} onClick={onClick} />;
}
