import React from 'react'
import "../Builder.js"
import { publishTemplate } from '../../../Api/tenantAPI.js'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import { actionsCreator } from '../../../Redux/actions/actionsCreator.js'
export default function SaveBtn() {

    const dispatch = useDispatch()
    const { config, tenantTemplateId } = useSelector(state => state.sectionsList);
    const updatedConfig = useSelector(state => state.updateSectionsList.config);


    const handlePublishTemplate = async () => {


        try {
            await publishTemplate({
                tenantId: tenantTemplateId,
                config: updatedConfig
            });
            dispatch(actionsCreator.SET_SECTIONS_LIST({ config: updatedConfig }))
            toast.success("Template Updated");
        } catch (err) {
            console.error(err);
            toast.error("Error Saving Template");
        }
    }
    return (
        <>
            <button className='save-template-btn'
                onClick={handlePublishTemplate}
            >
                Save
            </button>
        </>
    )
}
