import React from "react";
//Style
import "./Register.scss";

//Component
import RegistrationForm from "./RegistrationForm";

export default function Register() {
  return (
    <div id ="approot" className="">
        <RegistrationForm />
    </div>
  );
}
 