import React, { useEffect, useState } from 'react'
import styles from "./ViewPages.module.scss";
import { useDispatch, useSelector } from 'react-redux';
// import goToIcon from "../../../../Assets/Icons/goto.svg";
import { actionsCreator } from '../../../../Redux/actions/actionsCreator';

const ViewPages = () => {
    const { config } = useSelector(state => state.sectionsList);
    const [allPages, setAllPages] = useState(null);
    const dispatch = useDispatch();
    const handleNavigateToPage = (page) => {
        dispatch(actionsCreator.SET_SECTIONS_LIST({
            currPage: page,
            currConfig : getCurrConfigByPage(page),
        }))
    }

    const getCurrConfigByPage = (page) => {
        let counter = 0;
        for (const key in config) {
            if (counter === 0) {
                counter++;
                continue;
            }
            if(config?.[key]?.page == page){
                return key;
            }
            counter++;
        }
    }
    useEffect(() => {
        const pages = [];
        let counter = 0;
        for (const key in config) {
            if (counter === 0) {
                counter++;
                continue;
            }
            pages.push(config[key]?.page);
            counter++;
        }
        setAllPages(pages);
    }, []);
    return (
        <div className='optionBody'>
            {allPages && allPages?.map((page, idx) => (
                <div key={idx}
                    className={`component ${styles.page}`}
                    style={{ fontSize: '14px', }}
                >
                    <span>{page.toUpperCase()}</span>
                    {/* <img src={goToIcon} alt='icon' height={18} onClick={() => handleNavigateToPage(page)} /> */}
                </div>
            ))}
            <div className={styles.addNewPageBtn}>
                <button>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" height={20}>
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                    <span>Add New Page</span>
                </button>
            </div>
        </div>
    )
}

export default ViewPages