import React from "react";
import "./loader.scss";
import { useSelector } from "react-redux";

import { LuLoader2 } from "react-icons/lu";

const mapStateToProps = ({ message }) => ({
  message,
});

function Loader(props) {
  return (
    <div className="modal-loader">
      <LuLoader2 
        className="lds-ring" 
        style={{
          height : '3rem',
          aspectRatio : '1'
        }}
      />
      <div className="modal-message">{props.message}</div>
    </div>
  );
}

export default Loader;
