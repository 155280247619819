import React from 'react';
import ReactDOM from 'react-dom';

import './index.scss';
import './utility.scss'
import App from './App';
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
    <App />,
  // <React.StrictMode>
  // </React.StrictMode>,
  document.getElementById('root')
);
