import React, { useEffect, useState } from 'react'
import styles from "./Animation.module.scss"
import { useCustomizer } from '../../../../Provider/customizerProvider'
import Type from './Components/Type'
import Control from './Components/Control'
export default function Animation() {
    const { handleApplyCssRules } = useCustomizer()
    return (
        <>
            <Type></Type>
            <Control />
            <div className='flex items-center m-1'>
                <button className={styles.applyButton} onClick={() => handleApplyCssRules()}>Apply Changes</button>
            </div>
        </>

    )
}
