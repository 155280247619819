import React from "react";

//Style
import "./PreviewSlide.scss";

//Components
import { Switch, ImageSlider, Button } from "../../Components";
import { Link, useHistory } from "react-router-dom";

//images
import slide1 from "../../Assets/Images/preview/slide1.png";
import slide2 from "../../Assets/Images/preview/slide2.png";
import slide3 from "../../Assets/Images/preview/slide3.png";
import slide4 from "../../Assets/Images/preview/slide4.png";
import slide5 from "../../Assets/Images/preview/slide5.png";

export default function PreviewSlide() {
  const history = useHistory();
  const navigate = () => {
    history.push("/pricing");
  };
  const slides = [
    {
      image: slide1,
    },
    {
      image: slide2,
    },
    {
      image: slide3,
    },
    {
      image: slide4,
    },
    {
      image: slide5,
    },
  ];
  return (
    <div className="PreviewSlide">
      <div className="PreviewSlide__Heading">Your interface in Action!</div>
      <div className="PreviewSlide__Card">
        <div className="PreviewSlide__Card__Switch">
          <Switch />
          <div className="PreviewSlide__Card__Switch__Button">
            <Button text={"Continue"} onClick={navigate} />
          </div>
        </div>
        <div className="PreviewSlide__Card__Slider">
          <ImageSlider slides={slides} className={"ViewCard"} />
        </div>
      </div>
    </div>
  );
}
