import React from "react";
import "./Switch.scss";

export default function Switch() {
  return (
    <label className="Switch">
      <input type="checkbox" />
      <span className="Switch__Slider round"></span>
    </label>
  );
}
