import { SET_SECTIONS_LIST, SET_UPDATE_SECTIONS_LIST } from "../actions/actionTypes";
import { SET_CART_DATA, RESET_CART_DATA, SET_DELIVERY_CHARGE, SET_PREVIOUS_DELIVERY_CHARGE, SET_ALL_PRODUCTS } from '../actions/actionTypes';

const sectionsListInitialState = {
  tenantId: null,
  list: {},
  selectedElementId: null,
  hoveredElementId: null,
  showRightSidebar: false,
  showLeftSidebar: false,
  selectedSection: "",
  currMode: "view",
  config: {},
  currPage: "Home",
  currUrl: "domain.com",
  currConfig: "homepageConfig",
  tenantTemplateId: null,
  selectedCategory: "",
  viewMode : 'desktop',
}
export const sectionListReducer = (

  state = sectionsListInitialState,
  { type, payload },

) => {
  switch (type) {
    case SET_SECTIONS_LIST:
      return {
        ...state,
        ...payload,
      }

    default:
      return state;
  }
}


const initialState = {
};

export const cartReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CART_DATA: {
      return {
        ...state,
        ...payload
      };
    }
    case RESET_CART_DATA: {
      return {
        ...payload
      };
    }
    case SET_DELIVERY_CHARGE: {
      return {
        ...state,
        ...payload
      }
    }
    case SET_PREVIOUS_DELIVERY_CHARGE: {
      return {
        ...state,
        ...payload
      }
    }
    default:
      return state;
  }
};



const updatesectionsListInitialState = {
  config: {},

}
export const updatesectionListReducer = (

  state = updatesectionsListInitialState,
  { type, payload },

) => {
  switch (type) {
    case SET_UPDATE_SECTIONS_LIST:
      return {
        ...state,
        ...payload,
      }

    default:
      return state;
  }
}
