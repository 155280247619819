import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { getToken } from "../Utils";

export default function AuthWrapper({ children }) {
  const isLoggedIn = getToken();
  const history = useHistory();

  useEffect(() => {
    if (!isLoggedIn) {
      history.push("/");
    }
  }, []);

  if (isLoggedIn) {
    return <div>{children}</div>;
  }
  return null;
}
