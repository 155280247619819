/* loader reducers */
export const OPEN_LOADER = "OPEN_LOADER";

/* Login and user actions */
export const SET_LOGIN = "SET_LOGIN";
export const LOGOUT_USER = "LOGOUT_USER";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const FETCH_USER_DETAILS = "FETCH_USER_DETAILS";

// Tenants
export const FETCH_SUBSCRIPTIONS = "FETCH_SUBSCRIPTIONS";
export const SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS";
export const FETCH_TENANT_CATEGORIES = "FETCH_TENANT_CATEGORIES";
export const SET_TENANT_CATEGORIES = "SET_TENANT_CATEGORIES";
export const REGISTER_TENANT_USER = "REGISTER_TENANT_USER";
export const SEND_TENANT_USER_OTP = "SEND_TENANT_USER_OTP";
export const REGISTER_TENANT = "REGISTER_TENANT";
export const VERIFY_TENANT_OTP = "VERIFY_TENANT_OTP";
export const SET_TENANT = "SET_TENANT";
export const FETCH_ALL_TENANTS = "FETCH_ALL_TENANTS";
export const FETCH_TENANT_DETAILS = "FETCH_TENANT_DETAILS"
export const SET_TENANT_DETAILS = "SET_TENANT_DETAILS"
// Tenant form
export const SET_CHANGE_SLIDE = "SET_CHANGE_SLIDE";
export const SET_SHOW_PREVIEW = "SET_SHOW_PREVIEW";
export const SET_TENANT_ERRORS = "SET_TENANT_ERRORS";
export const RESET_TENANT_ERRORS = "RESET_TENANT_ERRORS";
export const SET_TENANT_FORM_DATA = "SET_TENANT_FORM_DATA";
export const RESET_TENANT_FORM_DATA = "RESET_TENANT_FORM_DATA";
export const SEARCH_BRANDS = "SEARCH_BRANDS";
export const SET_BRANDS = "SET_BRANDS";

// Payment
export const INITIATE_PAYMENT = "INITIATE_PAYMENT";
export const HANDLE_PAYMENT_SUCCESS = "HANDLE_PAYMENT_SUCCESS";
export const HANDLE_PAYMENT_FAILURE = "HANDLE_PAYMENT_FAILURE";

//Products
export const ADD_PRODUCT = "ADD_PRODUCT";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const FETCH_PRODUCTS = "FETCH_PRODUCTS";

//Categories
export const ADD_CATEGORY = "ADD_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const FETCH_CATEGORIES = "FETCH_CATEGORIES";

//Inventories
export const ADD_INVENTORY = "ADD_INVENTORY";
export const UPDATE_INVENTORY = "UPDATE_INVENTORY";
export const SET_INVENTORIES = "SET_INVENTORIES";
export const FETCH_INVENTORIES = "FETCH_INVENTORIES";

//STOCK
export const ADD_STOCK = "ADD_STOCK";
export const UPDATE_STOCK = "UPDATE_STOCK";
export const SET_STOCKS = "SET_STOCKS";
export const FETCH_STOCKS = "FETCH_STOCKS";

//STOCK
export const UPDATE_ORDER = "UPDATE_ORDER";
export const SET_ORDERS = "SET_ORDERS";
export const FETCH_ORDERS = "FETCH_ORDERS";

//Sellable Inventories
export const ADD_SELLABLE_INVENTORY = "ADD_SELLABLE_INVENTORY";
export const UPDATE_SELLABLE_INVENTORY = "UPDATE_SELLABLE_INVENTORY";
export const SET_SELLABLE_INVENTORIES = "SET_SELLABLE_INVENTORIES";
export const FETCH_SELLABLE_INVENTORIES = "FETCH_SELLABLE_INVENTORIES";

// Units
export const FETCH_UNITS = "FETCH_UNITS";
export const SET_UNITS = "SET_UNITS";

// cms

export const FETCH_STOCK_DROPDOWN = 'FETCH_STOCK_DROPDOWN'
export const SET_STOCK_DROPDOWN = 'SET_STOCK_DROPDOWN'
export const SET_ALL_PRODUCTS = 'SET_ALL_PRODUCTS'
export const SET_ALL_PAYMENTS = 'SET_ALL_PAYMENTS'
export const FETCH_ALL_PRODUCTS = 'FETCH_ALL_PRODUCTS'
export const FETCH_INVENTORY = 'FETCH_INVENTORY';
export const SET_INVENTORY = 'SET_INVENTORY';
export const SET_COMBINATION_PRODUCT = 'SET_COMBINATION_PRODUCT'
export const SET_COMBINATION_ITEM_PRODUCT = 'SET_COMBINATION_ITEM_PRODUCT'
export const SET_PERMISSIONS = 'SET_PERMISSIONS'
export const SET_CONFIGS = 'SET CONFIGS'

// crm 

export const FETCH_ALL_TICKETS = 'FETCH_ALL_TICKETS'
export const FETCH_WORKFLOW_CONFIGS = 'FETCH_WORKFLOW_CONFIGS'
export const SET_ALL_TICKETS = 'SET_ALL_TICKETS'
export const SET_WORKFLOW_CONFIGS = 'SET_WORKFLOW_CONFIGS'
export const AD_TICKET = 'ADD_TICKET'


//builder
export const SET_SECTIONS_LIST = 'SET_SECTIONS_LIST'
export const SET_UPDATE_SECTIONS_LIST = 'SET_UPDATE_SECTIONS_LIST'
export const UPDATE_WALLET = 'UPDATE_WALLET'
// Cart
export const FETCH_CART_DETAILS = 'FETCH_CART_DETAILS';
export const UPDATE_CART_DETAILS = 'UPDATE_CART_DETAILS';
export const SET_CART_DATA = 'SET_CART_DATA';
export const RESET_CART_DATA = 'RESET_CART_DATA';
export const SET_DELIVERY_CHARGE = 'SET_DELIVERY_CHARGE';
export const SET_PREVIOUS_DELIVERY_CHARGE = 'SET_PREVIOUS_DELIVERY_CHARGE';
