import { fetchTenantInitialErrors } from "../../Utils/tenant-utils";
import {
  SET_SUBSCRIPTIONS,
  SET_TENANT,
  SET_CHANGE_SLIDE,
  SET_TENANT_CATEGORIES,
  SET_SHOW_PREVIEW,
  SET_TENANT_ERRORS,
  RESET_TENANT_ERRORS,
  SET_TENANT_FORM_DATA,
  RESET_TENANT_FORM_DATA,
  SET_BRANDS,
  SET_TENANT_DETAILS
} from "../actions/actionTypes";

const subscriptionsInitialState = {
  list: [],
};

export const subscriptionsReducer = (
  state = subscriptionsInitialState,
  { type, payload }
) => {
  switch (type) {
    case SET_SUBSCRIPTIONS: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

const categoriesInitialState = {
  list: [],
};

export const tenantCategoriesReducer = (
  state = categoriesInitialState,
  { type, payload }
) => {
  switch (type) {
    case SET_TENANT_CATEGORIES: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

const brandsInitialState = {
  list: [],
  loading: false,
};

export const tenantBrandsReducer = (
  state = brandsInitialState,
  { type, payload }
) => {
  switch (type) {
    case SET_BRANDS: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

const initalErrorState = fetchTenantInitialErrors();

const tenantInitialState = {
  selectedTenant: {
    id: 1,
    config : {},
  },
  allTenants: [],
};

export const tenantReducer = (
  state = tenantInitialState,

  { type, payload }
) => {
  switch (type) {
    case SET_TENANT: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

const tenantFormInitialState = {
  isChangeSlide: false,
  showPreview: false,
  errors: initalErrorState,
  sessionId: "",
};

export const tenantFormReducer = (
  state = tenantFormInitialState,
  { type, payload }
) => {
  switch (type) {
    case SET_TENANT_FORM_DATA: {
      return {
        ...state,
        ...payload,
      };
    }
    case SET_CHANGE_SLIDE: {
      return {
        ...state,
        ...{ isChangeSlide: payload },
      };
    }
    case SET_SHOW_PREVIEW: {
      return {
        ...state,
        ...{ showPreview: payload },
      };
    }
    case SET_TENANT_ERRORS: {
      return {
        ...state,
        ...{ errors: { ...state.errors, ...payload } },
      };
    }
    case RESET_TENANT_ERRORS: {
      return {
        ...state,
        ...{ errors: initalErrorState },
      };
    }
    case RESET_TENANT_FORM_DATA: {
      return tenantFormInitialState;
    }
    default:
      return state;
  }
};

const companyDetailsInitialState ={
  list:[]
}

export const tenantDetailsReducer = (state = tenantInitialState,
  { type, payload }
) => {
  switch (type) {
    case SET_TENANT_DETAILS: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }

}