import basicDetailsPreview from "../Assets/Images/basic-details-preview.png";
import categorySelectionPreview from "../Assets/Images/category-selection-preview.png";
import BasicDetails from "../Containers/Register/RegistrationForm/BasicDetails";
import CategorySelection from "../Containers/Register/RegistrationForm/CategorySelection";
import BrandSelection from "../Containers/Register/RegistrationForm/BrandSelection";
import icon from "../Assets/Icons/restaurant.png";
import groceryImage from "../Assets/Images/grocery-preview.png";
import pharmacyImage from "../Assets/Images/pharmacy-preview.png";
import resturantImage from "../Assets/Images/resturant-preview.png";
import successIcon from "../Assets/Images/correct-image.png";
import productIcon from "../Assets/Icons/products.svg";
import dashboardIcon from "../Assets/Icons/dashboard.svg";
import orderIcon from "../Assets/Icons/orders.svg";
import categoryIcon from "../Assets/Icons/category.svg";
import inventoryIcon from "../Assets/Icons/inventory.svg";
import stockIcon from "../Assets/Icons/stock.svg";
import sellableInventoryIcon from "../Assets/Icons/sellable-inventory.svg";
import navbar from "../Assets/Images/preview-loader/amma's-header.png";
import main_banner from "../Assets/Images/preview-loader/amma's-main_banner.png";
import categories from "../Assets/Images/preview-loader/amma's-categories.jpg";
import bestseller from "../Assets/Images/preview-loader/amma's-bestseller.jpg";
import testimonials from "../Assets/Images/preview-loader/amma's-testimonials.jpg";
import footer from "../Assets/Images/preview-loader/amma's-footer.jpg";
// importing grocery images
import groceryNavBar from "../Assets/Images/preview-loader/grocery/navbar.png"
import groceryMainBanner from "../Assets/Images/preview-loader/grocery/main_banner.png"
import groceryCategories from "../Assets/Images/preview-loader/grocery/categories.png"
import groceryBanner from "../Assets/Images/preview-loader/grocery/banner.png"
import groceryFooter from "../Assets/Images/preview-loader/grocery/footer.png"

// importing bakery images
import bakeryNavBar from '../Assets/Images/preview-loader/bakery/navbar.png'
import bakeryMainBanner from '../Assets/Images/preview-loader/bakery/main_banner.png'
import bakeryBanner from '../Assets/Images/preview-loader/bakery/banner.png'
import bakeryTestimonials from '../Assets/Images/preview-loader/bakery/testimonials.png'
import bakeryFooter from '../Assets/Images/preview-loader/bakery/footer.png'

export const BRAND_CATEGORIES = [
  {
    name: "Grocery",
    logo_url: icon,
    description: "Your one-stop shop for fresh and flavorful groceries!",
    id: "GROCERY",
    previewImage: groceryImage,
    id: 1,
  },
  {
    name: "Restaurant",
    logo_url: icon,
    description: "Savor the Taste, Indulge in Culinary Delights!",
    id: "RESTAURANT",
    previewImage: resturantImage,
    id: 2,
  },
  {
    name: "Pharmacy",
    logo_url: icon,
    description: "Your Trusted Partner in Health and Wellness!",
    id: "PHARMACY",
    previewImage: pharmacyImage,
    id: 3,
  },
];
export const BASIC_DETAILS_INPUTS = {
  phone_number: {
    error: false,
    msg: "",
    defaultValue: "",
  },
  name: {
    error: false,
    msg: "",
    defaultValue: "",
  },
  password: {
    error: false,
    msg: "",
    defaultValue: "",
  },
  password2: {
    error: false,
    msg: "",
    defaultValue: "",
  },
  otp: {
    error: false,
    msg: "",
    defaultValue: "",
  },
};

export const CATEGORY_SELECTION_INPUTS = {
  tenant_category: {
    error: false,
    msg: "",
    defaultValue: "",
  },
};

export const BRAND_SELECTION_INPUTS = {
  title: {
    error: false,
    msg: "",
    defaultValue: "",
  },
  description: {
    error: false,
    msg: "",
    defaultValue: "",
  },
  subdomain: {
    error: false,
    msg: "",
    defaultValue: "",
  },
  address: {
    error: false,
    msg: "",
    defaultValue: "",
  },
  logo: {
    error: false,
    msg: "",
    defaultValue: {},
  },
  menuFile: {
    error: false,
    msg: "",
    defaultValue: {},
  },
};

export const TENANT_REGISTRATION_SLIDES = [
  {
    key: "CATEGORY_SELECTION",
    slide: 1,
    title: "Category Selection",
    component: CategorySelection,
    showBackBtn: false,
    previewImage: basicDetailsPreview,
    action: "",
    inputs: CATEGORY_SELECTION_INPUTS,
  },
  {
    key: "BASIC_DETAILS",
    slide: 2,
    title: "Basic Details",
    component: BasicDetails,
    showBackBtn: true,
    previewImage: basicDetailsPreview,
    action: "TENANT_USER_REGISTRATION",
    inputs: BASIC_DETAILS_INPUTS,
  },
  {
    key: "BRAND_SELECTION",
    slide: 3,
    title: "Brand Selection",
    component: BrandSelection,
    showBackBtn: false,
    previewImage: categorySelectionPreview,
    action: "TENANT_REGISTRATION",
    inputs: BRAND_SELECTION_INPUTS,
  },
];

export const FAQS = [
  {
    question: "How much does it cost to build an app?",
    answer:
      "This depends on how complicated your app is and who you choose to build it. Our ecommerce app is free and the order management system we built Makro cost thousands but compared to what they were using before – it was 98% cheaper! Unlike other app builders, we give you an accurate delivery time and price before you start. Our AI, Natasha, calculates this instantly and we’re so confident in her, that we guarantee your price.",
  },
  {
    question: "What’s your cancellation & returns policy?",
    answer:
      "Building your dream website and apps involves upfront work on our side. So unfortunately, once your project’s live, we can’t offer refunds on your deposit if you cancel. But rest assured, our team of experts will be with you every step of the journey towards finally owning your own website and apps! For peace of mind, you can always use Builder Now , our free prototyping tool, so you can see how your apps will work, first. Or speak to our team and they’ll give you a full rundown on how everything works.",
  },
  {
    question: "Is there a free trial?",
    answer:
      "No, as our service is based on actually building your website and apps using our platform, we can’t currently offer you a free trial. However, you can use our prototyping service Builder Now to get a sense of how your apps would work, or simply speak with our team who can give you a demo of what a similar design to your idea would be like.",
  },
  {
    question: "What about site maintenance? Can users access the backend?",
    answer:
      "The great thing about your Studio Store plan is that you won't have to worry about any of the technical stuff about maintaining your website and apps – it's all covered by Builder Care, our aftercare warranty, for your first 24 months. After that, you can choose to renew that policy, or you can also access and take ownership of the code yourself, if that's what you prefer. The power is in your hands, as the code belongs to you!",
  },
];

export const PAYMENT_RESULT = {
  TXN_SUCCESS: {
    title: "Your transaction is completed successfully",
    subTitle: "This is a test subtitle",
    icon: successIcon,
    action: {
      name: "Go to Dashboard",
      path: "/dashboard",
    },
  },
  TXN_FAILURE: {
    title: "Your transaction is failed",
    subTitle: "This is a test subtitle",
    icon: "",
    action: {
      name: "Try Again",
      path: "",
    },
  },
};

export const NAV_ITEMS = [
  {
    title: "",
    items: [
      {
        text: "Dashboard",
        icon: dashboardIcon,
        path: "/dashboard",
        exact: true,
      },
      {
        text: "Products",
        icon: productIcon,
        path: "/dashboard/products",
      },
      // {
      //   text: "Orders",
      //   icon: orderIcon,
      //   path: "/dashboard/orders",
      // },
      {
        text: "Categories",
        icon: categoryIcon,
        path: "/dashboard/categories",
      },
      {
        text: "Inventories",
        icon: inventoryIcon,
        path: "/dashboard/inventories",
      },
      {
        text: "Sellable Inventory",
        icon: sellableInventoryIcon,
        path: "/dashboard/sellable_inventories",
      },
      {
        text: "Stocks",
        icon: stockIcon,
        path: "/dashboard/stocks",
      },
      {
        text: "Orders",
        icon: orderIcon,
        path: "/dashboard/orders",
      },
    ],
  },
];

export const WEBSITE_PREVIEW_ITEMS = [
  {
    image: navbar,
    text: "Generating a beautiful navbar...",
    id: "wp_navbar",
  },
  {
    image: main_banner,
    text: "Generating a beautiful banner...",
    id: "wp_banner",
  },
  {
    image: categories,
    text: "Generating a section related to your categories",
    id: "wp_categories",
  },
  {
    image: bestseller,
    text: "Generating a section about your bestseller...",
    id: "wp_bestseller",
  },
  {
    image: testimonials,
    text: "Generating a beautiful testimonial...",
    id: "wp_testimonial",
  },
  {
    image: footer,
    text: "Generating a beautiful footer...",
    id: "wp_footer",
  },
];
export const GROCERY_WEBSITE_PREVIEW_ITEMS = [
  {
    image: groceryNavBar,
    text: "Generating a beautiful navbar...",
    id: "wp_navbar",
  },
  {
    image: groceryMainBanner,
    text: "Generating a beautiful banner...",
    id: "wp_banner",
  },
  {
    image: groceryCategories,
    text: "Generating a section related to your categories",
    id: "wp_categories",
  },
  {
    image: groceryBanner,
    text: "Generating a section of Banner...",
    id: "wp_bestseller",
  },
  // {
  //   image: testimonials,
  //   text: "Generating a beautiful testimonial...",
  //   id: "wp_testimonial",
  // },
  {
    image: groceryFooter,
    text: "Generating a beautiful footer...",
    id: "wp_footer",
  },
];

export const BAKERY_WEBSITE_PREVIEW_ITEMS = [
  {
    image: bakeryNavBar,
    text: "Generating a beautiful navbar...",
    id: "wp_navbar",
  },
  {
    image: bakeryMainBanner,
    text: "Generating a beautiful banner...",
    id: "wp_banner",
  },
  {
    image: bakeryBanner,
    text: "Generating a banner section",
    id: "wp_categories",
  },
  {
    image: bakeryTestimonials,
    text: "Generating a beautiful testimonial...",
    id: "wp_bestseller",
  },
  // {
  //   image: testimonials,
  //   text: "Generating a beautiful testimonial...",
  //   id: "wp_testimonial",
  // },
  {
    image: bakeryFooter,
    text: "Generating a beautiful footer...",
    id: "wp_footer",
  },
];
