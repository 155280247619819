import React from "react";
import "./SetupSteps.scss";
import StepCard from "../../../Components/StepCard/StepCard";
import Path from "./Path";

export default function SetupSteps() {
  const steps = [
    {
      title: "Signup",
      description:
        "Sign Up with your email address Or mobile number.",
      className: "Signup__Step",
    },
    {
      title: "Business Details",
      description:
        "Add your Business details - Name, Logo, Address.",
      className: "Business__Step",
    },
    {
      title: "Plan Selection",
      description:
        "Select your plan. Upload your product inventory in an excel sheet and you are ready to sell through your app and website.",
      className: "Plan__Step",
    },
  ];
  return (
    <div className="SetupSteps PagePadding">
      <div className="SetupSteps__Content">
        <div className="SetupSteps__Content__Heading Heading">
          Your ideas into reality in 3 easy steps
        </div>
        <div className="SetupSteps__Content__Desc">
          AI fits reusable features together based on a template you choose so
          our developers can focus on creating the custom features only your
          business needs. A1 also calculates a price based on the features you
          choose and gives you fixed costs and clear timelines.
        </div>
      </div>
      <div className="SetupSteps__Steps row">
        <Path />
        {steps.map((step, index) => {
          return <StepCard step={step} index={index} />;
        })}
      </div>
    </div>
  );
}
