import React, { useState } from "react";
import "./AddStock.scss";
import { toast } from "react-toastify";
import { add_hsnCode } from "../../../Api/cmsAPI";


const AddHsnCodeModal = ({ setOpenAddHsnCodeModal, cms_config }) => {
  // Initialize formData with the fields you need
  const [formData, setFormData] = useState({
    code: "",
    description: "",
    gst: "",
    isActive: false,
  });

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      // Check if GST is less than 100
      if (formData.gst >= 100) {
        return toast.error("GST should be less than 100");
      }
      const payload = {
        code: formData.code,
        description: formData.description,
        gst: formData.gst,
        active: formData.isActive,
      };
      await add_hsnCode(payload);
      toast.success("New HSN Code Added Successfully");
      setOpenAddHsnCodeModal(false);
    } catch (err) {
      console.error(err);
      toast.error("Error while adding new HSN Code");
    }
  };

  return (
    <>
      <h3 style={{ textAlign: "center", marginBottom: "10px" }}>
        Add New HSN Code
      </h3>
      <form onSubmit={handleSubmit} className="form-container">
        {Object.values(cms_config?.purchase_config?.hsn_config).map(
          (inputProps, index) => {
            if (inputProps.component === "LabelledInput") {
              return (
                <div className="form-group" key={index}>
                  <label>{inputProps.label}:</label>
                  <input
                    type={inputProps.type}
                    value={formData[inputProps.name]} // Access state from formData object
                    onChange={(e) =>
                      handleInputChange(inputProps.name, e.target.value)
                    }
                    className={inputProps.className}
                    required={inputProps.required}
                  />
                </div>
              );
            } else if (inputProps.component === "LabelledTextarea") {
              return (
                <div className="form-group" key={index}>
                  <label>{inputProps.label}:</label>
                  <textarea
                    value={formData[inputProps.name]} // Access state from formData object
                    onChange={(e) =>
                      handleInputChange(inputProps.name, e.target.value)
                    }
                    className={inputProps.className}
                    style={inputProps.style} // Apply style from config
                    required={inputProps.required}
                  />
                </div>
              );
            } else if (inputProps.component === "LabelledCheckbox") {
              return (
                <div className="form-group" key={index} style={{ width: "140px" }}>
                  <label style={{ marginTop: "5px" }}>{inputProps.label}:</label>
                  <input
                    type="checkbox"
                    checked={formData[inputProps.name]} // Access state from formData object
                    onChange={(e) =>
                      handleInputChange(inputProps.name, e.target.checked)
                    }
                    className={inputProps.className}
                    style={{ width: "fitContent", cursor: "pointer" }}
                    required={inputProps.required}
                  />
                  <span>{formData[inputProps.name] ? "Yes" : "No"}</span>
                </div>
              );
            }
            return null; // Just in case, to satisfy the map function
          }
        )}
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button type="submit" className="form-button">
            Add
          </button>
        </div>
      </form>
    </>
  );
};

export default AddHsnCodeModal
