import React from "react";
import "./Banner.scss";
import deliveryManImg from "../../Assets/Images/banner.svg";
import { Link } from "react-router-dom";

const Banner = (props) => {
  return (
    <div className="banner-giga-wrapper">
      <div className="banner">
        <div className="banner-text">
          <h1 className="banner-head">Your Retail store online in seconds !</h1>
          <p className="banner-sub-text">
            Your Business, Our code, Your App. <br />
            Get yourself online with your own order management system, website{" "}
            <br /> and mobile application in just a few clicks.
          </p>
          <br />
          <div className="banner-features-text">
            <li>
              Your Store Online in a few clicks with minimal documentation.
            </li>

            <li>Add Services and features as you grow your business.</li>

            <li>Billing to Complete Mobile Application with AikTech.</li>
          </div>
          <Link className="cursor see-our-pricing-link" to={`/pricing`}>
            <div className="see-our-pricing">See solutions and pricing</div>
          </Link>
          <div className="install-image"></div>
        </div>

        <img src={deliveryManImg} alt="404NotFound" className="banner-image" />
      </div>
    </div>
  );
};

export default Banner;

Banner.defaultProps = {};
