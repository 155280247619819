import React, { useState } from 'react'
import styles from "../Design.module.scss"
import { FaCaretDown } from "react-icons/fa";
export default function Wrapper({ children, title }) {
    const [isOpen, setOpen] = useState(true)
    return (
        <>
            <div className={styles.designWrapper}>

                <div className={styles.dropdownWrapper}>
                    <div className={`flex justify-between items-center ${styles.dropdownHeader}`}>
                        <h1 className={`${styles.dropdownLabel} font-semibold`}>{title}</h1>
                        <FaCaretDown onClick={() => setOpen(prev => !prev)} className={`${isOpen ? 'rotate180' : 'ratate0'}`} />
                    </div>
                    <div className={`${styles.dropdownWrapperContainer} ${isOpen ? styles.dropdownOpen : styles.dropdownClose} transition-all overflow-hidden  `}>
                        <div className='flex flex-col justify-start'>
                            {children}
                        </div>
                    </div>
                </ div>
            </div>
        </>
    )
}
