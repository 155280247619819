import React from 'react'
import Height from './Height'
import Width from './Width'
import styles from "../../Design.module.scss"
export default function Size() {
    return (


        <div className={`flex flex-col justify-start gap-1rem m-2`}>
            <div className={`flex justify-between items-center `}>
                <h1 className={` ${styles.dropdownLabel} font-semibold`}>Size</h1>
            </div>
            <Height />
            <Width />
        </div>

    )
}
