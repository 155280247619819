import React from 'react'
import "./Seller.scss"
import { useHistory } from "react-router-dom";
import Button from '../../../Components/Button/Button'
export default function Seller() {
    const history = useHistory();

    const navigate = (path) => {
        history.push(path);
    };
    return (
        <div className='home-v3-seller flex items-center justify-between '>
            <h1 className='font-bold home-v3-seller-h1 flex  flex-col'>
                <span>Start selling right now, right</span>
                <span>away</span>
            </h1>
            <div className='flex gap-1rem' style={{ margin: "25px 0px 0px 10px" }}>
                <Button className="home-v3-benefit-auth-btn" text="Create Now " onClick={() => navigate("/studio")}></Button>
                {/* <Button className="home-v3-benefit-auth-btn" style={{ backgroundColor: "transparent", color: "black" }} text="Book a Demo "></Button> */}
            </div>
        </div>
    )
}
