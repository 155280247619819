import React, { useEffect, useState, useRef } from 'react';
import styles from "../../Animation.module.scss"
import { useCustomizer } from '../../../../../../Provider/customizerProvider';

export default function Count() {
    const { onChange } = useCustomizer();
    const [count, setCount] = useState(0)

    useEffect(() => { onChange({ "animation-count": count }) }, [count])

    return (

        <div className={styles.itemWrapper} >
            <div className="flex justify-between items-center relative">
                <h4 className={styles.textLabel}>Count</h4>
                <div className={`flex items-center gap-05rem ${styles.inputContainer}`}>
                    <input
                        type="number"
                        className={`${styles.textInput} flex items-center`}
                        maxLength={10}
                        max={999}
                        value={count}
                        onChange={e => {
                            const value = e.target.value;
                            if (value > 999) {
                                setCount("infinite")
                            }
                            else {
                                setCount(value);
                            }

                        }}
                    />


                </div>
            </div>
        </div>

    );
}
