
import React, { useEffect, useState } from 'react';
import styles from "../../Design.module.scss";
import { FaLink } from "react-icons/fa6";
import { useCustomizer } from '../../../../../../Provider/customizerProvider';

export default function Translate() {

    const [sync, toggleSync] = useState(false);
    const [top, setTop] = useState(0);
    const [right, setRight] = useState(0);
    const [left, setLeft] = useState(0);
    const [bottom, setBottom] = useState(0);

    const { onChange } = useCustomizer();

    useEffect(() => {
        onChange({ 'transform': `translate(${left}px, ${top}px) translate(${right}px, ${bottom}px)` });
    }, [top, right, bottom, left, onChange]);

    const handleMarginChange = (setter, value) => {
        setter(value);
        if (sync) {
            setTop(value);
            setRight(value);
            setLeft(value);
            setBottom(value);
        }
    };

    return (
        <div className={styles.itemWrapper}>
            <div className="flex justify-between items-center align-center relative">
                <h4 className={styles.textLabel}>Translate</h4>
                <FaLink
                    onClick={() => toggleSync(prev => !prev)}
                    className={`${styles.syncButton} ${sync ? styles.activeSync : ""}`}
                />
            </div>
            <div className={`${styles.dummyWrapper} flex items-center flex-col`}>
                <div className="flex items-center gap-1rem">
                    <input
                        type="number"
                        className={`${styles.textSmallInput} flex items-center m-1`}
                        max={999}
                        value={top}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d+$/.test(value)) {
                                handleMarginChange(setTop, Number(value));
                            }
                        }}
                    />
                </div>
                <div className="flex items-center">
                    <input
                        type="number"
                        className={`${styles.textSmallInput} flex items-center m-1  text-align-right`}
                        max={999}
                        value={left}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d+$/.test(value)) {
                                handleMarginChange(setLeft, Number(value));
                            }
                        }}
                    />
                    <div className={styles.dummyBox}></div>
                    <input
                        type="number"
                        className={`${styles.textSmallInput} flex items-center m-1 p-0`}
                        max={999}
                        value={right}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d+$/.test(value)) {
                                handleMarginChange(setRight, Number(value));
                            }
                        }}
                    />
                </div>
                <div className="flex items-center gap-1rem">
                    <input
                        type="number"
                        className={`${styles.textSmallInput} flex items-center m-1`}
                        max={999}
                        value={bottom}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d+$/.test(value)) {
                                handleMarginChange(setBottom, Number(value));
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

