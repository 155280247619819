import React from "react";
import Select from "react-select";
import { isEqual, isEmpty } from "lodash";
import PropTypes from "prop-types";
import "./LabelledSelect.scss";

export default function LabelledSelect({
  className,
  label,
  name,
  data = [],
  onSelect,
  placeholder,
  values,
  error,
  multiSelect,
  isClearable,
  onInputChange,
  isLoading,
  customHandleChange = null,
  disabled = false,
  required = false,
}) {
  const styles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: "#d2d1d154",
    }),
  };

  const components = {
    IndicatorSeparator: () => null,
  };

  const handleChange = (option) => {
    if (multiSelect) {
      const optionValues = option.map((e) => {
        return e.value;
      });
      onSelect(optionValues);
    } else {
      const { value } = option;
      onSelect(value);
    }
  };

  const getSelectedOption = () => {
    if (isEmpty(data)) {
      return null;
    }
    if (multiSelect && values && values.length > 0) {
      const selectedOption = data.filter((obj) => values.includes(obj.value));
      return selectedOption ? selectedOption : null;
    } else {
      const selectedOption = data.find((option) => {
        return isEqual(option.value, values);
      });
      return selectedOption ? selectedOption : null;
    }
  };

  const selectedOption =getSelectedOption();

  return (
    <div className={`LabelledSelect ${className}`}>
      {label && <div className={`LabelledSelect__Label`}>{label}
        {required && <sup style={{ color: 'red' }}>*</sup>}
      </div>}
      <Select
        className={`LabelledSelect__Select`}
        options={data}
        // styles={styles}
        // components={components}
        onChange={customHandleChange || handleChange}
        placeholder={placeholder}
        isMulti={multiSelect}
        isClearable={isClearable}
        value={selectedOption}
        onInputChange={onInputChange}
        isDisabled={disabled}
      // isLoading={isLoading}
      // name={name}
      />
      {error && error.error && (
        <div className="LabelledSelect__Error">{error.msg}</div>
      )}
    </div>
  );
}

LabelledSelect.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array,
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
  values: PropTypes.object,
  error: PropTypes.object,
  multiSelect: PropTypes.bool,
};

LabelledSelect.defaultProps = {
  className: "",
  label: "",
  data: [],
  onSelect: () => { },
  placeholder: "",
  values: {},
  error: {},
  multiSelect: false,
};
