import React from "react";
import "./Path.scss";

export default function Path() {
  return (
    <div className="Path">
      <svg
        width="1256"
        height="631"
        viewBox="0 0 1256 631"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_262_3366)">
          <path
            d="M27 460.908C83.7255 508.698 215.849 598.818 290.537 576.971C383.898 549.662 406.942 409.021 551.12 376.25C695.297 343.479 801.066 457.494 912.745 292.958C1024.42 128.421 1078.78 20.5505 1228.87 43.7631"
            stroke="#F57059"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <rect
          x="174.132"
          y="519.621"
          width="75.6339"
          height="87.3887"
          rx="20"
          fill="white"
        />
        <rect
          x="197.767"
          y="546.93"
          width="27.181"
          height="31.4053"
          rx="10"
          fill="#C4C4C4"
        />
        <rect
          x="650.023"
          y="339.382"
          width="75.6339"
          height="87.3887"
          rx="20"
          fill="white"
        />
        <rect
          x="673.659"
          y="366.691"
          width="27.181"
          height="31.4053"
          rx="10"
          fill="#C4C4C4"
        />
        <rect
          x="1123.1"
          y="0.750732"
          width="75.6339"
          height="87.3887"
          rx="20"
          fill="white"
        />
        <rect
          x="1146.74"
          y="28.0596"
          width="27.181"
          height="31.4053"
          rx="10"
          fill="#C4C4C4"
        />
        <defs>
          <filter
            id="filter0_d_262_3366"
            x="0.5"
            y="38.1265"
            width="1254.87"
            height="592.702"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="24" />
            <feGaussianBlur stdDeviation="12" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.215686 0 0 0 0 0.203922 0 0 0 0 0.662745 0 0 0 0.3 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_262_3366"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_262_3366"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
}
