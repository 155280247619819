import React, { useEffect, useState } from 'react'
import styles from "../../Design.module.scss"
import { useCustomizer } from '../../../../../../Provider/customizerProvider';

import { MdOutlineVerticalAlignBottom, MdOutlineVerticalAlignTop, MdVerticalAlignCenter } from "react-icons/md";


import { BsDash } from 'react-icons/bs';
export default function Alignment() {
    const { onChange } = useCustomizer();

    const [prop, setProp] = useState('');
    const [val, setVal] = useState('')

    useEffect(() => {
        if (prop && val) {
            onChange({ prop: val })
        }
    }, [prop, val])
    return (
        <div className={styles.itemWrapper} >
            <div className="flex justify-between items-center relative">
                <h4 className={styles.textLabel}>Alignment</h4>
                <div className={`flex items-center gap-05rem`}>
                    <BsDash className={`${styles.Icon} ${prop === '' ? styles.activeIcon : ""}`} onClick={() => { setProp(''); setVal(''); }} />

                    <MdOutlineVerticalAlignBottom onClick={() => { setProp('margin-bottom'); setVal('auto'); }} className={`${styles.Icon} ${prop === 'margin-bottom' ? styles.activeIcon : ""}`} />

                    <MdOutlineVerticalAlignTop onClick={() => { setProp('margin-top'); setVal('auto'); }} className={`${styles.Icon} ${prop === 'margin-top' ? styles.activeIcon : ""}`} />

                    <MdVerticalAlignCenter onClick={() => { setProp('margin'); setVal('auto'); }} className={`${styles.Icon} ${prop === 'margin' ? styles.activeIcon : ""}`} />


                </div>

            </div>
        </div>
    )
}
