import React from "react";

//Style
import "./Preview.scss";

//Prop type
import PropTypes from "prop-types";

export default function Preview({ previewImage, slide, formData }) {
  const { tenant_category = "" } = formData || {};
  return (
    <div className={`Preview ${tenant_category ? `Slide${slide}` : ""}`}>
      <div
        className={`Preview__Image `}
        style={{
          backgroundImage: `url(${previewImage})`,
        }}
      ></div>
    </div>
  );
}

Preview.propTypes = {
  previewImage: PropTypes.string,
  slide: PropTypes.number,
};

Preview.defaultProps = {
  previewImage: "",
  slide: 1,
};
