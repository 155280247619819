export const componentTypeToPathMap = new Map([
    ["header", () => import("../../Templates/home-template/Header/Header")],
    ["search-bar", () => import('../../Templates/home-template/SearchBar')],
    ['gallery', () => import("../../Templates/home-template/Gallery")],
    ["banner", () => import("../../Templates/home-template/Banner")],
    ['faq', () => import("../../Templates/home-template/FAQs")],
    ['team', () => import("../../Templates/home-template/Teams")],
    ["promotional-bar", () => import("../../Templates/home-template/PromotionalBar")],
    ["featured-products", () => import("../../Templates/home-template/Featured")],
    ["category-showcase", () => import("../../Templates/home-template/Categories")],
    ["featured-categories", () => import("../../Templates/home-template/Featured-Categories")],
    ["offer", () => import("../../Templates/home-template/Offers")],
    ["testimonials", () => import("../../Templates/home-template/Testimonials")],
    ["video-section", () => import("../../Templates/home-template/VideoSection")],
    ["newsletter-signup", () => import("../../Templates/home-template/NewsLetter")],
    ["recently-viewed", () => import("../../Templates/home-template/NewsLetter")],
    ["brand-showcase", () => import("../../Templates/home-template/BrandShowcase")],
    ["blog-highlights", () => import("../../Templates/home-template/Blogs")],
    ["footer", () => import("../../Templates/home-template/Footer")],
    ["company-showcase", () => import("../../Templates/home-template/Companyshowcase")],
    ["why-us", () => import("../../Templates/home-template/WhyUs")],
    ["footer-description", () => import("../../Templates/home-template/FooterDescription")],
    ["hero-banner", () => import("../../Templates/home-template/HeroBanner/HeroBanner")],
    ["contact-form", () => import("../../Templates/home-template/FormContact/Form")],
    ["multi-column", () => import("../../Templates/home-template/Multi-Column/index")],
    ["image-text-list", () => import("../../Templates/home-template/ImageTextComponent/index")],
    ["subscription-form", () => import("../../Templates/home-template/Subscribe/index")],
    ["categories", () => import("../../Templates/category-template/Categories")],
    ["cart", () => import("../../Templates/cart-template/Cart")],
    ["recent-orders", () => import("../../Templates/recent-orders-template/RecentOrder")],
    ["invoice", () => import("../../Templates/invoice-template/InvoiceMain")],
    [""]
]);


export const getLoadingText = (type) => {
    const loadingTexts = {
        "header": "Header",
        "search-bar": "Search Bar",
        "banner": "Banner",
        "promotional-bar": "Promotional Bar",
        "featured-products": "Featured Products",
        "category-showcase": "Category Showcase",
        "offer": "Offers",
        "testimonials": "Testimonials",
        "video-section": "Video Section",
        "newsletter-signup": "Newsletter Signup",
        "recently-viewed": "Recently Viewed",
        "brand-showcase": "Brand Showcase",
        "blog-highlights": "Blog Highlights",
        "company-showcase": "Company Showcase",
        "footer-description": "Footer Description",
        "why-us": "Building Why Us",
        "footer": "Footer",
    };
    return loadingTexts[type] || "Component";
};

export function getCurrConfigByPage(config, page) {
    let counter = 0;
    for (const key in config) {
        if (counter === 0) {
            counter++;
            continue;
        }
        if (config?.[key]?.page == page) {
            return key;
        }
        counter++;
    }
}

export function generateNewId(prefix) {
    return `${prefix}-${Math.floor(100000 + Math.random() * 900000)}`;
}

export function updateIds(obj) {
    if (Array.isArray(obj)) {
        return obj.map(updateIds);
    } else if (typeof obj === 'object' && obj !== null) {
        const newObj = { ...obj };
        for (const key in newObj) {
            if (key === 'id' && typeof newObj[key] === 'string') {
                const [prefix] = newObj[key].split('-');
                newObj[key] = generateNewId(prefix);
            } else if (typeof newObj[key] === 'object' || Array.isArray(newObj[key])) {
                newObj[key] = updateIds(newObj[key]);
            }
        }
        return newObj;
    }
    return obj;
}
export function findElementListById(config, targetId) {
    const isObject = obj => obj && typeof obj === 'object';

    if (config?.id === targetId) {
        if (config.hasOwnProperty('list')) {
            const localCopy = { ...config.list[0] };
            const newSection = updateIds(localCopy);
            config.list.push(newSection);
            return config;
        }
    }
    for (let key in config) {
        if (config.hasOwnProperty(key)) {
            const value = config[key];
            if (isObject(value) || Array.isArray(value)) {
                const foundElement = findElementListById(value, targetId);
                if (foundElement) {
                    return foundElement;
                }
            }
        }
    }
}

export function findElementById(config, targetId) {
    const isObject = obj => obj && typeof obj === 'object';

    if (config?.id === targetId) {
        return config;
    }
    for (let key in config) {
        if (config.hasOwnProperty(key)) {
            const value = config[key];
            if (isObject(value) || Array.isArray(value)) {
                const foundElement = findElementById(value, targetId);
                if (foundElement) {
                    return foundElement;
                }
            }
        }
    }
    return null;
}
