import React from 'react';
import './Tooltip.scss';

const Tooltip = ({ children, content, position = "top" }) => {
  return (
    <div className="tooltip-container">
      {children}
      <div className={`tooltip-box tooltip-${position}`}>
        {content}
      </div>
    </div>
  );
};

export default Tooltip;
