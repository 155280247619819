import FullWidthBanner from "../Assets/TemplatesPreview/Banner/FullWidth.png";
import SlideShowBanner from "../Assets/TemplatesPreview/Banner/SlideShow.png";
import MultiImageBanner from "../Assets/TemplatesPreview/Banner/MultiImage.png";
import FullWidthOffer from "../Assets/TemplatesPreview/Offer/Full-Width.png";
import GridOffer from "../Assets/TemplatesPreview/Offer/Grid.png";
import ScrollingPromotionalBar from "../Assets/TemplatesPreview/Promotional-Bar/Scrolling.png";
import StepsCompanyShowCase from "../Assets/TemplatesPreview/Company-Showcase/Steps-With-Image.png";
import TriImgCompanyShowCase from "../Assets/TemplatesPreview/Company-Showcase/Tri-Img.png";
import StepsWhyUs from "../Assets/TemplatesPreview/Why-Us/Steps.png";
import CarouselTestimonials from "../Assets/TemplatesPreview/Testimonials/Carousel.png";
import FullWidthFooterDesc from "../Assets/TemplatesPreview/Footer-Description/Full-Width.png";
import MasonryCategory from "../Assets/TemplatesPreview/Categories/productcaterogry.png"
import CarouselCategory from "../Assets/TemplatesPreview/Categories/Carousel.png";
import Layout3Category from "../Assets/TemplatesPreview/Categories/Layout-3.png";
import FullHeightFullWidthImg from "../Assets/TemplatesPreview/Banner/FullHeightFullWidth.png"
import VideoBannerImg from "../Assets/TemplatesPreview/Banner/VideoBanner.png"
import Flexbox from "../Assets/TemplatesPreview/Footer/flexbox.png"
import green from "../Assets/TemplatesPreview/Footer/resortblur.png"
import flex from "../Assets/TemplatesPreview/Footer/flex.png"
import multicol from "../Assets/TemplatesPreview/Multi-Column/multiColumn.png"
import subscribe from "../Assets/TemplatesPreview/SubsciptionForm/Screenshot 2024-10-23 170503.png"
import contactform from "../Assets/TemplatesPreview/ContactForm/contactus.png"
import textwithimg from "../Assets/TemplatesPreview/ImageTextList/text-image.png"
import catergoriesimg from "../Assets/TemplatesPreview/Categories/categoriesss.png"
import OrderImg from "../Assets/TemplatesPreview/Categories/ordersssssss.png"
import cartImg from "../Assets/TemplatesPreview/Categories/cartss.png"
import InvoiceImg from "../Assets/TemplatesPreview/Categories/invoiceimg.png"
import clientlelimage from "../Assets/TemplatesPreview/Categories/clientelle.png"
import textwithimage from "../Assets/TemplatesPreview/Categories/textwithimagesbradnshowcase.png"
import Layout2 from "../Assets/TemplatesPreview/Categories/Layout3.png"
import Layout3 from "../Assets/TemplatesPreview/Categories/Screenshot 2024-10-28 224519.png"
import Layout4 from "../Assets/TemplatesPreview/Categories/Layout4.png"
import featuredproducImg from "../Assets/TemplatesPreview/Categories/featuredcateogry.png"
import Header2 from "../Assets/TemplatesPreview/Categories/header2.png"
import cart2 from "../Assets/TemplatesPreview/Cart/cartpage2.png"
import HeaderLayout3 from '../Assets/TemplatesPreview/Header/navbar/Layout-3.png'
import HeaderLayout4 from '../Assets/TemplatesPreview/Header/navbar/Layout4.png'
import HeaderLayout5 from '../Assets/TemplatesPreview/Header/navbar/layout5.png'
import HeaderLayout6 from '../Assets/TemplatesPreview/Header/navbar/layout6.png'
import HeaderLayout7 from '../Assets/TemplatesPreview/Header/navbar/layout7.png'
import HeaderLayout8 from '../Assets/TemplatesPreview/Header/navbar/layout8.png'
import HeaderLayout9 from '../Assets/TemplatesPreview/Header/navbar/layout9.png'
import ImageOnRight from '../Assets/TemplatesPreview/Banner/Banner-6.png'
import MultipleImageWithText from '../Assets/TemplatesPreview/Banner/Banner7.png'
import FullBanner from '../Assets/TemplatesPreview/Banner/Banner-8.png'
import MessageBan from '../Assets/TemplatesPreview/Banner/messagebanner.png'
import HeroBanner from '../Assets/TemplatesPreview/HeroBanner/Screenshot 2024-11-08 205404.png'
import HeroBanner2 from '../Assets/TemplatesPreview/HeroBanner/Screenshot 2024-11-09 204455.png'
import HeroBanner3 from '../Assets/TemplatesPreview/HeroBanner/Screenshot 2024-11-09 204751.png'
import heroBanner4 from '../Assets/TemplatesPreview/HeroBanner/Screenshot 2024-11-09 204919.png'
import HeroBanne5 from '../Assets/TemplatesPreview/HeroBanner/Screenshot 2024-11-09 205118.png'
import HeroBanner6 from '../Assets/TemplatesPreview/HeroBanner/Screenshot 2024-11-09 205820.png'
import cardimage from '../Assets/TemplatesPreview/HeroBanner/Screenshot 2024-11-09 111602.png'
import layout11 from '../Assets/TemplatesPreview/HeroBanner/LAyout11.png'
import hbanner5 from '../Assets/TemplatesPreview/HeroBanner/herbanner-layout5.png'
import hbanner7 from '../Assets/TemplatesPreview/HeroBanner/heroBannerlayout7.png'
import hbanner9 from '../Assets/TemplatesPreview/HeroBanner/herobannerLAyout9.png'
import hbanner11 from '../Assets/TemplatesPreview/HeroBanner/herobannerlayout11.png'
import hbanner14 from '../Assets/TemplatesPreview/HeroBanner/herobannerlayout14.png'
import hbanner16 from '../Assets/TemplatesPreview/HeroBanner/herobannerlayout16.png'
// import hbanner17 from '../Assets/TemplatesPreview/HeroBanner/herbanner-layout5.png'
import hbanner17 from '../Assets/TemplatesPreview/HeroBanner/herobannerlayout17.png'
import hbanner18 from '../Assets/TemplatesPreview/HeroBanner/herobannerlayout18.png'
import hbanner19 from '../Assets/TemplatesPreview/HeroBanner/herobannerlayout19.png'
import hbanner21 from '../Assets/TemplatesPreview/HeroBanner/herobannerlayout21.png'
import hbanner23 from '../Assets/TemplatesPreview/HeroBanner/realherobannerlayout23.png'
import hbanner25 from '../Assets/TemplatesPreview/HeroBanner/herobannerlayout25.png'
import habnner24 from '../Assets/TemplatesPreview/HeroBanner/layout24.png'


// gallery import 
import Gallery1 from '../Assets/TemplatesPreview/Gallery/Simple-Masonry.png'
import Gallery2 from "../Assets/TemplatesPreview/Gallery/Masonry-with-saturation.png"
import Gallery3 from "../Assets/TemplatesPreview/Gallery/Masonry-with-overlay.png"
import Gallery4 from "../Assets/TemplatesPreview/Gallery/honey-comb.png"
import Gallery5 from "../Assets/TemplatesPreview/Gallery/Accordion-effect.png"
import Gallery6 from "../Assets/TemplatesPreview/Gallery/Grid-with-overlay.png"
import Gallery7 from "../Assets/TemplatesPreview/Gallery/Grid-with-saturation.png"
// team card import
import Team1 from "../Assets/TemplatesPreview/Teams/Carousel-1.png"
import Team2 from "../Assets/TemplatesPreview/Teams/Carousel-2.png"
import Team3 from "../Assets/TemplatesPreview/Teams/Carousel-3.png"
import Team4 from "../Assets/TemplatesPreview/Teams/Grid-1.png"
import Team5 from "../Assets/TemplatesPreview/Teams/Grid-2.png"
import Team6 from "../Assets/TemplatesPreview/Teams/Grid-3.png"
// faq
import FAQ1 from "../Assets/TemplatesPreview/FAQ's/Grid-FAQ.png"
import FAQ2 from "../Assets/TemplatesPreview/FAQ's/Flexbox-FAQ.png"
import FAQ3 from "../Assets/TemplatesPreview/FAQ's/Hover-accordion.png"
import FAQ4 from "../Assets/TemplatesPreview/FAQ's/Click-accordion.png"
import FAQ5 from "../Assets/TemplatesPreview/FAQ's/Masonry-accordion.png"
import FAQ6 from "../Assets/TemplatesPreview/FAQ's/Masonry-click-accordion.png"


// product card
import ProductCard1 from "../Assets/TemplatesPreview/Product Card/Product-Card-1.png"
import ProductCard2 from "../Assets/TemplatesPreview/Product Card/Product-Card-2.png"
import ProductCard3 from "../Assets/TemplatesPreview/Product Card/Product-Card-3.png"
export const ComponentVariantsLists = {

    "Product Card": {
        "config": {
            "type": "product_card",
            "layout": "block",
            "id": "container-6747564752567",
            "style": {

            },
        },
        "variants": [
            {
                type: "Product-Card-1",
                previewImg: ProductCard1,
            },
            {
                type: "Product-Card-2",
                previewImg: ProductCard2,
            },
            {
                type: "Product-Card-3",
                previewImg: ProductCard3,
            }
        ]
    },

    "FAQ's": {
        "config": {
            "type": "faq",
            "layout": "Full-Screen",
            "id": "container-75686794356",
            "style": {

            },
            "heading": {
                "text": "FAQ's",
                "style": {
                    "color": "black",
                    "fontSize": "24px",
                    "fontWeight": "bold",
                    "textAlign": "center"
                },
                "id": "text-12434256357234523"
            },
            "faqs": [
                {
                    "ques": {
                        "id": "text-4363246346236",
                        "text": "What is your return policy?",
                        "style": {
                            "fontSize": "15",
                            "fontWeight": "bold",
                            "padding": "0",
                            "margin": "0"
                        }
                    },
                    "ans": {
                        "id": "text-9837465728374",
                        "text": "We offer a 30-day return policy on all items. Items must be returned in their original condition for a full refund.",
                        "style": {
                            "fontSize": "12px",
                            "padding": "0",
                            "margin": "0"
                        }
                    }
                },
                {
                    "ques": {
                        "id": "text-1239837461234",
                        "text": "How can I track my order?",
                        "style": {
                            "fontSize": "15",
                            "fontWeight": "bold",
                            "padding": "0",
                            "margin": "0"
                        }
                    },
                    "ans": {
                        "id": "text-2374623874623",
                        "text": "Once your order has been shipped, you’ll receive an email with a tracking number and link to follow your package.",
                        "style": {
                            "fontSize": "12px",
                            "padding": "0",
                            "margin": "0"
                        }
                    }
                },
                {
                    "ques": {
                        "id": "text-9872364827634",
                        "text": "Do you offer international shipping?",
                        "style": {
                            "fontSize": "15",
                            "fontWeight": "bold",
                            "padding": "0",
                            "margin": "0"
                        }
                    },
                    "ans": {
                        "id": "text-7482638472639",
                        "text": "Yes, we ship internationally. Shipping fees and delivery times vary based on location.",
                        "style": {
                            "fontSize": "12px",
                            "padding": "0",
                            "margin": "0"
                        }
                    }
                },
                {
                    "ques": {
                        "id": "text-4672349876234",
                        "text": "What payment methods do you accept?",
                        "style": {
                            "fontSize": "15",
                            "fontWeight": "bold",
                            "padding": "0",
                            "margin": "0"
                        }
                    },
                    "ans": {
                        "id": "text-2634876234976",
                        "text": "We accept major credit cards, PayPal, and Apple Pay. All transactions are securely processed.",
                        "style": {
                            "fontSize": "12px",
                            "padding": "0",
                            "margin": "0"
                        }
                    }
                },
                {
                    "ques": {
                        "id": "text-782353573723648",
                        "text": "How can I contact customer support?",
                        "style": {
                            "fontSize": "15",
                            "fontWeight": "bold",
                            "padding": "0",
                            "margin": "0"
                        }
                    },
                    "ans": {
                        "id": "text-7823648723648",
                        "text": "You can reach our customer support team by email at support@example.com or by phone at (555) 123-4567.",
                        "style": {
                            "fontSize": "12px",
                            "padding": "0",
                            "margin": "0"
                        }
                    }
                },
                {
                    "ques": {
                        "id": "text-6723648237648",
                        "text": "Can I cancel or change my order?",
                        "style": {
                            "fontSize": "15",
                            "fontWeight": "bold",
                            "padding": "0",
                            "margin": "0"
                        }
                    },
                    "ans": {
                        "id": "text-8374628374628",
                        "text": "Yes, you can cancel or modify your order within 24 hours of placing it by contacting customer support.",
                        "style": {
                            "fontSize": "12px",
                            "padding": "0",
                            "margin": "0"
                        }
                    }
                },
                {
                    "ques": {
                        "id": "text-2847632634632",
                        "text": "Do you offer discounts for bulk orders?",
                        "style": {
                            "fontSize": "15",
                            "fontWeight": "bold",
                            "padding": "0",
                            "margin": "0"
                        }
                    },
                    "ans": {
                        "id": "text-2847632874623",
                        "text": "Yes, we offer discounts on bulk orders. Please contact our sales team for more details.",
                        "style": {
                            "fontSize": "12px",
                            "padding": "0",
                            "margin": "0"
                        }
                    }
                },
                {
                    "ques": {
                        "id": "text-3648723648723",
                        "text": "Is my personal information secure?",
                        "style": {
                            "fontSize": "15",
                            "fontWeight": "bold",
                            "padding": "0",
                            "margin": "0"
                        }
                    },
                    "ans": {
                        "id": "text-8364872364873",
                        "text": "We use industry-standard security measures to protect your personal information. For more details, please review our Privacy Policy.",
                        "style": {
                            "fontSize": "12px",
                            "padding": "0",
                            "margin": "0"
                        }
                    }
                }
            ]
        }
        ,
        "variants": [
            {
                type: "Grid",
                previewImg: FAQ1,
            },
            {
                type: "Flexbox",
                previewImg: FAQ2,
            },
            {
                type: "Hover-Accordion",
                previewImg: FAQ3,
            },
            {
                type: "Click-Accordion",
                previewImg: FAQ4,
            },
            {
                type: "Masonry-Accordion",
                previewImg: FAQ5,
            },
            {
                type: "Masonry-Click-Accordion",
                previewImg: FAQ6,
            }
        ]
    },

    "Team": {
        "config": {
            "type": "team",
            "layout": "Full-Screen",
            "id": "container-436567232456",
            "style": {
                "backgroundColor": "#FFF",
            },
            "heading": {
                "text": "Meet Our Team",
                "style": {
                    color: "black",
                    fontSize: "24px",
                    fontWeight: "bold",
                    textAlign: "center",
                    marginBottom: "20px",
                    marginTop: "20px"
                },
                "id": "text-12434513451345346"
            }, "team": [
                {
                    "id": "team-12345678",
                    "name": {
                        "id": "text-87654321",
                        "text": "John Smith",
                        "style": { "fontSize": "16px" }
                    },
                    "desig": {
                        "id": "text-23456789",
                        "text": "Product Manager",
                        "style": { "fontSize": "12px" }
                    },
                    "img": {
                        "id": "image-34567890",
                        "src": "https://wallpapers.com/images/hd/plain-black-desktop-2560-x-1440-ugpl0479gu0vuwnl.jpg",
                        "style": {}
                    },
                    "about": {
                        "id": "text-45678901",
                        "text": "An experienced product manager with a focus on user-centered design and cross-functional team leadership.",
                        "style": { "fontSize": "10px", "paddingTop": "10px", "paddingBottom": "5px" }
                    },
                    "socialLinks": [
                        {
                            "id": "icon-56789012",
                            "url": "https://facebook.com/johnsmith",
                            "style": {
                                "height": "16px",
                                "width": "16px"
                            }
                        },
                        {
                            "id": "icon-67890123",
                            "url": "https://twitter.com/johnsmith",
                            "style": {
                                "height": "16px",
                                "width": "16px"
                            }
                        },
                        {
                            "id": "icon-78901234",
                            "url": "https://linkedin.com/in/johnsmith",
                            "style": {
                                "height": "16px",
                                "width": "16px"
                            }
                        }
                    ]
                },
                {
                    "id": "team-23456789",
                    "name": {
                        "id": "text-98765432",
                        "text": "Jane Doe",
                        "style": { "fontSize": "16px" }
                    },
                    "desig": {
                        "id": "text-34567890",
                        "text": "Software Engineer",
                        "style": { "fontSize": "12px" }
                    },
                    "img": {
                        "id": "image-45678901",
                        "src": "https://wallpapers.com/images/hd/plain-black-desktop-2560-x-1440-ugpl0479gu0vuwnl.jpg",
                        "style": {}
                    },
                    "about": {
                        "id": "text-56789012",
                        "text": "A passionate software engineer with expertise in full-stack development and cloud-based solutions.",
                        "style": { "fontSize": "10px", "paddingTop": "10px", "paddingBottom": "5px" }
                    },
                    "socialLinks": [
                        {
                            "id": "icon-67890124",
                            "url": "https://github.com/janedoe",
                            "style": {
                                "height": "16px",
                                "width": "16px"
                            }
                        },
                        {
                            "id": "icon-78901235",
                            "url": "https://instagram.com/janedoe",
                            "style": {
                                "height": "16px",
                                "width": "16px"
                            }
                        },
                        {
                            "id": "icon-89012346",
                            "url": "mailto:jane.doe@example.com",
                            "style": {
                                "height": "16px",
                                "width": "16px"
                            }
                        }
                    ]
                }, {
                    "id": "team-12345678",
                    "name": {
                        "id": "text-87654321",
                        "text": "John Smith",
                        "style": { "fontSize": "18px" }
                    },
                    "desig": {
                        "id": "text-23456789",
                        "text": "UI/UX Designer",
                        "style": { "fontSize": "14px" }
                    },
                    "img": {
                        "id": "image-34567890",
                        "src": "https://wallpapers.com/images/hd/plain-black-desktop-2560-x-1440-ugpl0479gu0vuwnl.jpg",
                        "style": {}
                    },
                    "about": {
                        "id": "text-45678901",
                        "text": "An innovative designer specializing in creating seamless user experiences and captivating visuals.",
                        "style": { "fontSize": "12px", "paddingTop": "8px", "paddingBottom": "6px" }
                    },
                    "socialLinks": [
                        {
                            "id": "icon-56789012",
                            "url": "https://github.com/johnsmith",
                            "style": {
                                "height": "20px",
                                "width": "20px"
                            }
                        },
                        {
                            "id": "icon-67890123",
                            "url": "https://linkedin.com/in/johnsmith",
                            "style": {
                                "height": "20px",
                                "width": "20px"
                            }
                        },
                        {
                            "id": "icon-78901234",
                            "url": "mailto:john.smith@example.com",
                            "style": {
                                "height": "20px",
                                "width": "20px"
                            }
                        }
                    ]
                },
                {
                    "id": "team-34567890",
                    "name": {
                        "id": "text-87654322",
                        "text": "Alice Johnson",
                        "style": { "fontSize": "16px" }
                    },
                    "desig": {
                        "id": "text-23456790",
                        "text": "UX Designer",
                        "style": { "fontSize": "12px" }
                    },
                    "img": {
                        "id": "image-45678902",
                        "src": "https://wallpapers.com/images/hd/plain-black-desktop-2560-x-1440-ugpl0479gu0vuwnl.jpg",
                        "style": {}
                    },
                    "about": {
                        "id": "text-45678902",
                        "text": "Specializes in creating intuitive user experiences with a strong background in UI design and usability testing.",
                        "style": { "fontSize": "10px", "paddingTop": "10px", "paddingBottom": "5px" }
                    },
                    "socialLinks": [
                        {
                            "id": "icon-56789013",
                            "url": "https://behance.net/alicejohnson",
                            "style": {
                                "height": "16px",
                                "width": "16px"
                            }
                        },
                        {
                            "id": "icon-67890125",
                            "url": "https://dribbble.com/alicejohnson",
                            "style": {
                                "height": "16px",
                                "width": "16px"
                            }
                        },
                        {
                            "id": "icon-78901236",
                            "url": "https://linkedin.com/in/alicejohnson",
                            "style": {
                                "height": "16px",
                                "width": "16px"
                            }
                        }
                    ]
                }

            ]

        },
        "variants": [
            {
                type: "Carousel-1",
                previewImg: Team1,
            },
            {
                type: "Carousel-2",
                previewImg: Team2,
            },
            {
                type: "Carousel-3",
                previewImg: Team3,
            },
            {
                type: "Grid-1",
                previewImg: Team4,
            },
            {
                type: "Grid-2",
                previewImg: Team5,
            },
            {
                type: "Grid-3",
                previewImg: Team6,
            }
        ]
    },
    "Gallery": {
        "config": {
            "type": "gallery",
            "layout": "Full-Screen",
            "id": "container-7345272427",
            "styles": {
            },
            "images": [
                {
                    "id": "image-23984756",
                    "tag": {
                        "id": "text-84927563",
                        "text": "Mountains and volcanos",
                        "style": {}
                    },
                    "src": "https://images.unsplash.com/photo-1631451095765-2c91616fc9e6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzNDA0OTI3Nw&ixlib=rb-1.2.1&q=80&w=400",
                    "style": {}
                },
                {
                    "id": "image-83749261",
                    "tag": {
                        "id": "text-92847563",
                        "text": "Adventure getaways",
                        "style": {}
                    },
                    "src": "https://images.unsplash.com/photo-1633621533308-8760aefb5521?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzNDA1MjAyMQ&ixlib=rb-1.2.1&q=500&w=400",
                    "style": {}
                },
                {
                    "id": "image-19284765",
                    "tag": {
                        "id": "text-19384756",
                        "text": "Forest escapes",
                        "style": {}
                    },
                    "src": "https://images.unsplash.com/photo-1633635146842-12d386e64058?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzNDA1MjA5OA&ixlib=rb-1.2.1&q=500&w=400",
                    "style": {}
                },
                {
                    "id": "image-39485726",
                    "tag": {
                        "id": "text-47829156",
                        "text": "Hiking trails",
                        "style": {}
                    },
                    "src": "https://images.unsplash.com/photo-1568444438385-ece31a33ce78?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzNDA1MjA5OA&ixlib=rb-1.2.1&q=500&w=400",
                    "style": {}
                },
                {
                    "id": "image-48275639",
                    "tag": {
                        "id": "text-37495826",
                        "text": "Street scenes",
                        "style": {}
                    },
                    "src": "https://images.unsplash.com/photo-1633515257379-5fda985bd57a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzNDA1MjA5OA&ixlib=rb-1.2.1&q=500&w=400",
                    "style": {}
                },
                {
                    "id": "image-92634578",
                    "tag": {
                        "id": "text-57634982",
                        "text": "Trending",
                        "style": {}
                    },
                    "src": "https://images.unsplash.com/photo-1633209931146-260ce0d16e22?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxNDU4OXwwfDF8cmFuZG9tfHx8fHx8fHx8MTYzNDA1MjA5OA&ixlib=rb-1.2.1&q=500&w=400",
                    "style": {}
                },
                {
                    "id": "image-57648293",
                    "tag": {
                        "id": "text-92837465",
                        "text": "Tag for overlay",
                        "style": {}
                    },
                    "src": "https://picsum.photos/450/489",
                    "style": {}
                },
                {
                    "id": "image-38472618",
                    "tag": {
                        "id": "text-48291047",
                        "text": "Tag for overlay",
                        "style": {}
                    },
                    "src": "https://picsum.photos/450/480",
                    "style": {}
                },
                {
                    "id": "image-18475632",
                    "tag": {
                        "id": "text-72938475",
                        "text": "Tag for overlay",
                        "style": {}
                    },
                    "src": "https://picsum.photos/450/488",
                    "style": {}
                },
                {
                    "id": "image-27483965",
                    "tag": {
                        "id": "text-38475629",
                        "text": "Tag for overlay",
                        "style": {}
                    },
                    "src": "https://picsum.photos/450/487",
                    "style": {}
                },
                {
                    "id": "image-63984752",
                    "tag": {
                        "id": "text-83749125",
                        "text": "Tag for overlay",
                        "style": {}
                    },
                    "src": "https://picsum.photos/450/486",
                    "style": {}
                },
                {
                    "id": "image-82739465",
                    "tag": {
                        "id": "text-49382756",
                        "text": "Tag for overlay",
                        "style": {}
                    },
                    "src": "https://picsum.photos/450/482",
                    "style": {}
                }
            ]
            ,
            "heading": {
                "is_dragabble": false,
                "text": "Gallery",
                "id": "text-5251235125",
                "style": {
                    "top": "0",
                    "left": "0",
                    "fontSize": "30px",
                    "fontWeight": "bold",
                    "color": "black",
                    "textAlign": "center"
                }
            }
        },
        "variants": [
            {
                type: "Simple-Masonry",
                previewImg: Gallery1,
            },
            {
                type: "Masonry-with-saturation",
                previewImg: Gallery2,
            }, {
                type: "Masonry-with-overlay",
                previewImg: Gallery3,
            },
            {
                type: "Honey-comb",
                previewImg: Gallery4,
            },
            {
                type: "Accordion-Effect",
                previewImg: Gallery5,
            },

            {
                type: "Grid-with-Saturation",
                previewImg: Gallery7,
            },
        ]
    },
    "header": {
        "config": {},
        "variants": [
            {
                type: "With-Search",
                previewImg: MultiImageBanner,
            },
            {
                type: "Layout-2",
                previewImg: Header2
            },
            {
                type: "Layout-3",
                previewImg: HeaderLayout3
            },
            {
                type: "Layout-4",
                previewImg: HeaderLayout4
            },
            {
                type: "Layout-5",
                previewImg: HeaderLayout5
            },
            {
                type: "Layout-6",
                previewImg: HeaderLayout6
            },

            {
                type: "Layout-7",
                previewImg: HeaderLayout7
            },
            {
                type: "Layout-8",
                previewImg: HeaderLayout8
            },
            {
                type: "Layout-9",
                previewImg: HeaderLayout9
            },

        ],
    },
    "banner": {
        "config": {
            "type": "banner",
            "layout": "Full-Screen",
            "id": "container-3523523",
            "style": {
                "backgroundColor": "#FFF",
                "cssClass": "custom-banner",
            },
            "images": {
                "id": "container-34243",
                "style": {},
                "content": [
                    {
                        "backgroundImage": {
                            "src": "https://media.istockphoto.com/id/1177595975/vector/horizontal-grunge-grungy-vector-illustration-of-an-empty-smudged-blue-colored-textured.jpg?s=612x612&w=0&k=20&c=L8XvUl8uYy0WNPtJECdX3GAjyibGYLkVwXVb88NNX88=",
                            "id": "image-45335",
                            "style": {

                            },
                        },
                        "bannerImage": {
                            "src": "https://realvaluemart.in/static/media/banner_girl.6ec2d4bf.png",
                            "id": "image-3532",
                            "style": {

                            },
                        },
                        "heading": {
                            "text": "Get Groceries Delivered In Just Minutes.",
                            "id": "text-332392",
                            "style": {
                                "color": "#FFFF",
                            }
                        },
                        "description": {
                            "id": "text-3459034",
                            "text": "Free Delivery on every order through website",
                            "style": {
                                "color": "#FFFF",
                            }
                        },
                        "button": {
                            "text": "Shop Now",
                            "id": "button-3953953",
                            "route": "/shop",
                            "style": {
                                "color": "#FFFFFF",
                                "backgroundColor": "#FF6347",
                                "fontSize": "16px"
                            }
                        }
                    },
                    {
                        "backgroundImage": {
                            "src": "https://img.freepik.com/free-photo/vintage-textured-paper-background-vector_53876-110673.jpg?size=626&ext=jpg&ga=GA1.1.1871743016.1727842231&semt=ais_hybrid-rr-similar",
                            "id": "image-325323d",
                            "style": {

                            },
                        },
                        "bannerImage": {
                            "src": "https://realvaluemart.in/static/media/banner_girl.6ec2d4bf.png",
                            "id": "image-43332",
                            "style": {

                            },
                        },
                        "heading": {
                            "text": "Get Groceries Delivered In Just Minutes.",
                            "id": "text-463432",
                            "style": {
                                "color": "#FFFF",
                            }
                        },
                        "description": {
                            "text": "Free Delivery on every order through website",
                            "id": "text-352522",
                            "style": {
                                "color": "#FFFF",
                            }
                        },
                        "button": {
                            "text": "Explore",
                            "id": "button-43532",
                            "route": "/new-arrivals",
                            "style": {
                                "color": "#FFFFFF",
                                "backgroundColor": "#4CAF50",
                                "hoverColor": "#45A049",
                                "borderRadius": "15px",
                                "padding": '0.5em 1em',
                            }
                        }
                    }
                ],
            }
        },
        "variants": [
            {
                type: "Multi-Image",
                previewImg: MultiImageBanner,
            },
            {
                type: "Full-Width",
                previewImg: FullWidthBanner,
            },
            {
                type: "Slide-Show",
                previewImg: SlideShowBanner
            },
            {
                type: "Video-Banner",
                previewImg: VideoBannerImg,


            },
            {
                type: "Full-Width-Full-Height",
                previewImg: FullHeightFullWidthImg,

            },
            {
                type: "Text-With-Image",
                previewImg: ImageOnRight
            },
            {
                type: "Multiple-Images-With-Text",
                previewImg: MultipleImageWithText

            },
            {
                type: "FullBanner",
                previewImg: FullBanner
            },
            {
                type: "Message-Banner",
                previewImg: MessageBan
            }
        ]
    },
    "offer": {
        "config": {
            "type": "offer",
            "layout": "Grid",
            "id": "container-435332",
            "style": {
                "backgroundColor": "#F9F9F9",
                "titleColor": "#FF6347",
            },
            "title": {
                "text": "Exclusive Offers",
                "id": 'text-3525',
                "style": {
                }
            },
            "offers": {
                "id": "container-35235",
                "style": {
                },
                "content": [
                    {
                        "id": "container-45332",
                        "style": {
                        },
                        "title": {
                            "text": "50% Off on Summer Collection",
                            "id": "text-3222",
                            "style": {

                            }
                        },
                        "image": {
                            "src": "https://realvaluemart.in/static/media/discountIMG.096eee90.png",
                            "id": "image-33323",
                            "style": {

                            }
                        },
                        "route": "/shop/summer",
                        "offerContent": {
                            "id": "container-849030",
                            "style": {

                            },
                            "heading": {
                                "text": "Want it, You got it!",
                                "id": "text-34322",
                                "style": {
                                    "color": "black",
                                }
                            },
                            "description": {
                                "text": "Get Flat 50% Discount Upto ₹100 on Your First Order!",
                                "id": "text-345939",
                                "style": {
                                }
                            }
                        },
                        "button": {
                            "text": "Shop Now",
                            "id": "ehdshsdgh",
                            "route": "/shop/summer",
                            "style": {
                                "backgroundColor": "#2B8AE4",
                                "color": "#FFFF",
                                "fontSize": "15px",
                                "fontWeight": "600",
                                "borderRadius": "20px",
                                "padding": "9px 16px"
                            }
                        }
                    },
                    {
                        "id": "bcxnncxsdd",
                        "style": {

                        },
                        "image": {
                            "src": "https://realvaluemart.in/static/media/discountIMG.096eee90.png",
                            "id": "dvxxs",
                        },
                        "route": "/shop/electronics",
                        "offerContent": {
                            "id": "vcvxcc",
                            "style": {

                            },
                            "heading": {
                                "text": "20% Discount on Electronics",
                                "id": "sdfxsxx",
                                "style": {
                                    "color": "black",
                                }
                            },
                            "description": {
                                "text": "Get Flat 50% Discount Upto ₹100 on Your First Order!",
                                "id": "dfdxxza",
                                "style": {
                                }
                            }
                        },
                        "button": {
                            "id": "dsvvzxzz",
                            "text": "Shop Now",
                            "route": "/shop/summer",
                            "style": {
                                "backgroundColor": "#2B8AE4",
                                "color": "#FFFF",
                                "fontSize": "15px",
                                "fontWeight": "600",
                                "borderRadius": "20px",
                                "padding": "9px 16px"
                            }
                        }
                    },
                    {
                        "id": "dsvvxzdsz",
                        "style": {

                        },
                        "image": {
                            "src": "https://realvaluemart.in/static/media/discountIMG.096eee90.png",
                            "id": "sdfsdg",
                            "style": {

                            },
                        },
                        "route": "/shop/shoes",
                        "offerContent": {
                            "id": "sfdsfxx",
                            "style": {

                            },
                            "heading": {
                                "id": 'dsgdsgvss',
                                "text": "Buy 1 Get 1 Free on Shoes",
                                "style": {
                                    "color": "black",
                                }
                            },
                            "description": {
                                "id": "deuuiebr",
                                "text": "Get Flat 50% Discount Upto ₹100 on Your First Order!",
                                "style": {
                                }
                            }
                        },
                        "button": {
                            "id": "dsfdsccc",
                            "text": "Shop Now",
                            "route": "/shop/summer",
                            "style": {
                                "backgroundColor": "#2B8AE4",
                                "color": "#FFFF",
                                "fontSize": "15px",
                                "fontWeight": "600",
                                "borderRadius": "20px",
                                "padding": "9px 16px"
                            }
                        }
                    },
                    {
                        "image": {
                            "src": "https://realvaluemart.in/static/media/discountIMG.096eee90.png",

                        },
                        "route": "/shop/electronics",
                        "offerContent": {
                            "heading": {
                                "text": "20% Discount on Electronics",
                                "style": {
                                    "color": "black",
                                }
                            },
                            "description": {
                                "text": "Get Flat 50% Discount Upto ₹100 on Your First Order!",
                                "style": {
                                }
                            }
                        },
                        "button": {
                            "text": "Shop Now",
                            "route": "/shop/summer",
                            "style": {
                                "backgroundColor": "#2B8AE4",
                                "color": "#FFFF",
                                "fontSize": "15px",
                                "fontWeight": "600",
                                "borderRadius": "20px",
                                "padding": "9px 16px"
                            }
                        }
                    },
                ],
            },
        },
        "variants": [
            {
                type: "Full-Width",
                previewImg: FullWidthOffer,
            },
            {
                type: "Grid",
                previewImg: GridOffer
            }
        ]
    },
    "promotional-bar": {
        "config": {
            "type": "promotional-bar",
            "layout": "Scrolling",
            "promotions": [
                {
                    "title": {
                        "text": "Free shipping on orders over $50!",
                        "id": "text-76876"
                    },
                    "icon": {
                        "name": "local_shipping",
                        "src": "https://cdn-icons-png.flaticon.com/512/5952/5952766.png",
                        "id": "image-768752356",
                        "height": "150px",
                        "background": "transparent",
                        "style": {
                            "iconColor": "#4CAF50",
                            "cssClass": "promo-bar-2",
                            "height": "150px",
                            "backgroundColor": "transparent"
                        }
                    },
                    "style": {
                        "iconColor": "#4CAF50",
                        "cssClass": "promo-bar-2",
                        "height": "150px",
                        "backgroundColor": "transparent"
                    }

                },
                {
                    "title": {
                        "text": "20% off first-time purchase with code FIRST20",
                        "id": "text-873298"
                    },
                    "icon": {
                        "name": "discount",
                        "src": "https://png.pngtree.com/png-vector/20230408/ourmid/pngtree-price-tag-with-the-discount-icon-vector-png-image_6686659.png",
                        "id": "image-8798",
                        "height": "150px",
                        "background": "transparent",
                        "style": {
                            "iconColor": "#4CAF50",
                            "cssClass": "promo-bar-2",
                            "height": "150px",
                            "backgroundColor": "transparent"
                        }
                    },
                    "style": {
                        "textColor": "#4CAF50",
                        "fontSize": "1.5em"
                    }
                }
            ],


            "style": {
                "backgroundColor": "#F1F1F1",
                "cssClass": "custom-promotional-bar"
            },
            "style_second_container": {
                "display": "inline-block",
                "minWidth": "100px",
                "textAlign": "center",
                "maxWidth": "400px"
            }
        },
        "variants": [
            {
                type: "Scrolling",
                previewImg: ScrollingPromotionalBar
            }
        ]

    },
    "featured-products": {
        "config": {
            "type": "featured-products",
            "title": "Featured Products",
            "layout": "Carousel",
            "carouselSettings": {
                "slidesToShow": 4,
                "slidesToScroll": 2,
                "autoplay": true,
                "loop": true
            },
            "api": {
                "endpoint": "/api/featured-products",
                "method": "GET",
                "params": {}
            },
            "productIds": [101, 102, 103, 104],
            "style": {
                "titleColor": "#212529",
                "cssClass": "featured-products-carousel"
            }
        },
        "variants": [
            {
                type: "Carousel",
                previewImg: FullWidthBanner,

            },
            {
                type: "Favourites",
                previewImg: featuredproducImg,

            }
        ]
    },
    "category-showcase": {
        "config": {
            "type": "category-showcase",
            "title": "Shop by Category",
            "layout": "Masonry",
            "categories": [
                {
                    "title": {
                        "text": "Men's Fashion",
                        "id": "text-5354",
                        "style": {

                        },
                    },
                    "image": {
                        "src": "https://images.pexels.com/photos/298863/pexels-photo-298863.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
                        "id": "image-5354",
                        "style": {

                        },
                    },
                    "route": "/category/men",
                    "style": {},
                    "id": "container-35235",

                },
                {
                    "title": {
                        "text": "Men's Fashion",
                        "id": "text-5354"
                    },
                    "image": {
                        "src": "https://duhpn7g3b6mt.cloudfront.net/phurti-cloudfront/category/2_medicine.jpeg",
                        "id": "image-5354"
                    },
                    "route": "/category/men",
                    "style": {}
                },
                {
                    "title": {
                        "text": "Women's Fashion",
                        "id": "text-354"

                    },
                    "image": {
                        "src": "/assets/category-women.jpg",
                        "id": "image-354"
                    },

                    "route": "/category/women",
                    "style": {}
                },
                {
                    "title": {
                        "text": "Electronics",
                        "id": "text-8798"
                    },
                    "image": {
                        "src": "/assets/category-electronics.jpg",
                        "id": "image-8798"
                    },
                    "route": "/category/electronics",
                    "style": {}
                },
                {
                    "title": {
                        "text": "Home & Garden",
                        "id": "text-8908"
                    },
                    "image": {
                        "src": "/assets/category-home.jpg",
                        "id": "image-8908"
                    },
                    "route": "/category/home-garden",
                    "style": {}
                },
                {
                    "title": {
                        "text": "Pharmacy",
                        "id": "text-9012"
                    },
                    "image": {
                        "src": "/assets/category-pharmacy.jpg",
                        "id": "image-9012"
                    },
                    "route": "",
                    "style": {}
                }
            ],
            "style": {
                "backgroundColor": "#FFFFFF",
            }
        },
        "variants": [
            {
                type: "Masonry",
                previewImg: MasonryCategory,
            },
            {
                type: "Full-Width",
                previewImg: CarouselCategory,
            },
            {
                type: "Layout 3",
                previewImg: Layout3Category,
            }
        ]
    },
    "company-showcase": {
        "config": {
            "type": "company-showcase",
            'id': 'content-9778678',
            "layout": "Tri-Img",
            "img": { "style": { width: '100' }, "id": "image-68768757657", "src": 'https://mahzedahrbakery.com/cdn/shop/files/home-page-collage-1.gif?v=1636169902' },
            "title": {
                "style": {
                    "fontSize": "1.5rem",
                    "textAlign": "left",
                    "fontStyle": "italic",
                    "letterSpacing": "1.5px",
                    "fontFamily": "\"GFS Didot\", serif",
                    "marginBottom": "1em"
                },
                "id": "text-2424234",
                "text1": "BRING THE FESTIVITIES HOME THIS YEAR, AND MAKE IT THE SWEETEST HOLIDAY YET ",
                "text2": "",
                "color": "rgb(11, 28, 44)"
            },
        },
        "variants": [
            {
                type: "Steps-With-Image",
                previewImg: StepsCompanyShowCase
            },
            {
                type: "Tri-Img",
                previewImg: TriImgCompanyShowCase
            },
            {
                type: "Grid",
                previewImg: TriImgCompanyShowCase

            }
        ]
    },
    "why-us": {
        "config": {
            "type": 'why-us',
            "layout": "Steps",
            "style": {
                "backgroundColor": "#FFF",
            },
            "title": {
                "text": "Want it, You got it",
                "id": "text-9089",
                "style": {
                    "fontSize": "30px",
                    "fontWeight": "600",
                    "textAlign": "center"
                }

            },
            "backgroundimg": {
                "src": "https://realvaluemart.in/static/media/wave.73663610.svg",
                "id": "image-52523",
                "style": {}
            },
            "list": [
                {
                    "image": {
                        "src": "https://realvaluemart.in/static/media/step1.3daff349.svg",
                        "id": 'image-87798456379088',
                        "style": {}
                    },
                    "content": {
                        "text": "Cooking gajar ka halwa and out of sugar?",
                        "id": 'text-34243',
                        "style": {
                            "color": "black",
                            "fontSize": "14px",
                            "fontWeight": "500",
                        }
                    }
                },
                {
                    "image": {
                        "src": "https://realvaluemart.in/static/media/step2.6347e33f.svg",
                        "id": "image-8798",
                        "style": {}
                    },
                    "content": {
                        "text": "Order through Realvaluemart.in",
                        "id": "text-057687",
                        "style": {
                            "color": "black",
                            "fontSize": "14px",
                            "fontWeight": "500",
                        }
                    }
                },
                {
                    "image": {
                        "src": "https://realvaluemart.in/static/media/step3.986c2899.svg",
                        "id": "image-424364574564654564543",
                        "style": {}
                    },
                    "content": {
                        "text": "Get it in just minutes!",
                        "id": "text-798798709",
                        "style": {
                            "color": "black",
                            "fontSize": "14px",
                            "fontWeight": "600",
                        }
                    }
                },
            ]
        },
        "variants": [
            {
                type: "Steps",
                previewImg: StepsWhyUs
            }
        ]
    },
    "testimonials": {
        "config": {
            "type": "testimonials",
            "title": { "text": "Customer Testimonials", "id": "text-309989", "style": {} },
            "description": { "text": "We like to keep working, so we let our customers speak.", "id": "text-906875", "style": { "textAlign": 'center' } },
            "layout": "Carousel",
            "imagestyle": {
                "borderRadius": "50%",
                "width": "100px",
                "objectFit": "cover",
                "height": "100px"
            },
            "items": [
                {
                    "id": "image-4324",
                    "name": {
                        "id": "text-12345",
                        "content": "Alice Johnson"
                    },
                    "image": {
                        "id": "image-98765",
                        "src": "https://t3.ftcdn.net/jpg/02/99/04/20/360_F_299042079_vGBD7wIlSeNl7vOevWHiL93G4koMM967.jpg",
                        "style": {}
                    },
                    "style": {},
                    "text": {
                        "id": "text-4344",
                        "content": "Amazing products and quick delivery!",
                        "style": {}
                    }
                },
                {
                    "id": "image-4325",
                    "name": {
                        "id": "text-12346",
                        "content": "Michael Smith"
                    },
                    "image": {
                        "id": "image-98766",
                        "src": "",
                        "style": {}
                    },
                    "style": {},
                    "text": {
                        "id": "text-42349879",
                        "content": "Best shopping experience I’ve had online.",
                        "style": {}
                    }
                }
            ]
            ,
            "style": {
                "backgroundColor": "#F1F1F1",
                "titleColor": "#4CAF50",
                "cssClass": "testimonials-carousel"
            }
        },
        "variants": [
            {
                type: "Carousel",
                previewImg: CarouselTestimonials
            }
        ]
    },
    // "video-section": {
    //     "config": {},
    //     "variants": [
    //         {
    //             type: "Centered",
    //         }
    //     ]
    // },
    "brand-showcase": {
        "config": {},
        "variants": [
            {
                type: "textwithimages",
                previewImg: textwithimage
            },
            {
                type: 'clientele',
                previewImg: clientlelimage
            }
        ]
    },
    // "recently-viewed": {
    //     "config": {},
    //     "variants": [
    //         {
    //             type: "Carousel",
    //         }
    //     ]
    // },
    // "blog-highlights": {
    //     "config": {},
    //     "variants": [
    //         {
    //             type: "List",
    //         }
    //     ]
    // },
    "footer-description": {
        "config": {
            "type": "footer-description",
            "layout": "Full-Width",
            "id": "container-43536",
            "style": {
            },
            "image": {
                "id": "image-243123",
                "src": "https://realvaluemart.in/static/media/bottom.7295ac58.png",
                "style": {

                }
            },
            "content": {
                "text": "Get your Grocery Delivered Instantly with Real Value Mart",
                "id": "text-424435",
                "styles": {
                    "fontSize": "18px",
                    "fontWeight": "500",
                    "textAlign": "center",
                    "color": "#FFF"
                }
            }

        },
        "variants": [
            {
                type: "Full-Width",
                previewImg: FullWidthFooterDesc,
            }
        ]
    },
    "footer": {
        "config": {},
        "variants": [
            {
                type: "Flex-Box",
                previewImg: Flexbox
            },
            {
                type: "Simple-Footer",
                previewImg: flex
            },
            {
                type: "Dual-Column",
                previewImg: green
            }
        ]
    },
    "hero-banner": {
        "config": {
            "id": "container-123456",
            "type": "hero-banner",
            "layout": "Layout-1",
            "style": {
                "cssClass": "heroBanner"
            },
            "content": {
                "textContent": {
                    "heading": {
                        "id": "text-101",
                        "text": "Add a short, strong value message here",
                        "style": {
                            "cssClass": "heading"
                        }
                    },
                    "subText": {
                        "id": "text-102",
                        "text": "Add extra information here to complement the headline. Highlight key benefits in one or two sentences.",
                        "style": {
                            "cssClass": "subText"
                        }
                    },
                    "button": {
                        "id": "button-103",
                        "text": "Discover More",
                        "style": {
                            "cssClass": "button"
                        }
                    }
                },
                "imageGrid": {
                    "images": [
                        {
                            "id": "image-201",
                            "src": "https://images.pexels.com/photos/1876790/pexels-photo-1876790.jpeg?auto=compress&cs=tinysrgb&w=400",
                            "alt": "Image 1",
                            "style": {
                                "cssClass": "image"
                            }
                        },
                        {
                            "id": "image-202",
                            "src": "https://images.pexels.com/photos/7099640/pexels-photo-7099640.jpeg?auto=compress&cs=tinysrgb&w=400",
                            "alt": "Image 2",
                            "style": {
                                "cssClass": "image"
                            }
                        },
                        {
                            "id": "image-203",
                            "src": "https://images.pexels.com/photos/1876790/pexels-photo-1876790.jpeg?auto=compress&cs=tinysrgb&w=400",
                            "alt": "Image 3",
                            "style": {
                                "cssClass": "image"
                            }
                        },
                        {
                            "id": "image-204",
                            "src": "https://images.pexels.com/photos/949273/pexels-photo-949273.jpeg?auto=compress&cs=tinysrgb&w=400",
                            "alt": "Image 4",
                            "style": {
                                "cssClass": "image"
                            }
                        },
                        {
                            "id": "image-205",
                            "src": "https://images.pexels.com/photos/1697620/pexels-photo-1697620.jpeg?auto=compress&cs=tinysrgb&w=400",
                            "alt": "Image 5",
                            "style": {
                                "cssClass": "image"
                            }
                        }
                    ],
                    "style": {
                        "cssClass": "imageGrid"
                    }
                },
                "image": {
                    "id": "image-987",
                    "style": {},
                    "src": "https://images.unsplash.com/photo-1500417148159-68083bd7333a"
                },

            }
        }
        ,
        "variants": [
            {
                type: 'Layout-1',
                previewImg: hbanner25
            },
            {
                type: 'Layout-2',
                previewImg: HeroBanner
            },
            {
                type: 'Layout-3',
                previewImg: cardimage
            },

            {
                type: 'Layout-4',
                previewImg: HeroBanne5
            },
            {
                type: 'Layout-5',
                previewImg: hbanner5
            },
            {
                type: 'Layout-6',
                previewImg: HeroBanner2
            },
            {
                type: 'Layout-7',
                previewImg: hbanner7
            },
            {
                type: "Layout-9",
                previewImg: hbanner9
            },
            {
                type: "Layout-11",
                previewImg: hbanner11
            },
            {
                type: "Layout-14",
                previewImg: hbanner14
            },
            {
                type: "Layout-16",
                previewImg: hbanner16
            },
            {
                type: "Layout-17",
                previewImg: hbanner17
            },
            {
                type: "Layout-18",
                previewImg: hbanner18
            },
            {
                type: "Layout-19",
                previewImg: hbanner19
            },
            {
                type: "Layout-20",
                previewImg: hbanner11
            },
            {
                type: "Layout-21",
                previewImg: hbanner21
            }
            , {
                type: "Layout-22",
                previewImg: heroBanner4
            },
            {
                type: "Layout-23",
                previewImg: hbanner23
            }

        ]
    },
    "contact-form": {
        "config": {
            "type": "contact-form",
            "layout": "simple-form",
            "id": "container-524626263",
            "style": {},
            "containerClassName": "contactFormContainer",
            "heading": {
                "text": "Contact Us",
                "id": "text-1534534",
                "className": "heading",
                "style": {}
            },
            "form": {
                "className": "contactForm",
                "fields": [
                    {
                        "type": "text",
                        "name": "name",
                        "id": "name",
                        "label": {
                            "text": "Name",
                            "id": "text-14634",
                            "style": {}
                        },
                        "inputClassName": "input",
                        "labelClassName": "label",
                        "required": true
                    },
                    {
                        "type": "email",
                        "name": "email",
                        "id": "email",
                        "label": {
                            "text": "Email",
                            "id": "text-275454",
                            "style": {}
                        },
                        "inputClassName": "input",
                        "labelClassName": "label",
                        "required": true
                    },
                    {
                        "type": "textarea",
                        "name": "message",
                        "id": "message",
                        "label": {
                            "text": "Message",
                            "id": "text-3",
                            "style": {}
                        },
                        "inputClassName": "textarea",
                        "labelClassName": "label",
                        "required": true
                    }
                ],
                "submitButton": {
                    "text": "Send Message",
                    "id": "button-196785",
                    "className": "submitButton",
                    "style": {}
                }
            }
        },
        "variants": [
            {
                type: "simple-form",
                previewImg: contactform
            }
        ]
    },
    "categories": {
        "config": {
            "type": "categories",
            "layout": "Layout 1",
            "api": "",


        },
        "variants": [
            {
                type: "Layout 1",
                previewImg: catergoriesimg
            },
            {
                type: "Layout 2",
                previewImg: Layout2
            },
            {
                type: "Layout 3",
                previewImg: Layout3
            },
            {
                type: "Layout 4",
                previewImg: Layout4
            },
            {
                type: "Layout 5"
            },
            {
                type: "Layout 6"
            }
        ]

    },
    "cart": {
        "config": {
            "type": "cart",
            "layout": "Layout 1",
            "api": "",


        },
        "variants": [
            {
                type: "Layout 1",
                previewImg: cartImg
            },
            {
                type: "Layout 2",
                previewImg: cart2
            }
        ]

    },
    "recent-orders": {
        "config": {
            "type": "recent-orders",
            "layout": "Layout 1",
            "api": "",


        },
        "variants": [
            {
                type: "Layout 1",
                previewImg: OrderImg
            }
        ]

    },
    "invoice": {
        "config": {
            "type": "invoice",
            "layout": "Layout 1",
            "api": "",


        },
        "variants": [
            {
                type: "Invoice 1",
                previewImg: InvoiceImg
            }
        ]

    },
    "multi-column": {
        "config": {
            "type": "multi-column",
            "layout": "three-column",
            "id": "container-52588",
            "style": {},
            "title": {
                "text": "Multicolumn",
                "id": "text-162362",
                "className": "title",
                "style": {}
            },
            "columns": [
                {
                    "style": {},
                    "id": "container-53543",
                    "title": {
                        "text": "Column 1",
                        "id": "text-26422",
                        "style": {}
                    },
                    "description": {
                        "text": "Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.",
                        "id": "text-36246246",
                        "style": {}
                    },
                    "className": "column"
                },
                {
                    "style": {},
                    "id": "container-53543",
                    "title": {
                        "text": "Column 2",
                        "id": "text-446326236",
                        "style": {}
                    },
                    "description": {
                        "text": "Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.",
                        "id": "text-5346226",
                        "style": {}
                    },
                    "className": "column"
                },
                {
                    "style": {},
                    "id": "container-53543",
                    "title": {
                        "text": "Column 3",
                        "id": "text-647789798789798789789775",
                        "style": {}
                    },
                    "description": {
                        "text": "Pair text with an image to focus on your chosen product, collection, or blog post. Add details on availability, style, or even provide a review.",
                        "id": "text-747547",
                        "style": {}
                    },
                    "className": "column"
                }
            ],
            "button": {
                "text": "Button label",
                "id": "button-874574",
                "className": "button",
                "style": {}
            },
            "containerClassName": "multiColumnContainer",
            "columnsClassName": "columns"
        },
        "variants": [
            {
                type: "multi-column",
                previewImg: multicol
            }
        ]
    },
    "image-text-list": {
        "config": {
            "type": "image-text-list",
            "layout": "image-text-multiple",
            "containerClassName": "containerList",
            "id": "container-52523",
            "style": {},
            "items": [
                {
                    "style": {},
                    "id": "container-509543",
                    "image": {
                        "src": "https://images.pexels.com/photos/1876790/pexels-photo-1876790.jpeg?auto=compress&cs=tinysrgb&w=400",
                        "id": "image-17457645",
                        "style": {}
                    },
                    "title": {
                        "text": "Explore the Beauty of Nature",
                        "id": "text-17454",
                        "style": {}
                    },
                    "description": {
                        "text": "Discover the serenity of natural landscapes with our curated collection of stunning nature photography.",
                        "id": "text-1",
                        "style": {}
                    },
                    "button": {
                        "text": "Learn More",
                        "id": "button-16457357",
                        "style": {}
                    },
                    "imageClassName": "imageWrapper",
                    "textClassName": "textWrapper",
                    "titleClassName": "title",
                    "descriptionClassName": "description",
                    "buttonClassName": "button"
                },
                {
                    "style": {},
                    "id": "container-867656",
                    "image": {
                        "src": "https://images.pexels.com/photos/1876790/pexels-photo-1876790.jpeg?auto=compress&cs=tinysrgb&w=400",
                        "id": "image-2756745",
                        "style": {}
                    },
                    "title": {
                        "text": "Adventure Awaits",
                        "id": "text-27896879",
                        "style": {}
                    },
                    "description": {
                        "text": "Embark on thrilling adventures with our comprehensive guides and recommendations.",
                        "id": "text-2",
                        "style": {}
                    },
                    "button": {
                        "text": "Start Journey",
                        "id": "button-235453",
                        "style": {}
                    },
                    "imageClassName": "imageWrapper",
                    "textClassName": "textWrapper",
                    "titleClassName": "title",
                    "descriptionClassName": "description",
                    "buttonClassName": "button"
                },
                {
                    "style": {},
                    "id": "container-909543",
                    "image": {
                        "src": "https://images.pexels.com/photos/1876790/pexels-photo-1876790.jpeg?auto=compress&cs=tinysrgb&w=400",
                        "id": "image-3534535",
                        "style": {}
                    },
                    "title": {
                        "text": "Capture the Moment",
                        "id": "text-33535",
                        "style": {}
                    },
                    "description": {
                        "text": "Learn tips and tricks from professional photographers to capture breathtaking moments.",
                        "id": "text-3",
                        "style": {}
                    },
                    "button": {
                        "text": "Get Tips",
                        "id": "button-33242",
                        "style": {}
                    },
                    "imageClassName": "imageWrapper",
                    "textClassName": "textWrapper",
                    "titleClassName": "title",
                    "descriptionClassName": "description",
                    "buttonClassName": "button"
                }
            ]
        },
        "variants": [
            {

                type: "image-text-list",
                previewImg: textwithimg
            }
        ]
    },
    "subscription-form": {
        "config": {
            "type": "subscription-form",
            "layout": "email-subscription",
            "containerClassName": "subscribeContainer",
            "id": 'container-354345',
            "style": {},
            "heading": {
                "text": "Subscribe to our emails",
                "id": "text-1345345345345",
                "className": "subscribeHeading",
                "style": {}
            },
            "description": {
                "text": "Be the first to know about new collections and exclusive offers.",
                "id": "text-1",
                "className": "subscribeDescription",
                "style": {}
            },
            "form": {
                "className": "subscribeForm",
                "fields": [
                    {
                        "type": "email",
                        "placeholder": "Email",
                        "value": "",
                        "className": "subscribeInput",
                        "errorClassName": "error",
                        "errorMessageClassName": "errorMessage",
                        "errorMessage": {
                            "text": "Please fill out this field.",
                            "id": "error-1",
                            "style": {}
                        }
                    }
                ],
                "submitButton": {
                    "text": "→",
                    "id": "button-145345",
                    "className": "subscribeButton",
                    "style": {}
                }
            }
        },
        "variants": [
            {
                type: 'subscription-form',
                previewImg: subscribe
            }
        ]
    }
};