import React, { useEffect, useState } from 'react';
import styles from "./RightSidebar.module.scss";
import { useDispatch, useSelector } from 'react-redux';
import { motion } from "framer-motion";
import { IoMdBrush } from "react-icons/io";
import { AiOutlineThunderbolt } from "react-icons/ai";
import { MdContentPasteSearch } from "react-icons/md";
import { FaChevronRight } from "react-icons/fa";
import CustomizerProvider from '../../../Provider/customizerProvider';
import { actionsCreator } from '../../../Redux/actions/actionsCreator';

import Content from './Content';
import Design from './Design';
import Animation from './Animation';

export default function RightSidebar() {
    const [isSidebarOpen, toggleSidebar] = useState(true);
    const [tab, updateTab] = useState('content')

    const rightSidebarStatus = useSelector(state => state.sectionsList.showRightSidebar);
    const dispatch = useDispatch();

    const toggleRightSidebar = () => {
        toggleSidebar(false);
        dispatch(
            actionsCreator.SET_SECTIONS_LIST({
                showRightSidebar: false,
            })
        )
    }

    useEffect(() => {
        if (rightSidebarStatus) {
            dispatch(
                actionsCreator.SET_SECTIONS_LIST({
                    showLeftSidebar: false,
                })
            );
        }
    }, [rightSidebarStatus])

    return (
        <div className={styles.rightSidebar}>
            <FaChevronRight onClick={toggleRightSidebar} className={styles.toggleButton} />

            <motion.div
                className={styles.rightSidebarContainer}
                initial={{ x: '100%' }}
                animate={{ x: isSidebarOpen ? 0 : '100%' }}
                exit={{ x: '100%' }}
                transition={{ type: 'spring', stiffness: 100, damping: 20, duration: 0.5 }}
            >

                <div className={styles.rightSidebarV3Header}>
                    <div className={`${styles.tabButton} ${tab === null || tab === 'content' ? styles.tabButtonActive : ''}`}>
                        <MdContentPasteSearch
                            onClick={() => updateTab('content')}
                            className={styles.tabIcon}
                        />
                    </div>
                    <div className={`${styles.tabButton} ${tab === 'design' ? styles.tabButtonActive : ''}`}>
                        <IoMdBrush
                            onClick={() => updateTab('design')}
                            className={styles.tabIcon}
                        />
                    </div>
                    <div className={`${styles.tabButton} ${tab === 'animate' ? styles.tabButtonActive : ''}`}>
                        <AiOutlineThunderbolt
                            onClick={() => updateTab('animate')}
                            className={styles.tabIcon}
                        />
                    </div>
                </div>
                {/* <CustomizerProvider> */}

                    <div className={styles.sidebarBody}>

                        {
                            tab === 'content' ? <Content /> : <></>
                        }
                        {
                            tab === 'design' ? <Design /> : <></>
                        }
                        {
                            tab === 'animate' ? <Animation /> : <></>
                        }
                    </div>
                {/* </CustomizerProvider> */}

            </motion.div >
        </div >
    )
}
