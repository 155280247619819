// import { FETCH_STOCK_DROPDOWN, FETCH_ALL_PRODUCTS  } from "../actions/actionTypes";
import { call, put, takeLatest } from 'redux-saga/effects';
import { cmsApi } from '../../Api'
import { actionsCreator } from '../actions/actionsCreator';
import { FETCH_STOCK_DROPDOWN, FETCH_ALL_PRODUCTS } from '../actions/actionTypes';
import get from 'lodash/get';
import { toast } from 'react-toastify'

function* fetchStockDropdown(action) {
    try {
        const response = yield call(cmsApi.stockDropdowndetail, {});

        const data = get(response, 'data', [])
        yield put(actionsCreator.SET_STOCK_DROPDOWN({ list: data }));
    } catch (error) {

    }
}

function* fetchProductsearch(action) {
    try {
        yield put(actionsCreator.SET_ALL_PRODUCTS({ loading: true }));
        const response = yield call(cmsApi.searchProduct, {inv: action.payload});
        yield put(actionsCreator.SET_ALL_PRODUCTS({ status: 400 }));
        const data = get(response, 'data.results', []);
        yield put(actionsCreator.SET_ALL_PRODUCTS({ results: data }));
        if (response.status===200){
            yield put(actionsCreator.SET_ALL_PRODUCTS({ loading: false }))
        }
        else{
            yield put(actionsCreator.SET_ALL_PRODUCTS({ loading: false }))
            toast.error("ERROR. in API");
        }
    } catch (error) {
        yield put(actionsCreator.SET_ALL_PRODUCTS({ loading: false }))
        toast.error("SERVER ERROR!");
    }
}

export default function* cmsSagas() {
    yield takeLatest(FETCH_STOCK_DROPDOWN, fetchStockDropdown);
    yield takeLatest(FETCH_ALL_PRODUCTS, fetchProductsearch);
}