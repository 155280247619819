import { FETCH_WORKFLOW_CONFIGS, FETCH_ALL_TICKETS, SET_WORKFLOW_CONFIGS, SET_ALL_TICKETS } from '../actions/actionTypes';

const crmInitialState = {
  ticketList: [],
  workflow: [],
  ticket: []
};

export const crmReducer = (state = crmInitialState, { type, payload }) => {
  switch (type) {
    case SET_WORKFLOW_CONFIGS: {
      return {
        ...state,
        workflow: [payload]
      };
    }
    case SET_ALL_TICKETS: {
      return {
        ...state,
        ticketList: [payload]
      }
    }
    default:
      return state;
  }
};
