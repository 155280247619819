import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreator } from "../Redux/actions/actionsCreator";
export const useLogger = (props) => {
    const [maxStackSpace] = useState(200); // Maximum size of the undo stack
    const dispatch = useDispatch();
    const { currMode } = useSelector(state => state.sectionsList) || {}
    const { config } = useSelector(state => state.updateSectionsList) || {}

    const [changeArray] = useState(['css', 'config', 'theme'])

    const convertKeysToCamelCase = (cssRules) => {
        const toCamelCase = (str) => str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());

        return Object.entries(cssRules).reduce((camelCasedRules, [key, value]) => {
            const camelCaseKey = toCamelCase(key);
            camelCasedRules[camelCaseKey] = value;
            return camelCasedRules;
        }, {});
    };

    const handleChange = (lastConfig) => {
        if (lastConfig.type === 'css') {
            const cssRules = lastConfig.cssRules;
            const id = lastConfig.id;
            const target = document.getElementById(id);
            if (!target) return;
            Object.keys(cssRules).forEach((key) => {
                target.style[key] = cssRules[key];
            });
            if (!lastConfig.config || Object.keys(lastConfig.config).length === 0) return;

            dispatch(actionsCreator.SET_UPDATE_SECTIONS_LIST({ config: lastConfig.config }))
        }
        else if (lastConfig.type === 'theme') {
            const theme = lastConfig.theme;
            function setTemplateCSSVariable(element, variableName, value) {
                element?.style?.setProperty(variableName, value);
            }
            const [rootContainer] = document.getElementsByClassName('root-container');
            setTemplateCSSVariable(rootContainer, '--font', theme?.font);
            setTemplateCSSVariable(rootContainer, '--primary-color', theme?.primaryColor);
            setTemplateCSSVariable(rootContainer, '--secondary-color', theme?.secondaryColor);
        }
        else if (lastConfig.type === 'config') {
            // dispatch(actionsCreator.SET_SECTIONS_LIST({ config: lastConfig.config }))
        }

    }

    const undo = () => {
        if (currMode != 'edit') {
            return;
        }
        console.log("Undo called")
        const undoStack = JSON.parse(sessionStorage.getItem('builder-undo') || "[]");
        const redoStack = JSON.parse(sessionStorage.getItem('builder-redo') || "[]");

        if (undoStack.length > 0) {
            const lastConfig = undoStack.pop();
            redoStack.push(lastConfig);
            sessionStorage.setItem('builder-undo', JSON.stringify(undoStack));
            sessionStorage.setItem('builder-redo', JSON.stringify(redoStack));
            handleChange(lastConfig);
            return;
        } else {
            return;
        }
    };


    const redo = () => {
        if (currMode != 'edit') {
            return;
        }
        console.log("Redo called")
        const undoStack = JSON.parse(sessionStorage.getItem('builder-undo') || "[]");
        const redoStack = JSON.parse(sessionStorage.getItem('builder-redo') || "[]");

        if (redoStack.length > 0) {
            const lastConfig = redoStack.pop();
            undoStack.push(lastConfig);
            sessionStorage.setItem('builder-undo', JSON.stringify(undoStack));
            sessionStorage.setItem('builder-redo', JSON.stringify(redoStack));
            handleChange(lastConfig);

            return;
        } else {
            return;
        }
    };

    const logger = (config) => {

        if (!config || Object.keys(config).length === 0) return;


        const undoStack = JSON.parse(sessionStorage.getItem('builder-undo') || "[]");
        const redoStack = [];

        if (undoStack.length >= maxStackSpace) {
            undoStack.shift();
        }

        undoStack.push(config);
        // sessionStorage.setItem('builder-undo', JSON.stringify(undoStack));
        sessionStorage.setItem('builder-redo', JSON.stringify(redoStack));
    };

    return { undo, redo, logger };
};

