import React from "react";
import "./PricingPlanCard.scss";

import correctImg from "./../../Assets/Images/correct-image.png";
import iconUrl from "./../../Assets/Images/PricingPlanCard-img1.png";
import Button from "../Button";

export default function PricingPlanCard({ plan, onClick }) {
  const headingDesc = "Dummy text";
  const { name = "", price = "" } = plan || {};
  const stringConvertor = () => {
    let description = plan.description ? plan.description : "";
    description = description.replaceAll("-", " ");
    description = description.split("\r\n");
    return description;
  };
  const features = stringConvertor();
  return (
    <div className="PricingPlanCard">
      <div className="PricingPlanCard__Head">
        <div className="PricingPlanCard__Head__Icon">
          <img
            src={iconUrl}
            className="PricingPlanCard__Head__Icon__Image"
            alt=""
          />
        </div>
        <div className="PricingPlanCard__Head__Content">
          <div className="PricingPlanCard__Head__Content__Text">{name}</div>
          <div className="PricingPlanCard__Head__Content__Desc">
            {headingDesc}
          </div>
        </div>
      </div>
      {features.map((feature, index) => (
        <div className="PricingPlanCard__Box">
          <div className="PricingPlanCard__Box__Desc">
            <img src={correctImg} alt="" />
            <div className="PricingPlanCard__Box__Desc__Text">{feature}</div>
          </div>
        </div>
      ))}

      <div className="PricingPlanCard__Text">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reprehenderit,
        numquam perspiciatis velit quasi quisquam
      </div>

      <div className="PricingPlanCard__Price">Rs {price}</div>

      <div className="PricingPlanCard__Button" onClick={onClick}>
        <Button text={"Get Started"} />
      </div>
    </div>
  );
}
