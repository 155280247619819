import React from "react";
import { useHistory } from "react-router-dom";
import { PAYMENT_RESULT } from "../../Assets/Constant";
import Button from "../Button";
import Modal from "../Modal";
import "./PaymentResult.scss";

export default function PaymentResult({ type, retryPayment, onClose }) {
  const paymentResultData = PAYMENT_RESULT[type] || {};
  const history = useHistory();
  const {
    title = "",
    subTitle = "",
    icon = "",
    action = {},
  } = paymentResultData || {};
  const { path = "", name: btnName = "" } = action || {};

  const onClick = () => {
    if (path) {
      history.push(path);
    } else {
      retryPayment();
    }
  };
  return (
    <Modal show={type}>
      <div className="PaymentResult">
        <div className="PaymentResult__CloseIcon" onClick={onClose}>
          x
        </div>
        <div className="PaymentResult__Icon">
          <img src={icon} />
        </div>
        <div className="PaymentResult__Title">{title}</div>
        <div className="PaymentResult__SubTitle">{subTitle}</div>
        <div className="PaymentResult__Action">
          <Button text={btnName} onClick={onClick} />
        </div>
      </div>
    </Modal>
  );
}
