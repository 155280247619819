import React from "react";
import PropTypes from "prop-types";
import "./LabelledInput.scss";

export default function LabelledInput({
  label,
  placeholder,
  value,
  type,
  name,
  onChange,
  error,
  textarea,
  className,
  required,
  disabled,
  id,
}) {
  return (
    <div className={`LabelledInput ${className}`}>
      {label && (
        <label
          htmlFor={id}
          className="LabelledInput__Label"
          style={{fontWeight:"500"}}
        >
          {label}
          {required && <sup style={{color : 'red'}}>*</sup>}
        </label>
      )}
      {!textarea ? (
        <input
          className="LabelledInput__Input"
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
          required={required}
          disabled={disabled}
          autocomplete="off"
          id={id}
        />
      ) : (
        <textarea
          className="LabelledInput__Input TextArea"
          type={type}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          value={value}
          required
          rows={"3"}
        />
      )}
      {error && <div className="LabelledInput__Error">{error.msg}</div>}
    </div>
  );
}

LabelledInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: () => {},
  error: PropTypes.object,
  textarea: PropTypes.bool,
  disabled: PropTypes.bool,
};

LabelledInput.defaultProps = {
  label: "",
  placeholder: "",
  type: "text",
  value: "",
  bool: {},
  type: "text",
  textarea: false,
  disabled: false
};
