import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

//components
import {
  Button,
  DragAndDrop,
  LabelledInput,
  LabelledSelect,
} from "../../../../Components";

//styles
import "./BasicDetails.scss";

//Utils
import { formatOptionsData, validate } from "../../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreator } from "../../../../Redux/actions/actionsCreator";
import { useHistory } from "react-router-dom";
import { getToken } from "../../../../Utils";
import { verifyOtp } from "../../../../Api/authAPI";
import { tenantAPI } from "../../../../Api";
import { toast } from "react-toastify";
import { get } from "lodash";
import { storeToken } from "../../../../Utils";

const mapStateToProps = ({ 

  tenantForm = {}, 
  tenantBrands = {} 
}) => ({
  tenantForm,
  tenantBrands,
});

export default function BasicDetails({
  onSubmit,
  onBack,
  showBackBtn,
  resendOTP,
  goToNextSlide
}) {

  const {
    tenantForm: { sessionId = "", errors = {} },
    tenantBrands: { list: brandList = [], loading = false },
  } = useSelector(mapStateToProps);

  // const {
  //   name = "",
  //   phone_number = "",
  //   password2 = "",
  //   password = "",
  //   email = "",
  //   otp=""
  // } = formData || {};

  const [formData, setFormData] = useState({
    name : "",
    phone_number : "",
    password : "",
    password2 : "",
    email : "",
    otp : ""
  })

  const dispatch = useDispatch();
  const history = useHistory();
  const isLoggedIn = getToken();

  // useEffect(()=>{
  //   if(isLoggedIn){
  //     goToNextSlide()
  //   }
  // },[])

  const showOtpInput = sessionId

  const validateInput = (value, rules, key) => {
    const { error = false, msg = "" } = validate(value, rules);
    const errorObj = { [key]: { error, msg } };
    dispatch(actionsCreator.SET_TENANT_ERRORS(errorObj));
  };

  // useEffect(() => {
  //   dispatch(actionsCreator.RESET_TENANT_ERRORS());
  //   dispatch(actionsCreator.SET_BRANDS({ list: [] }));
  // }, []);

  const navigate = (path) => { 
    history.push(path);
  };

  const onBrandSearch = (searchText) => {
    if (searchText) {
      dispatch(actionsCreator.SEARCH_BRANDS(searchText));
    }
  };
  
  // const isDisable = !(
  //   (
  //     name &&
  //     phone_number &&
  //     // password &&
  //     // password2 &&
  //     !errors["phone_number"].error &&
  //     !errors["name"].error
  //   )
  //   // !errors["password"].error &&
  //   // !errors["password2"].error &&
  //   // (showOtpInput ? !errors["otp"].error && otp : true)
  // );

  const [isVerified, notVerified] = useState(false);
  
  const brands = formatOptionsData(brandList, "title", "title");

  const onChange = (event) => {
    setFormData(prev => ({
      ...prev, 
      [event.target.name] : event.target.value
    }))

  }

  console.log(formData);

  const handleSubmit = (event) => {
    event.preventDefault();

    if(formData.password !== formData.password2){
      toast.info("Passwords don't match");
      return;
    }

    onSubmit(formData);
  }

  return (
    <form onSubmit={handleSubmit} className="BasicDetails Slide__Animate flex flex-col gap-20">
      <h2 className="BasicDetails__Head">Enter your Details</h2>
      <p className="BasicDetails__Subhead">
        You'll need to log in to access your Aik-tech account
      </p>

      <LabelledInput
        label="Name"
        placeholder="Enter Name here..."
        type="text"
        value={formData.name}
        name={'name'}
        onChange={(e) => { 
          onChange(e, "name");
        }}
        error={errors["name"]}
        required={true}
      />

      <LabelledInput
        label="Phone Number"
        placeholder="Enter Phone number here..."
        value={formData.phone_number}
        type="text"
        name={'phone_number'}
        onChange={(e) => {
          onChange(e, "phone_number");
          validateInput(
            e.target.value,
            ["isPhone", "isRequired"],
            "phone_number"
          );
        }}
        error={errors["phone_number"]}
        required={true}
      />

      <LabelledInput
        label="Email"
        placeholder="Enter Email id here..."
        value={formData.email}
        type="text"
        name={'email'}
        onChange={(e) => {
          onChange(e, "email");
          validateInput(
            e.target.value,
            ["isEmail", "isRequired"],
            "email"
          );
        }}
        error={errors["email"]}
        required={true}
      />
      <LabelledInput
        label="Password"
        placeholder="Enter Password here..."
        type="password"
        value={formData.password}
        name={'password'}
        onChange={(e) => {
          onChange(e, "password");
          validateInput(e.target.value, ["isRequired"], "password");
        }}
        error={errors["password"]}
        required={true}
      />
      <LabelledInput
        label="Confirm Password"
        placeholder="Enter Password here..."
        type="password"
        value={formData.password2}
        name={'password2'}
        onChange={(e) => {
          onChange(e, "password2");
          validateInput(e.target.value, ["isRequired"], "password2");
        }}
        error={errors["password2"]}
        required={true}
      />

      {
        /* <LabelledSelect
          className={"SmallInput"}
          placeholder={"Select Brand"}
          label={"Brand Name"}
          onSelect={(e) => {
            onChange(e, "name", true);
            validateInput(e, ["isRequired"], "name");
          }}
          data={brands || []}
          values={name}
          error={errors["name"]}
          onInputChange={onBrandSearch}
          isLoading={loading}
        /> */
      }
     
      {
        /* <DragAndDrop
          label="Document (Optional)"
          className={"Document__File"}
          value={menuFile}
          onChange={(e) => {
            onChange(e, "menuFile", true);
            validateInput(menuFile, "menuFile");
          }}
          error={errors["menuFile"]}
        /> */
      }

      {
        /* <LabelledInput
          label="Password"
          placeholder="Enter Password here..."
          type="password"
          value={password}
          onChange={(e) => {
            onChange(e, "password");
            validateInput(e.target.value, ["isRequired"], "password");
          }}
          error={errors["password"]}
        />
        <LabelledInput
          label="Confirm Password"
          placeholder="Enter Password here..."
          type="password"
          value={password2}
          onChange={(e) => {
            onChange(e, "password2");
            validateInput(e.target.value, ["isRequired"], "password2");
          }}
          error={errors["password2"]}
        />
        */
      }

      {
        showOtpInput && 
        (
          <LabelledInput
            label="OTP"
            placeholder="Enter OTP here..."
            type="text"
            value={formData.otp}
            name={'otp'}
            onChange={(e) => {
              onChange(e, "otp");
              validateInput(e.target.value, ["isRequired"], "otp");
            }}
            error={errors["otp"]}
          />
        )
      } 

      <div className="flex justify-center">

        <button
          type="submit"
          className="btn-active btn-md"
          // onClick={onSubmit}
        >
          {
            showOtpInput ? "Verify OTP" : "Register"
          }
        </button>

      </div>

      {
        showOtpInput ? 
        (
          <div className="RegistrationForm__Back" onClick={resendOTP}>
            Resend OTP
          </div>
        ) 
        : 
        (
          <div
            className="RegistrationForm__Login"
            onClick={() => navigate("/login")}
          >
            Already have an account? <span>Login</span>
          </div>
        )
      }
    </form>
  );
}

BasicDetails.propTypes = {
  onChange: PropTypes.func,
  formData: PropTypes.object,
  onSubmit: PropTypes.func,
  showBackBtn: PropTypes.bool,
  onBack: PropTypes.func,
};
BasicDetails.defaultProps = {
  onChange: () => {},
  formData: {},
  onSubmit: () => {},
  showBackBtn: false,
  onBack: () => {},
};
