import axios from "axios";
import requestMaker from "../Lib";

export const getSubscriptions = () => {
  const url = `/account/subscriptions/`;
  const params = {};
  const payload = {};
  return requestMaker(url, "get", { params, payload });
};

export const getTenantCategories = () => {
  const url = `/account/tenant_categories/`;
  const params = {};
  const payload = {};
  return requestMaker(url, "get", { params, payload });
};

export const registerTenantUser = (data) => {
  const url = `/account/tenant_user/register/`;
  const params = {};
  const payload = { ...data };
  return requestMaker(url, "post", { params, payload });
};

export const sendTenantUserOtp = (data) => {
  const { phone_number = "" } = data || {};
  const url = `/account/tenant_user/tenant_user_send_otp/?phone=${phone_number}`;
  const params = {};
  const payload = {};
  return requestMaker(url, "post", { params, payload });
};
export const getPermissions = () => {
  const url = `/account/user/permissions/`;
  const params = {};
  const payload = {};
  return requestMaker(url, "get", { params, payload });
}

export const verifyTenantOtp = async (data) => {
  const url = `/account/tenant_user/verifyotp/`;
  const params = {};
  const payload = { ...data };
  return requestMaker(url, "post", { params, payload });
};
export const createTenant = (data) => {
  const url = `/account/tenants/`;
  const params = {};
  const payload = data;
  return requestMaker(url, "post", { params, payload });
};

export const getAllTenants = () => {
  const url = `/account/user/tenants/`;
  const params = {};
  const payload = {};
  return requestMaker(url, "get", { params, payload });
};

export const searchBrands = (searchText) => {
  const url = `/account/tenants/search/?q=${searchText}`;
  const params = {};
  const payload = {};
  return requestMaker(url, "get", { params, payload });
};

export const fetchCompanyDetails = () => {
  const url = '/account/company/details';
  const params = {};
  const payload = {};
  return requestMaker(url, "post", { params, payload })
}

export const publishTemplate = (data) => {
  const { tenantId, config } = data;
  const url = `/account/tenant-templates/${tenantId}/update_config/`;
  const payload = { 'config' : {...config} };
  return requestMaker(url, "put", { payload });
}

export const fetchAllTenantsByUser = () => {
  const url = `/account/tenants/`;
  return requestMaker(url, "get", {});
}

export const updateTenant = (tenantId) => {
  const url = `/account/update-tenant/`;
  const payload = { "tenant_id": tenantId };
  return requestMaker(url, "post", { payload });
}
export const fetchConfigByTenant = (tenantId) => {
  const url = `/account/tenants/${tenantId}`;
  return requestMaker(url, "get", {});
}