import React from "react";
import "./EditButton.scss";
import edit from "../../Assets/Icons/edit-button.svg";

import PropTypes from "prop-types";

export default function EditButton({ text, onClick, isDisable, className }) {
  return (
    <img
      className={`EditButton ${className}`}
      onClick={onClick}
      disabled={isDisable}
      src={edit}
    />
  );
}
EditButton.propTypes = {
  text: PropTypes.string,
  onclick: PropTypes.func,
  isDisable: PropTypes.bool,
  className: PropTypes.string,
};

EditButton.defaultProps = {
  text: "",
  onclick: () => {},
  isDisable: false,
  className: "",
};
