import React, { useEffect, useRef, useState } from "react";
import "./Menu.scss";

export default function Menu({ items, menuIcon, menuText }) {
  const [openProfile, setOpenProfile] = useState(false);

  const onAction = (item) => {
    if (item.action) {
      item.action();
      setOpenProfile(false);
    }
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenProfile(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const clickOutsideProfile = useRef();
  useOutsideAlerter(clickOutsideProfile);
  return (
    <div className="MenuContainer" ref={clickOutsideProfile}>
      <div
        className="MenuContainer__Items"
        onClick={() => setOpenProfile(!openProfile)}
      >
        {menuIcon && (
          <div className="MenuContainer__Items__Icon">
            <img src={menuIcon} alt="" />
          </div>
        )}
        {menuText && (
          <div className="MenuContainer__Items__Text">{menuText}</div>
        )}
      </div>
      <div className={`MenuContainer__List ${openProfile ? "Active" : ""}`}>
        {items.map((item) => {
          return (
            <div
              className="MenuContainer__List__Item"
              onClick={() => onAction(item)}
            >
              {item.name}
            </div>
          );
        })}
      </div>
    </div>
  );
}
