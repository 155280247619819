import React, { useEffect, useState } from 'react';
import styles from "../../Design.module.scss";
import { useCustomizer } from '../../../../../../Provider/customizerProvider';
import { FiBold, FiItalic, FiUnderline } from "react-icons/fi";

export default function Styles() {
    const { onChange, currentStyles } = useCustomizer();
    const [isBold, toggleBold] = useState(currentStyles['fontWeight'] === 'bold');
    const [isItalic, toggleItalic] = useState(currentStyles['fontStyle'] === 'italic');
    const [isUnderline, toggleUnderline] = useState(currentStyles['textDecoration'] === 'underline');

    useEffect(() => {
        onChange({ 'font-weight': isBold ? "bold" : "500" });
    }, [isBold]);

    useEffect(() => {
        onChange({ 'font-style': isItalic ? "italic" : "normal" });
    }, [isItalic]);

    useEffect(() => {
        onChange({ 'text-decoration': isUnderline ? "underline" : "none" });
    }, [isUnderline]);

    useEffect(() => {
        toggleBold(currentStyles['fontWeight'] === 'bold')
        toggleItalic(currentStyles['fontStyle'] === 'italic')
        toggleUnderline(currentStyles['textDecoration'] === 'underline')
    }, [currentStyles])

    return (
        <div className={styles.itemWrapper}>
            <div className="flex justify-between items-center relative">
                <h4 className={styles.textLabel}>Styles</h4>
                <div className="flex items-center gap-05rem">

                    <FiBold className={`${styles.Icon} ${isBold ? styles.activeIcon : ''}`} onClick={() => toggleBold(!isBold)} />
                    <FiItalic className={`${styles.Icon} ${isItalic ? styles.activeIcon : ''}`} onClick={() => toggleItalic(!isItalic)} />
                    <FiUnderline className={`${styles.Icon} ${isUnderline ? styles.activeIcon : ''}`} onClick={() => toggleUnderline(!isUnderline)} />
                </div>
            </div>
        </div>
    );
}
