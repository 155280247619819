import React from "react";
import { Button } from "../../../Components";
import heroImg from "../../../Assets/Images/home/hero.png";
import overlayImage from "../../../Assets/Images/home/overlay.png";
import "./Hero.scss";
import { useHistory } from "react-router-dom";

export default function Hero() {
  const features = [
    "Your Store Online in a few clicks with minimal documentation.",
    "Add Services and features as you grow your business.",
    "Billing to Complete Mobile Application with AikTech.",
  ];
  const history = useHistory();

  const navigate = (path) => {
    history.push(path);
  };
  return (
    <div className="Hero">
      <div className="Hero__Content">
        
        <div className="Hero__Content__Line"></div>
        <div className="Hero__Content__Heading">
          Your Retail store online in
          <span className="Hero__Content__Heading__Highlight"> seconds</span> !
        </div>

        <div className="Hero__Content__SubHeading">
          Your Business, Our code, Your App. Get yourself online with your own
          order management system, website and mobile application in just a few
          clicks.
        </div>
        <div className="Hero__Content__Features">
          {features.map((feature, index) => {
            return (
              <li className="Hero__Content__Features__Item" key={index}>
                {feature}
              </li>
            );
          })}
        </div>
        <div 
          className="flex flex-row gap-10"
          style={{
            marginTop : '1rem'
          }}
        >
          <button
            className="btn-active btn-md "
            onClick={() => navigate("/register")}
          >
            Get Started
          </button>
          <button
            className="btn-inactive btn-md"
            onClick={() => navigate("")}
          >
            Contact Us
          </button>
        </div>
      </div>
      <div className="Hero__Image">
        <img src={heroImg} />
      </div>
      <div className="Hero__OverlayImage">
        <img src={overlayImage} />
      </div>
    </div>
  );
}
