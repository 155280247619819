import React from 'react'
import { useDrag } from 'react-dnd'

const DraggableDiv = ({ children, data, className }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'ticket',
    item: { data },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const touchDevice = navigator.maxTouchPoints > 0;

  const createAndMoveCopy = (event) => {
    if (!touchDevice) return;

    const originalElement = event.currentTarget;
    const copiedElement = originalElement.cloneNode(true);

    copiedElement.style.position = 'fixed';
    copiedElement.style.zIndex = '1000';
    copiedElement.classList.add('copied-element');
    document.body.appendChild(copiedElement);

    const moveCopiedElement = (moveEvent) => {
      moveEvent.preventDefault(); // Prevent default behavior during dragging
      copiedElement.style.left = `${moveEvent.clientX - 20}px`;
      copiedElement.style.top = `${moveEvent.clientY - 20}px`;
    };

    const removeCopiedElement = () => {
      document.body.removeChild(copiedElement);
      document.removeEventListener('pointermove', moveCopiedElement);
      document.removeEventListener('pointerup', removeCopiedElement);
      window.removeEventListener('blur', removeCopiedElement);
    };

    document.addEventListener('pointermove', moveCopiedElement);
    document.addEventListener('pointerup', removeCopiedElement);
    window.addEventListener('blur', removeCopiedElement);

    originalElement.classList.add('original-active');

    const removeActiveState = () => {
      originalElement.classList.remove('original-active');
    };

    document.addEventListener('pointerup', removeActiveState);
    document.addEventListener('pointercancel', removeActiveState);
    window.addEventListener('blur', removeActiveState);
  };

  return (
    <div
      ref={drag}
      className={className}
      onPointerDown={touchDevice ? createAndMoveCopy : null} // Trigger only for touch devices
      style={{
        boxShadow: isDragging ? '2px 20px 30px black' : '',
      }}
    >
      {children}
    </div>
  );
};

export default DraggableDiv;
