import React, { useState } from 'react';
import { ComponentVariantsLists } from '../../../../Utils/template-variants';
import { motion } from 'framer-motion';
import './AddSection.scss';
import { useDispatch, useSelector } from 'react-redux';
import { actionsCreator } from '../../../../Redux/actions/actionsCreator';
import toast from 'react-hot-toast';
import { useCustomizer } from '../../../../Provider/customizerProvider';

const AddSection = () => {
    const componentLists = Object.entries(ComponentVariantsLists);
    const [selectedComponentType, setSelectedComponentType] = useState(null);
    // const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState("");
    const { handleAddComponent } = useCustomizer()
    // const allSections = config?.homepageConfig?.sections;
    // console.log(allSections);


   
    return (
        <>
            <div className={'optionBody'} style={{ overflow: "hidden" }}>
                <div className='search-container'>
                    <input
                        type='text'
                        placeholder='Search any Components...'
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className='list-container'>
                    {componentLists?.filter(([component]) => component?.toLowerCase()?.includes(searchQuery?.toLowerCase()))
                        ?.map?.(([component, variants], idx) => {
                            return (
                                <div key={idx} className={'component'} onClick={() => {
                                    setSelectedComponentType(component);
                                }}>
                                    <span>{component.toUpperCase()}</span>
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" height={20}
                                onClick={() => handleAddComponent(component)}
                            >
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg> */}
                                </div>
                            )
                        })}
                </div>
            </div>
            {selectedComponentType &&
                <motion.div
                    className={'variantsContainer'}
                    initial={{ x: '-20px' }}
                    animate={{ x: 0 }}
                    transition={{ type: 'spring', stiffness: 100, damping: 20 }}
                >
                    <div className={'variantsHeader'}>
                        <span>{`Choose any one from the available variants:-`}</span>
                    </div>
                    <div className={'variantsBody'}>
                        {ComponentVariantsLists?.[selectedComponentType]?.variants?.map((vari, idx) => (
                            <div
                                className="variant"
                                key={idx}
                                onClick={() => handleAddComponent(selectedComponentType, vari)}
                            >
                                <span>{vari.type}</span>
                                <img src={vari.previewImg} alt='preview' className='previewImg' />
                            </div>
                        ))}
                    </div>
                </motion.div>
            }
        </>
    );
};

export default AddSection;
