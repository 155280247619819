import React, { useEffect } from "react";
import { SideModal } from "../../../Components";
import "./ManageOrder.scss";
import { useHistory, useLocation } from "react-router-dom";

export default function ManageOrder() {
  const location = useLocation();
  const { state: orderData = {} } = location || {};

  const history = useHistory();
  const orderAction = orderData ? orderData.action : "";
  const title = orderAction === "accept" ? "Accept Order" : "Reject Order";

  const onSave = () => {
    history.goBack();
  };

  return (
    <SideModal
      title={title}
      className={"Center"}
      onSave={onSave}
      saveButtonText={"Yes"}
    >
      <div className="ManageOrder__Text">
        {`Are you sure, you want to ${orderAction} the order?`}
      </div>
    </SideModal>
  );
}
