import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { actionsCreator } from "../../Redux/actions/actionsCreator";
import Auth from "../Auth/Auth";
import "./Navbar.scss";

import logoImg from "../../Assets/Images/navbar/new_logo.svg";
import searchIcon from "../../Assets/Images/navbar/search-icon.svg";
// import logoImg from "../../Assets/Images/navbar/blue_logo.png";
// import searchIcon from "../../Assets/Images/navbar/search.svg";
import everythingIcon from "../../Assets/Images/navbar/blue-icon.svg";
import favouriteIcon from "../../Assets/Images/navbar/favourites.svg";
import loginIcon from "../../Assets/Images/navbar/login.svg";

import cartIcon from "../../Assets/Icons/cart-icon.svg";
import userIcon from "../../Assets/Icons/user-icon.svg";

import basketIcon from "../../Assets/Images/navbar/basket.svg";
import walletIcon from "../../Assets/Images/navbar/wallet.png";

import arrowDown from "../../Assets/Images/navbar/arrow-down.svg";

import { toast } from "react-toastify";
// import useOutsideAlerter from '../../Hooks/useOutsideAlerter'
import { debounce, formatName } from "../../Utils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { cartAPI, authAPI } from "../../Api";

import Modal from "../Modal2/Modal.js";
import { LogoMaker } from "../../../../../../Containers/Register/RegistrationForm/RegistrationForm";

const mapStateToProps = ({ cart, auth, payment }) => ({
  cart,
  auth,
  payment,
});
const Navbar = ({data}) => {
  const { cart, auth, payment } = useSelector(mapStateToProps);
  const { showLoginPopup = false, isLoggedIn, userDetails = {} } = auth;

  // useEffect(() => {
  //   if (showLoginPopup === true) {
  //     document.querySelector("body").style.overflow = "hidden";
  //   } else {
  //     document.querySelector("body").style.overflow = "auto";
  //   }
  // }, [showLoginPopup]);

  // const { walletBalance = 0, isWallet = false } = payment;
  const [searchterm, setsearchterm] = useState(""); //text within searchbox
  const [showsearch, setshowsearch] = useState("");
  const [walletData, setWalletData] = useState(null);
  const [openProfile, setOpenProfile] = useState(false);
  const dispatch = useDispatch();
  const [company, setCompany] = useState([]); //company Details

  // const debounce = (fn, delay) => {   //debounce for text search
  //     let timeoutID;
  //     return function (...args) {

  //         if (timeoutID) {
  //             clearTimeout(timeoutID)
  //         }
  //         timeoutID = setTimeout(() => {
  //             fn(...args)
  //         }, delay)
  //     }
  // }

  // useEffect(() => {
  //   props.search(searchterm);
  // }, [searchterm]);

  const logOut = () => {
    dispatch(actionsCreator.LOGOUT_USER());
    toast.error("User is logged out successfully");
    setOpenProfile(false);
  };

  const walletFetcher = async () => {
    try {
      let res = await cartAPI.getWalletData();
      dispatch(
        actionsCreator.UPDATE_WALLET({
          walletBalance: res.data.data.balance,
          isWallet: true,
        })
      );
    } catch (error) {
      dispatch(actionsCreator.UPDATE_WALLET({ isWallet: false }));
      console.log(error);
    }
  };

  // const companyFetcher = async () => {
  //   try {
  //     let res = await authAPI.company();
  //     setCompany(res.data);
  //   } catch (error) {
  //     console.log("ERROR in Company API", error)
  //   }
  // };

  useEffect(() => {
    if (isLoggedIn) walletFetcher();
  }, [isLoggedIn]);

  // useEffect(() => {
  //   companyFetcher();
  // }, [])

  const { name = "", email = "" } = userDetails || {};
  const formattedName = formatName(name);
  const { final_item = 0, final_price = 0 } = cart || {};

  function useOutsideAlerter(ref, key, loginRef) {
    useEffect(() => {
      function handleClickOutside(event) {
        // if (ref.current && !ref.current.contains(event.target)) {
        //   if (
        //     key === "login" &&
        //     loginRef.current &&
        //     !loginRef.current.contains(event.target)
        //   )
        //     props.removeLoginPopup();
        //   else setOpenProfile(false);
        // }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const loginStateSetter = () => {
    dispatch(actionsCreator.SHOW_LOGIN());
  };

  const clickOutsideProfile = useRef();
  const clickOutsideLogin = useRef();
  const loginMenuBtn = useRef();

  useOutsideAlerter(clickOutsideProfile, "profile");
  useOutsideAlerter(clickOutsideLogin, "login", loginMenuBtn);

  const [showHamburgerNav, setShowHamburgerNav] = useState(false);
  const handleModalDisplay = () => {
    setShowHamburgerNav((prev) => !prev);
  };

  useEffect(() => {
    setShowLoginModal(showLoginPopup);
  }, [showLoginPopup]);

  const [showloginModal, setShowLoginModal] = useState(showLoginPopup);

  const removeLoginPopup = () => {
    dispatch(actionsCreator.SET_LOGIN({ showLoginPopup: false }));
    setShowLoginModal(false);
    // setsearchResults([]);
  };

  return (
    <nav className="w-full">
      {/* <div className='top-container'>
                <ul>
                    <li><a href='/'>About Us</a></li>
                    <p>|</p>
                    <li><a href='/'>My Account</a></li>
                    <p>|</p>
                    <li><a href='/'>Contact</a></li>
                </ul>
                <ul>
                    <li><a href="/">Hours:Open 24 hours
                    </a></li>
                    <li><a rel="noreferrer" target="_blank" href='https://www.facebook.com/'><img className='social' src={fbIcon} alt="" /></a></li>
                    <li><a rel="noreferrer" target="_blank" href='https://www.instagram.com/'><img className='social' src={instaIcon} alt="" /></a></li>
                    <li><a rel="noreferrer" target="_blank" href='https://www.linkedin.com/'><img className='social' src={linkedinIcon} alt="" /></a></li>
                </ul>

            </div> */}
      {showHamburgerNav && (
        <Modal closeModal={handleModalDisplay} backgroundColor={"#FBEDCD"}>
          <ul className="">
            <li>
              <Link >
                <span>Home</span>
              </Link>
            </li>
            {isLoggedIn && (
              <li >
                <Link >
                  <span>Recent Orders</span>
                </Link>
              </li>
            )}

            <li >
              <a
                rel="noreferrer"
                // target="_blank"
                // href="/privacy-policy#ContactUs"
              >
                Contact Us
              </a>
            </li>

            {true && (
              <li>
                {/* <Link to=""> */}
                  <span>Login/Signup</span>
                {/* </Link> */}
              </li>
            )}

            {/* {isLoggedIn && (
              <li
                onClick={() => {
                  handleModalDisplay();
                  logOut();
                }}
              >
                <Link to="/">
                  <span>Log Out</span>
                </Link>
              </li>
            )} */}
          </ul>
        </Modal>
      )}

      <div className="flex flex-row justify-between items-center p-2">
        <div className="logo-hamburger">
          <LogoMaker
            title={data.title}
          />

          <span className="logo-text">{data.title}</span>

          {/* <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1.5rem",
            }}
          > */}
            {/* {true && (
              <Link>
                <div className="navbar-items hamburger-mobile">
                  <div className="basket">
                    {final_item ? (
                      <span className="cart_count">{final_item}</span>
                    ) : null}
                    <img src={cartIcon} alt="" />
                  </div>
                </div>
              </Link>
            )} */}

            {/* {isLoggedIn && (
              <button
                onClick={() => setOpenProfile(!openProfile)}
                className="navbar-items hamburger-mobile"
              >
                <img src={userIcon} alt="" />
              </button>
            )} */}

            <button
              className="flex-md-only flex-col gap-10"
              style={{ justifySelf: "flex-end" }}
              // onClick={() => {
              //   setShowHamburgerNav((prev) => !prev);
              // }}
            >
              <span
                style={{
                  width: "2rem",
                  height: "2px",
                  backgroundColor: "#4a1d1e",
                }}
              ></span>
              <span
                style={{
                  width: "2rem",
                  height: "2px",
                  backgroundColor: "#4a1d1e",
                }}
              ></span>
              <span
                style={{
                  width: "2rem",
                  height: "2px",
                  backgroundColor: "#4a1d1e",
                }}
              ></span>
            </button>
          {/* </div> */}
        </div>

        {/* <div className='location '>
                    <img src={locationIcon} alt="" />
                    <h3>{props.location}</h3>
                </div> */}

        {/* {true && (
          <input
            type="text"
            placeholder="Search For Products...."
            className={"navbar-search " + showsearch}
            style={{ backgroundImage: `url(${searchIcon})` }}
            onChange={debounce((event) => {
              props.search(event.target.value);
            }, 400)}
          />
        )} */}

        <div className={`item-wrap`}>
          {/* <div className='navbar-items search-icon'
                        onClick={() => { setshowsearch(' .show-search-bar') }}
                    >
                        <img src="/v1/phurti-images/navbar/search.svg" alt="" />
                    </div> */}

          <div className="navbar-link-items gap-10 ul-style-none">
            <div>
              <div className="">
                {/* <img src={everythingIcon} alt="" className="icon-highlight" /> */}
                <span className="category-highlight">Home</span>
              </div>
            </div>

            {true && (
              <div className="">
                <span>
                  <span>Recent Orders</span>
                </span>
              </div>
            )}

              <span
                rel="noreferrer"
                // target="_blank"
                // href="/privacy-policy#ContactUs"
              >
                Contact Us
              </span>

            { true && (
              <li >
                <span>
                  <span>Login/Signup</span>
                </span>
              </li>
            )}

            <div/>

            {/* {isLoggedIn && (
              <div onClick={logOut}>
                <Link to="/">
                  <span>Log Out</span>
                </Link>
              </div>
            )} */}
          </div>

          <div className="">
            <div className="navbar-search-bakery">
              <input
                type="text"
                placeholder="Search"
                className={"" + showsearch}
                // style={{ backgroundImage: `url(${searchIcon})` }}
                // onChange={debounce((event) => {
                //   props.search(event.target.value);
                // }, 400)}
              />
              <button className="">
                <img src={searchIcon} />
              </button>
            </div>

            {/* CheckOut functionality */}
            {/* {isLoggedIn && (
              <Link>
                <div className="navbar-items shopping-cart">
                  <div className="basket">
                    {final_item ? (
                      <span className="cart_count">{final_item}</span>
                    ) : null}
                    <img src={cartIcon} alt="" />
                  </div>
                </div>
              </Link>
            )} */}

            <button
              className="hamburger-tablet hamburger"
              // onClick={() => {
              //   setShowHamburgerNav((prev) => !prev);
              // }}
            >
              <span
                style={{
                  width: "2rem",
                  height: "2px",
                  backgroundColor: "#4a1d1e",
                }}
              ></span>
              <span
                style={{
                  width: "2rem",
                  height: "2px",
                  backgroundColor: "#4a1d1e",
                }}
              ></span>
              <span
                style={{
                  width: "2rem",
                  height: "2px",
                  backgroundColor: "#4a1d1e",
                }}
              ></span>
            </button>
          </div>

          {/* Login Popup */}
          {showloginModal ? (
            <Modal closeModal={removeLoginPopup}>
              <div className="login-modal">
                <Auth />
              </div>
            </Modal>
          ) : (
            false
          )}

          {/* <div className='navbar-items shopping-cart-mini' >
                        <img src="/v1/phurti-images/navbar/basket-mini.svg" alt="aa" className='show-in-mobile' />
                    </div>
                    <div className='navbar-items shopping-cart-mini' >
                        <img src="/v1/phurti-images/navbar/basket-mini.svg" alt="aa" className='show-in-mobile' />
          </div> */}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

Navbar.defaultProps = {
  location: "Whitefield",
  total_cost: 0,
};
