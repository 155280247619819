import React from "react";
import "./FeatureCard.scss";

export default function FeatureCard({ feature }) {
  const {
    title = "",
    description = "",
    image = "",
    className = "",
  } = feature || {};
  return (
    <div className={`FeatureCard ${className}`}>
      <div className="FeatureCard__Image">
        <img src={image} />
      </div>
      <div className="FeatureCard__Title">{title}</div>
      <div className="FeatureCard__Description">{description}</div>
      <div className="FeatureCard__ViewMore">View More</div>
    </div>
  );
}
