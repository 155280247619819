import React from "react";
import "./ImageSlide.scss";

export default function ImageSlide({
  imageURL,
  title,
  description,
  className,
}) {
  return (
    <div className={`ImageSlide ${className}`}>
      {title && <div className="ImageSlide__Title">{title}</div>}
      <div
        style={{ backgroundImage: `url(${imageURL})` }}
        className="ImageSlide__Image"
      ></div>
      {description && (
        <div className="ImageSlide__Description text-white">{description}</div>
      )}
    </div>
  );
}
