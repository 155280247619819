import React, { Suspense } from 'react'
import Loader from '../../Containers/Crm/Components/Loader'

const LazyLoad = ({children}) => {
  return (
    <Suspense fallback={
        <div className='h-full w-full flex items-center justify-center'>
            <Loader/>
        </div>
    }>
        {
            children
        }
    </Suspense>
  )
}

export default LazyLoad