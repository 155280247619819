import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { actionsCreator } from "../../Redux/actions/actionsCreator";
import "./Navbar.scss";

import logoImg from "../../Assets/Icons/new_logo.svg";
import { toast } from "react-toastify";
import { debounce, formatName } from "../../Utils";
import dashboardIcon from "../../Assets/Icons/dashboard.png";
import profileIcon from "../../Assets/Icons/profile.svg";
import Button from "../Button";
import Menu from "../Menu";

const mapStateToProps = ({ auth = {} }) => ({
  auth,
});

const Navbar = () => {
  const { auth = {} } = useSelector(mapStateToProps);
  const { isLoggedIn = false, user = {} } = auth;
  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    dispatch(actionsCreator.LOGOUT_USER());
    toast.error("User is logged out successfully");
    history.push("/login");
  };

  const navigate = (path) => {
    history.push(path);
  };
  const { name = "" } = user || {};
  const formattedName = formatName(name);

  const menuItems = [
    {
      name: "Profile",
      action: () => {},
    },
    {
      name: "Logout",
      action: logout,
    },
  ];
  const menuText = `Hey ${formattedName}!`;
  return (
    <nav className="Navbar">
      <div className="Navbar__Container">
        <div className="Navbar__Container__Logo" onClick={() => navigate("")}>
          <img src={logoImg} alt="Logo" />
        </div>
        {!isLoggedIn ? (
          <div className="Navbar__Container__Actions">
            <div className="Navbar__Container__Actions__Register">
              <Button
                text="Get Started"
                onClick={() => navigate("/register")}
                className="Black__Button"
              />
            </div>
            <div className="Navbar__Container__Actions__Login">
              <Button
                text="Login"
                onClick={() => navigate("/login")}
                className="Black__Button"
              />
            </div>
          </div>
        ) : (
          <div className="Navbar__Container__DashboardActions">
            <div
              className="Navbar__Container__DashboardActions__Icon"
              onClick={() => navigate("/dashboard")}
            >
              <img src={dashboardIcon} />
            </div>
            <div className="Navbar__Container__DashboardActions__Profile">
              <Menu
                items={menuItems}
                menuIcon={profileIcon}
                menuText={menuText}
              />
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;

Navbar.defaultProps = {};
