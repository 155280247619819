import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//Components
import { Button, LabelledInput, DragAndDrop } from "./../../../../Components";
//Prop types
import PropTypes from "prop-types";
//Utils
import { validate } from "../../../../Utils";

//Style
// import "./BrandSelection.scss";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreator } from "../../../../Redux/actions/actionsCreator";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { checkAdmin } from "../../../../Api/authAPI";
import { getToken } from "../../../../Utils";

const mapStateToProps = ({ tenantForm = {} }) => ({
  tenantForm,
});

export default function BrandSelection({
  onChange,
  formData,
  onSubmit,
  showBackBtn,
  onBack,
}) {
  const {
    tenantForm: { errors = {} },
  } = useSelector(mapStateToProps);
  const dispatch = useDispatch();
  const isLoggedIn = getToken();
  const history = useHistory()
  useEffect(()=>{
    if(isLoggedIn){
      const response =  checkAdmin();
            response.then((res)=>{
              history.push("/cms")
            }).catch((err)=>{
                
               })
    }

  },[])

  const {
    title = "",
    logo = {},
    menuFile = {},
    subdomain = "",
    description = "",
    address = "",
  } = formData || {};

  const validateInput = (value, rules, key) => {
    const { error = false, msg = "" } = validate(value, rules);
    const errorObj = { [key]: { error, msg } };
    dispatch(actionsCreator.SET_TENANT_ERRORS(errorObj));
  };

  useEffect(() => {
    dispatch(actionsCreator.RESET_TENANT_ERRORS());
  }, []);

  const isDisable = !(
    title &&
    logo &&
    address &&
    subdomain &&
    description &&
    menuFile &&
    !errors["title"].error &&
    !errors["subdomain"].error &&
    !errors["description"].error &&
    !errors["address"].error &&
    !errors["logo"].error &&
    !errors["menuFile"].error
  );

  return (
    <div className="BrandSelection flex-column gap-10">
      {/* <div className="BrandSelection__Inputs"> */}
        <LabelledInput
          label="Title"
          placeholder="Enter Brand name here..."
          value={title}
          type="text"
          onChange={(e) => {
            onChange(e, "title");
            validateInput(e.target.value, ["isRequired"], "title");
          }}
          error={errors["title"]}
        />
        <LabelledInput
          label="Subdomain"
          placeholder="Enter Subdomain here..."
          value={subdomain}
          type="text"
          onChange={(e) => {
            onChange(e, "subdomain");
            validateInput(e.target.value, ["isRequired"], "subdomain");
          }}
          error={errors["subdomain"]}
        />
      {/* </div> */}
      <div className="BrandSelection__Inputs">
        <LabelledInput
          label="Description"
          placeholder="Enter Description here..."
          value={description}
          type="text"
          onChange={(e) => {
            onChange(e, "description");
            validateInput(e.target.value, ["isRequired"], "description");
          }}
          error={errors["description"]}
          textarea={true}
        />
        <LabelledInput
          label="Address"
          placeholder="Enter Address here..."
          value={address}
          type="text"
          onChange={(e) => {
            onChange(e, "address");
            validateInput(e.target.value, ["isRequired"], "address");
          }}
          error={errors["address"]}
          textarea={true}
        />
      </div>
      <DragAndDrop

        label="Logo"
        value={logo}
        onChange={(e) => {
          onChange(e, "logo", true);
          validateInput(logo, ["isRequired"], "logo");
        }}
        error={errors["logo"]}
      />
      <DragAndDrop
        label="Document"
        value={menuFile}
        onChange={(e) => {
          onChange(e, "menuFile", true);
          validateInput(menuFile, ["isRequired"], "menuFile");
        }}
        error={errors["menuFile"]}
      />
      <Button text="Submit" onClick={onSubmit} isDisable={isDisable} />
      {showBackBtn && (
        <div className="RegistrationForm__Back" onClick={onBack}>
          Back
        </div>
      )}
    </div>
  );
}

BrandSelection.propTypes = {
  onChange: PropTypes.func,
  formData: PropTypes.object,
  onSubmit: PropTypes.func,
  showBackBtn: PropTypes.bool,
  onBack: PropTypes.func,
};
BrandSelection.defaultProps = {
  onChange: () => {},
  formData: {},
  onSubmit: () => {},
  showBackBtn: false,
  onBack: () => {},
};
