import configureStore from './Redux/store/configureStore';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import './App.scss'
import 'react-toastify/dist/ReactToastify.css';
import Routes from './Routes';
import { ScrollToTop } from './Components';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend';
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

export const { store } = configureStore();

function App() {
  const touchDevice = navigator.maxTouchPoints > 0;

  return (
    <div className="AppRoot">
      <DndProvider 
        backend={ HTML5Backend }
      >
        <Provider store={store}>
          <Routes />
        </Provider>
      </DndProvider>
      <ToastContainer
        className="toast-container"
        autoClose={2000}
        closeOnClick
        hideProgressBar={true}
        newestOnTop={true}
        pauseOnHover
        position="top-right"
        type="default"
        theme="colored"
        limit={1}
        style={{
          zIndex : 10000000,
        }}
      />
    </div>
  );
} 

export default App;
