import React from "react";
import { MdCancel, MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";

export default function StockTable({ cartData, stockdropdownList, inventoryname, setEditIndex, setEditModal, setValues, deleteItem }) {
    return (
        <div
            className="table-stock"
            style={{
                overflow:'auto',
                flexGrow:'1',
                height:'100%',
                marginTop:"0rem",
                marginBottom:'1rem',
                display:"flex",
                flexDirection:'column'
            }}
        >
            <div className="table-head" style={{zIndex:'1'}}>
                <div>
                    <span>Image</span>
                </div>
                <div>
                    <span>Product Remaining</span>
                </div>
                <div>
                    <span>Barcode</span>
                </div>
                <div style={{minWidth:"120px"}}>
                    <span>Product</span>
                </div>
                <div>
                    <span>Unit</span>
                </div>
                <div style={{minWidth:"100px"}}>
                    <span>Inventory</span>
                </div>
                <div>
                    <span>Price</span>
                </div>
                <div>
                    <span>Quantity</span>
                </div>
                <div>
                    <span>Expiry</span>
                </div>
                <div>
                    <span>Batch No.</span>
                </div>
                <div>
                    <span>Serial No.</span>
                </div>
                <div style={{minWidth:"100px"}}>
                    <span>Catalogue No.</span>
                </div>
                <div>
                    <span>Net Rate</span>
                </div>
                <div>
                    <span>PTD</span>
                </div>
                <div>
                    <span>MFD</span>
                </div>
                <div>
                    <span>HSN Code</span>
                </div>
                <div>
                    <span>Discount</span>
                </div>
                <div>
                    <span>GST</span>
                </div>
                <div>
                    <span>Sale Rate</span>
                </div>
                <div>
                    <span>Delete</span>
                </div>
                <div>
                    <span>Edit</span>
                </div>

            </div>
            <div className="table-body" style={{gap:'10px', display:'flex', flexDirection:'column', flexGrow:'1', height:'100%'}}>
                {
                    cartData && cartData?.map((product, index) => {
                        const {
                            product_image,
                            stock_quantity,
                            temp_stock_product,
                            added_by,
                            stock_unit,
                            inventory,
                            procurement_price_per_product,
                            description,
                            barcode,
                            product_remaining,
                            product_address,
                            batch_number,
                            expiry,
                            serial_number,
                            purchase_trade_rate,
                            net_rate,
                            catalogue_number,
                            manufacturer_date,
                            discount,
                            gst,
                            mrp,
                            hsnCode,
                            saleRate,
                        } = product;
                        return (
                            <div className="table-body-row">
                                <div>
                                    <img
                                        src={product_image}
                                        style={{
                                            width: '3rem',
                                            aspectRatio: '1',
                                            objectFit: 'contain'
                                        }}
                                    />
                                </div>
                                <div>
                                    <span>{product_remaining}</span>
                                </div>
                                <div>
                                    <span>{barcode}</span>
                                </div>
                                <div style={{minWidth:"120px"}}>
                                    <span>{temp_stock_product} ({description})</span>
                                </div>
                                <div>
                                    <span>{stockdropdownList &&
                                        stockdropdownList.stock_unit?.map((stockunit) => {
                                            return stockunit.id === parseInt(stock_unit)
                                                ? stockunit.name
                                                : null;
                                        })}</span>
                                </div>
                                <div style={{minWidth:"100px"}}>
                                    <span>{inventoryname}</span>
                                </div>
                                <div>
                                    <span>{mrp}</span>
                                </div>

                                <div>
                                    <span>{stock_quantity}</span>
                                </div>
                                <div>
                                    <span>{expiry}</span>
                                </div>
                                <div>
                                    <span>{batch_number || ""}</span>
                                </div>
                                <div>
                                    <span>{serial_number}</span>
                                </div>
                                <div style={{minWidth:"100px"}}>
                                    <span>{catalogue_number}</span>
                                </div>
                                <div>
                                    <span>{((purchase_trade_rate-discount) + (gst/100*purchase_trade_rate)).toFixed(2)}</span>
                                </div>
                                <div>
                                    <span>{purchase_trade_rate}</span>
                                </div>
                                <div>
                                    <span>{manufacturer_date}</span>
                                </div>
                                <div>
                                    <span>{hsnCode}</span>
                                </div>
                                <div>
                                    <span>{discount}</span>
                                </div>
                                <div>
                                    <span>{Number(gst).toFixed(2)}</span>
                                </div>
                                <div>
                                    <span>{saleRate}</span>
                                </div>
                                <div>
                                    <button
                                        onClick={() => deleteItem(index)}
                                        className="btn-none flex-row place-item-center"
                                    >
                                        <MdDelete
                                            fontSize={"1.2rem"}
                                            width={"2rem"}
                                            color={"red"}
                                        />
                                    </button>
                                </div>
                                <div>
                                    <button
                                        type="button"
                                        onClick={() => {
                                            setEditModal(true);
                                            setEditIndex(index);
                                            setValues(index);
                                        }}
                                        className={"btn-none flex-row place-item-center"}
                                    >
                                        <FaEdit fontSize={"1.2rem"} width={"2rem"} />
                                    </button>
                                </div>

                            </div>
                        )
                    })}
            </div>
        </div>
    )
}