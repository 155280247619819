import React from "react";
import taskImage from "../../../Assets/Images/home/task-management.png";
import automationImage from "../../../Assets/Images/home/automation.png";
import toolImage from "../../../Assets/Images/home/business-tool.png";
import overlayImage from "../../../Assets/Images/home/overlay.png";
import "./Features.scss";
import FeatureCard from "./FeatureCard";

export default function Features() {
  const features = [
    {
      title: "Task Management",
      description:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
      image: taskImage,
    },
    {
      title: "Automation",
      description:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
      image: automationImage,
      className: "Automation",
    },
    {
      title: "Budgeting Tools",
      description:
        "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.",
      image: toolImage,
    },
  ];
  return (
    <div className="Features PagePadding">
      <div className="Features__Content">
        <div className="Features__Content__Heading Heading">
          Software Solutions That Fit Your Needs
        </div>
        <div className="Features__Content__SubHeading">
          The features to boost your productivity
        </div>
        <div className="Features__Content__List">
          {features.map((feature) => {
            return (
              <div className="Features__Content__List__Card">
                <FeatureCard feature={feature} />
              </div>
            );
          })}
          <div className="Features__Content__List__Overlay">
            <img src={overlayImage} />
          </div>
        </div>
      </div>
    </div>
  );
}
