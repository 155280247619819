import React from "react";
import "../Table.scss";

export default function ViewImage({ value }) {
  return (
    <div className="ViewImage">
      <img src={value} alt="" />
    </div>
  );
}
