import React, { useEffect } from "react";
import { LabelledInput, LabelledSelect, SideModal } from "../../../Components";
import "./ManageSellableInventory.scss";
import get from "lodash/get";
import { useState } from "react";
import { actionsCreator } from "../../../Redux/actions/actionsCreator";
import { validate } from "../../../Utils/validations-utils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { formatOptionsData } from "../../../Utils";

const mapStateToProps = ({ inventories = {}, products = {} }) => ({
  inventories,
  products,
});

export default function ManageSellableInventory() {
  const {
    inventories: { list: inventoryList = [] },
    products: { list: productList = [] },
  } = useSelector(mapStateToProps);
  const [data, setData] = useState({
    inventory: "",
    product: "",
    quantity_remaining: "",
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { state: sellableInventoryData = {} } = location || {};
  const { inventory = "", product = "", quantity_remaining = "" } = data || {};

  const sellableInventoryId = sellableInventoryData
    ? sellableInventoryData.id
    : "";
  const title = sellableInventoryId
    ? "Update Sellable Inventory"
    : "Add Sellable Inventory";

  useEffect(() => {
    fetchInventories();
    fetchProducts();
  }, []);

  const fetchInventories = () => {
    dispatch(actionsCreator.FETCH_INVENTORIES());
  };

  const fetchProducts = () => {
    dispatch(actionsCreator.FETCH_PRODUCTS());
  };

  const addInventory = async () => {
    const payload = { ...data };
    dispatch(actionsCreator.ADD_SELLABLE_INVENTORY(payload));
  };

  const updateInventory = async () => {
    const payload = { ...data, ...{ id: sellableInventoryId } };
    dispatch(actionsCreator.UPDATE_SELLABLE_INVENTORY(payload));
  };

  const onChange = (e, key, nonEvent) => {
    const value = nonEvent ? e : get(e, "target.value", "");
    const updatedData = { ...data, [key]: value };
    setData(updatedData);
  };

  const onSave = () => {
    if (sellableInventoryId) {
      updateInventory();
    } else {
      addInventory();
    }
    history.goBack();
  };

  const validateInput = (value, rules, key) => {
    const { error = false, msg = "" } = validate(value, rules);
    const errorObj = { ...errors, [key]: { error, msg } };
    setErrors(errorObj);
  };

  useEffect(() => {
    fillForm(sellableInventoryData);
  }, [sellableInventoryData]);

  const fillForm = (sellableInventoryData) => {
    if (!isEmpty(sellableInventoryData)) {
      const formData = {};
      const idKeys = ["inventory", "product"];
      Object.keys(data).forEach((key) => {
        formData[key] = idKeys.includes(key)
          ? get(sellableInventoryData, `${key}.id`, sellableInventoryData[key])
          : sellableInventoryData[key];
      });
      setData(formData);
    }
  };

  const inventories = formatOptionsData(inventoryList, "name", "id");
  const products = formatOptionsData(productList, "product_name", "id");

  return (
    <SideModal title={title} onSave={onSave}>
      <div>
        <LabelledSelect
          className={"SmallInput"}
          label={"Inventory"}
          data={inventories}
          onSelect={(e) => {
            onChange(e, "inventory", true);
            validateInput(e, ["isRequired"], "inventory");
          }}
          placeholder={"Select Inventory"}
          values={inventory}
          error={errors["inventory"]}
        />
        <LabelledSelect
          className={"SmallInput"}
          label={"Product"}
          data={products}
          onSelect={(e) => {
            onChange(e, "product", true);
            validateInput(e, ["isRequired"], "product");
          }}
          placeholder={"Select Product"}
          values={product}
          error={errors["product"]}
        />
        <LabelledInput
          className={"SmallInput"}
          label="Quantity Remaining"
          placeholder="Enter Quantity here..."
          type="number"
          value={quantity_remaining}
          onChange={(e) => {
            onChange(e, "quantity_remaining");
            validateInput(e.target.value, ["isRequired"], "quantity_remaining");
          }}
          error={errors["quantity_remaining"]}
        />
      </div>
    </SideModal>
  );
}
