import React, { useEffect, useState } from 'react';
import styles from "../../Design.module.scss";
import { useCustomizer } from '../../../../../../Provider/customizerProvider';

import { AiOutlineDash, AiOutlineSmallDash } from "react-icons/ai";
import { TfiLineDouble } from "react-icons/tfi";
import { RxDividerVertical } from "react-icons/rx";
import { BsDash } from 'react-icons/bs';

export default function Type() {
    const { onChange, currentStyles } = useCustomizer();
    const [borderType, setBorderType] = useState(currentStyles['borderStyle']);

    useEffect(() => {
        onChange({ "border-style": borderType });
    }, [borderType]);
    useEffect(() => { setBorderType(currentStyles['borderStyle']) }, [currentStyles])

    return (
        <div className={styles.itemWrapper}>
            <div className="flex justify-between items-center relative">
                <h4 className={styles.textLabel}>Type</h4>
                <div className="flex items-center gap-05rem">
                    <BsDash
                        title="None"
                        onClick={() => setBorderType('hidden')}
                        className={`${styles.Icon} ${borderType === 'hidden' ? styles.activeIcon : ""}`}
                    />
                    <RxDividerVertical
                        title="Solid"
                        onClick={() => setBorderType('solid')}
                        className={`${styles.Icon} ${borderType === 'solid' ? styles.activeIcon : ""}`}
                    />
                    <AiOutlineDash
                        title="Dashed"
                        onClick={() => setBorderType('dashed')}
                        className={`${styles.Icon} ${borderType === 'dashed' ? styles.activeIcon : ""}`}
                    />
                    <TfiLineDouble
                        title="Double"
                        onClick={() => setBorderType('double')}
                        className={`${styles.Icon} ${borderType === 'double' ? styles.activeIcon : ""}`}
                    />
                    <AiOutlineSmallDash
                        title="Dotted"
                        onClick={() => setBorderType('dotted')}
                        className={`${styles.Icon} ${borderType === 'dotted' ? styles.activeIcon : ""}`}
                    />
                </div>
            </div>
        </div>
    );
}
