import React from 'react'
import Wrapper from '../Wrapper'
import Color from './Color'
import Opacity from './Opacity'
import Width from './Width'
import Type from './Type'
import Radius from './Radius'
export default function Border() {
    return (
        <Wrapper title={"Border"}>
            <Color />
            <Opacity />
            <Width />
            <Type />
            <Radius />
        </Wrapper >

    )
}
