import Wrapper from "../Wrapper"

import React from 'react'
import Translate from "./Translate"
import Alignment from "./Alignment"

import Scale from "./Scale"
export default function Layout() {
    return (
        <Wrapper title="Layout">
            <Alignment />
            {/* <Scale /> */}
            <Translate />

        </Wrapper >
    )
}
