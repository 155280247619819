import React from "react";
import "./Button.scss";

import PropTypes from "prop-types";

export default function Button({ text, onClick, isDisable, className, style }) {
  return (
    <button
      className={`Button ${className}`}
      onClick={onClick}
      disabled={isDisable}
      style={style}
    >
      {text}
    </button>
  );
}
Button.propTypes = {
  text: PropTypes.string,
  onclick: PropTypes.func,
  isDisable: PropTypes.bool,
  className: PropTypes.string,
};

Button.defaultProps = {
  text: "",
  onclick: () => {},
  isDisable: false,
  className: "",
};
