import axios from "axios";
import errorHandler from "../../Lib/errorHandler";

// Function to create a request based on the current URL

export function extractTenantId(path) {
  const segments = path.split('/').filter(Boolean);
  let tenantId = null;
  if(segments[0] === "builder"){
    tenantId = new URLSearchParams(window.location.search).get('tenant');
  }else{
    tenantId = segments[1];
  }
  return tenantId;
}
const requestMakerWithCurrentUrl = (
  baseQuery,
  method,
  requestData,
  additionalHeaders = {}
) => {
  let pathName = window.location.pathname;
  const tenantId = extractTenantId(pathName);
  let en_t = "";
  try {
    const auth = JSON.parse(localStorage.getItem('auth')) || {};
    en_t = localStorage && auth?.[tenantId]?.token;
  } catch (error) {
    console.warn("No auth token found: ", error);
  }

  if (!navigator.onLine) {
    throw new Error("No internet connection");
  }

  const headers = {
    ...additionalHeaders,
    // 'Content-type': 'application/json',
  };

  let baseUrl = window.location.origin;
  if (origin === "http://localhost:3000") {
    baseUrl = "https://aiktech.in";
  }
  let fullUrl = "";
  if (baseUrl === "https://aiktech.in") {
    fullUrl = `${baseUrl}/app/${tenantId}/api${baseQuery}`;
  } else {
    fullUrl = `${baseUrl}/api/${baseQuery}`;
  }
  const { params, payload } = requestData;
  // Set Authorization header if token exists
  if (en_t) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${en_t}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
  // Create the axios request
  let apiCall = axios({
    method: method,
    url: fullUrl,
    params: params,
    data: payload,
    headers: headers,
  });
  // Handle the response and errors
  return apiCall
    .then((response) => {
      return response;
    })
    .catch((error) => {
      const pe = errorHandler(error);
      console.error("Error in requestMakerWithCurrentUrl :: ", error, pe);
      throw error;
    });
};

export default requestMakerWithCurrentUrl;