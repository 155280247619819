import React, { useEffect } from "react";

import "./ImageSlider.scss";
import ImageSlide from "../ImageSlide/ImageSlide";
export default function ImageSlider({ slides, className }) {
  const slide = (wrapper, items, prev, next) => {
    var posInitial,
      slides = items.getElementsByClassName("ImageSlide"),
      slidesLength = slides.length,
      slideSize = items.getElementsByClassName("ImageSlide")[0].offsetWidth,
      index = 0,
      allowShift = true;

    wrapper.classList.add("loaded");

    const appendAfter = (n, original, appendTo) => {
      for (var i = 0; i < n; i++) {
        var clone = original[i].cloneNode(true);
        appendTo.appendChild(clone);
      }
    };

    const checkIndex = () => {
      items.classList.remove("Shifting");

      if (index == -1) {
        items.style.left = -(slidesLength * slideSize) + "px";
        index = slidesLength - 1;
      }

      if (index == slidesLength) {
        items.style.left = -(1 * slideSize) + "px";
        index = 0;
      }
      allowShift = true;
    };

    const shiftSlide = (dir, action) => {
      items.classList.add("Shifting");

      if (allowShift) {
        if (!action) {
          posInitial = items.offsetLeft;
        }

        if (dir == 1) {
          items.style.left = posInitial - slideSize + "px";
          index++;
        } else if (dir == -1) {
          items.style.left = posInitial + slideSize + "px";
          index--;
        }
      }

      allowShift = false;
    };

    appendAfter(4, slides, items);

    items.insertBefore(slides[slidesLength - 1].cloneNode(true), slides[0]);

    // Click events
    prev.addEventListener("click", function () {
      shiftSlide(-1);
    });
    next.addEventListener("click", function () {
      shiftSlide(1);
    });

    // Transition events
    items.addEventListener("transitionend", checkIndex);
  };

  useEffect(() => {
    initalizeSlider();
  }, []);

  const initalizeSlider = () => {
    const slider = document.getElementById("slider");
    const sliderItems = document.getElementById("slides");
    const prev = document.getElementById("prev");
    const next = document.getElementById("next");
    slide(slider, sliderItems, prev, next);
  };

  return (
    <div id="slider" className={`ImageSlider ${className}`}>
      <div className="ImageSlider__Wrapper">
        <div id="slides" className="ImageSlider__Wrapper__Slides">
          {slides.map((singleSlide, index) => {
            const {
              image = "",
              title = "",
              description = "",
            } = singleSlide || {};
            return (
              <ImageSlide
                className={className}
                imageURL={image}
                title={title}
                description={description}
                key={index}
              />
            );
          })}
        </div>
      </div>
      <a id="prev" className="ImageSlider__Controls Prev"></a>
      <a id="next" className="ImageSlider__Controls Next"></a>
    </div>
  );
}
