import React from "react";
import { Route, Switch } from "react-router-dom";
import { Board } from "../Components";
import {
  DashboardV2,
  Orders,
  Pricing,
  Products,
  Category,
  Inventory,
  Stock,
  SellableInventory,
} from "../Containers";
import ManageProduct from "../Containers/Products/ManageProduct";
import ManageCategory from "../Containers/Category/ManageCategory";
import ManageInventory from "../Containers/Inventory/ManageInventory";
import ManageStock from "../Containers/Stock/ManageStock";
import ManageSellableInventory from "../Containers/SellableInventory/ManageSellableInventory/ManageSellableInventory";
import ManageOrder from "../Containers/Orders/ManageOrder/ManageOrder";

export default function DashboardRoutes() {
  return (
    <Board>
      <Route exact path="/dashboard" component={DashboardV2} />
      <Route path="/dashboard/products" component={Products} />
      <Route exact path="/dashboard/products/add" component={ManageProduct} />
      <Route
        exact
        path="/dashboard/products/:id/update"
        component={ManageProduct}
      />

      <Route path="/dashboard/categories" component={Category} />
      <Route
        exact
        path="/dashboard/categories/add"
        component={ManageCategory}
      />
      <Route
        exact
        path="/dashboard/categories/:id/update"
        component={ManageCategory}
      />

      <Route path="/dashboard/inventories" component={Inventory} />
      <Route
        exact
        path="/dashboard/inventories/add"
        component={ManageInventory}
      />
      <Route
        exact
        path="/dashboard/inventories/:id/update"
        component={ManageInventory}
      />

      <Route path="/dashboard/stocks" component={Stock} />
      <Route exact path="/dashboard/stocks/add" component={ManageStock} />
      <Route
        exact
        path="/dashboard/stocks/:id/update"
        component={ManageStock}
      />

      <Route
        path="/dashboard/sellable_inventories"
        component={SellableInventory}
      />
      <Route
        exact
        path="/dashboard/sellable_inventories/add"
        component={ManageSellableInventory}
      />
      <Route
        exact
        path="/dashboard/sellable_inventories/:id/update"
        component={ManageSellableInventory}
      />

      <Route path="/dashboard/orders" component={Orders} />
      <Route
        exact
        path="/dashboard/orders/:id/:action"
        component={ManageOrder}
      />
    </Board>
  );
}
