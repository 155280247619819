import React, { useEffect } from "react";
import {
  DragAndDrop,
  LabelledInput,
  CheckBox,
  SideModal,
  LabelledSelect,
} from "../../../Components";
import "./ManageProduct.scss";
import get from "lodash/get";
import { useState } from "react";
import { actionsCreator } from "../../../Redux/actions/actionsCreator";
import { validate } from "../../../Utils/validations-utils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { formatOptionsData, validateFormData } from "../../../Utils";

const mapStateToProps = ({ categories = {} }) => ({
  categories,
});

export default function ManageProduct() {
  const {
    categories: { list: categoryList = [] },
  } = useSelector(mapStateToProps);

  const [data, setData] = useState({
    photo: "",
    product_name: "",
    is_active_product: true,
    description: "",
    sku: "",
    barcode: "",
    category: [],
  });

  const [errors, setErrors] = useState({});
  const [showErrors, setShowErrors] = useState(true);
  const dispatch = useDispatch();
  const location = useLocation();
  const { state: productData = {} } = location || {};

  const {
    photo = "",
    product_name = "",
    is_active_product = true,
    description = "",
    sku = "",
    barcode = "",
    category = [],
  } = data || {};

  const history = useHistory();
  const productId = productData ? productData.id : "";
  const title = productId ? "Update Product" : "Add Product";

  useEffect(() => {
    fetchCatogries();
  }, []);

  const fetchCatogries = () => {
    dispatch(actionsCreator.FETCH_CATEGORIES());
  };

  const addProduct = async () => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    dispatch(actionsCreator.ADD_PRODUCT(formData));
  };

  const updateProduct = async () => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    dispatch(actionsCreator.UPDATE_PRODUCT({ formData, productId }));
  };

  const onChange = (e, key, nonEvent) => {
    const value = nonEvent ? e : get(e, "target.value", "");
    const updatedData = { ...data, [key]: value };
    setData(updatedData);
  };

  const onSave = () => {
    if (productId) {
      updateProduct();
    } else {
      addProduct();
    }
    history.goBack();
  };

  const validateInput = (value, rules, key) => {
    const { error = false, msg = "" } = validate(value, rules);
    const errorObj = { ...errors, [key]: { error, msg } };
    setErrors(errorObj);
  };

  useEffect(() => {
    fillForm(productData);
  }, [productData]);

  const fillForm = (productData) => {
    if (!isEmpty(productData)) {
      const formData = {};
      Object.keys(data).forEach((key) => {
        if (key === "category" && productData[key]) {
          const categoriesData = productData[key].map((e) => {
            return e.value;
          });
          formData[key] = categoriesData;
        } else {
          formData[key] = productData[key];
        }
      });
      setData(formData);
    }
  };

  const categories = formatOptionsData(categoryList, "name", "id");

  return (
    <SideModal title={title} onSave={onSave}>
      <div>
        <DragAndDrop
          className={"SmallInput"}
          label="Photo"
          value={photo}
          onChange={(e) => {
            onChange(e, "photo", true);
            validateInput(e, ["isRequired"], "photo");
          }}
          error={showErrors ? errors["picture"] : {}}
        />
        <LabelledInput
          className={"SmallInput"}
          label="Product Name"
          placeholder="Enter Product Name here..."
          type="text"
          value={product_name}
          onChange={(e) => {
            onChange(e, "product_name");
            validateInput(e.target.value, ["isRequired"], "product_name");
          }}
          error={showErrors ? errors["product_name"] : {}}
        />
        <LabelledInput
          className={"SmallInput"}
          label="Description"
          placeholder="Enter Description here..."
          type="text"
          value={description}
          onChange={(e) => {
            onChange(e, "description");
            validateInput(e.target.value, ["isRequired"], "description");
          }}
          error={showErrors ? errors["description"] : {}}
        />
        <LabelledInput
          className={"SmallInput"}
          label="SKU"
          placeholder="Enter SKU here..."
          type="text"
          value={sku}
          onChange={(e) => {
            onChange(e, "sku");
            validateInput(e.target.value, ["isRequired"], "sku");
          }}
          error={showErrors ? errors["sku"] : {}}
        />
        <LabelledInput
          className={"SmallInput"}
          label="Barcode"
          placeholder="Enter Barcode here..."
          type="text"
          value={barcode}
          onChange={(e) => {
            onChange(e, "barcode");
            validateInput(e.target.value, ["isRequired"], "barcode");
          }}
          error={showErrors ? errors["barcode"] : {}}
        />

        <LabelledSelect
          className={"SmallInput"}
          placeholder={"Select Categories"}
          label={"Categories"}
          onSelect={(e) => {
            onChange(e, "category", true);
            validateInput(e, ["isRequired"], "category");
          }}
          data={categories}
          multiSelect={true}
          values={category}
          error={showErrors ? errors["category"] : {}}
        />
        
        <CheckBox
          label={"Active"}
          value={is_active_product}
          onChange={(e) => onChange(e, "is_active_product", true)}
        />
      </div>
    </SideModal>
  );
}
