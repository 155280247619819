import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import sound from "./Message notification.mp3";
import { FaCopy } from "react-icons/fa";
// apis
import { getBaseUrl } from "../../../Lib/NetworkHandler";
import { IoArchive, IoArrowUndo } from "react-icons/io5";
import Loader from "../Components/Loader/Loader";
import { Modal } from "../../../Components";
import "./Ticket.scss";
import AddSellableInventoryModal from "../../Crm/AddSellableInventory/AddSellableInventoryModal";
import {
  archiveTicket,
  unarchiveTicket,
  changeDoctors,
  createTicket,
  edit_invoice_status,
  fetch_invoice_status,
  fetchTicket,
  fetchWorkflows,
  ticketTransition,
  patch_priority,
} from "../../../Api/crmApi";

import Attribute from "../Components/Attributes/Attribute";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreator } from "../../../Redux/actions/actionsCreator";
import { IoEye } from "react-icons/io5";
import Field from "./Fields/Field";
import { useDrag } from "react-dnd";
import DraggableDiv from "./DraggableDiv";
import DroppableDiv from "./DroppableDiv";
import { GoMoveToTop } from "react-icons/go";
import MoveButton from "./MoveButton";
import { FiDelete } from "react-icons/fi";
import { errorMsg } from "../../../Utils";
import useDebounce from "../../../Utils/debounce-utils";

const mapStateToProps = ({ crm = {}, companyDetails }) => ({
  crm,
  companyDetails,
});

const Ticket = ({ activeComponent }) => {
  const { crm, companyDetails } = useSelector(mapStateToProps);
  const [orders, setOrders] = useState([]);
  const [notifications, setNotifications] = useState(false);
  const [loading, setLoading] = useState(true);
  const [load, setLoad] = useState(false);
  const [display, setDisplay] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showProductForm, setShowProductForm] = useState(false);
  const [showAttributeForm, setShowAttributeForm] = useState(false);
  const [draggedItem, setDraggedItem] = useState(null);
  const [columns, setColumns] = useState([]);
  const [column1Cards, setColumn1Cards] = useState(["Card 1", "Card 2"]);
  const [column2Cards, setColumn2Cards] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [addOrEditModal, toggleAddOrEditModal] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggleModal = () => {
    toggleAddOrEditModal((prev) => !prev);
  };

  const [ticketId, setTicketId] = useState(null);
  const [ticketTitle, setTicketTitle] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [attributes, setAttributes] = useState({});
  const [selectedTicketAttributes, setSelectedTicketAttributes] = useState([]);
  const [selectedTicket, setSelectedTicket] = useState([]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const [ticketType, setTicketType] = useState("");
  const [ticketTenant, setTicketTenant] = useState("");
  const [workflowId, setWorkflowId] = useState(null);
  const [workflowTickets, setWorkflowTickets] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);
  const [comments, setComments] = useState([]);
  const [ticketLayout, setTicketLayout] = useState({});
  const [execution, setexecution] = useState([]);
  const [comment, setComment] = useState("");
  const [invoice_statuses, setInvoiceStatutes] = useState(null);
  const [selected_invoice_status, SetSelectedInvoiceStatus] = useState();
  const [archivedTickets, setArchivedTickets] = useState([]);
  const handleAddComment = (ticket) => {
    setSelectedTicket(ticket);
    setTickets((prev) =>
      prev.map((item) => (item.id === ticket.id ? ticket : item))
    );
  };
  const fetchInvoiceStatuses = async () => {
    try {
      const res = await fetch_invoice_status();
      setInvoiceStatutes(res.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const updateTicket = (ticket) => {
    setSelectedTicket(ticket);
    setTickets((prev) => {
      return prev.map((item) => {
        if (item.id === ticket.id) {
          return ticket;
        } else {
          return item;
        }
      });
    });
  };

  const [isHoveredUndo, setIsHoveredUndo] = useState(false);

  const attributesHandler = (e) => {
    const { name, value } = e.target;

    setAttributes((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const [transitionStates, setTransitionStates] = useState([]);

  useEffect(() => {
    if (crm.workflow[0]) {
      const data = crm.workflow[0]?.workflow;
      setTransitionStates(
        data
          .find((workflow) => workflow.workflow_config.id === activeComponent)
          ?.states.map((item) => item.title)
      );

      setWorkflows(data);
    }
  }, [companyDetails, crm]);
  const [currequest, setcurrequest] = useState(false);
  const currentComponentRef = useRef(activeComponent);
  useEffect(() => {
    let abortController = new AbortController();
    const signal = abortController.signal;
    const fetchTickets = async () => {
      if (activeComponent === 0) return;
      setLoading(true);
      try {
        setcurrequest(true);
        const response = await fetch(
          `${getBaseUrl()}/api/crm/workflows/${activeComponent}/tickets/`,
          { signal }
        );
        const data = await response.json();
        if (currentComponentRef.current === activeComponent) {
          dispatch(actionsCreator.SET_ALL_TICKETS(data));
          setTickets(data);
          setWorkflowTickets(data);
        }
      } catch (error) {
        if (error.name === "AbortError") {
        } else {
          console.error("Error fetching tickets:", error);
        }
      } finally {
        setLoading(false);
      }
    };
    currentComponentRef.current = activeComponent;

    fetchTickets();

    return () => {
      abortController.abort();
    };
  }, [activeComponent]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return date.toLocaleString("en-US", options);
  };

  const openForm = () => {
    setShowForm(true);
  };

  const closeForm = () => {
    setShowForm(false);
  };

  const openProductForm = (ticket) => {
    setTicketId(ticket.id);
    setTicketTitle(ticket.title);
    setTicketDescription(ticket.description);
    setTicketType(ticket.ticket_type);
    setTicketTenant(ticket.tenant);
    setShowProductForm(true);
  };

  const closeProductForm = () => {
    setShowProductForm(false);
  };

  const openAttributeForm = (ticket) => {
    setSelectedTicket(ticket);
    setSelectedTicketAttributes(ticket.attributes);
    setShowAttributeForm(true);
  };

  const closeAttributeForm = () => {
    setShowAttributeForm(false);
  };

  useEffect(() => {
    fetchInvoiceStatuses();
  }, []);

  const handleAssignedToChange = (event, ticketId) => {
    const selectedUserId = event.target.value;
    const updatedTickets = tickets.map((ticket) => {
      if (ticket.id === ticketId) {
        return { ...ticket, assigned_to: selectedUserId };
      }
      return ticket;
    });

    setTickets(updatedTickets);
  };

  const uniqueTickets = Array.from(
    new Set((archivedTickets || []).map((ticket) => ticket.id))
  ).map((id) => {
    return (archivedTickets || []).find((ticket) => ticket.id === id);
  });

  useEffect(() => {
    crm.workflow[0]?.workflow.map((workflow) => {
      if (workflow.workflow_config?.id === activeComponent) {
        setTicketLayout(workflow.workflow_config?.ticket_layout);
        setexecution(workflow?.execution);
      }
    });
  }, [activeComponent, crm]);

  const handleDragStart = (e, ticket) => {
    setDraggedItem(ticket);
    if ("touches" in e) {
      e.preventDefault();
    }
    // e.target.addEventListener('mousemove', handleMouseMove);
  };
  const handleMouseMove = (e) => {
    const draggedCard = document.querySelector(".dragging");
    if (draggedCard) {
      const xOffset = e.clientX - draggedCard.getBoundingClientRect().left;
      const yOffset = e.clientY - draggedCard.getBoundingClientRect().top;
      draggedCard.style.transform = `translate(${xOffset}px, ${yOffset}px) rotate(-3deg) scale(1.05)`; // Apply transform based on mouse position
    }
  };

  const handleDragEnd = () => {
    setDraggedItem(null);
    const draggedCard = document.querySelector(".dragging");
    if (draggedCard) {
      draggedCard.style.transform = ""; // Reset transform style
      draggedCard.removeEventListener("mousemove", handleMouseMove); // Remove mousemove event listener
    }
  };

  const handleDocChange = (e) => {
    const data = e.target.value;

    changeDoctors(data)
      .then((res) => {})
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleTouchStart = (e, ticket) => {
    handleDragStart(e, ticket);
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
  };

  const handleDragOver = (e, target) => {
    e.preventDefault();

    // Check if the target column is valid (present in next_states of the dragged ticket)
    if (draggedItem && draggedItem.next_states.includes(target)) {
      e.dataTransfer.dropEffect = "move"; // Allow dropping
    } else {
      e.dataTransfer.dropEffect = "none"; // Disable dropping
    }
  };

  const getNextTransition = (currTransition) => {
    const currInd = transitionStates.findIndex(
      (element) => element === currTransition
    );

    return transitionStates[(currInd + 1) % transitionStates.length];
  };

  const updateNextState = (currState, nextStates) => {
    // const
    const nextState = getNextTransition(currState);
    nextStates = [nextState, ...nextStates];

    return [nextState];
  };

  const [draggedData, setDraggedData] = useState(null);
  const handleDrop = (result, target) => {
    const draggedItem = result.data;
    if (draggedItem.current_state == target) {
      return;
    }
    toggleActiveMoveBtn("");
    setDraggedData({ ...draggedItem, target });
  };

  useEffect(() => {
    if (!draggedData) return;
    const updatedDraggedData = {
      ...draggedData,
      current_state: draggedData.target,
      prev_states: [draggedData.current_state, ...draggedData.prev_states],
    };

    const { target, id, next_states } = updatedDraggedData;

    // Check if the target state is valid
    if (!next_states.includes(target)) {
      toast.info(`Ticket cannot be moved to ${target}`);
      return;
    }

    const ticketInd = tickets.findIndex((item) => item.id === id);
    if (ticketInd < 0) return; // If the ticket doesn't exist, exit early

    // Update UI immediately by setting the new ticket state
    setTickets((prevTickets) => [
      ...prevTickets.slice(0, ticketInd),
      updatedDraggedData,
      ...prevTickets.slice(ticketInd + 1),
    ]);

    // Find the correct index in the workflows
    const findIndex = (activeComponent) => {
      for (let i = 0; i < workflows.length; i++) {
        if (workflows[i].workflow_config.id === activeComponent) return i;
      }
      return null;
    };

    const index = findIndex(activeComponent);
    if (index === null) return; // If no matching component is found, exit early

    const data = {
      ticket_id: id,
      next_state_id: workflows[index].states.find(
        (state) => state.title === target
      )?.id,
    };

    // Async API call to update the ticket state in the backend
    ticketTransition(data)
      .then((res) => {
        setTickets((prevTickets) => [
          ...prevTickets.slice(0, ticketInd),
          { ...updatedDraggedData, next_states: res.data.next_states }, // Update next states from response
          ...prevTickets.slice(ticketInd + 1),
        ]);

        // Update Redux store if necessary
        dispatch(
          actionsCreator.SET_ALL_TICKETS([
            ...tickets.slice(0, ticketInd),
            { ...updatedDraggedData, next_states: res.data.next_states },
            ...tickets.slice(ticketInd + 1),
          ])
        );

        setDraggedData(null); // Clear the dragged data after success
      })
      .catch((err) => {
        const errMsg = errorMsg(err);
        toast.error(errMsg);

        // Revert the ticket to its previous state in case of error
        setTickets((prevTickets) => [
          ...prevTickets.slice(0, ticketInd),
          draggedData, // Revert to the original state
          ...prevTickets.slice(ticketInd + 1),
        ]);

        setDraggedData(null); // Clear the dragged data even on failure
      });
  }, [draggedData, dispatch]);
  useEffect(() => {}, [
    draggedData,
    tickets,
    workflows,
    activeComponent,
    dispatch,
  ]);
  const printTicket = () => {};
  const handleSubmit = async (event) => {
    event.preventDefault();
    formData["channel"] = "WEBSITE";
    formData["workflow_Config"] = activeComponent;
    formData["tenant"] = companyDetails?.list?.id;
    formData["attributes"] = attributes;
    formData["title"] = attributes["name"];

    await createTicket(formData, activeComponent)
      .then((res) => {
        const data = res.data;
        toast.success("Ticket Created");
        setTickets((prevData) => [data, ...prevData]);
      })
      .catch((err) => {});

    closeForm();
  };

  const copyHandler = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Text copied successfully");
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard:", err);
      });
  };

  const handleDelete = (ticketId) => {
    setTickets((prev) => prev.filter((item) => item.id !== ticketId));
  };

  const [activeMoveBtn, toggleActiveMoveBtn] = useState("");
  const sortedTickets = uniqueTickets.sort(
    (a, b) => new Date(a.created_on) - new Date(b.created_on)
  );

  const [searchQuery, setSearchQuery] = useState("");
  const filteredTickets = sortedTickets.filter(
    (ticket) =>
      (ticket.ticket_id &&
        ticket.ticket_id.toLowerCase().includes(searchQuery.toLowerCase())) ||
      (ticket.customer_name &&
        ticket.customer_name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())) ||
      (ticket.description &&
        ticket.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const invoiceStatusChangeHandler = (e, url, ticketId) => {
    try {
      const res = edit_invoice_status({
        order_id: Number.parseInt(url.split("/").pop()),
        status: e.target.value,
      })
        .then((res) => {
          setTickets((prev) => {
            const updatedTickets = prev.map((ticket) => {
              if (ticket.id === ticketId) {
                return {
                  ...ticket,
                  invoice: {
                    ...ticket.invoice,
                    invoice_status: e.target.value,
                  },
                };
              } else {
                return ticket;
              }
            });
            return updatedTickets;
          });
        })
        .catch((err) => console.log(err));
    } catch (error) {
      console.error(error);
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const [isHoveredArchive, setIsHoveredArchive] = useState(false);
  const [isArchiving, setIsArchiving] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showArchivedTicketsModal, setShowArchivedTicketsModal] =
    useState(false);
  const [selectorValues, setSelectorValues] = useState(
    Array(tickets.length).fill("")
  );

  const TicketManagement = ({ crm, workflows, activeComponent }) => {
    const [tickets, setTickets] = useState([]);
  };

  useEffect(() => {
    const savedArchivedTickets = localStorage.getItem("archivedTickets");
    if (savedArchivedTickets) {
      setArchivedTickets(JSON.parse(savedArchivedTickets));
    } else {
      setArchivedTickets([]);
    }
  }, []);

  // Store archived tickets in localStorage whenever they change
  useEffect(() => {
    localStorage.setItem("archivedTickets", JSON.stringify(archivedTickets));
  }, [archivedTickets]);

  const handleConfirmArchive = async () => {
    if (!selectedTicket || !selectedTicket.id) {
      return;
    }

    setIsArchiving(true);

    try {
      // Archive the ticket with "ARCHIVED" status
      const response = await archiveTicket(selectedTicket.id, "ARCHIVED");

      if (response.status === 200) {
        const updatedTicket = {
          ...selectedTicket,
          status: "ARCHIVED",
          originalStatus: selectedTicket.status,
        };

        const updatedArchivedTickets = [...archivedTickets, updatedTicket];
        setArchivedTickets(updatedArchivedTickets);

        setTickets(tickets.filter((ticket) => ticket.id !== selectedTicket.id));

        setIsModalOpen(false);
        setShowArchivedTicketsModal(false);
        toast.success(
          `Ticket archived successfully (ID: ${selectedTicket.ticket_id})`
        );
      } else {
        console.error(
          "Failed to archive ticket. Status code:",
          response.status
        );
      }
    } catch (error) {
      console.error("Error archiving ticket:", error);
    } finally {
      setIsArchiving(false);
    }
  };

  const handleUndoArchive = async (ticket) => {
    if (!ticket || !ticket.id) {
      console.error("Ticket is not defined or missing ID");
      return;
    }

    setIsArchiving(true);

    try {
      // Unarchive the ticket
      const response = await archiveTicket(ticket.id, ticket.originalStatus); // Restore original status

      if (response.status === 200) {
        // Update the ticket's status back to its original status
        const updatedTicket = { ...ticket, status: ticket.originalStatus };

        // Add back to active tickets
        setTickets((prevTickets) => [...prevTickets, updatedTicket]);

        // Remove from archived tickets
        setArchivedTickets((prevArchived) =>
          prevArchived.filter(
            (archivedTicket) => archivedTicket.id !== ticket.id
          )
        );

        setIsModalOpen(false);
        setShowArchivedTicketsModal(true);
        setUndoVisible(false);
        toast.success(
          `Ticket Unarchived successfully (ID: ${selectedTicket.ticket_id})`
        );
      } else {
        console.error("Failed to undo archive");
      }
    } catch (error) {
      console.error("Error undoing archive:", error);
    } finally {
      setIsArchiving(false);
    }
  };
  const openArchivedTicketsModal = () => {
    setShowArchivedTicketsModal(true);
  };

  const closeArchivedTicketsModal = () => {
    setShowArchivedTicketsModal(false);
  };
  const handleConfirm = () => {
    archiveTicket();
  };
  const [bgCol, setbgCol] = useState("green");
  const handlePriorityChange = async (id, value) => {
    const updatedValues = { ...selectorValues };

    // Update the value for the specific ticket ID
    updatedValues[id] = value;
    const payload = { priority: value };
    patch_priority(id, payload)
      .then((data) => {
        setSelectorValues(updatedValues);
        if (value === "2") {
          setbgCol("yellow");
        } else if (value === "3") {
          setbgCol("red");
        }
      })
      .catch(function (error) {
        if (error.response) {
          if (error?.response?.data?.error) {
            toast.error(error.response.data.error);
          } else if (error?.response?.data?.contract_file) {
            toast.error(error?.response?.data?.contract_file[0]);
          } else if (error?.response?.data?.tender_number) {
            toast.error(error?.response?.data?.tender_number[0]);
          } else {
            toast.error("Bad Request");
          }
        }
      });
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const [ticket, setTicket] = useState({ id: 341 });
  const formContainerStyle = {
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    border: "2px solid #ddd",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    padding: "30px",
    width: "100%",
    maxWidth: "550px",
    margin: "0 auto",
    // boxShadow: 'none', // Uncomment this if you want to remove the box-shadow
  };
  const debouncedQuery = useDebounce(searchQuery, 400);

  const [recentlyArchivedTicket, setRecentlyArchivedTicket] = useState(null);
  const [undoVisible, setUndoVisible] = useState(false);
  useEffect(() => {
    if (showArchivedTicketsModal) {
      setSearchQuery(""); // Clear search input when modal is opened
    }
  }, [showArchivedTicketsModal]);

  return (
    <div
      className="h-full w-full"
      style={{
        overflowX: "hidden",
      }}
    >
      {loading === true ? (
        <div
          className="w-full flex place-item-center"
          style={{ height: "75vh" }}
        >
          <Loader />
        </div>
      ) : (
        <div className="">
          <button
            onClick={openForm}
            type="button"
            className="p-2 px-3 rounded-pill create-ticket-button"
          >
            Create{" "}
            {`${
              crm.workflow[0].workflow.filter(
                (workflow) => workflow.workflow_config?.id === activeComponent
              )[0]?.workflow_config?.title
            }`}
          </button>

          <Modal
            show={showForm}
            style={{ width: "30rem", paddingTop: "4px", paddingBottom: "6px" }}
            onClose={closeForm}
          >
            <form
              onSubmit={(event) => handleSubmit(event)}
              className="ticket-form-container"
            >
              <h2 className="ticket-form-heading">Ticket Details</h2>

              <div className="ticket-form-group">
                <label htmlFor="name">Title</label>
                <input
                  type="text"
                  name="customer_name"
                  id="name"
                  value={formData?.customer_name}
                  onChange={changeHandler}
                  className="ticket-input-field"
                />
                <label htmlFor="detail">Description</label>
                <input
                  type="text"
                  id="detail"
                  name="description"
                  required
                  value={formData?.description}
                  onChange={changeHandler}
                  className="ticket-input-field"
                />
              </div>

              <div className="ticket-form-buttons">
                <button
                  type="button"
                  className="ticket-form-button"
                  onClick={closeForm}
                >
                  Go Back
                </button>
                <button type="submit" className="ticket-form-button">
                  Submit
                </button>
              </div>
            </form>
          </Modal>
          <button
            onClick={openArchivedTicketsModal}
            type="button"
            className="view-archive-btn"
          >
            View Archived Tickets <IoArchive style={{ marginLeft: "8px" }} />
          </button>
          <div className="top-div">
            {workflows
              .find(
                (workflow) => workflow.workflow_config.id === activeComponent
              )
              ?.states?.sort((a, b) => a.priority - b.priority)
              .map((state, stateIndex) => (
                <DroppableDiv
                  key={stateIndex}
                  className="columns relative overflowY-hidden"
                  handleDrop={(result) => handleDrop(result, state.title)}
                  title={state.title}
                >
                  <div
                    style={{
                      width: "100%",
                      position: "sticky",
                      top: "0",
                      left: "0",
                      zIndex: "100",
                      marginTop: "5px",
                    }}
                  >
                    <div className="column-head">{state.title}</div>
                  </div>

                  <div className="column-body overflowY-scroll">
                    {tickets?.filter(
                      (ticket) => ticket.current_state === state.title
                    ).length === 0 ? (
                      <p
                        className="text-center bold-lg text-md"
                        style={{ marginTop: "4rem" }}
                      >
                        No Tickets to show...
                      </p>
                    ) : (
                      tickets
                        ?.filter((ticket) => {
                          // Check if the ticket is archived
                          const isArchived = archivedTickets.some(
                            (archivedTicket) => archivedTicket.id === ticket.id
                          );
                          return (
                            !isArchived && ticket.current_state === state.title
                          );
                        })
                        // Remove duplicates by ticket_id
                        .reduce((uniqueTickets, currentTicket) => {
                          if (
                            !uniqueTickets.some(
                              (ticket) =>
                                ticket.ticket_id === currentTicket.ticket_id
                            )
                          ) {
                            uniqueTickets.push(currentTicket);
                          }
                          return uniqueTickets;
                        }, [])
                        .sort(
                          (a, b) =>
                            new Date(b.created_on) - new Date(a.created_on)
                        )
                        .map((ticket, ticketIndex) => (
                          <DraggableDiv
                            key={ticket?.id}
                            className={`card`}
                            data={ticket}
                          >
                            <div className="card-header">
                              <span>{ticket.ticket_id}</span>
                              <div className="flex flex-row items-center gap-10">
                                <div>
                                  <select
                                    id="customSelect"
                                    value={selectorValues[ticket?.id]}
                                    onChange={(e) =>
                                      handlePriorityChange(
                                        ticket?.id,
                                        e.target.value
                                      )
                                    }
                                    style={{
                                      width: "auto",
                                      border: "1px solid #ddd",
                                      borderRadius: "6px",
                                      backgroundColor:
                                        selectorValues[ticket?.id] === "Low"
                                          ? "#d4edda"
                                          : selectorValues[ticket?.id] ===
                                            "Medium"
                                          ? "#fff3cd"
                                          : selectorValues[ticket?.id] ===
                                            "High"
                                          ? "#f8d7da"
                                          : "#f1f1f1",
                                      fontSize: "10px",
                                      margin: "10px 0",
                                      cursor: "pointer",
                                      height: "1.5rem",
                                      color:
                                        selectorValues[ticket?.id] === "Low"
                                          ? "#155724"
                                          : selectorValues[ticket?.id] ===
                                            "Medium"
                                          ? "#856404"
                                          : selectorValues[ticket?.id] ===
                                            "High"
                                          ? "#721c24"
                                          : "#333",
                                      fontWeight: "bold",
                                      transition:
                                        "background-color 0.3s ease, color 0.3s ease",
                                    }}
                                  >
                                    <option value="" disabled>
                                      Priority
                                    </option>
                                    <option value="Low">Low</option>
                                    <option value="Medium">Medium</option>
                                    <option value="High">High</option>
                                  </select>
                                </div>
                                {/* Eye Button */}
                                <div
                                  className="eye-icon-wrapper"
                                  onMouseEnter={() => setIsHovered(true)}
                                  onMouseLeave={() => setIsHovered(false)}
                                >
                                  <IoEye
                                    color="green"
                                    size={20}
                                    onClick={() => openAttributeForm(ticket)}
                                    style={{ cursor: "pointer" }}
                                  />
                                  {isHovered && (
                                    <div className="floating-alert">Ticket</div>
                                  )}
                                </div>
                                {/* Archive Button */}
                                <div
                                  className="archive-icon-wrapper"
                                  onMouseEnter={() => setIsHoveredArchive(true)}
                                  onMouseLeave={() =>
                                    setIsHoveredArchive(false)
                                  }
                                >
                                  <IoArchive
                                    color={isArchiving ? "grey" : "green"}
                                    size={20}
                                    onClick={() => {
                                      setSelectedTicket(ticket); // Save the ticket to archive
                                      setIsModalOpen(true); // Open the confirmation modal
                                    }}
                                    style={{
                                      cursor: isArchiving
                                        ? "not-allowed"
                                        : "pointer",
                                    }}
                                    disabled={isArchiving}
                                  />
                                  {isHoveredArchive && (
                                    <div className="floating-alert">
                                      Archive
                                    </div>
                                  )}
                                </div>

                                {/* Move Button */}
                                <MoveButton
                                  activeMoveBtn={activeMoveBtn}
                                  toggleActiveMoveBtn={toggleActiveMoveBtn}
                                  ticket={ticket}
                                  handleDrop={handleDrop}
                                />
                              </div>
                            </div>
                            <div className="heading-ticket">
                              {" "}
                              Title: {ticket.customer_name}{" "}
                            </div>
                            <div className="description-ticket">
                              {" "}
                              Desc: {ticket.description}{" "}
                            </div>
                            {/* Challan URL */}
                            {ticket?.order_items?.[0]?.order_id != null &&
                              String(ticket.order_items[0].order_id).trim() !==
                                "" &&
                              ticket?.challan_url &&
                              ticket.challan_url.trim() &&
                              ticket.challan_url !==
                                "https://incitoindia.com/challan/" &&
                              !ticket.challan_url.endsWith("null") && (
                                <div className="ticket-invoice-wrapper">
                                  <div className="assigned_name">
                                    <b style={{ color: "violet" }}>
                                      Challan Url:
                                    </b>
                                    <p className="invoice-url">
                                      {ticket.challan_url?.substring(0, 30)}
                                    </p>
                                    <FaCopy
                                      onClick={() =>
                                        copyHandler(ticket.challan_url)
                                      }
                                      className="copy-icon"
                                    />
                                  </div>
                                  {ticket?.invoice?.invoice_url && (
                                    <div className="assigned_name">
                                      <b style={{ color: "violet" }}>
                                        Invoice Url:
                                      </b>
                                      <p className="invoice-url">
                                        {ticket?.invoice?.invoice_url?.substring(
                                          0,
                                          30
                                        )}
                                      </p>
                                      <FaCopy
                                        onClick={() =>
                                          copyHandler(
                                            ticket?.invoice?.invoice_url
                                          )
                                        }
                                        className="copy-icon"
                                      />
                                    </div>
                                  )}
                                </div>
                              )}
                            <div className="author">
                              <div
                                className="created-on"
                                style={{ marginRight: "8px" }}
                              >
                                {formatDate(ticket.created_on)}
                              </div>
                              <div className="status">
                                {ticket.status}
                                <span className="mx-2">{ticket.channel}</span>
                              </div>
                            </div>
                          </DraggableDiv>
                        ))
                    )}
                  </div>
                </DroppableDiv>
              ))}

            {/* Confirmation Modal outside of the ticket loop */}
            {isModalOpen && (
              <Modal
                show={isModalOpen}
                onClose={handleClose}
                title="Confirm Archive"
              >
                <div>
                  <p>Are you sure you want to archive this ticket?</p>
                  <button
                    onClick={handleConfirmArchive}
                    disabled={isArchiving}
                    className={`confirm-button ${
                      isArchiving ? "disabled" : ""
                    }`}
                  >
                    Yes
                  </button>
                  <button
                    onClick={handleClose}
                    disabled={isArchiving}
                    className={`cancel-button ${isArchiving ? "disabled" : ""}`}
                  >
                    No
                  </button>
                </div>
              </Modal>
            )}
          </div>
        </div>
      )}
      {/* Archived Tickets Modal */}

      <Modal
        show={showArchivedTicketsModal}
        onClose={closeArchivedTicketsModal}
        title="Archived Tickets"
      >
        <div className="archived-tickets-form">
          {/* Redesigned Search Bar */}
          <div className="search-bar-wrapper">
            <input
              type="text"
              placeholder="Search by Ticket ID, Title, or Description"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="ticket-search-input"
            />
          </div>
        </div>
        <div style={{ width: "98%", margin: "10px" }}>
          <div
            handleDrop={(result) => handleDrop(result, "All Tickets")}
            title={"Archived Tickets"}
          >
            <div
              style={{
                width: "100%",
                position: "sticky",
                top: "0",
                left: "0",
                zIndex: "100",
                marginTop: "5px",
              }}
            >
              <div className="column-head">{"All TIckets"}</div>
            </div>

            <div
              className="column-body "
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(31%, 0.5fr))",
                gap: "15px",
                width: "100%",
                gridAutoRows: "minmax(150px, auto)",
              }}
            >
              {archivedTickets
                ?.filter((item, ind) => {
                  return (
                    item?.ticket_id
                      ?.toString()
                      .toLowerCase()
                      .includes(debouncedQuery.toLowerCase()) ||
                    item?.coustomer_name
                      ?.toString()
                      .toLowerCase()
                      .includes(debouncedQuery.toLowerCase()) ||
                    item?.description
                      ?.toString()
                      .toLowerCase()
                      .includes(debouncedQuery.toLowerCase())
                  );
                })
                .map((ticket, ticketIndex) => (
                  <div
                    key={ticket?.id}
                    className={`card`}
                    height={"10rem"}
                    data={ticket}
                  >
                    <div className="card-header">
                      <span>{ticket.ticket_id}</span>
                      <div className="flex flex-row items-center gap-10">
                        <div>
                          <select
                            id="customSelect"
                            value={selectorValues[ticket?.id]}
                            onChange={(e) =>
                              handlePriorityChange(ticket?.id, e.target.value)
                            }
                            style={{
                              width: "auto",
                              border: "1px solid #ddd",
                              borderRadius: "6px",
                              backgroundColor:
                                selectorValues[ticket?.id] === "Low"
                                  ? "#d4edda"
                                  : selectorValues[ticket?.id] === "Medium"
                                  ? "#fff3cd"
                                  : selectorValues[ticket?.id] === "High"
                                  ? "#f8d7da"
                                  : "#f1f1f1",
                              fontSize: "10px",
                              margin: "10px 0",
                              cursor: "pointer",
                              height: "1.5rem",
                              color:
                                selectorValues[ticket?.id] === "Low"
                                  ? "#155724"
                                  : selectorValues[ticket?.id] === "Medium"
                                  ? "#856404"
                                  : selectorValues[ticket?.id] === "High"
                                  ? "#721c24"
                                  : "#333",
                              fontWeight: "bold",
                              transition:
                                "background-color 0.3s ease, color 0.3s ease",
                            }}
                          >
                            <option value="" disabled>
                              Priority
                            </option>
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                          </select>
                        </div>
                        {/* Eye Button */}
                        <div
                          className="eye-icon-wrapper"
                          onMouseEnter={() => setIsHovered(true)}
                          onMouseLeave={() => setIsHovered(false)}
                        >
                          <IoEye
                            color="green"
                            size={20}
                            onClick={() => openAttributeForm(ticket)}
                            style={{ cursor: "pointer" }}
                          />
                          {isHovered && (
                            <div className="floating-alert">Ticket</div>
                          )}
                        </div>
                        {/* Archive Button */}
                        <div
                          className="archive-icon-wrapper"
                          onMouseEnter={() => setIsHoveredArchive(true)}
                          onMouseLeave={() => setIsHoveredArchive(false)}
                        >
                          <IoArchive
                            color={isArchiving ? "grey" : "green"}
                            size={20}
                            onClick={() => {
                              setSelectedTicket(ticket); // Save the ticket to archive
                              setIsModalOpen(true); // Open the confirmation modal
                            }}
                            style={{
                              cursor: isArchiving ? "not-allowed" : "pointer",
                            }}
                            disabled={isArchiving}
                          />
                          {isHoveredArchive && (
                            <div className="floating-alert">Archive</div>
                          )}
                        </div>

                        {/* Move Button */}
                        <MoveButton
                          activeMoveBtn={activeMoveBtn}
                          toggleActiveMoveBtn={toggleActiveMoveBtn}
                          ticket={ticket}
                          handleDrop={handleDrop}
                        />
                      </div>
                    </div>
                    <div className="heading-ticket">
                      {" "}
                      Title: {ticket.customer_name}{" "}
                    </div>
                    <div className="description-ticket">
                      {" "}
                      Desc: {ticket.description}{" "}
                    </div>

                    {ticket?.order_items?.[0]?.order_id != null &&
                      String(ticket.order_items[0].order_id).trim() !== "" &&
                      ticket?.challan_url &&
                      ticket.challan_url.trim() &&
                      ticket.challan_url !==
                        "https://incitoindia.com/challan/" &&
                      !ticket.challan_url.endsWith("null") && (
                        <div className="ticket-invoice-wrapper">
                          <div className="assigned_name">
                            <b style={{ color: "violet" }}>Challan Url:</b>
                            <p className="invoice-url">
                              {ticket.challan_url?.substring(0, 30)}
                            </p>
                            <FaCopy
                              onClick={() => copyHandler(ticket.challan_url)}
                              className="copy-icon"
                            />
                          </div>
                          {ticket?.invoice?.invoice_url && (
                            <div className="assigned_name">
                              <b style={{ color: "violet" }}>Invoice Url:</b>
                              <p className="invoice-url">
                                {ticket?.invoice?.invoice_url?.substring(0, 30)}
                              </p>
                              <FaCopy
                                onClick={() =>
                                  copyHandler(ticket?.invoice?.invoice_url)
                                }
                                className="copy-icon"
                              />
                            </div>
                          )}
                        </div>
                      )}
                    <div className="author">
                      <div
                        className="created-on"
                        style={{ marginRight: "8px" }}
                      >
                        {formatDate(ticket.created_on)}
                      </div>
                      <div className="status">
                        {ticket.status}
                        <span className="mx-2">{ticket.channel}</span>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {/* Confirmation Modal outside of the ticket loop */}
          {isModalOpen && (
            <Modal
              show={isModalOpen}
              onClose={handleClose}
              title="Confirm Archive"
            >
              <div>
                <p>Are you sure you want to Unarchive this ticket?</p>
                <button
                  onClick={() => {
                    handleUndoArchive(selectedTicket);
                  }}
                  disabled={isArchiving}
                  className={`confirm-button ${isArchiving ? "disabled" : ""}`}
                >
                  Yes
                </button>
                <button
                  onClick={handleClose}
                  disabled={isArchiving}
                  className={`cancel-button ${isArchiving ? "disabled" : ""}`}
                >
                  No
                </button>
              </div>
            </Modal>
          )}
        </div>
      </Modal>

      {showAttributeForm && (
        <Attribute
          fileUrls={fileUrls}
          setFileUrls={setFileUrls}
          attributes={selectedTicketAttributes}
          setSelectedTicket={setSelectedTicket}
          closeModal={closeAttributeForm}
          tickets={selectedTicket}
          ticketId={selectedTicket.id}
          allTickets={tickets}
          setAllTickets={setTickets}
          updateTicket={updateTicket}
          comments={comments}
          onAddComment={handleAddComment}
          setTickets={setTickets}
          ticketLayout={ticketLayout}
          execution={execution}
        />
      )}
    </div>
  );
};

export default Ticket;
