import React, { useEffect, useRef, useState } from "react";
import { GoMoveToTop } from "react-icons/go";
import { toast } from "react-toastify";

const MoveButton = ({ toggleActiveMoveBtn, activeMoveBtn, ticket, handleDrop }) => {
  const editRef = useRef();
  const [isInsideElement, setIsInsideElement] = useState(false);

  const handleClickOutside = (event) => {
    const myElement = editRef?.current;

    if (myElement && !myElement.contains(event.target)) {
      // Clicked outside the element
      setIsInsideElement(false);
    } else {
      // Clicked inside the element
      setIsInsideElement(true);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <span className="flex flex-col relative move-button">
      {
        ticket.next_states?.length > 0  
        &&
        <button
          ref={editRef}
          onClick={(e) => {
            if (isInsideElement && activeMoveBtn !== "") {
              toggleActiveMoveBtn("");
            } else {
              toggleActiveMoveBtn(ticket.id);
            }
          }}
          className="btn-none"
        >
          <GoMoveToTop />
        </button>
      }

      {
      activeMoveBtn === ticket.id && 
      isInsideElement && 
      ticket.next_states.length > 0 && (
        <div
          className="absolute flex flex-col gap-5"
          style={{
            top: "10px",
            right: "20px",
            zIndex: "1000",
            backgroundColor: "#00000080",
            padding: "10px",
            borderRadius: "10px",
            backdropFilter : 'blur(5px)'
          }}
        >
          {ticket.next_states.map((item) => (
            <button
              key={item}
              className="btn-none btn-md"
              style={{
                padding: "2px",
                border: "1px solid #60e1cb",
                borderRadius: "5px",
                color: "#60e1cb",
              }}
              onClick={() => {
                handleDrop({ data: ticket }, item);
              }}
            >
              {item}
            </button>
          ))}
        </div>
      )}
    </span>
  );
};

export default MoveButton;
