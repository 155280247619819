import React, { useEffect, useState } from 'react';
import styles from "../../Design.module.scss";
import { useCustomizer } from '../../../../../../Provider/customizerProvider';
import { RxLetterCaseUppercase, RxLetterCaseLowercase, RxLetterCaseCapitalize } from "react-icons/rx";
import { BsDash } from "react-icons/bs";

export default function Appearance() {
    const { onChange, currentStyles } = useCustomizer();
    const [appearance, setAppearance] = useState(currentStyles['textTransform']);

    useEffect(() => {
        onChange({ 'text-transform': appearance });
    }, [appearance]);

    useEffect(() => { setAppearance(currentStyles['textTransform']) }, [currentStyles])
    return (
        <div className={styles.itemWrapper}>
            <div className="flex justify-between items-center relative">
                <h4 className={styles.textLabel}>Appearance</h4>
                <div className="flex items-center gap-05rem">
                    <BsDash
                        className={`${styles.Icon} ${appearance === 'none' ? styles.activeIcon : ''}`}
                        onClick={() => setAppearance('none')}
                    />
                    <RxLetterCaseCapitalize
                        className={`${styles.Icon} ${appearance === 'capitalize' ? styles.activeIcon : ''}`}
                        onClick={() => setAppearance('capitalize')}
                    />
                    <RxLetterCaseUppercase
                        className={`${styles.Icon} ${appearance === 'uppercase' ? styles.activeIcon : ''}`}
                        onClick={() => setAppearance('uppercase')}
                    />
                    <RxLetterCaseLowercase
                        className={`${styles.Icon} ${appearance === 'lowercase' ? styles.activeIcon : ''}`}
                        onClick={() => setAppearance('lowercase')}
                    />
                </div>
            </div>
        </div>
    );
}
