import React, { useState } from 'react';
import styles from "../../theme.module.scss";
import { IoEyedrop } from "react-icons/io5";
import { useUtils } from '../../../../../../Hooks/useUtils';
import { useCustomizer } from '../../../../../../Provider/customizerProvider';

export default function SecondaryColor() {
    const { adjustBrightness } = useUtils()
    const { theme, onThemeChange } = useCustomizer()
    const [color, setColor] = useState(theme.secondaryColor)
    const lighter1 = adjustBrightness(color, 180);
    const lighter2 = adjustBrightness(color, 90);
    const darker1 = adjustBrightness(color, -90);
    const darker2 = adjustBrightness(color, -180);


    return (
        <div className={styles.itemWrapper}>
            <div className="flex justify-start relative flex-col">
                <h4 className={styles.textLabel}>Secondary Color</h4>

                <div className={`${styles.colorWrapper} flex gap-175rem`}>
                    <div
                        className={`${styles.colorPreview} flex items-center justify-center `}

                    >
                        <input
                            type="color"
                            className={styles.colorInput}
                            onChange={e => {
                                setColor(e.target.value)
                                onThemeChange({ secondaryColor: e.target.value })
                            }}
                            value={color}

                        />
                        < IoEyedrop style={{ 'fill': "#cacaca" }} />
                    </div>

                    <div className={`${styles.colorCardWrapper} flex items-center`}>
                        {/* Display color boxes */}
                        <div onClick={() => onThemeChange({ secondaryColor: lighter1 })} className={styles.colorCard} style={{ backgroundColor: lighter1 }}></div>
                        <div onClick={() => onThemeChange({ secondaryColor: lighter2 })} className={styles.colorCard} style={{ backgroundColor: lighter2 }}></div>
                        <div onClick={() => onThemeChange({ secondaryColor: color })} className={styles.colorCard} style={{ backgroundColor: color }}></div>
                        <div onClick={() => onThemeChange({ secondaryColor: darker1 })} className={styles.colorCard} style={{ backgroundColor: darker1 }}></div>
                        <div onClick={() => onThemeChange({ secondaryColor: darker2 })} className={styles.colorCard} style={{ backgroundColor: darker2 }}></div>
                    </div>
                </div>
            </div>
        </div>
    );
}
