import React from 'react'
import Wrapper from '../Wrapper'
import Duration from './Duration'
import Delay from './Delay'
import Count from './Count'
export default function Control() {
    return (
        <Wrapper title={"Control"}>
            <Duration></Duration>
            <Delay></Delay>
            <Count></Count>
        </Wrapper>
    )
}
