export const useUtils = () => {
    const isObject = obj => obj && typeof obj === 'object';

    const parseNumber = (styleProp) => {
        if (!styleProp) return 0;
        const match = String(styleProp).match(/\d+/);
        return match ? Number(match[0]) : 0;
    };


    const searchElementById = (config, targetId, targetType) => {
        if (config?.id === targetId) {
            return config;
        }
        for (let key in config) {
            if (config.hasOwnProperty(key)) {
                const value = config[key];
                if (isObject(value) || Array.isArray(value)) {
                    const foundElement = searchElementById(value, targetId, targetType);
                    if (foundElement) {
                        return foundElement;
                    }
                }
            }
        }

        return null;
    }


    function adjustBrightness(color, amount) {
        if (color && amount) {
            let colorValue = color.startsWith('#') ? color.slice(1) : color;
            let num = parseInt(colorValue, 16);

            let r = (num >> 16) + amount;
            let g = ((num >> 8) & 0x00FF) + amount;
            let b = (num & 0x0000FF) + amount;

            r = Math.max(0, Math.min(255, r));
            g = Math.max(0, Math.min(255, g));
            b = Math.max(0, Math.min(255, b));

            return `#${(r << 16 | g << 8 | b).toString(16).padStart(6, '0')}`;
        }

    }
    function convertToCamelCaseFontKeys(Obj) {
        const convertedObj = {};

        for (const key in Obj) {
            // Convert "font-family" to "fontFamily"
            const camelCaseKey = key.replace(/-([a-z])/g, (_, char) => char.toUpperCase());
            convertedObj[camelCaseKey] = Obj[key];
        }

        return convertedObj;
    }
    function convertToKebabCaseFontKeys(obj) {
        const convertedObj = {};

        for (const key in obj) {
            // Convert "fontFamily" to "font-family"
            const kebabCaseKey = key.replace(/[A-Z]/g, char => `-${char.toLowerCase()}`);
            convertedObj[kebabCaseKey] = obj[key];
        }

        return convertedObj;
    }


    return { convertToKebabCaseFontKeys, parseNumber, isObject, searchElementById, adjustBrightness, convertToCamelCaseFontKeys };
}