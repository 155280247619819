import React, { useEffect, useState } from 'react'
import Wrapper from '../../Wrapper'
import EventCard from './EventCard'
import styles from "../../../Animation.module.scss"
export default function EventType({ event, onChange }) {
    return (
        <Wrapper title={"Event"}>
            <div className={styles.grid_2}>
                <EventCard title="None" active={event} value="none" onClick={() => onChange("none")} />
                <EventCard title="On Active" active={event} value="active" onClick={() => onChange("active")} />
                <EventCard title="On Hover" active={event} value="hover" onClick={() => onChange("hover")} />
                <EventCard title="On Click" active={event} value="click" onClick={() => onChange("click")} />
            </div>

        </Wrapper >
    )
}
