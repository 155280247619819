import React from "react";

import digitising from "../../../Assets/Images/home/Digitising.png";
import oneClick from "../../../Assets/Images/home/oneClick.png";
import mob from "../../../Assets/Images/home/mob.gif";

import overlayImg from "../../../Assets/Images/home/overlay.png";

import "./Offerings.scss";
import { ImageSlider } from "../../../Components";

export default function Offerings() {
  const features = [
    {
      title: "Minimal Documentation",
      description: "Your Store Online in a few clicks.",
      image: oneClick,
    },
    {
      title: "10x Growth",
      description: "Minimal Documention",
      image: digitising,
    },
    {
      title: "Analytics",
      description:
        "Get started with the most advanced  Order Management System.",
      image: mob,
    },
  ];
  return (
    <div className="Offerings">
      <div className="Offerings__Content">
        <div className="Offerings__Content__Heading Heading">
          Why Should You Use Us?
        </div>
        <div className="Offerings__Content__List">
          <div className="Offerings__Content__List__Overlay">
            <img src={overlayImg} />
          </div>
          <ImageSlider slides={features} className={"OfferingSlides"} />
          <div className="Offerings__Content__List__Background"></div>
        </div>
      </div>
    </div>
  );
}
