import React from "react";
import { Button } from "../../../Components";
import "./ContactUs.scss";

export default function ContactUs() {
  return (
    <div className="ContactUs PagePadding">
      <div className="ContactUs__Heading Heading text-white">
        Interested to work with us ?
      </div>
      <div className="ContactUs__SubHeading text-white">
        Send a line here get and update daily
      </div>
      <div className="ContactUs__Button">
        <Button text="care@aiktech.in" className="Radius__Button" />
      </div>
    </div>
  );
}
