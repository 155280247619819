import Margin from "./Margin";
import Padding from "./Padding";
import Wrapper from '../Wrapper'


import React from 'react'

export default function Spacing() {
    return (
        <Wrapper title="Spacing">
            <Margin />
            <Padding />
        </Wrapper>
    )
}
