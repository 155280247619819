import React, { useEffect, useState } from 'react';
import styles from "../../Design.module.scss"
import { useCustomizer } from '../../../../../../Provider/customizerProvider';
import { RxWidth } from "react-icons/rx";
import { useUtils } from '../../../../../../Hooks/useUtils';
export default function Width() {
    const { parseNumber } = useUtils()
    const { onChange, currentStyles } = useCustomizer()
    const [width, setWidth] = useState(parseNumber(currentStyles['width']), [currentStyles]);

    useEffect(() => {
        if (width) {
            onChange({ 'width': width })
        }
    }, [width])

    useEffect(() => { setWidth(parseNumber(currentStyles['width'])) }, [currentStyles])


    return (
        <div className={styles.designWrapper} >
            <div className="flex justify-between items-center relative">
                <h4 className={styles.textLabel}>Width</h4>
                <div className={`flex items-center gap-05rem ${styles.inputContainer}`}>
                    <RxWidth />
                    <input
                        type="number"
                        className={`${styles.textInput} flex items-center`}

                        value={width}
                        onChange={e => {
                            const value = e.target.value;
                            if (/^\d*$/.test(value)) {
                                setWidth(value);
                            }
                        }}
                    />
                    <span className={styles.textLabel}>px</span>

                </div>
            </div>
        </div>
    );
}
