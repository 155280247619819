import React, { useEffect } from "react";
import Button from "../../Button/Button";
import { useHistory } from "react-router-dom";
import { get } from "lodash";
import "./components.scss";

export default function OrderAction(props) {
  const history = useHistory();
  const handleOrder = (actionValue) => {
    const order = get(props, "row.original", "");
    Object.assign(order, { action: actionValue });
    const { id } = order || {};
    history.push(`/dashboard/orders/${id}/${actionValue}`, order);
  };

  return (
    <div className={"OrderChoice"}>
      <div className={"OrderChoice__Accept"}>
        <Button
          className={"Success"}
          text={"Accept"}
          onClick={() => handleOrder("accept")}
        />
      </div>
      <div className={"OrderChoice__Reject"}>
        <Button
          className={"Failure"}
          text={"Reject"}
          onClick={() => handleOrder("reject")}
        />
      </div>
    </div>
  );
}
