import React, { useCallback, useEffect, useRef, useState } from "react";
import get from "lodash/get";

//Constant
import {
  BRAND_CATEGORIES,
  TENANT_REGISTRATION_SLIDES,
} from "../../../Assets/Constant";

//Style
import "./RegistrationForm.scss";
import axios from 'axios'

//Component
import Preview from "../Preview";
import { actionsCreator } from "../../../Redux/actions/actionsCreator";
import { useDispatch, useSelector } from "react-redux";
import { errorMsg, fetchTenantInupts, getToken } from "../../../Utils";
import { useHistory } from "react-router-dom";
import { GrFormAttachment } from "react-icons/gr";
import { IoIosSend } from "react-icons/io";
import Home from "../../Home";
import Avatar from "../../../Assets/Images/avatar.png";
import { toast, ToastContainer } from "react-toastify";
import { IoAdd } from "react-icons/io5";
import { RxQuestionMarkCircled } from "react-icons/rx";
import Progress from '../../../Assets/Images/Frame 44.svg'
import { FaUserLarge } from "react-icons/fa6";
import heroImg from '../../../Assets/Images/heroimage.png'
import { getBaseUrl } from "../../../Lib/NetworkHandler";
import BasicDetails from "./BasicDetails";
import { createTenant } from "../../../Api/tenantAPI";

const Thinking = () => {
  return (
    <span
      className={`flex flex-row items-center justify-center gap-5`}
    >
      <span
        className="dot first"
        style={{
          height: "0.5rem",
          aspectRatio: "1",
          backgroundColor: "white",
          borderRadius: "100%",
        }}
      />
      <span
        className="dot second"
        style={{
          height: "0.5rem",
          aspectRatio: "1",
          backgroundColor: "white",
          borderRadius: "100%",
        }}
      />
      <span
        className="dot third"
        style={{
          height: "0.5rem",
          aspectRatio: "1",
          backgroundColor: "white",
          borderRadius: "100%",
        }}
      />
    </span>
  );
};

const CategoryCard = ({
  id,
  data,
  selectedId,
  onClick,
  animationDelay
}) => {
  const { name = "", logo_url = "", description = "" } = data || {};

  const targetRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Add your class to the target element when it comes into view
          entry.target.classList.add('appear-from-left');
          // Disconnect the observer after adding the class
          observer.disconnect();
        }
      });
    },);

    // Start observing the target element
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    // Cleanup by disconnecting the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, [targetRef]);

  return (
    <div
      onClick={onClick}
      ref={targetRef}
      className={`${animationDelay} ${id === selectedId && 'active-card'}`}
    // style={{
    //   opacity: '0'
    // }}

    >
      <div
        onClick={onClick}
        // ref={targetRef}
        className={`border flex flex-col gap-10 hover-card`}
        style={{
          padding: "10px 15px",
          cursor: 'pointer',
          // opacity : '0'
        }}
      >
        <div
          className="flex flex-row items-center justify-between"
          style={{
            padding: "15px 0",
            borderBottom: "1px solid #E2E8F0",
          }}
        >
          <img
            className=""
            style={{
              height: "3rem",
              aspectRatio: "1",
              objectFit: "contain",
            }}
            src={logo_url}
          />
          <div
            className="h-full w-full flex flex-col justify-center"
            style={{
              paddingLeft: "10px",
            }}
          >
            <p className="text-sm text-inactive">Build an app like</p>
            <p className="text-lg text-active bold-xl text-uppercase">{name}</p>
          </div>
          <button className="border round-full flex items-center justify-center">
            <IoAdd size={"2rem"} />
          </button>
        </div>
        <div
          className="w-full"
          style={{
            borderBottom: "1px solid #E2E8F0",
            padding: "10px 0",
          }}
        >
          <img
            src={logo_url}
            style={{
              width: "100%",
              height: "12rem",
              objectFit: "contain",
            }}
          />
        </div>
        <div
          className="flex flex-col gap-10"
          style={{
            borderBottom: "1px solid #E2E8F0",
            padding: "10px 0",
          }}
        >
          <p className="text-md text-inactive">Included Features</p>
          <p
            className="text-md text-active"
            style={{
              lineHeight: "22px",
            }}
          >
            Signup/Login, Instagram Login, Videos <br />
            +40 Other features
          </p>
        </div>

        <div
          className="flex flex-row justify-between"
          style={{
            padding: "10px 0",
          }}
        >
          <div className="flex flex-col">
            <p className="text-sm text-inactive">From</p>
            <span className="flex flex-row items-end gap-5">
              <p className="text-lg text-active bold-xl">2070k</p>
              <p className="text-sm text-inactive">
                per <br />
                month
              </p>
            </span>
          </div>
          <button className="border btn-md round-5">View Details</button>
        </div>
      </div>
    </div>
  );
};


const CategorySelectionPage = ({ loading, handleClickNext, handleClickBack, toggleSelectedId, selectedId }) => {

  const {
    tenantCategories: { list: categories = [] },
  } = useSelector(mapStateToProps);
  const [expandContainer, toggleExpandContainer] = useState(false);

  // console.log(categories);

  const handleClick = (id) => {
    toggleSelectedId(id);
  }


  return (
    <div>
      <div
        className="flex flex-row items-center gap-10"
        style={{
          borderBottom: "1px solid #E2E8F0",
          padding: "10px 0",
          marginBottom: '10px'
        }}
      >
        <p className="text-xl text-active bold-xl">Choose a base</p>
        <RxQuestionMarkCircled size={'1.4rem'} />
        <span className="purple-dot" />
        <button className="ml-auto">
          {!expandContainer ?
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style={{ height: "28px" }} onClick={() => toggleExpandContainer(true)}>
              <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
            </svg> :
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" style={{ height: "28px" }} onClick={() => toggleExpandContainer(false)}>
              <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
            </svg>
          }

        </button>
      </div>

      {expandContainer && <>
        <div
          className="flex flex-col gap-20"
          style={{
            padding: '25px 0'
          }}
        >
          <div className="flex flex-row gap-10">
            <button
              className="border btn-sm round-5"
              style={{
                backgroundColor: '#E2E8F0'
              }}
            >All</button>
            <button className="border btn-sm round-5">Pro</button>
            <button className="border btn-sm round-5">Free</button>
          </div>

          <p className="text-md text-inactive">
            Choose up to 3 templates (apps similiar to your idea) to use as a base
          </p>
        </div>
        <div className="responsive-cards-3-1">
          {
            categories.map((item, ind) => {
              let delay = ind % 3 == 0 ? 200 : (ind % 3 == 1 ? 400 : (ind % 3 == 2 ? 600 : 200));
              return (
                <CategoryCard
                  id={item.id}
                  data={item}
                  selectedId={selectedId}
                  animationDelay={`animation-delay-${delay}`}
                  onClick={() => { handleClick(item.id) }}
                />
              )
            })
          }
        </div>
      </>
      }
      <div
        className="flex flex-row gap-10"
        style={{
          marginTop: '2rem'
        }}
      >
        <button
          className="btn-inactive btn-md"
          onClick={handleClickBack}
        >
          Back
        </button>
        <button
          className="btn-active btn-md"
          onClick={handleClickNext}
        >
          {loading ? <Thinking /> : 'Next'}
        </button>
      </div>
    </div>
  );
};

const BuildingTemplate = ({ handleClickNext }) => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleSubmit = setTimeout(() => {
      handleClickNext();
    }, 3000)

    return () => {
      clearTimeout(handleSubmit)
    }
  }, [])

  return (
    <div className="flex flex-col gap-20 items-center">
      <p className="text-xl bold-xl">
        Great, let me build a template for you
      </p>
      <img
        src={heroImg}
        style={{
          height: '40vh',
          width: 'fit-content',
          objectFit: 'contain'
        }}
      />
      <div>
        <button
          onClick={handleClickNext}
          className="btn-active btn-md"
        >
          {
            loading && <Thinking />
          }
        </button>
      </div>
    </div>
  )
}

const SubmitTemplate = () => {
  const history = useHistory();

  return (
    <div className="">
      <div className="flex flex-row gap-10 w-full">
        <span
          className="border round-full flex items-center justify-center"
          style={{
            height: '3rem',
            // padding : '1rem',
            aspectRatio: '1'
          }}
        >
          <FaUserLarge size={'1.2rem'} color={'#4A25E1'} />
        </span>

        <div
          className="border round-10 w-full text-md bold-lg flex items-center"
          style={{
            paddingLeft: '1rem'
          }}
        >
          Features
        </div>
      </div>
      <button
        onClick={() => {
          history.push('/builder')
        }}
        style={{
          marginTop: '1rem'
        }}
        className="btn-active btn-md"
      >
        Submit
      </button>
    </div>
  )
}

export const LogoMaker = ({ title = 'Prince Ratogi' }) => {

  function getFirstLetters(str) {
    const words = str.split(' ');
    const firstLetters = words.map(word => word.charAt(0));
    return firstLetters.join(' ');
  }

  return (
    <div
      className="round-full flex flex-row justify-center items-center text-xl bold-xl text-white"
      style={{
        height: '4rem',
        aspectRatio: '1',
        backgroundColor: 'black'
      }}
    >
      {
        getFirstLetters(title)
      }
    </div>
  )
}

const mapStateToProps = ({ tenantForm = {}, auth = {}, tenantCategories = {} }) => ({
  tenantForm,
  auth,
  tenantCategories
});

export default function RegistrationForm() {
  const {
    tenantForm: { isChangeSlide = false, sessionId = "", showPreview = false },
    auth: auth = {},
    tenantCategories: tenantCategories = []
  } = useSelector(mapStateToProps);

  const [selectedId, toggleSelectedId] = useState(null);

  // const { isLoggedIn = false } = auth;

  const isLoggedIn = getToken();

  const slides = TENANT_REGISTRATION_SLIDES;
  const initialFormData = fetchTenantInupts(slides);
  const [formData, setFormData] = useState(initialFormData);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(1);
  const dispatch = useDispatch();
  const history = useHistory();

  const onChange = (e, key, nonEvent) => {
    const value = nonEvent ? e : get(e, "target.value", "");
    const updatedFormData = { ...formData, [key]: value };
    setFormData(updatedFormData);
  };

  useEffect(() => {
    if (isChangeSlide) {
      goToNextSlide();
    }
  }, [isChangeSlide]);

  useEffect(() => {
    if (showPreview) {
      goToPreviewScreen();
    }
  }, [showPreview]);

  useEffect(() => {
    resetForm();
    getCategories();
  }, []);

  const resetForm = () => {
    dispatch(actionsCreator.RESET_TENANT_FORM_DATA());
    dispatch(actionsCreator.SET_SHOW_PREVIEW(false));
    setCurrentSlideIndex(1);
  };

  const getCategories = () => {
    dispatch(actionsCreator.FETCH_TENANT_CATEGORIES());
  };

  const onSubmit = (formData) => {
    // const currentSlide = slides[currentSlideIndex - 1];
    // const { action = "" } = currentSlide || {};
    !sessionId ? registerTenantUser(formData) : verifyTenantUserOtp(formData);
    // switch (action) {
    //   case "TENANT_USER_REGISTRATION":
    //     break;
    //   case "TENANT_REGISTRATION":
    //     registerTenant();
    //     break;
    //   default:
    //     goToNextSlide();
    // }
  };

  const registerTenantUser = async (formData) => {
    const {
      name = "",
      phone_number = "",
      password = "",
      password2 = "",
      email = "",
    } = formData || {};

    const payload = {
      name,
      phone_number,
      password: password,
      password2: password2,
      email,
    };

    dispatch(actionsCreator.REGISTER_TENANT_USER(payload));
  };

  const verifyTenantUserOtp = async (formData) => {
    const { phone_number = "", otp = "" } = formData || {};

    const payload = {
      phone: phone_number,
      otp,
      session_id: sessionId,
    };

    dispatch(actionsCreator.VERIFY_TENANT_OTP(payload));
  };

  const resendOTP = async () => {
    const { phone_number = "" } = formData || {};
    const payload = {
      phone_number,
    };
    dispatch(actionsCreator.SEND_TENANT_USER_OTP(payload));
  };

  const registerTenant = async () => {
    const {
      title = "",
      logo = {},
      menuFile = {},
      tenant_category = "",
      subdomain = "",
      address = "",
      description = "",
    } = formData || {};
    const payload = new FormData();
    payload.append("title", title);
    payload.append("logo_url", logo);
    payload.append("tenant_category", tenant_category);
    payload.append("subdomain", subdomain);
    payload.append("address", address);
    payload.append("description", description);
    dispatch(actionsCreator.REGISTER_TENANT(payload));
  };

  const goToNextSlide = () => {
    const updatedIndex =
      currentSlideIndex !== slides.length
        ? currentSlideIndex + 1
        : currentSlideIndex;
    setCurrentSlideIndex(updatedIndex);
    dispatch(actionsCreator.SET_CHANGE_SLIDE(false));
  };

  const goToPreviewScreen = () => {
    const subdomain = formData.subdomain || "ammaspastry";
    const tenant_category = formData.tenant_category || "grocery";

    // setTimeout(() => {
    //   history.push(
    //     `/website-preview?url=${subdomain}&tenant_category=${tenant_category}`
    //   );
    //   dispatch(actionsCreator.SET_SHOW_PREVIEW(false));
    // }, 1500);

  };

  const onBack = () => {
    const updatedIndex =
      currentSlideIndex !== 1 ? currentSlideIndex - 1 : currentSlideIndex;
    setCurrentSlideIndex(updatedIndex);
  };

  const getCurrentSlide = (index) => {
    const slideIndex = index || 1;
    return slides.find((s) => s.slide === slideIndex);
  };

  const getPreviewImage = () => {
    const previewImage = slides[currentSlideIndex - 1]
      ? slides[currentSlideIndex - 1]["previewImage"]
      : "";
    if (currentSlideIndex != 1) {
      return previewImage;
    } else {
      const categoryItem = BRAND_CATEGORIES.find(
        (c) => c.id === formData.tenant_category
      );
      return categoryItem ? categoryItem.previewImage : previewImage;
    }
  };

  const currentSlide = getCurrentSlide(currentSlideIndex);
  const { component: Component, showBackBtn = false } = currentSlide || {};

  const previewImage = getPreviewImage();

  const [pageIndex, setPageIndex] = useState(1);

  const [currentAns, setCurrentAns] = useState("");
  const [tenantLogo, setTenantLogo] = useState(null);
  const fileInputRef = useRef(null);

  const [answersOfClient, setAnswersOfClient] = useState(
    Array.from({ length: 3 }, () => 0)
  );

  const questionsOfBot = [
    {
      questions: "What's your Name ?",
      placeHolder: "Enter name for your App",
    },
    {
      questions: `Hey ${answersOfClient[0]}. What are we building ?`,
      placeHolder: "I want to build a music e-commerce platform...",
    },
  ];

  const [loading, setLoading] = useState(false);
  const handleClickNext = async (data) => {
    if (currentAns.length === 0 && pageIndex === 1) {
      toast.info(`Please answer ${questionsOfBot[pageIndex - 1].questions}`);
      return;
    }
    if (!tenantLogo) {
      toast.info("Please Select a logo");
      return;
    }
    setLoading(true);
    await new Promise(() => {
      setTimeout(() => {
        const allAnswers = answersOfClient;

        if (pageIndex == 2) {
          allAnswers[pageIndex - 1] = currentAns;
          allAnswers[pageIndex] = data;
        } else if (pageIndex == 4) {

        }
        else {
          allAnswers[pageIndex - 1] = currentAns;
          setCurrentAns("");
        }

        setAnswersOfClient(allAnswers);

        setLoading(false);
        setPageIndex((prev) => prev + 1);

        window.scrollTo({ top: 0, behavior: 'smooth' });
      }, 2000);
    });
  };

  const handleClickBack = (event) => {
    setCurrentAns(answersOfClient[pageIndex - 2]);

    setPageIndex((prev) => prev - 1);
  };

  const handleSubmit = async () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (currentAns.length === 0 && !selectedId) {
      toast.info(`Please provide tenant details.`);
      return;
    }
    setLoading(true);
    try {
      const payload = new FormData();
      payload.append("title", answersOfClient[0]);
      payload.append("logo_url", tenantLogo);
      payload.append("tenant_category", selectedId);
      payload.append("subdomain", answersOfClient[0]);
      // payload.append("address", address);
      payload.append("description", currentAns);
      // const res = dispatch(actionsCreator.REGISTER_TENANT(payload));
      const { data } = await createTenant(payload);
      const tenantId = data?.id;
      console.log(data);
      history.push(`/builder?tenant=${tenantId}`,
        {
          from: window.location.pathname,
        })
    } catch (err) {
      const errMsg = errorMsg(err);
      toast.error(errMsg);
    }

    setLoading(false);
  }


  return (
    <div className="registeration-form w-full">
      {
        isLoggedIn ?
          <>
            <p
              className="text-3xl bold-xl"
              style={{
                marginBottom: '2rem'
              }}
            >Building Next Gen E-Commerce.</p>
            <div
              className="form-progress-bar border flex flex-row round-20"
              style={{
                backgroundColor: '#4A25E140',
                height: '10px',
                position: 'sticky',
                top: '0',
                zIndex: '1',
              }}
            >
              <div
                className="round-20"
                style={{
                  width: `${parseInt(((pageIndex - 1) / 3) * 100)}%`,
                  backgroundColor: '#4A25E1',
                  height: '100%',
                  transition: 'all ease-in-out 200ms'
                }}
              />
            </div>
            <div className="flex flex-col gap-20">
              <div />
              {
                questionsOfBot.map((item, index) => {
                  return (
                    index === pageIndex - 1 && (
                      <>
                        <span className="text-lg bold-xl appear-from-left">
                          {item.questions}
                        </span>
                        <div
                          className={`question-form-input ${index === 1 && "w-full"}`}
                        >
                          <input
                            type={"text"}
                            placeholder={item.placeHolder}
                            value={currentAns}
                            onChange={(event) => {
                              setCurrentAns(event.target.value);
                            }}
                            className="input-active w-full appear-from-left animation-delay-200"
                          />
                        </div>
                        {
                          index === 0 &&
                          <span className="text-lg bold-xl appear-from-left mt-3">
                            Upload Your Company Logo
                            <div className="flex items-center gap-20" style={{ alignItems: 'center' }}>
                              <div
                                className="upload-image-input"
                                onClick={() => {
                                  if (fileInputRef.current) {
                                    fileInputRef.current.click();
                                  }
                                }}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"
                                  height={30}>
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                                </svg>
                                <span>Upload</span>
                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  style={{ display: 'none' }}
                                  accept="image/*"
                                  onChange={
                                    (e) => {
                                      const file = e.target.files[0];
                                      if (file) {
                                        setTenantLogo(file);
                                      }
                                    }
                                  }
                                />
                              </div>
                              {tenantLogo &&
                                <div style={{ marginTop: '10px' }}>
                                  <img src={URL.createObjectURL(tenantLogo)} alt="logo" height={90} style={{ objectFit: "fill" }} />
                                </div>
                              }
                            </div>
                          </span >

                        }
                        <div>

                        </div>
                        {
                          index === 1 &&
                          <>
                            <div className="flex w-full gap-10 items-center my-2 mt-4">
                              <hr style={{ width: "100%", borderWidth: "2px", }} />
                              <span className="text-md bold-lg">OR</span>
                              <hr style={{ width: "100%", borderWidth: "2px", }} />
                            </div>
                            <CategorySelectionPage
                              loading={loading}
                              handleClickNext={handleSubmit}
                              handleClickBack={handleClickBack}
                              selectedId={selectedId}
                              toggleSelectedId={toggleSelectedId}
                            />
                          </>
                        }
                        {
                          index === 0 &&
                          <div className="flex flex-row gap-10">
                            {pageIndex >= 2 && (
                              <span className="appear-from-left animation-delay-400">
                                <button
                                  className="btn-inactive btn-md"
                                  onClick={handleClickBack}
                                >
                                  Back
                                </button>
                              </span>
                            )}

                            <span className="appear-from-left animation-delay-400">
                              <button
                                className="btn-active btn-md"
                                onClick={handleClickNext}
                              >
                                {loading ? <Thinking /> : "Next"}
                              </button>
                            </span>
                          </div>
                        }
                      </>
                    )
                  );
                })
              }
              {/* {
                pageIndex == 3 &&
                <CategorySelectionPage
                  loading={loading}
                  handleClickNext={handleClickNext}
                  handleClickBack={handleClickBack}
                />
              } */}
              {
                pageIndex == 4 &&
                <BuildingTemplate
                  loading={loading}
                  handleClickNext={handleSubmit}
                />
              }
            </div>
          </>
          :
          <BasicDetails
            formData={formData}
            onSubmit={onSubmit}
            sessionId={sessionId}
          />
      }
    </div >
  );
}
