import React, { useEffect } from "react";
import {
  LabelledInput,
  SelectOption,
  CheckBox,
  SideModal,
} from "../../../Components";
import "./ManageInventory.scss";
import get from "lodash/get";
import { useState } from "react";
import { actionsCreator } from "../../../Redux/actions/actionsCreator";
import { validate } from "../../../Utils/validations-utils";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";

export default function ManageInventory() {
  const [data, setData] = useState({
    name: "",
    address: "",
    code: "",
    pincode: "",
    is_active: true,
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { state: inventoryData = {} } = location || {};
  const {
    name = "",
    address = "",
    code = "",
    pincode = "",
    is_active = true,
  } = data || {};

  const inventoryId = inventoryData ? inventoryData.id : "";
  const title = inventoryId ? "Update Inventory" : "Add Inventory";

  const addInventory = async () => {
    const payload = { ...data };
    dispatch(actionsCreator.ADD_INVENTORY(payload));
  };

  const updateInventory = async () => {
    const payload = { ...data, ...{ id: inventoryId } };
    dispatch(actionsCreator.UPDATE_INVENTORY(payload));
  };

  const onChange = (e, key, nonEvent) => {
    const value = nonEvent ? e : get(e, "target.value", "");
    const updatedData = { ...data, [key]: value };
    setData(updatedData);
  };

  const onSave = () => {
    if (inventoryId) {
      updateInventory();
    } else {
      addInventory();
    }
    history.goBack();
  };

  const validateInput = (value, rules, key) => {
    const { error = false, msg = "" } = validate(value, rules);
    const errorObj = { ...errors, [key]: { error, msg } };
    setErrors(errorObj);
  };

  useEffect(() => {
    fillForm(inventoryData);
  }, [inventoryData]);

  const fillForm = (inventoryData) => {
    if (!isEmpty(inventoryData)) {
      const formData = {};
      Object.keys(data).forEach((key) => {
        formData[key] = inventoryData[key];
      });
      setData(formData);
    }
  };

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  return (
    <SideModal title={title} onSave={onSave}>
      <div>
        <LabelledInput
          className={"SmallInput"}
          label="Inventory Name"
          placeholder="Enter Inventory Name here..."
          type="text"
          value={name}
          onChange={(e) => {
            onChange(e, "name");
            validateInput(e.target.value, ["isRequired"], "name");
          }}
          error={errors["name"]}
        />
        <LabelledInput
          className={"SmallInput"}
          label="Address"
          placeholder="Enter Address here..."
          type="text"
          value={address}
          onChange={(e) => {
            onChange(e, "address");
            validateInput(e.target.value, ["isRequired"], "address");
          }}
          error={errors["address"]}
        />
        <LabelledInput
          className={"SmallInput"}
          label="Code"
          placeholder="Enter Code here..."
          type="number"
          value={code}
          onChange={(e) => {
            onChange(e, "code");
            validateInput(e.target.value, ["isRequired"], "code");
          }}
          error={errors["code"]}
        />
        <LabelledInput
          className={"SmallInput"}
          label="Pincode"
          placeholder="Enter Pincode here..."
          type="number"
          value={pincode}
          onChange={(e) => {
            onChange(e, "pincode");
            validateInput(e.target.value, ["isRequired"], "pincode");
          }}
          error={errors["pincode"]}
        />
        <CheckBox
          label={"Status"}
          value={is_active}
          onChange={(e) => onChange(e, "is_active", true)}
        />
      </div>
    </SideModal>
  );
}
