import Text from "../Components/Table/components/Text";
import EditProduct from "../Components/Table/components/EditProduct";
import EditCategory from "../Components/Table/components/EditCategory";
import EditInventory from "../Components/Table/components/EditInventory";
import EditStock from "../Components/Table/components/EditStock";
import Status from "../Components/Table/components/Status";
import EditSellableInventory from "../Components/Table/components/EditSellableInventory";

import ViewImage from "../Components/Table/components/ViewImage";
import OrderAction from "../Components/Table/components/OrderAction";

export const productTableColumns = [
  {
    Header: "Photo",
    accessor: "photo",
    Cell: ViewImage,
    key: "photo",
  },
  {
    Header: "Name",
    accessor: "product_name",
    Cell: Text,
    key: "product_name",
  },
  {
    Header: "Categories",
    accessor: "category",
    Cell: Text,
    key: "category",
  },
  {
    Header: "Status",
    accessor: "is_active_product",
    Cell: Status,
    key: "is_active_product",
  },
  {
    Header: "Description",
    accessor: "description",
    Cell: Text,
    key: "description",
  },
  {
    Header: "Sku",
    accessor: "sku",
    Cell: Text,
    key: "sku",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: EditProduct,
    key: "edit",
  },
];
export const categoryTableColumns = [
  {
    Header: "Photo",
    accessor: "image",
    Cell: ViewImage,
    key: "image",
  },
  {
    Header: "Name",
    accessor: "name",
    Cell: Text,
    key: "name",
  },
  {
    Header: "Description",
    accessor: "description",
    Cell: Text,
    key: "description",
  },
  {
    Header: "Slug",
    accessor: "slug",
    Cell: Text,
    key: "slug",
  },
  {
    Header: "Status",
    accessor: "active",
    Cell: Status,
    key: "active",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: EditCategory,
    key: "edit",
  },
];
export const inventoryTableColumns = [
  {
    Header: "Inventory Name",
    accessor: "name",
    Cell: Text,
    key: "name",
  },
  {
    Header: "Address",
    accessor: "address",
    Cell: Text,
    key: "address",
  },
  {
    Header: "Code",
    accessor: "code",
    Cell: Text,
    key: "code",
  },
  {
    Header: "Pincode",
    accessor: "pincode",
    Cell: Text,
    key: "pincode",
  },
  {
    Header: "Status",
    accessor: "is_active",
    Cell: Status,
    key: "tag",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: EditInventory,
    key: "edit",
  },
];
export const stockTableColumns = [
  {
    Header: "Inventory",
    accessor: "inventory.name",
    Cell: Text,
    key: "inventory",
  },
  {
    Header: "Product",
    accessor: "product.product_name",
    Cell: Text,
    key: "product",
  },
  {
    Header: "Units",
    accessor: "units.name",
    Cell: Text,
    key: "units",
  },
  // {
  //   Header: "Price",
  //   accessor: "mrp",
  //   Cell: Text,
  //   key: "mrp",
  // },
  {
    Header: "Quantity",
    accessor: "quantity",
    Cell: Text,
    key: "quantity",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: EditStock,
    key: "edit",
  },
];
export const sellableInventoryTableColumns = [
  {
    Header: "Inventory",
    accessor: "inventory.name",
    Cell: Text,
    key: "inventory",
  },
  {
    Header: "Product",
    accessor: "product.product_name",
    Cell: Text,
    key: "product",
  },
  {
    Header: "Quantity Remaining",
    accessor: "quantity_remaining",
    Cell: Text,
    key: "quantity_remaining",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: EditSellableInventory,
    key: "edit",
  },
];
export const orderTableColumns = [
  {
    Header: "Order Id",
    accessor: "order_id",
    Cell: Text,
    key: "id",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: Text,
    key: "status",
  },
  {
    Header: "Customer",
    accessor: "customer",
    Cell: Text,
    key: "customer",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: OrderAction,
    key: "action",
  },
];
