import React, { useEffect, useState } from 'react';
import styles from "../../Design.module.scss"
import { useCustomizer } from '../../../../../../Provider/customizerProvider';
export default function Highlight() {
    const { onChange, currentStyles } = useCustomizer()
    const [color, setColor] = useState(currentStyles['backgroundColor']);

    useEffect(() => {
        onChange({ 'background-color': color })
    }, [color])

    useEffect(() => { setColor(currentStyles['backgroundColor']) }, [currentStyles])
    return (
        <div className={styles.itemWrapper} >
            <div className="flex justify-between items-center relative">
                <h4 className={styles.textLabel}>Highlight</h4>
                <div
                    style={{ backgroundColor: color }}
                    className={`${styles.colorPreview}`}
                >
                    <input
                        type="color"
                        className={styles.colorInput}
                        onChange={e => setColor(e.target.value)}
                        value={color}
                    />

                </div>


            </div>
        </div >
    );
}
