import React from 'react'
import Wrapper from '../../Wrapper'
import PrimaryColor from './PrimaryColor'
import SecondaryColor from './SecondaryColor'
import TextColor from './TextColor'
import ActionColor from './ActionColor'
export default function Color() {
    return (
        <Wrapper title="Color Theme">

            <PrimaryColor />
            <SecondaryColor />
            <TextColor></TextColor>
            <ActionColor></ActionColor>
        </Wrapper>
    )
}
