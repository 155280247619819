import requestMaker from "../Lib";

export const initiatePayment = (data) => {
  const url = `/account/payment/initiate/`;
  const params = { subscription_id: data.subscription_id };
  const payload = { ...data };
  return requestMaker(url, "post", { params, payload });
};

export const paymentSuccess = (data) => {
  const url = `/account/payment/success/`;
  const params = { subscription_id: data.subscription_id };
  const payload = { ...data };
  return requestMaker(url, "post", { params, payload });
};

export const paymentFailure = (data) => {
  const url = `/account/payment/failure/`;
  const params = { subscription_id: data.subscription_id };
  const payload = { ...data };
  return requestMaker(url, "post", { params, payload });
};

// search invoice, order, entity
export const getAllPayments = () => {
  const url = `/shop/payments/`;
  const params = {};
  const payload = {};
  return requestMaker(url, "get", { params, payload });
}

export const submitPayment = (data) => {
  const url = `/shop/payments/`;
  const params = {};
  const payload = {...data};
  return requestMaker(url, "post", { params, payload });
}
export const createPaymentComment = (data) => {
  const url = `/shop/payment/comments/`;
  const params = {};
  const payload = {...data};
  return requestMaker(url, "post", {params,payload});
}
export const deletePaymentAttachments = (data) => {
  const url = `/shop/payment/attachments/`;
  const params = {};
  const payload = {...data};
  return requestMaker(url, "delete", {params,payload});
}
export const searchInvoice = (key) => {
  const url = `/shop/invoices`;
  const params = key;
  const payload = { };
  return requestMaker(url, "get", { params, payload });
};

export const searchOrder = (key) => {
  const url = `/shop/orders`;
  const params = key;
  const payload = { };
  return requestMaker(url, "get", { params, payload });
};

export const searchEntity = (key) => {
  const url = `/shop/entities`;
  const params = key;
  const payload = { };
  return requestMaker(url, "get", { params, payload });
};
export const handlePaymentSuccess = (payload) => {
  const url = `/payments/success/`;
  const params = {};
  return requestMaker(url, 'post', { params, payload });
};

export const handlePaymentFailure = (payload) => {
  const url = `/payments/failure/`;
  const params = {};
  return requestMaker(url, 'post', { params, payload });
};
