import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { NAV_ITEMS } from "../../../Assets/Constant";
import notificationIcon from "../../../Assets/Icons/notification.svg";
import { actionsCreator } from "../../../Redux/actions/actionsCreator";
import { formatOptionsData } from "../../../Utils";
import LabelledSelect from "../../LabelledSelect";
import "./Nav.scss";

const mapStateToProps = ({ tenantData = {} }) => ({
  tenantData,
});
export default function Nav() {
  const {
    tenantData: { allTenants = [], selectedTenant = {} },
  } = useSelector(mapStateToProps);
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname = "" } = location || {};

  const getTitle = (path) => {
    let text = "";
    const items = NAV_ITEMS;
    const allItems = [];
    items.forEach((item) => {
      allItems.push(...item.items);
    });
    const item = allItems.find((item) => {
      return item.path === String(path);
    });
    if (!isEmpty(item)) {
      text = item.text;
    }
    return text ? text : "Dashboard";
  };

  useEffect(() => {
    fetchTenanats();
  }, []);

  const fetchTenanats = () => {
    dispatch(actionsCreator.FETCH_ALL_TENANTS());
  };

  const onSelect = (value) => {
    const selectedTenant = allTenants.find((tenant) => {
      return tenant.id === value;
    });
    dispatch(actionsCreator.SET_TENANT({ selectedTenant: selectedTenant }));
    window.location.reload();
  };

  const title = getTitle(pathname);
  const tenantList = formatOptionsData(allTenants, "title", "id");
  const activeTenant = selectedTenant ? selectedTenant.id : "";
  return (
    <div className="Nav">
      <div className="Nav__Title">{title}</div>
      <div className="Nav__Content">
        <div className="Nav__Content__Tenant">
          <LabelledSelect
            className={"SmallInput"}
            data={tenantList}
            onSelect={onSelect}
            placeholder={"Select Tenant"}
            values={activeTenant}
          />
        </div>
        <div className="Nav__Content__Notification Icon">
          <img src={notificationIcon} />
        </div>
      </div>
    </div>
  );
}
