import { all } from "redux-saga/effects";
import userSagas from "./userSaga";
import tenantSagas from "./tenantSagas";
import catalogSaga from "./catalogSaga";
import cmsSagas from "./cmsSaga";
import crmSagas from "./crmSaga";
import cartSagas from "./cartSagas";


export default function* rootSaga() {
  yield all([userSagas(), tenantSagas(), catalogSaga(), cmsSagas(), crmSagas(), cartSagas() ]);
}
