import React from 'react'
import Opacity from './Opacity'
import Appearance from './Appearance'
import Color from './Color'
import Alignment from './Alignment'
import Orientation from './Orientation'
import Styles from './Styles'
import Wrapper from '../Wrapper'
import Highlight from './HighLight'
import FontSize from './FontSize'
export default function Typography() {
    return (
        <Wrapper title={"Design"}>
            <FontSize />
            <Color />
            <Highlight />
            <Opacity />
            <Styles />
            <Alignment />
            <Appearance />
            <Orientation />
        </Wrapper >
    )
}
