import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTable, useSortBy } from "react-table";
import "./Table.scss";
import noData from "../../Assets/Icons/no-data.svg";
import get from "lodash/get";
const Table = ({ columns, data, height, onRowClick, isLoading }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  const tableRef = useRef();
  const tableDivRef = useRef();

  let tableStyle = {};
  if (height) {
    tableStyle.height = height;
  }

  return (
    <div className="Table__Container" style={tableStyle}>
      <div className={`Table`} ref={tableDivRef}>
        <table {...getTableProps()} ref={tableRef}>
          <thead>
            {headerGroups.map((headerGroup) => {
              const { key: headRowKey, ...headRowProps } =
                headerGroup.getHeaderGroupProps();
              return (
                <tr key={headRowKey} {...headRowProps}>
                  {headerGroup.headers.map((column, i) => {
                    const isSortingEnabled = get(
                      column,
                      "isSortingEnabled",
                      false
                    );
                    const {
                      getResizerProps,
                      getHeaderProps,
                      getSortByToggleProps,
                      ...restColumn
                    } = column;
                    const { key: headCellKey, ...headCellProps } =
                      isSortingEnabled
                        ? getHeaderProps(getSortByToggleProps)
                        : getHeaderProps();
                    return (
                      <th
                        key={headCellKey}
                        {...headCellProps}
                        className={`${column.headerClassName}`}
                      >
                        <div className="Table__Container__HeaderContainer">
                          {column.render("Header")}{" "}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              );
            })}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, rowIndex) => {
              prepareRow(row);
              const { values, getRowProps, original = {} } = row;
              const { key: rowKey, ...rowProps } = getRowProps();
              return (
                <tr
                  key={rowKey}
                  className={`${get(original, "customClassName", "")}`}
                  {...rowProps}
                >
                  {row.cells.map((cell) => {
                    const { key: cellKey, ...cprops } = cell.getCellProps();
                    return (
                      <td
                        key={cellKey}
                        {...cprops}
                        className={cell.column.className}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {!rows.length && !isLoading && (
              <div className="EmptyTable">
                <img className="EmptyTable__Image" src={noData} alt="" />
                <div className="EmptyTable__Text">No Data</div>
              </div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string,
      accessor: PropTypes.string,
      Cell: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.func,
      ]),
      className: PropTypes.string,
      headerClassName: PropTypes.string,
      isSortingEnabled: PropTypes.bool,
    })
  ),
  goToNextPage: PropTypes.func,
  goToPrevPage: PropTypes.func,
  isLoading: PropTypes.bool,
  loaderColor: PropTypes.oneOfType([
    "ocean-blue",
    "gray",
    "navy-blue",
    "white",
    "black",
  ]),
  data: PropTypes.arrayOf(PropTypes.object),
  height: PropTypes.string,
  maxHeight: PropTypes.string,
  rowIdentifier: PropTypes.string,
  selectedRow: PropTypes.string,
  onRowClick: PropTypes.func,
  updateCellData: PropTypes.func,
  emptyMessage: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  hidePagination: PropTypes.bool,
  fixedCol: PropTypes.bool,
  hoverHighlight: PropTypes.bool,
  showScrollArrows: PropTypes.bool,
};
Table.defaultProps = {
  updateCellData: () => {},
  fixedCol: false,
  hoverHighlight: false,
  showScrollArrows: false,
};

export default Table;
