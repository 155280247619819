import React, { useEffect, useState } from "react";
import "./Pricing.scss";
import {
  PricingPlanCard,
  Collapsable,
  Button,
  Paytm,
  PaymentResult,
} from "../../Components";
import { FAQS } from "../../Assets/Constant";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreator } from "../../Redux/actions/actionsCreator";
import { useHistory } from "react-router-dom";

const mapStateToProps = ({ subscriptions = {}, auth = {} }) => ({
  subscriptions,
  auth,
});

export default function Pricing() {
  const {
    subscriptions: { list: plans = [] },
    auth: { isLoggedIn = false },
  } = useSelector(mapStateToProps);
  const [showPaymentScreen, setShowPaymentScreen] = useState(false);
  const [paymentData, setPaymentData] = useState(null);
  const [paymentResult, setPaymentResult] = useState("");

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(actionsCreator.FETCH_SUBSCRIPTIONS());
  }, []);

  const onSelectPlan = (plan) => {
    if (!isLoggedIn) {
      history.push("/login");
      return;
    }
    const { id } = plan || {};
    const payload = {
      subscription_id: id,
      callback: initiatePayment,
    };
    dispatch(actionsCreator.INITIATE_PAYMENT(payload));
  };

  const initiatePayment = (data) => {
    const { token = "", id = "" } = data || {};
    const plan = plans.find((pl) => pl.id === id);
    const { price = "" } = plan || {};
    const paytmData = {
      order_id: id,
      token,
      amount: price,
      mode_of_payment: "Paytm",
      website: "",
      mid: "",
    };
    setPaymentData(paytmData);
    setShowPaymentScreen(true);
  };

  const handleSuccess = (data) => {
    const { order_id: id = "", paymentStatus = "" } = data || {};
    setPaymentResult(paymentStatus);
    showPaymentScreen(false);
    const payload = {
      subscription_id: id,
    };
    dispatch(actionsCreator.HANDLE_PAYMENT_SUCCESS(payload));
  };

  const handleFailure = (data) => {
    const { order_id: id = "", paymentStatus = "" } = data || {};
    setPaymentResult(paymentStatus);
    showPaymentScreen(false);
    const payload = {
      subscription_id: id,
    };
    dispatch(actionsCreator.HANDLE_PAYMENT_FAILURE(payload));
  };

  const retryPayment = () => {
    setPaymentResult("");
  };

  return (
    <div className="PricingPlan">
      <div className="PricingPlan__Heading">
        Pricing plans that suits you
        <div className="PricingPlan__Heading__Desc">
          We have offer a range of plan offering for you to choose from.
        </div>
        <div className="PricingPlan__Heading__Switch">
          <div className="PricingPlan__Heading__Switch__Item">
            <div className="PricingPlan__Heading__Switch__Item__Sub">
              Monthly
            </div>
            <div className="PricingPlan__Heading__Switch__Item__Text">
              Yearly
            </div>
          </div>
        </div>
      </div>
      <div className="PricingPlan__Cards">
        {plans &&
          plans.length > 0 &&
          plans.map((plan, index) => (
            <div className="PricingPlan__Cards__Card">
              <PricingPlanCard
                key={index}
                onClick={() => onSelectPlan(plan)}
                plan={plan}
              />
            </div>
          ))}
      </div>
      <div className="PricingPlan__FAQ">
        <div className="PricingPlan__FAQ__Head">
          <div className="PricingPlan__FAQ__Head__Heading">
            Frequently Asked Questions
          </div>
        </div>
        <div className="PricingPlan__FAQ__Ques">
          <Collapsable data={FAQS} />
        </div>
      </div>
      {showPaymentScreen && (
        <Paytm
          paymentData={paymentData}
          handleSuccess={handleSuccess}
          handleFailure={handleFailure}
        />
      )}
      <PaymentResult
        type={paymentResult}
        retryPayment={retryPayment}
        onClose={() => setPaymentResult("")}
      />
    </div>
  );
}
