import React, { useEffect, useState } from "react";
// Prop types
import PropTypes from "prop-types";
//Components
import { BrandCategory, Button } from "../../../../Components";

//Style
// import "./CategorySelection.scss";
import { useSelector } from "react-redux";
import { BRAND_CATEGORIES } from "../../../../Assets/Constant";

const mapStateToProps = ({ tenantCategories }) => ({
  tenantCategories,
});

export default function CategorySelection({
  onSubmit,
  onBack,
  onChange,
  formData,
  showBackBtn,
}) {
  const {
    tenantCategories: { list: categories = [] },
  } = useSelector(mapStateToProps);
  const { tenant_category } = formData || {};

  const isDisable = !tenant_category;
  return (
    <>
    <div className="CategorySelection Slide__Animate h-full w-full overflow-hidden">
      <div className="CategorySelection__Info">
        <h2 className="CategorySelection__Info__Head">Select a category</h2>
        <p className="CategorySelection__Info__Subhead">
          You can choose the category of your business
        </p>
      </div>
      <div 
        className="CategorySelection__Cards responsive-cards-3-1 w-full overflowY-scroll"
        style={{
          paddingRight : '10px'
        }}
      >
        {categories && categories.length > 0
          ? categories.map((categoryItem, index) => {
              const isActive = categoryItem.id === tenant_category;
              return (
                <BrandCategory
                  categoryItem={categoryItem}
                  key={index}
                  isActive={isActive}
                  onClick={() =>
                    onChange(categoryItem.id, "tenant_category", true)
                  }
                />
              );
            })
          : null}
      </div>
    </div>
    <Button text="Next" onClick={onSubmit} isDisable={isDisable} />
    {showBackBtn && (
      <div className="RegistrationForm__Back" onClick={onBack}>
        Back
      </div>
    )}
    </>
  );
}

CategorySelection.propTypes = {
  onChange: PropTypes.func,
  formData: PropTypes.object,
  onSubmit: PropTypes.func,
  showBackBtn: PropTypes.bool,
  onBack: PropTypes.func,
};
CategorySelection.defaultProps = {
  onChange: () => {},
  formData: {},
  onSubmit: () => {},
  showBackBtn: false,
  onBack: () => {},
};
