import React, { useEffect } from "react";
import { Table } from "../../Components";
import { orderTableColumns } from "../../Utils/table-utils";
import "./Orders.scss";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreator } from "../../Redux/actions/actionsCreator";

const mapStateToProps = ({ orders = {} }) => ({
  orders,
});
export default function Orders() {
  const {
    orders: { list: orderList = [] },
  } = useSelector(mapStateToProps);
  const columns = orderTableColumns;
  const dispatch = useDispatch();

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = () => {
    dispatch(actionsCreator.FETCH_ORDERS());
  };

  return (
    <div className="Products">
      <Table columns={columns} data={orderList || []} height="100%" />
    </div>
  );
}
