import React from 'react';
import Wrapper from '../../Wrapper';
import FontPicker from './FontPicker';
export default function Font() {

    return (
        <>
            <FontPicker></FontPicker>
        </>

    )
}
