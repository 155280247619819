import React from "react";

// style
import "./Collapsable.scss";
//hook
import { useState } from "react";
// prop type
import PropTypes from "prop-types";

export default function Collapsable({ data }) {
  const [selected, setSelected] = useState(null);
  const toggle = (i) => {
    if (selected == i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <div className="Collapsable">
      <div className="Collapsable__Accordion">
        {data &&
          data.length > 0 &&
          data.map((item, i) => (
            <div className="Collapsable__Accordion__Item">
              <div
                className="Collapsable__Accordion__Item__Title"
                onClick={() => toggle(i)}
              >
                {item.question}
                <span>{selected === i ? "-" : "+"}</span>
              </div>
              <div
                className={`Collapsable__Accordion__Item__${
                  selected === i ? "Content Show" : "Content"
                }`}
              >
                {item.answer}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
Collapsable.propTypes = {
  data: PropTypes.string,
};

Collapsable.defaultProps = {
  data: "",
};
