import React from 'react'
import styles from "./Design.module.scss"


// design import 
import Typography from './Components/Typography/index'
import Border from './Components/Border/index'
import Spacing from './Components/Spacing'
import Size from './Components/Size'
import Layout from './Components/Layout/index'
export default function Design() {
    return (
        <>
            <Size />
            <Typography />
            <Border />
            <Spacing />
            <Layout />

        </>
    )
}
