/* loader reducers */
export const OPEN_LOADER = 'OPEN_LOADER';

/* Login and user actions */
export const SET_LOGIN = 'SET_LOGIN';
export const SHOW_LOGIN = 'SHOW_LOGIN';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const FETCH_USER_DETAILS = 'FETCH_USER_DETAILS';

// Cart 
export const FETCH_CART_DETAILS = 'FETCH_CART_DETAILS';
export const UPDATE_CART_DETAILS = 'UPDATE_CART_DETAILS';
export const SET_CART_DATA = 'SET_CART_DATA';
export const RESET_CART_DATA = 'RESET_CART_DATA';
export const SET_DELIVERY_CHARGE = 'SET_DELIVERY_CHARGE';
export const SET_PREVIOUS_DELIVERY_CHARGE = 'SET_PREVIOUS_DELIVERY_CHARGE';
export const SET_TENANT_DETAILS = 'SET_TENANT_DETAILS';
export const FETCH_TENANT_DETAILS = 'FETCH_TENANT_DETAILS';

// Products
export const FETCH_FAVOURITE_PRODUCTS = 'FETCH_FAVOURITE_PRODUCTS';
export const SET_FAVOURITE_PRODUCTS = 'SET_FAVOURITE_PRODUCTS';
export const RESET_FAVOURITE_PRODUCTS = 'RESET_FAVOURITE_PRODUCTS';
export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_GLOBAL = 'FETCH_CATEGORIES_GLOBAL';
export const SET_CATEGORIES_GLOBAL = 'SET_CATEGORIES_GLOBAL';
export const SET_CATEGORIES = 'SET_CATEGORIES';
export const FETCH_STOCK_DROPDOWN = 'FETCH_STOCK_DROPDOWN'
export const SET_STOCK_DROPDOWN = 'SET_STOCK_DROPDOWN'
export const SET_ALL_PRODUCTS = 'SET_ALL_PRODUCTS'
export const FETCH_ALL_PRODUCTS = 'FETCH_ALL_PRODUCTS'
// Everything delivery

export const SET_EVERYTHING_ITEMS = 'SET_EVERYTHING_ITEMS';
export const RESET_EVERYTHING_ITEMS = 'RESET_EVERYTHING_ITEMS';


// inventories
export const FETCH_INVENTORY = 'FETCH_INVENTORY';
export const SET_INVENTORY = 'SET_INVENTORY';

// Payments
export const UPDATE_WALLET = 'UPDATE_WALLET'