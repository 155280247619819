import React, { useEffect, useState } from "react";
import { AddTicketFields } from "../../../../Api/crmApi";
import { toast } from "react-toastify";
import { color } from "d3";


export const Progress_bar = ({ bgcolor, progress, height }) => {
  const Parentdiv = {
    height: height,
    width: "90%",
    backgroundColor: "whitesmoke",
    borderRadius: 40,
    margin: 50,
    marginLeft:'30px'
  };

  const Childdiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: bgcolor,
    borderRadius: 40,
    textAlign: "right",
  };

  const progresstext = {
    padding: 10,
    color: "white",
    fontWeight: 900,
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv}>
        <span style={progresstext}>{`${progress}%`}</span>
      </div>
    </div>
  );
};

const Checklist = ({ ticket_id, attributes,ticket , setAllTickets, setTickets }) => {
  const [items, setItems] = useState([]);
  const [newItem, setNewItem] = useState("");
  const [isEditing, setIsEditing] = useState(null);
  const [editItem, setEditItem] = useState("");
  console.log(ticket)
  

  useEffect(()=>{

    setItems(ticket?.attributes?.checklist || [])
  },[ticket])
  let index = 0 


  const addItem = () => {
    console.log(items)
    
    if (newItem.trim() !== "") {
      setItems([...items, { name: newItem, checked: false, id:index++ }]);
      setNewItem("");
    }
  };


  const deleteItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
  };


  const saveEdit = (index) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, name: editItem } : item
    );
    setItems(updatedItems);
    setIsEditing(null);
    
  };
  const handleSave = () => {
    // if(items.length < 1) {
    //     toast.error('Please Select The Checkbox');
    //     return;
    // }

    const payload = { 'checklist': [...items], ticket_id: ticket_id };

    AddTicketFields(payload)
        .then((response) => {
            const ticket = response.data.ticket;
            console.log(ticket);
            // console.log('Comment posted successfully:', response.data.ticket.attributes.comments);
            // onAddComment(response.data.ticket);
            // const temp = allti

            setAllTickets((prev) =>
                prev.map((item) =>
                    item.id === ticket.id ? ticket : item
                )
            );
            let j = {}

            setTickets(ticket);
            toast.success('Saved successfully');
        })
        .catch((error) => {
            // Handle any errors here
            console.error('Error saving ticket:', error);
            toast.error('Error saving ticket');
        });
};



  const toggleCheckbox = (index) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, checked: !item.checked } : item
    );
    setItems(updatedItems);
  };


  const getCompletionPercentage = () => {
    const checkedItems = items.filter((item) => item.checked).length;
    return items.length === 0 ? 0 : (checkedItems / items.length) * 100;
  };

  return (
    <>
      <div style={styles.container}>
        <h1 style={{fontSize: '1.5rem'}}>CheckList</h1>

        {items && items.length>0 && <div style={styles.percentage}>
          <Progress_bar
            bgcolor="#000000"
            progress={getCompletionPercentage().toFixed(0)}
            height={20}
          />
        </div>}

        <ul style={styles.list}>
          {items?.map((item, index) => (
            <div style={{display:'flex', alignItems:'center'}}>
            <div key={index} style={styles.item}>
              <div style={styles.itemRow}>
                <input
                  type="checkbox"
                  checked={item.checked}
                  onChange={() => toggleCheckbox(index)}
                  style={styles.checkbox} // Larger checkbox
                />
                <span style={item.checked ? styles.checkedItem : styles.itemText}>
                  {item.name}
                </span>
              </div>

            </div>
                          <button onClick={() => deleteItem(index)} style={styles.button}>
                          Delete
                        </button>
                        </div>
          ))}
        </ul>

        <div style={styles.inputContainer}>
          <input
            type="text"
            placeholder="Add an item"
            value={newItem}
            onChange={(e) => setNewItem(e.target.value)}
            style={styles.input}
          />
          <button onClick={addItem} style={styles.button}>
            Add
          </button>
        </div>
        <button style={styles.savebutton} onClick={handleSave}>Save</button>
      </div>

    </>
  );
};

const styles = {
  container: {
    padding: "20px",
    borderRadius: "15px",
    margin: "1rem 0.3rem",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
  },
  percentage: {
    fontSize: "14px",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  list: {
    listStyleType: "none",
    padding: 0,
    margin: "5px 0",
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "10px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
    padding: "8px", 
    width: '100%',
  },
  itemRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start", 
    width: '100%',
    border:'none'
  },
  checkbox: {
    marginRight: "20px", 
    transform: "scale(2)", 
    width:'8%'
  },
  itemText: {
    flex: 1, 
    textAlign: 'center', 
    fontWeight: "bold",
    fontSize: "16px",
  },
  checkedItem: {
    flex: 1, 
    textAlign: 'center',
    textDecoration: "line-through",
    color: "#999",
    fontWeight: "bold",
    fontSize: "16px",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    width: '100%',
  },
  input: {
    flex: 1, 
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.05)",
  },
  button: {
    padding: "5px 10px", 
    minWidth: "70px", 
    cursor: "pointer",
    backgroundColor: "#000000",
    color: "white",
    border: "none",
    borderRadius: "5px",
    marginLeft: "10px",
  },
  savebutton: {
    maxWidth: '120px',
    minWidth: '120px',
    width: '120px',
    borderRadius: '20px',
    backgroundColor: '#000000',
    color: 'white',
    display: 'block',
    border: '2px solid white',
    padding: '7px',
    margin: '1rem auto',
    fontWeight: 'bold',
    fontSize: '1.1em',
  }
};



export default Checklist;

