import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useCallback } from "react";
// import throttle from 'lodash.throttle';
import axios from "axios";
import { actionsCreator } from "../Redux/actions/actionsCreator";
import { getBaseUrl } from "../Lib/NetworkHandler";
import toast from "react-hot-toast";
import { useLogger } from "../Hooks/useLogger";
import { throttle } from "lodash"
import React, { useContext, createContext } from 'react'

import { ComponentVariantsLists } from "../Utils/template-variants";

const CustomizerContext = createContext(null)

export default function CustomizerProvider({ children }) {

    const { config, selectedElementId, selectedSection, currConfig, tenantTemplateId } = useSelector(state => state?.sectionsList);
    const selectedElementType = selectedElementId ? selectedElementId.split('-')[0] : null;
    const [selectedImage, setSelectedImage] = useState(null);
    const [revertImage, setRevertImage] = useState("");
    const [revertText, setRevertText] = useState("");
    const [cssRules, setCssRules] = useState({});
    const [editText, setEditText] = useState(null);
    const [uploadingImage, setUploadingImage] = useState(false);
    const [currentStyles, setCurrentStyles] = useState({});
    const { logger } = useLogger()
    const [theme, updateTheme] = useState({})
    const dispatch = useDispatch();

    // useEffect(() => { console.log(currentStyles) }, [currentStyles])

    const getImageLink = async (formData) => {
        try {
            const { data } = await axios.post(`${getBaseUrl()}/api/shop/upload_image/`,
                formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }
                ,);
            setSelectedImage(prev => ({ ...prev, src: data }));

        } catch (err) {
            console.error(err);
            toast.error("Error while uploading image");
        }
    }


    const findElementById = (config, targetId, targetType) => {
        const isObject = obj => obj && typeof obj === 'object';

        if (config?.id === targetId) {
            if (targetType === 'image' && config?.src) {
                setSelectedImage(config);
                setRevertImage(config?.src);
            } else if (targetType === 'text' && config?.text) {
                setEditText(config?.text);
                setRevertText(config?.text);
            } else if (targetType === "button" && config?.text) {
                setEditText(config?.text);
                setRevertText(config?.text);
            }
            return config;
        }

        for (let key in config) {
            if (config.hasOwnProperty(key)) {
                const value = config[key];
                if (isObject(value) || Array.isArray(value)) {
                    const foundElement = findElementById(value, targetId, targetType);
                    if (foundElement) {
                        return foundElement;
                    }
                }
            }
        }

        return null;
    }

    function updateStyleById(config, targetId, newStyle) {
        const isObject = obj => obj && typeof obj === 'object';

        // Check if the current config itself is the target
        if (config.id === targetId) {
            if (!isObject(config.style)) {
                config.style = {};
            }
            const newStylesObject = parseCSSStringToObject(newStyle);
            config.style = { ...config.style, ...newStylesObject };
            return true;
        }

        // Iterate through each key-value pair in the config object
        for (let key in config) {
            if (config.hasOwnProperty(key)) {
                const value = config[key];
                // If the value is an object or array, recursively search inside it
                if (isObject(value) || Array.isArray(value)) {
                    const found = updateStyleById(value, targetId, newStyle);
                    if (found) {
                        return true; // Stop further searching once the style is updated
                    }
                }
            }
        }
        return false; // Return false if the target is not found in the current branch
    }

    // Helper function to parse CSS string to an object
    function parseCSSStringToObject(cssString) {
        // Split based on semicolons and convert to key-value pairs
        return cssString.split(';').reduce((acc, rule) => {
            const [key, value] = rule.split(':').map(str => str.trim());
            if (key && value) {
                // Convert to camelCase for React inline style compatibility
                const camelCaseKey = key.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
                acc[camelCaseKey] = value.replace(/['"]/g, ''); // Remove any quotes around values
            }
            return acc;
        }, {});
    }





    const handleCheckContent = (type) => {
        const currentContent = type === "image" ? selectedImage?.src : editText;
        const revertContent = type === "image" ? revertImage : revertText;
        if (currentContent === revertContent) {
            return;
        }
        const fullConfig = { ...config };
        const selectedSectionCopy = { ...selectedSection };

        updateContent(selectedSectionCopy, selectedElementId, currentContent, type);

        const updatedConfig = {
            ...fullConfig,
            [selectedSection?.id]: selectedSectionCopy,
        };

        dispatch(actionsCreator.SET_SECTIONS_LIST({
            config: updatedConfig,
        }));
    };

    function updateContent(config, targetId, newValue, contentType) {
        if (config.id === targetId) {
            if (contentType === 'image' && config.src) {
                config.src = newValue;
            } else if (contentType === 'text' && config.text) {
                config.text = newValue;
            } else if (contentType === "button" && config.text) {
                config.text = newValue;
            }
            return true;
        }

        for (let key in config) {
            if (config.hasOwnProperty(key)) {
                const value = config[key];
                if (typeof value === 'object' && value !== null) {
                    const updated = updateContent(value, targetId, newValue, contentType);
                    if (updated) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                setUploadingImage(true);
                const formData = new FormData();
                formData.append("image", file);
                await getImageLink(formData);
                setUploadingImage(false);
            };
            reader.readAsDataURL(file);
        }
    };
    const handleRevertContent = (type) => {
        const currentContent = type === "image" ? selectedImage?.src : editText;
        const revertContent = type === "image" ? revertImage : revertText;

        if (currentContent === revertContent) {
            return;
        }
        const fullConfig = { ...config };
        const selectedSectionCopy = { ...selectedSection };
        updateContent(selectedSectionCopy, selectedElementId, revertContent, type);
        const updatedConfig = {
            ...fullConfig,
            [selectedSection?.id]: selectedSectionCopy,
        };
        dispatch(actionsCreator.SET_SECTIONS_LIST({
            config: updatedConfig,
        }));

        // Update the state based on the type
        if (type === "image") {
            setSelectedImage(prev => ({ ...prev, src: revertContent }));
        } else if (type === "text") {
            setEditText(prev => ({ ...prev, text: revertContent }));
        }
    };


    function deleteSectionById(config, id) {
        function recursiveDelete(obj) {
            if (Array.isArray(obj)) {
                // If the object is an array, filter out items with the matching ID
                return obj
                    .map(item => {
                        if (typeof item === 'object') {
                            item = recursiveDelete(item); // Recursively process each item
                        }
                        return item;
                    })
                    .filter(item => item && item.id !== id); // Filter out deleted item
            } else if (typeof obj === 'object' && obj !== null) {
                // If the object is a non-null object
                Object.keys(obj).forEach(key => {
                    if (obj[key] && obj[key].id === id) {
                        delete obj[key]; // Delete if the key's value matches the ID
                    } else if (typeof obj[key] === 'object') {
                        obj[key] = recursiveDelete(obj[key]); // Recursively check nested objects
                    }
                });
            }
            return obj;
        }

        // Start the recursive deletion process from the root config
        return recursiveDelete(config);
    }


    const handleAddComponent = (type, variant) => {
        console.log("Add function called")
        const updatedConfig = { ...config };
        // console.log("Before change:", config.homepageConfig.sections.filter(section => section.type !== type))
        const newSection = { ...ComponentVariantsLists?.[type]?.config };
        newSection.layout = variant?.type;
        const allSections = config?.homepageConfig?.sections;
        updatedConfig.homepageConfig.sections = [...allSections, newSection];
        dispatch(actionsCreator.SET_SECTIONS_LIST({
            config: updatedConfig,
        }));
        dispatch(actionsCreator.SET_UPDATE_SECTIONS_LIST({
            config: updatedConfig,
        }));
        toast.success(`Component Added.`);
    };

    function handleRemoveElement() {
        const fullConfig = { ...config };
        const currPageConfig = fullConfig[currConfig];
        deleteSectionById(currPageConfig, selectedElementId);
        const updatedConfig = {
            ...fullConfig,
            [currConfig]: currPageConfig,
        };
        dispatch(actionsCreator.SET_SECTIONS_LIST({
            config: updatedConfig,
        }));
        dispatch(actionsCreator.SET_UPDATE_SECTIONS_LIST({
            config: updatedConfig,
        }));
    }



    useEffect(() => {
        // console.log(selectedElementId, selectedSection)
        let content = findElementById(selectedSection, selectedElementId, selectedElementType);
        // console.log(content)
        if (selectedElementType === "image") {
            setSelectedImage(content);
        } else if (selectedElementType === "text") {
            setEditText(content);
        } else if (selectedElementType === "button") {
            setEditText(content);
        }

        setCurrentStyles(content?.style || {})
        setCssRules({})

    }, [selectedElementId, selectedSection]);


    const handleApplyCssRules = () => {
        const cssString = Object.entries(cssRules)
            .map(([key, value]) => `${key}: ${JSON.stringify(value)};`)
            .join(' ');
        const fullConfig = config;
        const homepageConfig = fullConfig.homepageConfig;
        if (homepageConfig && homepageConfig.sections) {
            const sectionIndex = homepageConfig.sections.findIndex(section => section?.id === selectedSection?.id);

            if (sectionIndex !== -1) {
                const selectedSectionCopy = { ...homepageConfig.sections[sectionIndex] };
                updateStyleById(selectedSectionCopy, selectedElementId, cssString);
                homepageConfig.sections[sectionIndex] = selectedSectionCopy;
            }
        }

        dispatch(actionsCreator.SET_UPDATE_SECTIONS_LIST({ config: fullConfig }))

        logger({ config: fullConfig, type: "css", id: selectedElementId, cssRules: cssRules, tenantTemplateId })
    }

    const onChange = useCallback(
        throttle((newCss) => {
            const target = document.getElementById(selectedElementId);
            if (!target) return;
            Object.keys(newCss).forEach((key) => {
                target.style[key] = newCss[key];
            });
            setCssRules(prev => ({ ...prev, ...newCss }))
        }, 500),
        [selectedElementId]
    );


    useEffect(() => {
        handleApplyCssRules()
    }, [cssRules])









    // to change theme 
    const onThemeChange = useCallback(
        throttle((newCss) => {
            updateTheme({ ...theme, ...newCss });
        }, 500),
        [theme]
    );

    // to handle real time theme update
    useEffect(() => {
        function setTemplateCSSVariable(element, variableName, value) {
            element?.style?.setProperty(variableName, value);
        }
        const rootContainer = document.getElementById('root-container');
        setTemplateCSSVariable(rootContainer, '--font', theme?.font);
        setTemplateCSSVariable(rootContainer, '--primary-color', theme?.primaryColor);
        setTemplateCSSVariable(rootContainer, '--secondary-color', theme?.secondaryColor);
        const fullConfig = config;

        fullConfig.siteConfig = { ...config.siteConfig, "font": theme?.font, "primaryColor": theme?.primaryColor, "secondaryColor": theme?.secondaryColor }
        dispatch(actionsCreator.SET_UPDATE_SECTIONS_LIST({ config: fullConfig }))
        logger({ type: "theme", tenantTemplateId, theme: theme })
    }, [theme]);

    // to initialize the theme according to config
    useEffect(() => {
        updateTheme(config['siteConfig'] || {});
    }, [config])

    return (
        <CustomizerContext.Provider value={{ handleAddComponent, onChange, handleRemoveElement, handleRevertContent, handleImageUpload, handleApplyCssRules, uploadingImage, selectedElementId, selectedElementType, selectedImage, editText, setEditText, handleCheckContent, cssRules, setCurrentStyles, currentStyles, theme, onThemeChange }}> {children} </CustomizerContext.Provider >
    )
}


export const useCustomizer = () => {
    const context = useContext(CustomizerContext);
    if (!context) throw new Error('Context not added');
    return context;
}


