import React, { useEffect, useState, useRef } from 'react';
import styles from "../../Animation.module.scss"
import { useCustomizer } from '../../../../../../Provider/customizerProvider';

export default function Duration() {
    const { onChange } = useCustomizer();
    const [duration, setDuration] = useState(0)

    useEffect(() => { onChange({ "animation-duration": duration }) }, [duration])

    return (

        <div className={styles.itemWrapper} >
            <div className="flex justify-between items-center relative">
                <h4 className={styles.textLabel}>Duration</h4>
                <div className={`flex items-center gap-05rem ${styles.inputContainer}`}>
                    <input
                        type="number"
                        className={`${styles.textInput} flex items-center`}
                        maxLength={10}
                        step="0.01"
                        value={duration}
                        onChange={e => {
                            const value = e.target.value;
                            setDuration(value);

                        }}
                    />
                    <span className={styles.textLabel}>s</span>

                </div>
            </div>
        </div>

    );
}
