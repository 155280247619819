import { LOGOUT_USER, SET_LOGIN, SET_USER_DETAILS } from "../actions/actionTypes";

const initialState = {
  isLoggedIn: !!localStorage.getItem('user'),
  user: JSON.parse(localStorage.getItem('user')) || {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_LOGIN: {
      localStorage.setItem('user', JSON.stringify(payload.user));
      return {
        ...state,
        ...payload,
      };
    }
    case SET_USER_DETAILS: {
      localStorage.setItem('user', JSON.stringify(payload));
      return {
        ...state,
        ...payload,
      };
    }
    case LOGOUT_USER:
      localStorage.removeItem('user');
      return {
        ...state,
        isLoggedIn: false,
      };
    default:
      return state;
  }
};
