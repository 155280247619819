import { LabelledInput, Modal } from "../../../../Components";
import React, { useEffect, useRef, useState } from "react";
import Comments from "./Comments";
import { at } from "lodash";
import Checklist from "./CheckList";
import {
  IoIosAttach,
  IoAddOutline,
  IoIosCheckbox,
  IoIosCheckboxOutline,
} from "react-icons/io";
import {
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
} from "react-icons/md";
import { errorMsg } from "../../../../Utils";
import { FiMinus, FiPlus, FiChevronDown } from "react-icons/fi";
import { FaCopy } from "react-icons/fa";
import "./Attributes.scss";

import { getBaseUrl } from "../../../../Lib/NetworkHandler";
import { ImCross } from "react-icons/im";
import { deleteAttachments, fetchCombinationItems, placeOrder, place_order, edit_order, delete_order } from "../../../../Api/crmApi";
import { toast } from "react-toastify";
import useDebounce from "../../../../Utils/debounce-utils";
import HoverComponent from "../../../Cms/Components/HoverComponent/HoverComponent";
import { CiSearch } from "react-icons/ci";
import Select from "react-select";
import Field from "../../Ticket/Fields/Field";
import { RxCross1 } from "react-icons/rx";
// import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import { checkActionExists } from "../../Ticket/Fields/Field";
import { pdfImage, showAttachmnet, showChallan, showCheckList, showComment } from "../../Ticket/Constans";
export const CombinationBox = ({
  id,
  data,
  handleFormChange,
  changeQuantity,
  selectable,
  isOrderPresent,
  selectedTicket
}) => {

  const isDisabled = selectedTicket?.invoice?.invoice_url;
  const [inputValue, setInputValue] = useState(Number.parseInt(data.quantity) || 0);

  useEffect(() => {
    // Synchronize inputValue with data.quantity if needed
    setInputValue(Number.parseInt(data.quantity) || 0);
  }, [data.quantity]);

  const handleInputChange = (e) => {
    const newValue = Number.parseInt(e.target.value) || 0;

    // Define the condition to prevent update
    const shouldUpdate = true; // Replace this with your condition

    if (changeQuantity && shouldUpdate) {
      setInputValue(newValue); // Update local state
      handleFormChange(id, 'setValue', newValue); // Call handleFormChange
    }
  };
  return (
    <div>
      {/* If the `challan_url` and `invoice_url` conditions are met */}
      {(!selectedTicket?.challan_url && !selectedTicket?.invoice?.invoice_url) ||
        (selectedTicket?.challan_url && selectedTicket?.invoice?.invoice_url) ? (
        <div className="border w-full py-1 px-2 round-md flex responsive-flex-row gap-10" style={{ marginBottom: "0.5rem" }}>
          <div className="flex flex-row gap-10 items-center w-full">
            {/* Add validation: Only show checkbox button if invoice_url is not present */}
            {!selectedTicket?.invoice?.invoice_url && (
              data.selected ? (
                <button onClick={() => handleFormChange(id, "toggleSelect")}>
                  <MdOutlineCheckBox color="red" size="1.6rem" />
                </button>
              ) : (
                <button onClick={() => handleFormChange(id, "toggleSelect")}>
                  <MdOutlineCheckBoxOutlineBlank size="1.6rem" />
                </button>
              )
            )}
            <span className="w-full" style={{ whiteSpace: "normal", fontSize:'13px' }}>
              {data.title}
            </span>
          </div>

          <span className="nowrap flex items-center" style={{ width: "6rem" }}>
            {/* Show input when both challan_url and invoice_url are present */}
            {selectedTicket?.challan_url && selectedTicket?.invoice?.invoice_url ? (
              <>
                <input
                  type="number"
                  className="text-center"
                  style={{ padding: 0 }}
                  value={Number.parseInt(data.quantity)}
                  readOnly // Mark as read-only when both URLs are present
                  onChange={(e) => {
                    if (changeQuantity) {
                      handleFormChange(id, 'setValue', e.target.value);
                    }
                  }}
                />
              </>
            ) : (
              <>
                {/* Controls when neither challan_url nor invoice_url are present */}
                <button onClick={() => handleFormChange(id, "decrement")} className="flex-1">
                  <FiMinus color="red" size="1.3rem" />
                </button>
                <input
                  type="number"
                  className="text-center"
                  style={{ padding: 0 }}
                  value={Number.parseInt(data.quantity)}
                  disabled={selectedTicket?.challan_url && selectedTicket?.invoice?.invoice_url}
                  onChange={(e) => {
                    if (changeQuantity) {
                      handleFormChange(id, 'setValue', e.target.value);
                    }
                  }}
                />
                {!selectedTicket?.challan_url && (
                  <button onClick={() => handleFormChange(id, "increment")} className="flex-1">
                    <FiPlus color="green" size="1.3rem" />
                  </button>
                )}
              </>
            )}
          </span>
        </div>
      ) : (
        <div>
          <div className="accordion-item">
            <h2 className="accordion-header" id={`heading-${id}`}>
              <button
                className={`accordion-button ${data?.inventories?.length === 0 ? 'disabled' : ''}`}
                type="button"
                data-bs-toggle={data?.inventories?.length > 1 ? 'collapse' : ''}
                data-bs-target={data?.inventories?.length > 1 ? `#collapse-${id}` : ''}
                aria-expanded={data?.inventories?.length > 1 ? 'true' : 'false'}
                aria-controls={data?.inventories?.length > 1 ? `collapse-${id}` : ''}
                onClick={(e) => {
                  if (data?.inventories?.length <= 1) {
                    e.preventDefault();
                    e.stopPropagation();
                  }
                }}
              >
                <div className="flex flex-row gap-2 items-center w-full">
                  {data.selected ? (
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents accordion from toggling
                        handleFormChange(id, 'toggleSelect');
                      }}
                    >
                      <MdOutlineCheckBox color="green" size="1.6rem" />
                    </button>
                  ) : (
                    <button
                      onClick={(e) => {
                        e.stopPropagation(); // Prevents accordion from toggling
                        handleFormChange(id, 'toggleSelect');
                      }}
                    >
                      <MdOutlineCheckBoxOutlineBlank size="1.6rem" />
                    </button>
                  )}

                  <span className="flex-1" style={{ marginLeft: "10px", fontSize: "13px" }}>
                    {data.title}
                  </span>

                  <span
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents accordion from toggling
                      const currentQuantity = Number.parseInt(data.quantity) || 0;
                      const newQuantity = Math.max(0, currentQuantity - 1); // Prevent quantity from going negative
                      handleFormChange(id, 'setValue', newQuantity);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <FiMinus color="red" size="1.3rem" />
                  </span>

                  <input
                    type="number"
                    className="text-center"
                    style={{
                      padding: 0,
                      width: '2rem', // Adjusted width for consistency
                      margin: '0 0.5rem', // Added margin for spacing
                    }}
                    value={Number.parseInt(data.quantity) || 0}
                    disabled={false}
                    onChange={(e) => {
                      const shouldUpdate = Number.parseInt(e.target.value) < 100;

                      if (shouldUpdate) {
                        const newValue = Number.parseInt(e.target.value) || 0;
                        handleFormChange(id, 'setValue', newValue);
                      }
                    }}
                  />

                  <span
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents accordion from toggling
                      const currentQuantity = Number.parseInt(data.quantity) || 0;
                      const newQuantity = currentQuantity + 1;
                      handleFormChange(id, 'setValue', newQuantity);
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <FiPlus color="green" size="1.3rem" />
                    {data?.inventories?.length > 1 && (
                      <button
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents accordion from toggling
                        }}
                        className="flex-1"
                      >
                        <FiChevronDown color="blue" size="1.3rem" />
                      </button>
                    )}
                  </span>

                </div>
              </button>
            </h2>

            {/* Display inventory data */}
            {data?.inventories?.length > 1 && (
              <div
                id={`collapse-${id}`}
                className={`accordion-collapse collapse`}
                aria-labelledby={`heading-${id}`}
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div className="new-content" style={{ padding: '1rem' }}>
                    {/* Inventory data code here */}
                    {/* Header Row */}
                    <div className="inventory-header" style={{ display: 'flex', marginBottom: '0.5rem', fontWeight: 'bold' }}>
                      <div className="header-item" style={{ flex: 1 }}>Batch Number</div>
                      <div className="header-item" style={{ flex: 1 }}>Serial Number</div>
                      <div className="header-item" style={{ flex: 1 }}>Quantity</div>
                    </div>

                    {/* Data Rows */}
                    {data.inventories.map((inventory) => (
                      <div key={inventory?.batch_number || inventory?.serial_number} className="inventory-row" style={{ display: 'flex', marginBottom: '0.5rem', alignItems: 'center' }}>
                        <div className="inventory-cell" style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                          {inventory?.selected ? (
                            <button
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents accordion from toggling
                                handleFormChange(id, 'sellableInventory', inventory?.id);
                              }}
                            >
                              <MdOutlineCheckBox color="green" size="1.6rem" />
                            </button>
                          ) : (
                            <button
                              onClick={(e) => {
                                e.stopPropagation(); // Prevents accordion from toggling
                                handleFormChange(id, 'sellableInventory', inventory?.id);
                              }}
                            >
                              <MdOutlineCheckBoxOutlineBlank size="1.6rem" />
                            </button>
                          )}
                          <span className="combination-box-batch-number">
                            {inventory?.batch_number}
                          </span>
                        </div>
                        <div className="inventory-cell" style={{ flex: 1 }}>
                          <span className="combination-serial-number">
                            {inventory?.serial_number}
                          </span>
                        </div>
                        <div className="inventory-cell" style={{ flex: 1, display: 'flex', alignItems: 'center', gap: '8px' }}>
                          <span
                            onClick={(e) => {
                              e.stopPropagation(); // Prevents accordion from toggling
                              const currentQuantity = inventory?.quantity || 0; // Use 0 if quantity is undefined
                              const newQuantity = Math.max(0, currentQuantity - 1);
                              handleFormChange({ item: id, inv: inventory?.id }, 'sellableInventoryQuantity', newQuantity);
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <FiMinus color="red" size="1.3rem" />
                          </span>

                          <input
                            className="quantity-input"
                            type="number"
                            style={{
                              padding: 0,
                              height: '1rem',
                              textAlign: 'center',
                              width: '2rem',
                              margin: '0 1px',
                            }}
                            value={inventory?.quantity || 0} // Use 0 if quantity is undefined
                            disabled={selectedTicket?.challan_url && selectedTicket?.invoice?.invoice_url}
                            onChange={(e) => {
                              const newQuantity = Math.max(0, Number.parseInt(e.target.value, 10) || 0);
                              handleFormChange({ item: id, inv: inventory?.id }, 'sellableInventoryQuantity', newQuantity);
                            }}
                          />

                          <span
                            onClick={(e) => {
                              e.stopPropagation(); // Prevents accordion from toggling
                              const currentQuantity = inventory?.quantity || 0; // Use 0 if quantity is undefined
                              const newQuantity = currentQuantity + 1;
                              handleFormChange({ item: id, inv: inventory?.id }, 'sellableInventoryQuantity', newQuantity);
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <FiPlus color="green" size="1.3rem" />
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {/* Show the new content with inventory data inside when inventory count is less than 2 and greater than 0 */}
            {data?.inventories?.length > 0 && data?.inventories?.length < 2 && (
              <div className="new-content" style={{ padding: '1rem' }}>
                {/* Inventory data code here */}
                {/* Header Row */}
                <div className="inventory-header" style={{ display: 'flex', marginBottom: '0.5rem', fontWeight: 'bold' }}>
                  <div className="header-item" style={{ flex: 1 }}>Batch Number</div>
                  <div className="header-item" style={{ flex: 1 }}>Serial Number</div>
                  <div className="header-item" style={{ flex: 1 }}>Quantity</div>
                </div>

                {/* Data Rows */}
                {data.inventories.map((inventory) => (
                  <div key={inventory?.batch_number || inventory?.serial_number} className="inventory-row" style={{ display: 'flex', marginBottom: '0.5rem', alignItems: 'center' }}>
                    <div className="inventory-cell" style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                      {inventory?.selected ? (
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevents accordion from toggling
                            handleFormChange(id, 'sellableInventory', inventory?.id);
                          }}
                        >
                          <MdOutlineCheckBox color="green" size="1.6rem" />
                        </button>
                      ) : (
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevents accordion from toggling
                            handleFormChange(id, 'sellableInventory', inventory?.id);
                          }}
                        >
                          <MdOutlineCheckBoxOutlineBlank size="1.6rem" />
                        </button>
                      )}
                      <span className="combination-box-batch-number">
                        {inventory?.batch_number}
                      </span>
                    </div>
                    <div className="inventory-cell" style={{ flex: 1 }}>
                      <span className="combination-serial-number">
                        {inventory?.serial_number}
                      </span>
                    </div>
                    <div className="inventory-cell" style={{ flex: 1, display: 'flex', alignItems: 'center', gap: '8px' }}>
                      <span
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents accordion from toggling
                          const currentQuantity = inventory?.quantity || 0; // Use 0 if quantity is undefined
                          const newQuantity = Math.max(0, currentQuantity - 1);
                          handleFormChange({ item: id, inv: inventory?.id }, 'sellableInventoryQuantity', newQuantity);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <FiMinus color="red" size="1.3rem" />
                      </span>

                      <input
                        className="quantity-input"
                        type="number"
                        style={{
                          padding: 0,
                          height: '1rem',
                          textAlign: 'center',
                          width: '2rem',
                          margin: '0 -1px',
                        }}
                        value={inventory?.quantity || 0} // Use 0 if quantity is undefined
                        disabled={selectedTicket?.challan_url && selectedTicket?.invoice?.invoice_url}
                        onChange={(e) => {
                          const newQuantity = Math.max(0, Number.parseInt(e.target.value, 10) || 0);
                          handleFormChange({ item: id, inv: inventory?.id }, 'sellableInventoryQuantity', newQuantity);
                        }}
                      />

                      <span
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents accordion from toggling
                          const currentQuantity = inventory?.quantity || 0; // Use 0 if quantity is undefined
                          const newQuantity = currentQuantity + 1;
                          handleFormChange({ item: id, inv: inventory?.id }, 'sellableInventoryQuantity', newQuantity);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <FiPlus color="green" size="1.3rem" />
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

        </div>
      )}
    </div>



  );
};

const Attribute = ({
  attributes,
  closeModal,
  ticketId,
  allTickets,
  setAllTickets,
  updateTicket,
  fileUrls,
  setFileUrls,
  comments,
  onAddComment,
  selectedTicket,
  ticketLayout,
  execution
}) => {

  const [files, setFiles] = useState([]);
  const [fileData, setFileData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [combinationData, setCombinationData] = useState([]);
  const [tickets, setTickets] = useState({});
  const [selectedCombination, toggleSelectedCombination] = useState({
    ticket_id: tickets?.id,
    combination_title: "",
    combination_id: "",
    combination_items: [],
    inventories: []
  });

  useEffect(() => {
    const fetchCombinationData = async () => {
      try {
        const res = await fetchCombinationItems();
        setCombinationData(res.data.data);
      } catch (e) {
        console.error(e)
      }
    }

    setTickets(allTickets.filter(item => item.id == ticketId)[0]);
    fetchCombinationData();


  }, [])
  const [isOrderPresent, toggleIsOrderPresent] = useState(false);
  const [orderCreated, toggleOrderCreated] = useState(false);
  const [invoiceGenerated, toggleInvoiceGenerated] = useState(false)

  const copyHandler = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Text copied successfully");
      })
      .catch((err) => {
        console.error("Failed to copy text to clipboard:", err);
      });
  };


  useEffect(() => {

    if (tickets != {}) {
      toggleSelectedCombination((prev) => ({ ...prev, combination_items: [] }))
      tickets?.order_items?.length > 0 && tickets?.order_items?.map((item) => {
        if (!selectedCombination?.combination_items) {
          toggleSelectedCombination((prev) => ({ ...prev, combination_items: [] }))
        }
        toggleIsOrderPresent(true)

        toggleSelectedCombination((prev) => ({
          ...prev,
          combination_items: [
            ...prev.combination_items,
            {
              id: item.id,
              title: item.title || item.product_details.product_name,
              quantity: item.quantity,
              selected: false,
              object_id: item.product_id,
              inventories: item.inventories
            },
          ],
          order_id: item.order_id
        }));


      })

    }
  }, [tickets])


  const [combinationSearchData, setCombinationSearchData] = useState([]);



  const searchRef = useRef();

  const handleAttachClick = (ticket) => {
    const fileInput = document.getElementById(`file-input-${ticket.id}`);
    if (fileInput) {
      fileInput.click();
    }
  };


  const imageDeleteHandler = (attachment_id, ticket_id) => {
    const payload = {
      attachment_id: attachment_id,
      ticket_id: ticket_id,
    };

    deleteAttachments(payload)
      .then((res) => {
        updateTicket(res.data.ticket);
        setTickets(res?.data?.ticket)
        // const temp = allTickets.filter(item => item.id !== ticket_id)
        // setTickets(...temp, res?.ticket)
        toast.success("attachment deleted successfully");
      })
      .catch((err) => {
        console.log(err)
        toast.error("Error while deleting image");
      });
  };

  const handleFileSelect = async (e, ticket) => {
    const filesuploaded = e.target.files;
    setFiles([...files, filesuploaded[0]]);

    const formData = new FormData();
    formData.append("attachment_file", filesuploaded[0]);
    formData.append("ticket", ticket.id);

    try {
      const response = await fetch(
        `${getBaseUrl()}/api/crm/ticket/attachments/`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload files");
      }

      const data = await response.json();
      updateTicket(data.ticket);
      setTickets(data.ticket);
      setAllTickets((prev) =>
        prev.map((item) =>
          item.id === ticket.id ? data.ticket : item
        )
      );
      // setFileUrls([...fileUrls, data.ticket.attributes.attachments[0].url]);

      // const data = await response.json();
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };

  const debouncedQuery = useDebounce(searchQuery, 400);

  useEffect(() => {
    const handleDebounceFilter = () => {
      const data = combinationData.filter((item) =>
        item.title.toLowerCase().includes(debouncedQuery.toLowerCase())
      );
      setCombinationSearchData(data);
    };

    handleDebounceFilter();
    // handleCombinationChange()
  }, [debouncedQuery]);

  const handleCombinationChange = (id) => {
    const data = combinationSearchData.filter((item) => item.id == id)[0];

    if (data.combination_items.length <= 0) {
      toast.info("There is no combination item for this")
      return;
    }

    setCombinationData((prev) => {
      return prev.filter((item) => (item.id = id));
    });

    toggleSelectedCombination((prev) => {
      const prev_combination_items = data.combination_items;
      const new_combination_items = prev_combination_items.map((item) => {
        return {
          ...item,
          selected: true,
        };
      });

      return {
        ticket_id: tickets.id,
        combination_id: data.id,
        combination_title: data.title,
        combination_items: new_combination_items,
      };
    });
  };

  const handleFormChange = (id, key, value) => {
    if (key === "toggleSelect") {
      // Toggle selection state
      toggleSelectedCombination((prev) => {
        const newItemList = prev.combination_items.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              selected: !item.selected,
            };
          } else return item;
        });

        return {
          ...prev,
          combination_items: newItemList,
        };
      });
    } else if (key === "increment" || key === "decrement") {
      // Handle increment and decrement logic
      toggleSelectedCombination((prev) => {
        const newItemList = prev.combination_items.map((item) => {
          if (item.id === id) {
            const updatedInventories = item.inventories.map((inv) => {
              if (inv.id === value) {
                const currentQuantity = parseFloat(inv.quantity) || 0;
                const newQuantity = key === "increment" ? currentQuantity + 1 : Math.max(0, currentQuantity - 1);
                return { ...inv, quantity: newQuantity };
              } else {
                return inv;
              }
            });

            const totalQuantity = updatedInventories.reduce((sum, inv) => {
              return sum + parseFloat(inv.quantity || 0);
            }, 0);

            return {
              ...item,
              inventories: updatedInventories,
              quantity: totalQuantity,
            };
          } else {
            return item;
          }
        });

        return {
          ...prev,
          combination_items: newItemList,
        };
      });
    } else if (key === "setValue") {
      // Set specific value
      toggleSelectedCombination((prev) => {
        const newItemList = prev.combination_items.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              quantity: value,
            };
          } else return item;
        });

        return {
          ...prev,
          combination_items: newItemList,
        };
      });
    } else if (key === "sellableInventory") {
      // Toggle inventory selection
      toggleSelectedCombination((prev) => {
        const newItemList = prev.combination_items.map((item) => {
          if (item.id === id) {
            const updatedInventories = item.inventories.map((inv) => {
              if (inv.id === value) {
                return { ...inv, selected: !inv.selected };
              } else {
                return inv;
              }
            });

            const anySelected = updatedInventories.some((inv) => inv.selected);

            return {
              ...item,
              inventories: updatedInventories,
              selected: anySelected,
            };
          } else {
            return item;
          }
        });

        return {
          ...prev,
          combination_items: newItemList,
        };
      });
    } else if (key === "sellableInventoryQuantity") {
      // Update inventory quantity
      toggleSelectedCombination((prev) => {
        const newItemList = prev.combination_items.map((item) => {
          if (item.id === id["item"]) {
            const updatedInventories = item.inventories.map((inv) => {
              if (inv.id === id["inv"]) {
                return { ...inv, quantity: value };
              } else {
                return inv;
              }
            });

            const totalQuantity = updatedInventories.reduce((sum, inv) => {
              return sum + parseFloat(inv.quantity || 0);
            }, 0);

            return {
              ...item,
              inventories: updatedInventories,
              quantity: totalQuantity,
            };
          } else {
            return item;
          }
        });

        return {
          ...prev,
          combination_items: newItemList,
        };
      });
    }
  };



  const [entityId, setEntityId] = useState(null);
  // const [selectedCombination, setSelectedCombination] = useState(null); // Assume you have logic to set this

  // Function to update entityId
  const updateEntityId = (id) => {
    setEntityId(id);
  };
  const [orderId, setOrderId] = useState(null);
  const [selectedTicket1, setSelectedTicket1] = useState(null);
  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await fetch('api/tickets');
        const data = await response.json();
        setAllTickets(data);
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    };

    fetchTickets();
  }, []);
  const handlePlaceOrder = async () => {
    if (!selectedCombination || !selectedCombination.combination_items) {
      toast.error("No combination items available.");
      return;
    }
    const foundTicket = allTickets.find(ticket => ticket.id === ticketId);
    if (!foundTicket) {
      console.log("Ticket not found.");
      return;
    }
    setSelectedTicket1(foundTicket);
    const orderItems = selectedCombination.combination_items.filter(
      (item) => item.selected
    );

    const entityId = foundTicket.attributes.ticket_fields?.entity_id;
    if (orderItems.length <= 0) {
      toast.info("Please select at least one item.");
      return;
    }

    const quantityCheckOrderList = orderItems.filter(
      (item) => item.quantity > 0
    );
    if (quantityCheckOrderList.length !== orderItems.length) {
      toast.info("Please set the quantity");
      return;
    }

    const orderList = quantityCheckOrderList.map((item) => ({
      product_id: item.object_id,
      quantity: parseFloat(item.quantity) // Ensure quantity is a number
    }));

    const orderPayload = {
      ticket_id: selectedCombination.ticket_id,
      order_list: orderList,
      entity_id: entityId // Pass the extracted entity_id here
    };
    try {
      const res = await place_order(orderPayload);

      // Update the tickets list with the newly created order details
      setAllTickets((prev) =>
        prev.map((item) =>
          item.id === selectedCombination.ticket_id
            ? res.data.data
            : item
        )
      );

      // Mark the order as created and update the order ID
      toggleOrderCreated(true);
      setOrderId(res.data.data.id);

      // Show success message
      // toast.success(`OrderId ${res.data.data.id} is placed successfully`);
      toast.success(res?.data?.message, {
        autoClose: 2500,
      });

      // Check if the invoice URL is present and update the state accordingly
      if (res.data.data.invoice?.invoice_url) {
        setTickets((prev) => ({
          ...prev,
          invoice: {
            ...prev.invoice,
            invoice_url: res.data.data.invoice.invoice_url
          }
        }));
      } else {
        // If there's no invoice URL, just update the ticket data without changing the invoice_url
        setTickets((prev) => ({
          ...prev,
          ...res.data.data,
        }));
      }
    } catch (err) {
      const errMsg = errorMsg(err);
      toast.error(errMsg, {
        autoClose: 5000,
      });
    }
  };

  // Expose the function to be called by Field component
  window.updateEntityId = updateEntityId;


  const editAndPlaceOrder = async () => {
    const orderItems = selectedCombination.combination_items.filter(
      (item) => item.selected
    );
    if (orderItems.length <= 0) {
      toast.info("Please select at least one item.");
      return;
    }

    const quantityCheckOrderList = orderItems.filter(
      (item) => item.quantity > 0
    );
    if (quantityCheckOrderList.length !== orderItems.length) {
      toast.info("Please set the quantity");
      return;
    }

    const orderList = quantityCheckOrderList.map((item) => {
      return {
        product_id: item.object_id,
        quantity: item.quantity,
        inventory: item.inventories.filter((prev) => prev?.selected),
      };
    });

    const Payload = {
      ticket_id: selectedCombination.ticket_id,
      order_list: orderList,
      order_id: tickets.order_items[0]?.order_id || tickets.order_items[0]?.object_id,
    };

    try {
      const res = await edit_order(Payload);

      setAllTickets((prev) => {
        return prev.map((item) => {
          if (item.id === selectedCombination.ticket_id) {
            return res.data.data;
          }
          return item;
        });
      });

      setTickets(res.data.data);

      toggleOrderCreated(true);
      toggleInvoiceGenerated(true);
      toast.success(res?.data?.message, {
        autoClose: 3000,
      });
    } catch (err) {
      const errMsg = errorMsg(err);
      toast.error(errMsg, {
        autoClose: 5000,
      });
    }
  };

  const handleDeleteOrder = async () => {
    const payload = {
      order_id: tickets.order_items[0]?.order_id || tickets.order_items[0]?.object_id,
    };
    try {
      const res = await delete_order(payload);

      toggleSelectedCombination((prev) => ({
        ...prev,
        combination_items: [],
      }));
      setTickets((prev) => ({
        ...prev,
        order_items: [],
      }));

      toggleIsOrderPresent(false);
      toast.success(res?.data?.message, { autoClose: 3000 });
    } catch (err) {
      const errMsg = errorMsg(err);
      toast.error(errMsg, {
        autoClose: 5000,
      });
    }
  };
  const [containerHeight, setContainerHeight] = useState('70vh');
  const containerRef = useRef(null);

  useEffect(() => {
    // Update container height based on the number of search results
    if (containerRef.current) {
      const newHeight = combinationSearchData.length > 0
        ? `${Math.min(70 + combinationSearchData.length * 5, 80)}vh`
        : '70vh';

      setContainerHeight(newHeight);
    }
  }, [combinationSearchData]);
  const [showImg, setshowImg] = useState(false)
  const [currentImg, setcurrentImg] = useState('')
  const ImageDisplayer = (url)=>{
    setcurrentImg(url)
    setshowImg(true)

  }
  const ShowImage = ({ imgSrc, onClose }) => {
    const fileExtension = imgSrc.split('.').pop().toLowerCase();

    // Check if the file is an image
    const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);

    return (

      <div className="show-image-modal">
        <div className="image-container">
          

          <img src= { isImage? imgSrc : pdfImage } alt="Displayed"  style={{display:'flex', position:'relative'}}/>
          <button className="close-button" style={{display:'flex', position:'absolute'}} onClick={onClose}>X</button>
          
        </div>
      </div>
      


    );

  };
  return (
    <>
    <Modal 
    width={'600px'}
    // style={{maxwidth:'54rem', minwidth:'54rem'}}
    show 
    onClose={closeModal} 
    style={{ float: "right", maxWidth: '54rem', minWidth: '54rem' , overflowY: 'hidden', padding:'29px'}}

    title={
      !tickets?.attributes?.attachments || tickets?.attributes?.attachments?.length === 0 
        ? `${tickets?.ticket_id} | ${tickets?.title}` 
        :  <div style={{ position: 'relative', 
        width: '100%', 
        maxWidth: '54rem', // Ensuring the modal width is fixed
        minWidth: '54rem', 
        padding: '30px !important',
        overflow: 'hidden',   margin:'-2.8rem',        marginBottom:'0.4rem',
        marginRight:'-10rem',  }}> <img src={tickets?.attributes?.attachments?.[0]?.url} style={{width:'90rem' , height:'8rem', margin:'-2.2rem' , marginTop:'-1.3rem', 
          padding: '0',
          display: 'block',
          border: 'none',
          display:'flex',
          marginBottom:'0.4rem',
          marginRight:'-0.22rem',
          objectFit:'cover',
          zIndex:0,
          objectPosition: 'center',
        position:'relative'}}  alt="Ticket Attachment" /> </div>
    }
  >
    
          {showImg && (
        <ShowImage 
          imgSrc={currentImg} 
          onClose={() => setshowImg(false)} // Pass a function to close the modal
        />
      )}
  
      <div
        className="attribute-modal"
        style={{
          height: "75vh",
          width: '100%',
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <div className="ticket-searchbar relative fields-wrapper">

          <Field
            selectedTicket={tickets}
            setTickets={setAllTickets}
            ticketLayout={ticketLayout}
            execution={execution}
            attributes={attributes} ticket_id={ticketId} ticket={tickets}
          />
        </div>

        {/* Search bar */}

        {checkActionExists(execution, showCheckList) &&  <Checklist attributes={attributes} ticket_id={ticketId} ticket={tickets} setAllTickets={setAllTickets} setTickets={setTickets}/>}


        {checkActionExists( execution, showChallan) && <div ref={containerRef} className="ticket-searchbar relative" style={{ height: "auto" }}>
          <div className="d-flex justify-content-between">
            <h4 style={{ margin: "5px 0" }}>
              {tickets?.invoice?.invoice_url
                ? `Invoice Items of order id: ${tickets?.order_items?.[0]?.order_id || tickets?.order_items?.[0]?.object_id}`
                : tickets?.challan_url && tickets?.order_items?.[0]?.order_id
                  ? `Challan Items of order id: ${tickets.order_items[0].order_id}`
                  : "Create Challan"
              }
            </h4>
          </div>

          {tickets?.challan_url &&
            tickets.challan_url.trim() &&
            tickets.challan_url !== 'https://incitoindia.com/challan/' &&
            !tickets.challan_url.endsWith('null') &&
            tickets.order_items?.[0]?.order_id && (
              <div className="ticket-invoice-wrapper ticket-invoice-div-wrapper" style={{ padding: "5px", }}>
                <div style={{ display: 'flex', alignItems: 'center', gap: "5px" }}>
                  {tickets.challan_url && <b>Challan URL : </b>}
                  <div className="assigned_name">
                    {tickets.challan_url &&
                      < p className="invoice-url">
                        {tickets.challan_url}
                      </p>
                    }
                    <div>
                      <FaCopy onClick={() => copyHandler(tickets?.challan_url)} className="copy-icon" />
                    </div>
                  </div>
                </div>
              </div>
            )
          }

          {tickets.invoice?.invoice_url && (
            <div className="ticket-invoice-wrapper ticket-invoice-div-wrapper" style={{ padding: "5px", marginBottom: '0.5rem' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: "5px" }}>
                <b>{tickets.invoice?.invoice_url && `Invoice Url : `}</b>
                <div className="assigned_name">
                  <div className="invoice-url">
                    {tickets.invoice?.invoice_url &&
                      <p>{tickets.invoice?.invoice_url}</p>
                    }
                  </div>
                  <div>
                    <FaCopy onClick={() => copyHandler(tickets.invoice?.invoice_url)} className="copy-icon" />
                  </div>
                </div>
              </div>
            </div>
          )}

          {tickets?.challan_url && selectedCombination?.combination_items?.length > 0 ? (
            <div>
              {selectedCombination.combination_items.map((item) => (
                <CombinationBox
                  key={item.id}
                  id={item.id}
                  data={{
                    title: item.title || item.product_details.product_name,
                    quantity: item.quantity,
                    selected: item?.selected,
                    inventories: item?.inventories
                  }}
                  selectable={true}
                  changeQuantity={false}
                  handleFormChange={handleFormChange}
                  isOrderPresent={isOrderPresent}
                  selectedTicket={tickets}
                />
              ))}
              {/* If the order is not created, show the "Generate Challan" button */}
              <div className="upload-button-wrapper">
                {/* Show Delete Order and Generate Invoice Buttons if order ID is present */}
                {tickets.order_items?.[0]?.order_id && (
                  <>
                    <button className="btn-md btn-active-attribute delete-btn" onClick={handleDeleteOrder}>
                      Delete Order
                    </button>
                    {!tickets.invoice?.invoice_url && checkActionExists(execution && 'CREATE_INVOICE') && (
                      <button className="btn-md btn-active-attribute Generate-inovice-btn" onClick={editAndPlaceOrder}>
                        Generate Invoice
                      </button>
                    )}
                  </>
                )}

                {/* Show Generate Challan Button only if order ID is not present and order is not created */}
                {!tickets.order_items?.[0]?.order_id && !tickets.invoice?.invoice_url && !orderCreated && (
                  <button className="btn-md btn-active-attribute" onClick={handlePlaceOrder}>
                    Generate Challan
                  </button>
                )}
              </div>


            </div>
          ) : (
            <>
              {/* Display Create Challan part if no order is created */}
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  padding: "10px",
                  paddingBottom: "1rem",
                  height: "auto" // Ensures the height adjusts based on content
                }}
              >
                {/* Search Bar */}
                {!orderCreated && (
                  <div className="flex gap-10 items-center w-full" style={{ marginTop: "10px" }}>
                    <LabelledInput
                      className={"w-full"}
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                        toggleSelectedCombination((prev) => ({
                          combination_title: "",
                          combination_id: "",
                          combination_items: [],
                          ticket_id: prev.ticket_id,
                          inventories: []
                        }));
                      }}
                      placeholder="Search..."
                    />
                  </div>
                )}

                {/* Search Results */}
                {debouncedQuery.length > 0 && (
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "white",
                      border: "1px solid #f2f2f2",
                      padding: "10px",
                      boxShadow: "0px 10px 10px #888",
                      marginBottom: "10px",
                      height: "auto", // Adjusts based on content
                    }}
                  >
                    <div>
                      {combinationSearchData.length > 0 && (
                        <div className="flex justify-between items-center">
                          <p
                            className="p-2 round-10"
                            style={{
                              background: "#60E1CB",
                              color: "white",
                              marginBottom: "1rem",
                              width: "fit-content",
                            }}
                          >
                            Please select a combination
                          </p>
                          <button
                            onClick={() => {
                              setSearchQuery("");
                              toggleSelectedCombination((prev) => ({
                                combination_title: "",
                                combination_id: "",
                                combination_items: [],
                                ticket_id: prev.ticket_id,
                                inventories: []
                              }));
                            }}
                          >
                            <RxCross1 color="red" size="1.3rem" />
                          </button>
                        </div>
                      )}
                      {combinationSearchData?.map((item, index) => (
                        <button
                          key={index}
                          onClick={() => {
                            setSearchQuery("");
                            handleCombinationChange(item.id);
                          }}
                          className="border p-2 round-10 text-md"
                          style={{ marginRight: '10px', marginBottom: '10px' }}
                        >
                          {item.title}
                        </button>
                      ))}
                      {combinationSearchData.length <= 0 && (
                        <p className="text-center">No search result found</p>
                      )}
                    </div>
                  </div>
                )}

                {/* Show Create Challan button only when no order is created and search results are empty */}
                {/* {!orderCreated && debouncedQuery.length > 0 && combinationSearchData.length === 0 && (
          <div className="upload-button-wrapper" style={{ marginTop: '5px' }}>
            <button
              className="btn-md btn-active-attribute"
              onClick={handlePlaceOrder}
            >
              Create Challan
            </button>
          </div>
        )} */}
              </div>
            </>
          )}
        </div>}


        {checkActionExists(execution, showAttachmnet) && <div className="upload my-3">
          <div className="d-flex justify-content-between">
            <h4
              style={{
                margin: "5px 0",
              }}
            >
              Attachments
            </h4>
          </div>
          <div className="attachment">
          {tickets?.attributes?.attachments?.map((file, index) => {
            // Get the file extension from the URL
            const fileExtension = file.url.split('.').pop().toLowerCase();

            // Check if the file is an image
            const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(fileExtension);

            return (
              <div className="d-flex flex-row py-3 mx-3 attachment-image-wrapper" key={index}>
                {isImage ? (
                  <div onClick={()=>{ImageDisplayer(file?.url)}} >
                    <img src={file.url} width={70} height={70} alt={`Attachment ${index}`} />
                  </div>
                ) : (
                  <div onClick={()=>{ImageDisplayer(file?.url)}}>
                  
                    <img src={pdfImage} style={{ width: '70px', height: '70px' }} alt={`Attachment ${index}`} />
                  
                  </div>
                )}
                <div
                  className="delete-option"
                  onClick={() => imageDeleteHandler(file.id, file.ticket_id)}
                >
                  <ImCross style={{ color: "#60E1CB", fontSize: "14px" }} />
                </div>
              </div>
            );
          })}

          </div>
          <span
            className="px-2 upload-button-wrapper"
            style={{ cursor: "pointer" }}
          >
            <button
              className="btn-md btn-active-attribute"
              style={{backgroundColor:'black'}}
              onClick={() => handleAttachClick(tickets)}
            >
              {" "}
              Upload Here <IoIosAttach />
            </button>
            <input
              id={`file-input-${tickets.id}`}
              type="file"
              multiple
              style={{ display: "none" }}
              onChange={(e) => handleFileSelect(e, tickets)}
            />
          </span>
        </div>}

        {checkActionExists(execution, showComment) && <Comments
          attributes={attributes}
          ticket={tickets}
          setTickets={setTickets}
          comments={comments}
          onAddComment={onAddComment}
          setAllTickets={setAllTickets}
        />}
      </div >
    </Modal >

    </>
  );
};

export default Attribute;
