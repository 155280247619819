import React, { useEffect, useState } from 'react'
import TypeCard from './TypeCard'
import styles from "../../../Animation.module.scss"
import Wrapper from '../../Wrapper'
export default function AnimationType({ onChange, type }) {
    return (
        <Wrapper title={"Animation"}>
            <div className={styles.grid_2}>
                <TypeCard title="None" active={type} value="none" onClick={() => onChange("none")} />
                <TypeCard title="Fade In" active={type} value="fade-in" onClick={() => onChange("fade-in")} />
                <TypeCard title="Fade Out" active={type} value="fade-out" onClick={() => onChange("fade-out")} />
                <TypeCard title="Spin" active={type} value="spin" onClick={() => onChange("spin")} />
                <TypeCard title="Pulse" active={type} value="pulse" onClick={() => onChange("pulse")} />
                <TypeCard title="Bounce" active={type} value="bounce" onClick={() => onChange("bounce")} />
            </div>

        </Wrapper >
    )
}
