import React, { useEffect } from "react";
import {
  CheckBox,
  DragAndDrop,
  LabelledInput,
  LabelledSelect,
  SideModal,
} from "../../../Components";
import "./ManageCategory.scss";
import get from "lodash/get";
import { useState } from "react";
import { actionsCreator } from "../../../Redux/actions/actionsCreator";
import { validate } from "../../../Utils/validations-utils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "lodash";
import { formatOptionsData } from "../../../Utils";

const mapStateToProps = ({ categories = {} }) => ({
  categories,
});

export default function ManageCategory() {
  const {
    categories: { list: categoryList = [] },
  } = useSelector(mapStateToProps);

  const [data, setData] = useState({
    image: {},
    name: "",
    slug: "",
    description: "",
    active: true,
    parent: "",
  });
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const location = useLocation();
  const { state: categoryData = {} } = location || {};
  const {
    image = {},
    name = "",
    slug = "",
    description = "",
    active = true,
    parent = "",
  } = data || {};
  const history = useHistory();
  const categoryId = categoryData ? categoryData.id : {};
  const title = categoryId ? "Update Category" : "Add Category";

  useEffect(() => {
    fetchCatogries();
  }, []);

  const fetchCatogries = () => {
    dispatch(actionsCreator.FETCH_CATEGORIES());
  };

  const addCategory = async () => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    dispatch(actionsCreator.ADD_CATEGORY(formData));
  };

  const updateCategory = async () => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key != "parent" || (key === "parent" && parent))
        formData.append(key, data[key]);
    });
    dispatch(actionsCreator.UPDATE_CATEGORY({ formData, categoryId }));
  };

  const onChange = (e, key, nonEvent) => {
    const value = nonEvent ? e : get(e, "target.value", "");
    const updatedData = { ...data, [key]: value };
    setData(updatedData);
  };

  const onSave = () => {
    if (categoryId) {
      updateCategory();
    } else {
      addCategory();
    }
    history.goBack();
  };

  const validateInput = (value, rules, key) => {
    const { error = false, msg = "" } = validate(value, rules);
    const errorObj = { ...errors, [key]: { error, msg } };
    setErrors(errorObj);
  };

  useEffect(() => {
    fillForm(categoryData);
  }, [categoryData]);

  const fillForm = (categoryData) => {
    if (!isEmpty(categoryData)) {
      const formData = {};
      const idKeys = ["parent"];
      Object.keys(data).forEach((key) => {
        formData[key] = idKeys.includes(key)
          ? get(categoryData, `${key}.id`, categoryData[key])
          : categoryData[key];
      });
      setData(formData);
    }
  };

  const categories = formatOptionsData(categoryList, "name", "id");

  const categoriesData = categories.filter((e) => e.id !== categoryId);

  return (
    <SideModal title={title} onSave={onSave}>
      <div>
        <DragAndDrop
          className={"SmallInput"}
          label="Photo"
          value={image}
          onChange={(e) => {
            onChange(e, "image", true);
            validateInput(e, ["isRequired"], "image");
          }}
          error={errors["image"]}
        />
        <LabelledInput
          className={"SmallInput"}
          label="Category Name"
          placeholder="Enter Category Name here..."
          type="text"
          value={name}
          onChange={(e) => {
            onChange(e, "name");
            validateInput(e.target.value, ["isRequired"], "name");
          }}
          error={errors["name"]}
        />

        <LabelledInput
          className={"SmallInput"}
          label="Description"
          placeholder="Enter Description here..."
          type="text"
          value={description}
          onChange={(e) => {
            onChange(e, "description");
            validateInput(e.target.value, ["isRequired"], "description");
          }}
          error={errors["description"]}
        />
        <LabelledInput
          className={"SmallInput"}
          label="Slug"
          placeholder="Enter Slug here..."
          type="text"
          value={slug}
          onChange={(e) => {
            onChange(e, "slug");
            validateInput(e.target.value, ["isRequired"], "slug");
          }}
          error={errors["slug"]}
        />
        <LabelledSelect
          className={"SmallInput"}
          label={"Parent Category"}
          data={categoriesData}
          onSelect={(e) => {
            onChange(e, "parent", true);
          }}
          multiSelect={false}
          placeholder={"Select Parent"}
          values={parent}
        />
        <CheckBox
          label={"Active"}
          value={active}
          onChange={(e) => onChange(e, "active", true)}
        />
      </div>
    </SideModal>
  );
}
