import React from 'react'
import "./Benefits.scss"
// import "../../../Assets/Icons/v3-sample-svg.svg"
import Benefit1 from "../../../Assets/Icons/home/benefit-1.svg"
import Benefit2 from "../../../Assets/Icons/home/benefit-2.svg"
import Benefit3 from "../../../Assets/Icons/home/benefit-3.svg"
import Benefit4 from "../../../Assets/Icons/home/benefit-4.svg"
import Benefit5 from "../../../Assets/Icons/home/benefit-5.svg"
import Benefit6 from "../../../Assets/Icons/home/benefit-6.svg"

const BenefitsCard = ({ data }) => {
    return <>
        <div className='flex items-start flex-1 gap-05rem flex-col' >
            <img src={data?.img} alt="" className='home-v3-benefits-card-image' />
            <h1 className='home-v3-benefits-card-h1 font-bold'>{data?.title}</h1>
            <p className='home-v3-benefits-card-p'> {data?.desc}</p>
        </div>
    </>
}



export default function Benefits() {
    const benefits = [
        {
            img: Benefit1,
            title: "Unlimited Products",
            desc: "With Appsketch, you have the freedom to add as many products as you wish to your online store. No restrictions mean you can stock and showcase the diverse range of products your customers are looking for."
        },
        {
            img: Benefit2,
            title: "POS Integration",
            desc: "Appsketch allows you to effortlessly integrate both your online and offline sales with a robust POS system. Sell your products seamlessly, wherever your customers choose to shop."
        },
        {
            img: Benefit3,
            title: "Enhanced Security",
            desc: "Appsketch provides comprehensive fraud analysis and ensures a secure website across all domains. Our platform is also Level 1 PCI DSS compliant, offering maximum security for all financial transactions."
        },
        {
            img: Benefit4,
            title: "User-Friendly Design",
            desc: "Appsketch enables you to create a website with simple drag-and-drop WYSIWYG tools. Even without coding skills, anyone can build a professional-looking site using Appsketch’s intuitive interface."
        },
        {
            img: Benefit5,
            title: "Full Customization",
            desc: "Appsketch provides you with full control to personalize your website and reflect your brand’s identity. Customize colors, fonts, images, and choose responsive designs that make your site truly yours."
        },
        {
            img: Benefit6,
            title: "Free Tools and Apps",
            desc: "Appsketch offers a variety of free business and marketing tools, product sourcing apps, and other essential features to help you build a high-performing and successful website."
        },
    ]
    
    return (
        <div className='home-v3-benefits  flex items-center gap-175rem flex-col'>
            <h1 className='home-v3-benefits-h1 font-bold' style={{ marginRight: 'auto' }}> Your Benefits</h1>
            <div className='home-v3-benefit-grid'>
                {
                    benefits.map((ele, index) => <BenefitsCard data={ele} key={`benefit-card-${index}`} />)
                }
            </div>
        </div>
    )
}
