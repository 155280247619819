import React, { useEffect, useState } from 'react'
import styles from "../../Design.module.scss"
import { useCustomizer } from '../../../../../../Provider/customizerProvider';
import { FaAlignJustify, FaAlignLeft, FaAlignRight, FaAlignCenter } from "react-icons/fa";
import { BsDash } from 'react-icons/bs';
export default function Alignment() {
    const { onChange, currentStyles } = useCustomizer();
    const [align, setAlign] = useState(currentStyles['textAlign'])

    useEffect(() => { onChange({ "text-align": align }) }, [align])

    useEffect(() => { setAlign(currentStyles['textAlign']) }, [currentStyles])

    return (
        <div className={styles.itemWrapper} >
            <div className="flex justify-between items-center relative">
                <h4 className={styles.textLabel}>Alignment</h4>
                <div className={`flex items-center gap-05rem`}>
                    < BsDash onClick={() => setAlign('none')} className={`${styles.Icon} ${align === 'none' ? styles.activeIcon : ""}`} />

                    <FaAlignJustify onClick={() => setAlign('justify')} className={`${styles.Icon} ${align === 'justify' ? styles.activeIcon : ""}`} />

                    <FaAlignLeft onClick={() => setAlign('left')} className={`${styles.Icon} ${align === 'left' ? styles.activeIcon : ""}`} />

                    <FaAlignRight onClick={() => setAlign('right')} className={`${styles.Icon} ${align === 'right' ? styles.activeIcon : ""}`} />

                    <FaAlignCenter onClick={() => setAlign('center')} className={`${styles.Icon} ${align === 'center' ? styles.activeIcon : ""}`} />


                </div>

            </div>
        </div>
    )
}
