import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Table } from "../../Components";
import { stockTableColumns } from "../../Utils/table-utils";
import "./Stock.scss";
import { actionsCreator } from "../../Redux/actions/actionsCreator";

const mapStateToProps = ({ stocks = {} }) => ({
  stocks,
});
export default function Stock() {
  const {
    stocks: { list: stockList = [] },
  } = useSelector(mapStateToProps);
  const history = useHistory();
  const columns = stockTableColumns;
  const dispatch = useDispatch();

  useEffect(() => {
    fetchStocks();
  }, []);

  const fetchStocks = () => {
    dispatch(actionsCreator.FETCH_STOCKS());
  };

  const addStock = () => {
    history.push(`/dashboard/stocks/add`);
  };

  return (
    <div className="Stocks">
      <div className="Stocks__Actions">
        <Button
          text={"Add Stock"}
          className={"Black__Button"}
          onClick={addStock}
        />
      </div>
      <Table columns={columns} data={stockList || []} height="100%" />
    </div>
  );
}
