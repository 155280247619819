import get from "lodash/get";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { debounce } from "./debounce-utils";


export const storeToken = (token) => {
  localStorage.setItem("auth_token", token);
};

export const getToken = () => {
  let token = localStorage.getItem("auth_token");
  return token;
};
export const getPhoneNumber=(props)=>{
  let phone_number = localStorage.getItem("phone_number");
  return phone_number

}
export const findError = (key, errors, showErr) => {
  if (showErr) {
    if (errors && errors[key] && errors[key]['error']) {
      let err = errors[key]['msg'];
      return err;
    }
  }
  return '';
}
export const updateCartDetailsLocalStorage = (data)=>{
  const {modifiedCartItems, final_price, final_item  } = data
  localStorage.setItem("cartItem", JSON.stringify(modifiedCartItems))
  localStorage.setItem("final_price",final_price)
  localStorage.setItem("final_item", final_item)
}
export const storePhoneNumber=(props)=>{
  let temp = localStorage.setItem('phone_number', props)
}
export const findFavouriteItem = (id, products) => {
  let item = products?.find((element) => {
    const product_id = get(element, "product_id.id");
    return id === product_id;
  });
  if (item) {
    return true;
  }
  return false;
};
//Function to CheckPermission for the components
//Note-->Not to use Inside condiotional Rendering
 export const CheckPermissionFunc = (prop) => {
  const mapStateToProps = ({ permissionProduct = {} }) => ({
    permissionProduct,
  });
  const {
    permissionProduct: { list: PermissionList = [] },
  } = useSelector(mapStateToProps);
  function extractArrays(obj) {
    let result = []
    for (let key in obj) {
        if (Array.isArray(obj[key])) {
            result = result.concat(obj[key])
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            result = result.concat(extractArrays(obj[key])); 
        }
    }

    return result
}
const mergedArray = extractArrays(PermissionList)
    .filter((value, index, self) =>        
        index === self.findIndex((item) => item.name === value.name)
    )

  if(PermissionList?.role==='ADMIN'){
    return true
  }
  if (Array.isArray(mergedArray) ) {
    return mergedArray.some(item => item.name === prop);
  }
  return false 
};

export const errorMsg = (error) => {
 
  if (error && error["response"]) {
    const msg =
      get(error, "response.data.msg", "") ||
      get(error, "response.data.message", "");
    return msg;
  }
  return "Something went wrong!";
};

export const formatOptions = (options = [], key, val, act) => {
  let formattedOptions = [];
  formattedOptions = options.map((element) => {
    let name = get(element, key, "");
    let value = get(element, val, "");
    let active = get(element, act, "");
    return {
      label: name,
      value: value,
      active: active,
    };
  });
  return formattedOptions;
};

export function isNewLine(str, char = "\n") {
  return str.indexOf(char) > -1;
}
export const removeNewLine = (str) => {
  return str.replace("\n", "");
};

export const findLabel = (options = [], value) => {
  let option = options.find((element) => {
    return element.value === value;
  });
  return option ? option.label : "";
};

export const formatName = (name) => {
  let formattedName = "";
  if (name) {
    let nameString = name.split(" ");
    if (nameString && nameString.length > 0) {
      formattedName = nameString[0];
    }
  }
  return formattedName ? formattedName : "User";
};

export const formatOptionsData = (options, nameKey, valueKey) => {
  const formattedOptions = options.map((element) => {
    let name = get(element, nameKey, "");
    let value = get(element, valueKey, "");
    return {
      label: name,
      value: value,
      data: element,
    };
  });
  return formattedOptions;
};

export const validateFormData = (errors) => {
  const isInvalid = Object.keys(errors).some((key) => {
    return (
      errors[key] && errors[key] && errors[key]["error"] && errors[key]["msg"]
    );
  });
  return isInvalid ? isInvalid : false;
};
export const dateExtractor = (value, type) => {
  let ans;
  let temp;
  let time;
  if (type === "24h") {
    ans = value.slice(11, 19);
    temp = value.slice(11, 13);
    temp = parseInt(temp);
    if (temp > 12) {
      temp = temp - 12;
      time = "pm";
    } else if (temp == 0) {
      temp = 12;
      time = "am";
    } else {
      time = "am";
    }
    temp =
      temp +
      value.slice(13, 16) +
      " " +
      time +
      ", " +
      value.slice(5, 7) +
      "/" +
      value.slice(8, 10) +
      "/" +
      value.slice(0, 4);
    ans = temp;
  } else
    ans =
      value.slice(5, 7) +
      "/" +
      value.slice(8, 10) +
      "/" +
      value.slice(0, 4) +
      " " +
      value.slice(11, 19);
  return ans;
};

const delay = (milliseconds) => {
  return new Promise(resolve => {
      setTimeout(() => {
          resolve('Promise resolved after ' + milliseconds + 'ms');
      }, milliseconds);
  });
};
export const fetchQuantity = (id, items = []) => {
  let item = items.find((p) => {
    return p.id === id;
  });
  if (item) {
    return item.quantity;
  }
  return 0;
};
export function useOutsideAlerter(ref, setshowmodal) {
  useEffect(() => {
      function handleClickOutside(event) {
          console.log(ref)
          if (ref.current && !ref.current.contains(event.target)) {
            setshowmodal(false)
              // setoverlayOpen(false);
              // setsearchResults([]);
              // setshowSearchResults(false);
              // setSearchQuery("")
          }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
          document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [ref]);
}
export const modifyCartData = (cartitem, product, action) => {
  console.log(cartitem, product, action)
  const { id, category, title, image, price, description } = product || {};
  let modefiedCartItems = [...cartitem];
  let itemIndex = modefiedCartItems.findIndex((item) => {
    return item.id === id;
  });
  if (itemIndex >= 0) {
    let item = modefiedCartItems[itemIndex];
    if (action === "decrement") {
      if (item.quantity <= 1) {
        modefiedCartItems.splice(itemIndex, 1);
      } else {
        item["quantity"] = item["quantity"] - 1;
      }
    } else {
      item["quantity"] = item["quantity"] + 1;
    }
  } else {
    if (action !== "decrement") {
      modefiedCartItems.push({
        quantity: 1,
        id: id,
        product_name: title,
        original_price: price,
        photo: image,
        category: category,
        description: description,
      });
    }
  }
  return modefiedCartItems;
};



export const fetchData = ( data = [] , page ) => {
  const size = 18;
  const findex = page * size;
  const lindex = findex + size;

  let item;
  if(lindex > data.length){
    item = data?.slice(findex);
  }else{
    item = data?.slice(findex, lindex);
  }


  return item;
}
export const coordinateDistanceFinder=(lat1, lon1, lat2, lon2)=>{

  function toRadians(degrees) {
    return degrees * (Math.PI / 180);
  }
    const earthRadius = 6371; 
  
    const lat1Rad = toRadians(lat1);
    const lon1Rad = toRadians(lon1);
    const lat2Rad = toRadians(lat2);
    const lon2Rad = toRadians(lon2);
  
    const latDiff = lat2Rad - lat1Rad;
    const lonDiff = lon2Rad - lon1Rad;
  
    const a =
      Math.sin(latDiff / 2) ** 2 +
      Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(lonDiff / 2) ** 2;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
    const distance = earthRadius * c;
  
    return distance; 
}
export  function getCoordinates(string){
  const lat_start = string.indexOf("lat");
  const lat_end = string.indexOf("long");
  const long_end = string.indexOf("link")
  try{
    const latitude = parseFloat(string.substring(lat_start+5,lat_end-2));
  const longitude = parseFloat(string.substring(lat_end+6,long_end-2));
  return [latitude,longitude]

  }
  catch(err){
    console.error(err.message)
    return null
  }
  
}
export const extractFinalPrice = (items) => {
  let final_price = 0;
  let final_item = 0;
  items.forEach((item) => {
    final_price += Number(item.original_price) * Number(item.quantity);
    final_item += Number(item.quantity);
  });
  return { final_price, final_item };
};
export const sortItems = (items) => {
  const formattedItems = items.sort(compare);
  return formattedItems;
};
const compare = (a, b) => {
  if (a.id < b.id) {
    return -1;
  }
  if (a.id > b.id) {
    return 1;
  }
  return 0;
};
