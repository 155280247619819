import React, { useState } from 'react';
import "./AddStock.scss";
import { add_Entity } from '../../../Api/cmsAPI';
import { toast } from 'react-toastify';

const AddEntityModal = ({ setOpenAddEntityModal, cms_config }) => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    is_active: false,
    gstin: "",
    dl_no: "",
    cin: "",
    address: "",
    phone_number: "",
  });

  const handleInputChange = (name, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = { ...formData }; // Using formData directly
      await add_Entity(payload);
      setOpenAddEntityModal(false);
      toast.success("New Entity Added Successfully");
    } catch (err) {
      console.error(err.message);
      toast.error("Error while Adding New Entity");
    }
  };

  return (
    <>
      <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>
        {cms_config?.purchase_config?.entity_config_modal?.heading}
      </h3>
      <form onSubmit={handleSubmit} className="space-y-4 add-entity-form">
        {Object.values(cms_config?.purchase_config?.entity_config_modal).map((inputProps, index) => {
          if (inputProps?.component === "LabelledInput") {
            return (
              <div key={index}>
                <label>{inputProps?.label}:</label>
                <input
                  type={inputProps?.type}
                  value={formData[inputProps?.name]} // Access state from formData object
                  onChange={(e) =>
                    handleInputChange(inputProps?.name, e.target.value)
                  }
                  className={inputProps?.className}
                  required={inputProps?.required}
                />
              </div>
            );
          } else if (inputProps?.component === "LabelledTextarea") {
            return (
              <div key={index}>
                <label>{inputProps?.label}:</label>
                <textarea
                  value={formData[inputProps?.name]} // Access state from formData object
                  onChange={(e) =>
                    handleInputChange(inputProps?.name, e.target.value)
                  }
                  className={inputProps?.className}
                  required={inputProps?.required}
                />
              </div>
            );
          } else if (inputProps?.component === "LabelledCheckbox") {
            return (
              <div key={index} style={{ width: '180px', marginLeft: "80px" }}>
                <label>{inputProps?.label}:</label>
                <input
                  type="checkbox"
                  checked={formData[inputProps?.name]} // Access state from formData object
                  onChange={(e) =>
                    handleInputChange(inputProps?.name, e.target.checked)
                  }
                  style={{ width: "fitContent", height: "20px", cursor: "pointer" }}
                  required={inputProps?.required}
                />
                <span>{formData[inputProps?.name] ? 'Yes' : 'No'}</span>
              </div>
            );
          }

        })}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <button type="submit" className="form-button">
            Add
          </button>
        </div>
      </form>
    </>
  );
};



export default AddEntityModal;
