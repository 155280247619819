import React, { useState } from 'react';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { motion } from "framer-motion";
import { useDispatch, useSelector } from 'react-redux';
import { actionsCreator } from '../../../Redux/actions/actionsCreator';
import chevronDown from "../../../Assets/Icons/builder/chevronDown.svg";
import chevronRight from "../../../Assets/Icons/builder/chevronRight.svg";
import dragIcon from "../../../Assets/Icons/builder/drag.svg";
import deleteIcon from "../../../Assets/Icons/builder/sectionDelete.svg";
import Tooltip from '../../TooltipV2';

const DraggableSection = ({ section, idx, moveSection, selectedSectionIdx, setSelectedComponentType, childSections, setSelectedSectionIdx }) => {
    const [expandOption, setExpandOption] = useState(false);
    const [expandedSections, setExpandedSections] = useState({});
    const dispatch = useDispatch();
    const config = useSelector(state => state.sectionsList.config);
    const { currConfig } = useSelector(state => state.sectionsList);
    const currPage = useSelector(state => state.sectionsList.currPage);

    const [{ isDragging }, dragRef] = useDrag({
        type: 'SECTION',
        item: { idx },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, dropRef] = useDrop({
        accept: 'SECTION',
        hover: (draggedItem) => {
            if (draggedItem.idx !== idx) {
                moveSection(draggedItem.idx, idx);
                draggedItem.idx = idx;
            }
        },
    });

    const handleDeleteSection = (e) => {
        e.stopPropagation();
        const updatedConfig = { ...config };
        const updatedSections = [...updatedConfig?.[currConfig]?.sections];
        updatedSections.splice(idx, 1);
        updatedConfig[currConfig].sections = [...updatedSections];
        dispatch(
            actionsCreator.SET_SECTIONS_LIST({
                config: updatedConfig,
            })
        )
    }
    const handleExpansion = (e) => {
        e.stopPropagation();
        setExpandOption(prev => !prev);
    };
    const handleTargetSection = (sectionId) => {
        dispatch(actionsCreator.SET_SECTIONS_LIST({
            selectedElementId: sectionId,
            showRightSidebar: true,
            selectedSection: section,
        }))
        // console.log(selectedElementId)
        const targetElement = document.getElementById(sectionId);
        if (targetElement) {
            targetElement.click();
        } else {
            console.error(`Element with ID ${sectionId} not found`);
        }
    };

    const toggleSectionExpansion = (id) => {
        setExpandedSections((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    // Recursive function to render nested sections
    const renderChildSections = (section) => {
        const isExpanded = expandedSections[section?.id] || false;
        return (
            <div key={section.id} style={{ marginLeft: '15px' }}>
                <div
                    style={{ display: "flex", justifyContent: 'space-between', alignItems: 'center', width: "100%", padding: "5px 10px", fontSize: "11px" }}
                    className={`draggableSection`}
                >
                    <div style={{ display: 'flex', alignItems: 'center', gap: '5px', fontWeight: "700", fontSize: '11px', }}
                    >
                        {section && section?.children?.length > 0 ?
                            <button
                                style={{ translate: '0 -2px', outline: 'none' }}
                                onClick={() => toggleSectionExpansion(section.id)}
                            >
                                {!isExpanded ?
                                    <svg fill="gray" width="15px" height="15px" viewBox="0 0 24.00 24.00" xmlns="http://www.w3.org/2000/svg" stroke="#404040" stroke-width="2.4"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M9.64,6.231A1,1,0,0,0,8,7V17a1,1,0,0,0,.576.905A.989.989,0,0,0,9,18a1,1,0,0,0,.64-.231l6-5a1,1,0,0,0,0-1.538ZM10,14.865V9.135L13.438,12Z"></path></g></svg>
                                    :
                                    <svg fill="#000000" width="15px" height="15px" viewBox="0 0 24.00 24.00" xmlns="http://www.w3.org/2000/svg" stroke="#404040" stroke-width="2.4" transform="rotate(90)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path d="M9.64,6.231A1,1,0,0,0,8,7V17a1,1,0,0,0,.576.905A.989.989,0,0,0,9,18a1,1,0,0,0,.64-.231l6-5a1,1,0,0,0,0-1.538ZM10,14.865V9.135L13.438,12Z"></path></g></svg>
                                }
                            </button>
                            :
                            <svg width="15px" height="13px" viewBox="-1.6 -1.6 19.20 19.20" fill="#404040" xmlns="http://www.w3.org/2000/svg" stroke="#404040" stroke-width="0.00016" style={{ translate: "1px 1px" }}
                            ><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#404040" stroke-width="0.576"></g><g id="SVGRepo_iconCarrier"> <rect x="1" y="1" width="10" height="10" fill="#404040"></rect> </g></svg>
                        }
                        <span onClick={() => handleTargetSection(section?.id)}>{section?.type?.toUpperCase()}</span>
                    </div>
                </div>
                {
                    isExpanded && section?.children?.length > 0 && (
                        <motion.div
                            className={'sectionsContainer'}
                            initial={{ opacity: 0, y: '-10%', scaleY: 0.8 }}
                            animate={{ opacity: 1, y: 0, scaleY: 1 }}
                            exit={{ opacity: 0, y: '-10%', scaleY: 0.8 }}
                            transition={{
                                type: 'spring',
                                stiffness: 300,
                                damping: 25,
                                duration: 0.5,
                            }}
                        >
                            {section.children.map(child => renderChildSections(child))}
                        </motion.div>
                    )
                }
            </div >
        );
    };

    return (
        <div>
            <div
                id={'mainOption'}
                className={`${'draggableSection'} ${isDragging ? 'dragging' : ''} ${selectedSectionIdx === idx ? 'selectedSectionOption' : ''}`}
                onClick={() => {
                    setSelectedComponentType(section?.type);
                    setSelectedSectionIdx(idx);
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                    {
                        <button
                            style={{ translate: '0 -2px', outline: 'none' }}
                            onClick={handleExpansion}
                        >
                            {!expandOption ?
                                <Tooltip content={"Expand"} position='right'>
                                    <img src={chevronRight} height={15} />
                                </Tooltip>
                                :
                                <Tooltip content={"Collapse"} position='right'>
                                    <img src={chevronDown} height={15} />
                                </Tooltip>
                            }
                        </button>
                    }
                    <span>{section?.type?.toUpperCase()}</span>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: "15px" }}>
                    <button
                        className={'dragButton'}
                        ref={(node) => dragRef(dropRef(node))}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                    >
                        <Tooltip content={'Drag Section'} position='top'>
                            <img src={dragIcon} />
                        </Tooltip>
                    </button>
                    <button
                        className='deleteButton'
                        onClick={handleDeleteSection}
                    >
                        <Tooltip content={'Delete Section'} position='left'>
                            <img src={deleteIcon} height={18} />
                        </Tooltip>
                    </button>
                </div>
            </div>
            {expandOption && childSections && childSections?.children?.length > 0 && (
                <motion.div
                    className={'sectionsContainer'}
                    initial={{ opacity: 0, y: '-10%', scaleY: 0.8 }}
                    animate={{ opacity: 1, y: 0, scaleY: 1 }}
                    exit={{ opacity: 0, y: '-10%', scaleY: 0.8 }}
                    transition={{
                        type: 'spring',
                        stiffness: 300,
                        damping: 25,
                        duration: 0.5,
                    }}
                >
                    {childSections?.children?.map((child) => renderChildSections(child))}
                </motion.div>
            )}
        </div>
    );
};

export default DraggableSection;
