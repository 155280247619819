import {
  SET_CATEGORIES,
  SET_PRODUCTS,
  SET_INVENTORIES,
  SET_STOCKS,
  SET_SELLABLE_INVENTORIES,
  SET_UNITS,
  SET_ORDERS,
} from "../actions/actionTypes";

const productsInitialState = {
  list: [],
  loading : true,
};

export const productsReducer = (
  state = productsInitialState,
  { type, payload }
) => {
  switch (type) {
    case SET_PRODUCTS: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

const categoryInitialState = {
  list: [],
  loading : true,
};

export const categoriesReducer = (
  state = categoryInitialState,
  { type, payload }
) => {
  switch (type) {
    case SET_CATEGORIES: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

const inventoryInitialState = {
  list: [],
  loading : true,
};

export const inventoriesReducer = (
  state = inventoryInitialState,
  { type, payload }
) => {
  switch (type) {
    case SET_INVENTORIES: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};
const stockInitialState = {
  list: [],
};

export const stocksReducer = (state = stockInitialState, { type, payload }) => {
  switch (type) {
    case SET_STOCKS: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};
const ordersInitialState = {
  list: [],
};

export const ordersReducer = (
  state = ordersInitialState,
  { type, payload }
) => {
  switch (type) {
    case SET_ORDERS: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

const sellableInventoryInitialState = {
  list: [],
};

export const sellableInventoriesReducer = (
  state = sellableInventoryInitialState,
  { type, payload }
) => {
  switch (type) {
    case SET_SELLABLE_INVENTORIES: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};

const unitsInitialState = {
  list: [],
};

export const unitsReducer = (state = unitsInitialState, { type, payload }) => {
  switch (type) {
    case SET_UNITS: {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
};
