import React, { useEffect, useRef, useState } from "react";
import "./Banner.scss";
import backImg from "../../../Assets/Images/banner/background.svg";
import playStoreIcon from "../../../Assets/Images/playstore.png";
import appStoreIcon from "../../../Assets/Images/appstore.png";
import deliveryManImg from "../../../Assets/Images/banner/banner_girl.png";
import chocolateCakeImg from "../../../Assets/Images/banner/banner-choclate-image.png";
import Button from "../Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { actionsCreator } from "../../..//Redux/actions/actionsCreator";
import discountIMG from "../../../Assets/Images/banner/discountIMG.png";
import blob1 from "../../../Assets/Images/banner/blob1.svg";
import blob2 from "../../../Assets/Images/banner/blob2.svg";
import useWindowDimensions from "../../../Hooks/useWindowDimensions";

import leftArrow from "../../../Assets/Images/banner/arrow-left.svg";
import rightArrow from "../../../Assets/Images/banner/arrow-right.svg";

import cakeSliderPic1 from "../../../Assets/Images/banner/cake-slider-picture-1.png";
import cakeSliderPic2 from "../../../Assets/Images/banner/cake-slider-picture-2.png";
import cakeSliderPic3 from "../../../Assets/Images/banner/cake-slider-picture-3.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { productAPI } from "../../../Api";
import { itemCount } from "../../../../api/request.api";

const mapStateToProps = ({ isLoggedIn, auth, categories }) => ({
  isLoggedIn,
  auth,
  categories,
});

const Banner = (props) => {
  const [templateCategories, setTemplateCategories] = useState([]);
  const dispatch = useDispatch();

  useEffect(async () => {
    const res = await productAPI.fetchCategories();
    setTemplateCategories(res.data);
    dispatch(actionsCreator.FETCH_CATEGORIES());
  }, []);

  const {
    categories: { list: categoryList },
  } = useSelector(mapStateToProps);
  const { height, width } = useWindowDimensions();
  const { auth } = useSelector(mapStateToProps);
  const { showLoginPopup, isLoggedIn } = auth;
  const [currentValue, setCurrentValue] = useState(0);
  const history = useHistory();

  const [categoryDetails, setCategoryDetails] = useState({
    name: categoryList[0]?.name || "Category Name",
    desc:
      categoryList[0]?.desc ||
      `Here's our best creations that everyone loves. Lightness and
    sweetness of the cake make you want more and more. Start
    from cake, bread and other creations.`,
  });

  const selectCategory = (name, desc) => {
    setCategoryDetails((prev) => ({ ...prev, ["name"]: name, ["desc"]: desc }));
    history.push(`/categories/${name}`);
  };

  const loginStateSetter = () => {
    dispatch(actionsCreator.SHOW_LOGIN());
  };

  const [max, setMax] = useState(categoryList.length);
  const sliderRef = useRef(null);

  const handleSlider = (direction) => {
    const scrollValue = 200;

    if (sliderRef.current) {
      const scrollWidth = sliderRef.current.scrollWidth;

      if (direction > 0) {
        if (currentValue < categoryList.length) {
          setCurrentValue(currentValue + 1);
        }
        const newScrollX = sliderRef.current.scrollLeft + scrollValue;
        if (scrollWidth >= newScrollX) {
          sliderRef.current.scrollLeft = newScrollX;
        }
      } else {
        if (currentValue > 0) {
          setCurrentValue(currentValue - 1);
        }
        const newScrollX = sliderRef.current.scrollLeft - scrollValue;

        if (newScrollX >= 0) {
          sliderRef.current.scrollLeft = newScrollX;
        } else {
          sliderRef.current.scrollLeft = 0;
        }
      }
    }
  };
  return (
    <div className="banner-giga-wrapper">
      {/* <div className="banner-giga-wrapper-overlay" /> */}
      <div className="flex flex-row w-full h-full">
        {/* <img src={blob1} alt="" className="blob1" /> */}
        {/* <img src={blob2} alt="" className="blob2" /> */}
        <div className="banner-text flex-1">
          <h2 className="banner-head">
            {'Bring you Happiness through a piece of cake'}
          </h2>
          <p className="text-lg">
            {'Spoon up a luscious pastry or order for a signature chocolate cake, We Are there to make all your occasions special with a specially curated cakes Your occassions our preparations… all you do is KEEP CALM & EAt CAKE!'}
          </p>
          <br />

          <div className="button-container">
            {/* {!props.showButton && ( */}
              <Button
                text="Order Now"
                bgcolor="#4a1d1e"
                textcolor={"#FBEDCD"}
                border={"none"}
                className="btn-md"
              />
            {/* )} */}
          </div>

          {/* <div className="install-image"> */}
          {/* <img src={playStoreIcon} alt="" /> */}
          {/* <img src={appStoreIcon} alt="" /> */}
          {/* </div> */}
        </div>

        <div className="flex-1 block-lg-only">
          <img
            src={chocolateCakeImg}
            className=""
          />
        </div>
      </div>

      {/* slide to be included */}

      {true && (
        <div>
          <div className="category-name-desc">
            <p className="category-name">Categories</p>
          </div>
          <div className="product-info-slider-container">
            <div className="product-info-slider">
              <div className="product-slider-button">
                <button onClick={() => handleSlider(1)}>
                  <img src={leftArrow} />
                </button>
                <span>
                  {currentValue + 1}/{categoryList.length}
                </span>
                <button onClick={() => handleSlider(-1)}>
                  <img src={rightArrow} />
                </button>
              </div>

              <div 
                ref={sliderRef} 
                style={{
                  width : '100%',
                  whiteSpace : 'nowrap',
                  overflowX : 'auto',
                  overflowY : 'hidden'
                }}
              className="">
                {
                  [1,2,3].map((category, index) => (
                    <div
                      // onClick={() =>
                      //   selectCategory(category.name, category.description)
                      // }
                      style={{
                        height : '12rem',
                        aspectRatio : '1',
                        overflow : 'hidden',
                        display : 'inline-flex',
                        flexDirection : 'column',
                        gap : '5px', 
                        padding : '10px 0',
                        marginRight : '1%',
                        cursor : 'pointer'
                      }}
                    >
                      <img
                        src={cakeSliderPic1}
                        style={{
                          objectFit: "contain", 
                          height : '8rem',
                          aspectRatio : '1',
                          border : '1px solid #f2f2f2',
                          borderRadius : '5px',
                        }}
                      />
                      <span
                        style={{
                          overflow : 'hidden',
                          textOverflow : 'ellipsis',
                          textAlign : 'center'
                        }}
                      >
                        {`Category ${index+1}`}
                      </span>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <div className="seller-information">
        <p className="head">Try Our Best Selling</p>
        <span className="seller-information-bar"></span>
        <p className="subhead">Here’s our best creations that everyone loves. Lightness and<br />
          sweetness of the cake make you want more and more. Start<br />
          from cake, bread and other creations.</p>
      </div> */}

      {/* {!isLoggedIn && (
        <div className="banner-discount">
          <img src={discountIMG} />
          <div className="banner-discount-text">
            <div>
              <h3>Want it, You got it!</h3>
              <p>
                Get Flat <span>50%</span> Discount Upto <span>₹100</span> on
                Your First Order!
              </p>
              <div className="hide_in_normal_view button_container">
                <Button text="Shop Now" clicker={loginstatesetter} />
              </div>
            </div>
          </div>
          <div className="hide_button_in_mobile button_container">
            <Button text="Shop Now" clicker={loginstatesetter} />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Banner;

Banner.defaultProps = {};
